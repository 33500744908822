// UseCaseForm.jsx

import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { UserInformation } from '../auth_mgmt/auth_handling'; // CurrentUser

import JourneyBar from './uc_journey';

import Chat from '../guide/chat';
import { useChat } from '../guide/chat_utils';
import ChatModal from '../guide/chat_modal';

import Info from './uc_info';
//import Meta from './uc_meta';
import Actors from './uc_actors';
import Action from './uc_action';
import Components from './uc_component';
import Model from './uc_model';

import { useCaseDataHookTrack, initialNodes, initialEdges } from '../create_handling/use_case_data'; // Former structure useCaseDataHook

// Read and write ontology data
import { fetchOntologyData } from '../db_mgmt/db_handling_ontologies/db_ontology_read';

//import { comp_semantic_enrichment_peamonto_enhanced, comp_semantic_enrichment_baseline_enhanced } from '../knowledge_handling/knwl_enrichment_structure';
import { PEAMONTOontologyURL } from '../onto_handling/onto_config';

import { OWLFileParser } from '../onto_handling/onto_parse';
import { buildSidebarItems } from '../onto_tree_handling/parse_items';

import { fetchUseCaseData } from '../db_mgmt/db_handling_use_cases/read';
import { saveUseCaseData, updateUseCaseData } from '../db_mgmt/db_handling_use_cases/write';

import ModelModalSave from '../model_handling/model_modal_save';
import ModelModalEdit from '../model_handling/model_modal_edit';
import ModelModalExecuteSave from '../model_handling/model_modal_execute_save';

import LoadingModal from '../elems/elem_load_prompt_gen';
import { getCurrentDateTime } from '../utils/utils_date';

// Study modules
import { useParticipantDataHookTrack } from '../study_handling/study_data_states/study_state_participant';
import StudyProgressSidebar from '../study_handling/study_control_bar';
import StudyGuide from '../study_handling/study_guide';
import { Survey } from '../../views/Survey';
import { Interview } from '../../views/Interview';
import { saveParticipantData } from '../db_mgmt/db_handling_participants/db_participant_write';
import { StudyModalComplete } from '../study_handling/study_modal_complete';

// Extract the scenario name and description from the response
const extractScenario = (response) => {
  // Check if "Scenario:" and "Description:" are present in the response
  const hasScenario = response.includes('Scenario:');
  const hasDescription = response.includes('Description:');

  // If both "Scenario:" and "Description:" are present
  if (hasScenario && hasDescription) {
      // Split the response at "Description:"
      const [scenarioPart, descriptionPart] = response.split('Description:');

      // Extracting scenario name and description
      const scenarioName = scenarioPart.split('Scenario:')[1].trim();
      const description = descriptionPart.trim();

      return [scenarioName, description];
  } else {
      // Return an empty array if the response doesn't have both parts
      return [];
  }
};

// Extract the condition type, actor, and description from the response
const extractCondition = (response) => {
  // Check if "Precondition:" and "Postcondition:" are present in the response
  //const hasPrecondition = response.includes('Pre-condition');
  //const hasPostcondition = response.includes('Post-condition');

  // Define regex patterns for precondition and postcondition
  const preconditionRegex = /pre-?condition(?::|,)?/i;
  const postconditionRegex = /post-?condition(?::|,)?/i;
  const constraintRegex = /constraint(?::|,)?/i;
  const assumptionRegex = /assumption(?::|,)?/i;

  // Test if the response matches either pattern
  const isPrecondition = preconditionRegex.test(response);
  const isPostcondition = postconditionRegex.test(response);
  const isConstraint = constraintRegex.test(response);
  const isAssumption = assumptionRegex.test(response);

  // If it matches any of the patterns
  if (isPrecondition || isPostcondition || isConstraint || isAssumption) {
    // Determine the condition type based on the matched pattern
    let conditionType;
    if (isPrecondition) conditionType = 'Pre-condition';
    else if (isPostcondition) conditionType = 'Post-condition';
    else if (isConstraint) conditionType = 'Constraint';
    else if (isAssumption) conditionType = 'Assumption';

    // Extract condition text based on the matched pattern
    const conditionText = response.split(
        isPrecondition ? preconditionRegex :
        isPostcondition ? postconditionRegex :
        isConstraint ? constraintRegex : assumptionRegex
    )[1].trim();

    return [conditionType, conditionText];
} else {
    // Return an empty array if no pattern is found
    return [];
}
};

/*
const formatTimestamp = (timestamp) => {
  if (timestamp && timestamp.seconds) {
    const date = new Date(timestamp.seconds * 1000);
    console.log(date)
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  }
  return 'N/A';
};
*/

const formatTimestamp = (timestamp) => {
  if (timestamp && timestamp.seconds) {
    const date = new Date(timestamp.seconds * 1000);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  return 'N/A';
};

const getDuration = (startTimestamp, endTimestamp) => {
  if (startTimestamp?.seconds && endTimestamp?.seconds) {
    const start = new Date(startTimestamp.seconds * 1000);
    const end = new Date(endTimestamp.seconds * 1000);

    const diffMs = end - start; // Difference in milliseconds

    // Calculate hours, minutes, and seconds from milliseconds
    const hours = Math.floor(diffMs / (1000 * 60 * 60));
    const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diffMs % (1000 * 60)) / 1000);

    // Format the result to be more readable
    return `${hours}h ${minutes}m ${seconds}s`;
  }

  return 'Invalid timestamps';
};

const UseCaseForm = () => {

  const [currentStep, setCurrentStep] = useState(0);
  const steps = ['Description', 'Scenarios', 'Actors', 'Components', 'Check-Out']; // 'Meta Data', Actions
  const [showChat, setShowChat] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [isPrompt, setIsPrompt] = useState(true);

  const [showModalSave, setShowModalSave] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showModalExecuteSave, setShowModalExecuteSave] = useState(false);

  // State to start survey and interview
  const [showSurvey, setShowSurvey] = useState(false);
  const [showInterview, setShowInterview] = useState(false);

  // Indicates the modeling context
  // 'edit', 'template', 'study'; 
  // ATTENTION: those are distinct from the two modeling modes: 'manually', 'copilot', in turn indicating the creator mode
  const [ucMode, setUCMode] = useState('');

  // use case data
  const [useCaseData, setUseCaseData] = useCaseDataHookTrack();

  const [participantData, setParticipantData] = useParticipantDataHookTrack();

  // RDF handling and sidebar items
  const [rdfData, setRdfData] = useState(null);
  const [sidebarItems, setSidebarItems] = useState([]);  
  const [onto_name, setOntoName] = useState('Ontology');

  // Request mode for the chat
  //const [requestMode, setRequestMode] = useState(0);
  const requestModes = ['init', 'uc_info', 'uc_actions', 'uc_actor', 'uc_components', 'uc_model']; // 'uc_meta',

  // --- Component Model Architecture ---

  const [technicalLayer, setTechnicalLayer] = useState();

  const [componentsSemanticEnrichment, setComponentsSemanticEnrichment] = useState([]); // useState(comp_semantic_enrichment_peamonto_enhanced);

  const [domainModelURL, setDomainModelURL] = useState('');
  const [domainModels, setDomainModels] = useState([]);
  /*
  useState([
    { name: 'Baseline (agnostic)', acronym:'BAAG', id:'BAAG', format: 'rdf', url: '', file: '...', date_added: '', added_by: '', isActive: true, isSelected: false, components_semantic_enrichment: comp_semantic_enrichment_baseline_enhanced, technicalLayer: technicalLayerBaseline },
    { name: 'Mobility and Transportation', acronym:'PEAMONTO', id:'PEAMONTO', format: 'owl', url: PEAMONTOontologyURL, file: '...', date_added: '', added_by: '', isActive: true, isSelected: false, components_semantic_enrichment: comp_semantic_enrichment_peamonto_enhanced, technicalLayer: technicalLayerMobility },
    { name: 'Energy', acronym:'', id:'', format: 'owl', url: '', file: '...', date_added: '', added_by: '', isActive: false, isSelected: false, components_semantic_enrichment: {} },
    { name: 'Workplace', acronym:'', id:'', format: 'rdf', url: '', file: '...', date_added: '', added_by: '', isActive: false, isSelected: false, components_semantic_enrichment: {} },
    { name: 'Healthcare', acronym:'', id:'', format: 'rdf', url: '', file: '...', date_added: '', added_by: '', isActive: false, isSelected: false, components_semantic_enrichment: {} },
  ]);
  */

  // --- Study Mode Init Handling of the Participant data ---

  const location = useLocation();

  // get study mode data and store it.
  const [studyExpertData, setStudyExpertData] = useState();

  useEffect(() => {
    // initialize study mode only once
    if (location.state && location.state.mode && ucMode === '') {
      const { expertData } = location.state || {}; // mode,
      setStudyExpertData(expertData);
      setUCMode('study');
    } 
  }, [location.state, ucMode]);

  // --- Semantic Model Handling ---

  // Fetch ontology data / semantic models from the database
  useEffect(() => {
    fetchOntologyData().then((data) => {
        setDomainModels(data);
        if (data.length > 0) {
            // Automatically set the first model as default
            //setSemModel(data[0].acronym);
            //setOntologyURL(data[0].ontologyURL);
            //setEnrichment(data[0].mapping.enrichment || comp_semantic_enrichment_guco_enhanced); // Ensure enrichment is fetched                                    
        }
    });
  }, []);

  // handle semantic model init
  useEffect(() => {

    // find active semantic model
    //const selectedModel = domainModels.find(model => model.isSelected);

    /*
    const technicalLayerMobility = { category: 'Technical Layer', 
      subCategories: ['Applications / Interfaces (proprietary, third-party, partially distributed)', 'Platform and Cloud (proprietary, third-party, partially distributed)', 'ADS: Automated Driving System', 'AI-Middleware and Edge (Devices)', 'Hardware (Vehicle, Infrastructure)'] 
    };

    const technicalLayerBaseline = { category: 'Technical Layer', 
        subCategories: ['Applications / Interfaces (proprietary, third-party, partially distributed)', 'Platform and Cloud (proprietary, third-party, partially distributed)', 'Control Layer (Central Functionality)', 'AI-Middleware and Edge (Devices)', 'Hardware (Infrastructure, Computing)'] 
    };
    */

    const technicalLayerMobility = { category: 'Technical Layer',
      subCategories: ['Application', 'Platform', 'Control', 'Middleware', 'Hardware']
    };

    const technicalLayerBaseline = { category: 'Technical Layer',
      subCategories: ['Application', 'Platform', 'Control', 'Middleware', 'Hardware']
    };

    const selectedModelURL = useCaseData.domain_model.value.ontologyURL;
    const selectedModelEnrichment = useCaseData.domain_model.value?.mapping?.enrichment || [];
    //console.log('Selected model:', selectedModel);
    if (selectedModelURL !== '') {
      setDomainModelURL(selectedModelURL);
      setComponentsSemanticEnrichment(selectedModelEnrichment);

      // assign technical layer
      if (useCaseData.domain_model.value.acronym === 'PEAMONTO') {
        setTechnicalLayer(technicalLayerMobility);
      } else {
        setTechnicalLayer(technicalLayerBaseline);
      }

    }

    // temporarily used to initialize ontology for already created use cases as these have not assigned an ontology in the db
    if (ucMode === 'edit' || ucMode === 'template') {
      //console.log(useCaseData.domain_model)
      //if (useCaseData.domain_model === '')
        setDomainModelURL(PEAMONTOontologyURL);
        setTechnicalLayer(technicalLayerMobility);
        //setComponentsSemanticEnrichment(components_semantic_enrichment);
      //}
    }
    
  }, [useCaseData.domain_model, ucMode, domainModels, domainModelURL]);

  let parsedRDFData = OWLFileParser(domainModelURL, useState, useEffect);
  useEffect(() => {
    setRdfData(parsedRDFData);
  }, [parsedRDFData]);

  // set creator mode (manually or copilot); 
  useEffect(() => {

    //  check if creator mode is set (manually or copilot); not related to study mode
    if (location.state && (location.state === 'copilot' || location.state === 'manually') && !location.state.mode) {
      if (location.state === 'copilot') {
        setUseCaseData((prevData) => {
          return {
            ...prevData,
            mode: {
              value: 'normal',
              source: 'manually',
              modified: false,
              created_timestamp: getCurrentDateTime(),
              updated_timestamp: getCurrentDateTime(),
              updated_by: UserInformation().displayName,
              uid: UserInformation().uid,
              version: 1,
              priority: 'normal',
              relevance: 'normal',
            },
            creation_mode: {
              value: 'copilot',
              source: 'manually',
              modified: false,
              created_timestamp: getCurrentDateTime(),
              updated_timestamp: getCurrentDateTime(),
              updated_by: UserInformation().displayName,
              uid: UserInformation().uid,
              version: 1,
              priority: 'normal',
              relevance: 'normal',
            },
            domain_model: {
              value: {},
              source: 'manually',
              modified: false,
              created_timestamp: getCurrentDateTime(),
              updated_timestamp: getCurrentDateTime(),
              updated_by: UserInformation().displayName,
              uid: UserInformation().uid,
              version: 1,
              priority: 'normal',
              relevance: 'normal',
            }, 
            created_by: {
              value: UserInformation().displayName,
              source: 'manually',
              modified: false,
              created_timestamp: getCurrentDateTime(),
              updated_timestamp: getCurrentDateTime(),
              updated_by: UserInformation().displayName,
              uid: UserInformation().uid,
              version: 1,
              priority: 'normal',
              relevance: 'normal',
            },
            prompt: {
              value: '',
              source: 'manually',
              modified: false,
              created_timestamp: getCurrentDateTime(),
              updated_timestamp: getCurrentDateTime(),
              updated_by: UserInformation().displayName,
              uid: UserInformation().uid,
              version: 1,
              priority: 'normal',
              relevance: 'normal',
            },     
            prompt_response: {
              value: '',
              source: 'manually',
              modified: false,
              created_timestamp: getCurrentDateTime(),
              updated_timestamp: getCurrentDateTime(),
              updated_by: UserInformation().displayName,
              uid: UserInformation().uid,
              version: 1,
              priority: 'normal',
              relevance: 'normal',
            },
            copilot_messages: {
              value: [],
              source: 'manually',
              modified: false,
              created_timestamp: getCurrentDateTime(),
              updated_timestamp: getCurrentDateTime(),
              updated_by: UserInformation().displayName,
              uid: UserInformation().uid,
              version: 1,
              priority: 'normal',
              relevance: 'normal',
            },
            user: {
              value: UserInformation().displayName,
              source: 'manually',
              modified: false,
              created_timestamp: getCurrentDateTime(),
              updated_timestamp: getCurrentDateTime(),
              updated_by: UserInformation().displayName,
              uid: UserInformation().uid,
              version: 1,
              priority: 'normal',
              relevance: 'normal',
            },
          };
        });
        setShowModal(true);
      } else if (location.state === 'manually') {
        setUseCaseData((prevData) => {
          return {
            ...prevData,
            mode: {
              value: 'normal',
              source: 'manually',
              modified: false,
              created_timestamp: getCurrentDateTime(),
              updated_timestamp: getCurrentDateTime(),
              updated_by: UserInformation().displayName,
              uid: UserInformation().uid,
              version: 1,
              priority: 'normal',
              relevance: 'normal',
            },
            creation_mode: {
              value: 'manually',
              source: 'manually',
              modified: false,
              created_timestamp: getCurrentDateTime(),
              updated_timestamp: getCurrentDateTime(),
              updated_by: UserInformation().displayName,
              uid: UserInformation().uid,
              version: 1,
              priority: 'normal',
              relevance: 'normal',
            },
            created_by: {
              value: UserInformation().displayName,
              source: 'manually',
              modified: false,
              created_timestamp: getCurrentDateTime(),
              updated_timestamp: getCurrentDateTime(),
              updated_by: UserInformation().displayName,
              uid: UserInformation().uid,
              version: 1,
              priority: 'normal',
              relevance: 'normal',
            },
            prompt: {
              value: '',
              source: 'manually',
              modified: false,
              created_timestamp: getCurrentDateTime(),
              updated_timestamp: getCurrentDateTime(),
              updated_by: UserInformation().displayName,
              uid: UserInformation().uid,
              version: 1,
              priority: 'normal',
              relevance: 'normal',
            },
            prompt_response: {
              value: '',
              source: 'manually',
              modified: false,
              created_timestamp: getCurrentDateTime(),
              updated_timestamp: getCurrentDateTime(),
              updated_by: UserInformation().displayName,
              uid: UserInformation().uid,
              version: 1,
              priority: 'normal',
              relevance: 'normal',
            },
            copilot_messages: {
              value: [],
              source: 'manually',
              modified: false,
              created_timestamp: getCurrentDateTime(),
              updated_timestamp: getCurrentDateTime(),
              updated_by: UserInformation().displayName,
              uid: UserInformation().uid,
              version: 1,
              priority: 'normal',
              relevance: 'normal',
            },
            user: {
              value: UserInformation().displayName,
              source: 'manually',
              modified: false,
              created_timestamp: getCurrentDateTime(),
              updated_timestamp: getCurrentDateTime(),
              updated_by: UserInformation().displayName,
              uid: UserInformation().uid,
              version: 1,
              priority: 'normal',
              relevance: 'normal',
            },
          };
        });
        setShowModal(false);
      }
    }

    // check if study mode is set (manually or copilot)
    // And remove identifying information from the initialized fields: ucid and user and user_first_name
    if (location.state && location.state.mode) {
      if (location.state.mode === 'copilot') {
        setUseCaseData((prevData) => {
          return {
            ...prevData,
            mode: {
              value: 'study',
              source: 'manually',
              modified: false,
              created_timestamp: getCurrentDateTime(),
              updated_timestamp: getCurrentDateTime(),
              // use the anonymous id as the creator
              updated_by: studyExpertData?.anonymous_id || 'No user name', //UserInformation().displayName,
              uid: UserInformation().uid,
              version: 1,
              priority: 'normal',
              relevance: 'normal',
            },
            creation_mode: {
              value: 'copilot',
              source: 'manually',
              modified: false,
              created_timestamp: getCurrentDateTime(),
              updated_timestamp: getCurrentDateTime(),
              updated_by: studyExpertData?.anonymous_id || 'No user name', //UserInformation().displayName,
              uid: UserInformation().uid,
              version: 1,
              priority: 'normal',
              relevance: 'normal',
            },
            created_by: {
              value: '',
              source: 'manually',
              modified: false,
              created_timestamp: getCurrentDateTime(),
              updated_timestamp: getCurrentDateTime(),
              updated_by: studyExpertData?.anonymous_id || 'No user name', //UserInformation().displayName,
              uid: UserInformation().uid,
              version: 1,
              priority: 'normal',
              relevance: 'normal',
            },
            /*  => assigned in UC info 
            ucid: {
              value: '',
              source: 'manually',
              modified: false,
              created_timestamp: getCurrentDateTime(),
              updated_timestamp: getCurrentDateTime(),
              updated_by: studyExpertData?.anonymous_id || '',
              uid: UserInformation().uid,
              version: 1,
              priority: 'normal',
              relevance: 'normal',
            },
            */
            user: {
              value: studyExpertData?.anonymous_id || 'No user name',
              source: 'manually',
              modified: false,
              created_timestamp: getCurrentDateTime(),
              updated_timestamp: getCurrentDateTime(),
              updated_by: studyExpertData?.anonymous_id || 'No user name',
              uid: UserInformation().uid,
              version: 1,
              priority: 'normal',
              relevance: 'normal',
            },
            user_first_name: {
              value: studyExpertData?.anonymous_id || 'No user name',
              source: 'manually',
              modified: false,
              created_timestamp: getCurrentDateTime(),
              updated_timestamp: getCurrentDateTime(),
              updated_by: studyExpertData?.anonymous_id || 'No user name',
              uid: UserInformation().uid,
              version: 1,
              priority: 'normal', 
              relevance: 'normal',
            },
            prompt: {
              value: '',
              source: 'manually',
              modified: false,
              created_timestamp: getCurrentDateTime(),
              updated_timestamp: getCurrentDateTime(),
              updated_by: studyExpertData?.anonymous_id || 'No user name',
              uid: UserInformation().uid,
              version: 1,
              priority: 'normal',
              relevance: 'normal',
            },
            prompt_response: {
              value: '',
              source: 'manually',
              modified: false,
              created_timestamp: getCurrentDateTime(),
              updated_timestamp: getCurrentDateTime(),
              updated_by: studyExpertData?.anonymous_id || 'No user name',
              uid: UserInformation().uid,
              version: 1,
              priority: 'normal',
              relevance: 'normal',
            },
            copilot_messages: {
              value: [],
              source: 'manually',
              modified: false,
              created_timestamp: getCurrentDateTime(),
              updated_timestamp: getCurrentDateTime(),
              updated_by: studyExpertData?.anonymous_id || 'No user name',
              uid: UserInformation().uid,
              version: 1,
              priority: 'normal',
              relevance: 'normal',
            },
          };
        });
        setShowModal(true);
        setShowSurvey(false);
        setShowInterview(false);
      } else if (location.state.mode === 'manually') {
        // initialize study mode use case data
        setUseCaseData((prevData) => {
          return {
            ...prevData,
            title: {
              value: '',
              source: 'manually',
              modified: false,
              created_timestamp: '',
              updated_timestamp: '',
              updated_by: '',
              uid: '',
              version: 1,
              priority: 'medium',
              relevance: 'medium',
            },
            acronym: {
                value: '',
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: '',
                updated_by: '',
                uid: '',
                version: 1,
                priority: 'medium',
                relevance: 'medium'
            },
            maturity: {
                value: '',
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: '',
                updated_by: '',
                uid: '',
                version: 1,
                priority: 'low',
                relevance: 'low'
            },
            application: {
                value: '',
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: '',
                updated_by: '',
                uid: '',
                version: 1,
                priority: 'high',
                relevance: 'high'
            },
            status: {
                value: '',
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: '',
                updated_by: '',
                uid: '',
                version: 1,
                priority: 'high',
                relevance: 'high'
            },
            tags: {
                value: [],
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: '',
                updated_by: '',
                uid: '',
                version: 1,
                priority: 'medium',
                relevance: 'medium'
            },
            city: {
                value: [],
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: '',
                updated_by: '',
                uid: '',
                version: 1,
                priority: 'medium',
                relevance: 'medium'
            },
            country: {
                value: [],
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: '',
                updated_by: '',
                uid: '',
                version: 1,
                priority: 'medium',
                relevance: 'medium'
            },
            country_code: {
                value: [],
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: '',
                updated_by: '',
                uid: '',
                version: 1,
                priority: 'low',
                relevance: 'low'
            },
            country_flag: {
                value: [],
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: '',
                updated_by: '',
                uid: '',
                version: 1,
                priority: 'low',
                relevance: 'low'
            },
            continents: {
                value: [],
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: '',
                updated_by: '',
                uid: '',
                version: 1,
                priority: 'medium',
                relevance: 'medium'
            },
            description: {
                value: '',
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: '',
                updated_by: '',
                uid: '',
                version: 1,
                priority: 'high',
                relevance: 'high'
            },
            created: {
                value: '',
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: '',
                updated_by: '',
                uid: '',
                version: 1,
                priority: 'high',
                relevance: 'high'
            },
            updated: {
                value: '',
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: '',
                updated_by: '',
                uid: '',
                version: 1,
                priority: 'high',
                relevance: 'high'
            },
            start_processing: {
                value: '',
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: '',
                updated_by: '',
                uid: '',
                version: 1,
            },
            end_processing: {
                value: '',
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: '',
                updated_by: '',
                uid: '',
                version: 1,
            },
            prompt: {
                value: '',
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: '',
                updated_by: '',
                uid: '',
                version: 1,
            },
            prompt_response: {
                value: '',
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: '',
                updated_by: '',
                uid: '',
                version: 1,
            },
            copilot_messages: {
                value: [],
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: '',
                updated_by: '',
                uid: '',
                version: 1,
            },
            visibility: {
                value: true,
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: '',
                updated_by: '',
                uid: '',
                version: 1,
                priority: 'high',
                relevance: 'high'
            },
            adoption: {
                value: true,
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: '',
                updated_by: '',
                uid: '',
                version: 1,
                priority: 'medium',
                relevance: 'medium'
            },
            share: {
                value: false,
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: '',
                updated_by: '',
                uid: '',
                version: 1,
                priority: 'low',
                relevance: 'low'
            },
            creation_mode: {
                value: 'manually',
                source: 'manually',
                modified: false,
                created_timestamp: getCurrentDateTime(),
                updated_timestamp: getCurrentDateTime(),
                updated_by: studyExpertData?.anonymous_id || 'No user name', //studyExpertData?.anonymous_id.value ? studyExpertData.anonymous_id.value : UserInformation().displayName,                
                uid: UserInformation().uid,
                version: 1,
                priority: 'normal',
                relevance: 'normal',
            },
            semantic_model: {
                value: true,
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: '',
                updated_by: '',
                uid: '',
                version: 1,
                priority: 'high',
                relevance: 'high'
            },
            domain_model: {
                value: [''],
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: '',
                updated_by: '',
                uid: '',
                version: 1,
                priority: 'medium',
                relevance: 'medium'
            },
            llm_model: {
                value: [],
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: [],
                updated_by: [],
                uid: [],
                version: 1,
                priority: 'low',
                relevance: 'low'
            },
            file: {
                value: '',
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: '',
                updated_by: '',
                uid: '',
                version: 1,
                priority: 'low',
                relevance: 'low'
            },
            actions: {
                value: [],
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: '',
                updated_by: '',
                uid: '',
                version: 1,
                priority: 'medium',
                relevance: 'medium'
            },
            conditions: {
                value: [],
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: '',
                updated_by: '',
                uid: '',
                version: 1,
                priority: 'medium',
                relevance: 'medium'
            },
            actors: {
                value: {
                    list: {
                        Producer: {
                            value: [],
                            source: 'manually',
                            modified: false,
                            created_timestamp: '',
                            updated_timestamp: '',
                            updated_by: '',
                            uid: '',
                            version: 1,
                            priority: 'medium',
                            relevance: 'medium'
                        },
                        Consumer: {
                            value: [],
                            source: 'manually',
                            modified: false,
                            created_timestamp: '',
                            updated_timestamp: '',
                            updated_by: '',
                            uid: '',
                            version: 1,
                            priority: 'medium',
                            relevance: 'medium'
                        },
                        Regulator: {
                            value: [],
                            source: 'manually',
                            modified: false,
                            created_timestamp: '',
                            updated_timestamp: '',
                            updated_by: '',
                            uid: '',
                            version: 1,
                            priority: 'medium',
                            relevance: 'medium'
                        },
                        Technical: {
                            value: [],
                            source: 'manually',
                            modified: false,
                            created_timestamp: '',
                            updated_timestamp: '',
                            updated_by: '',
                            uid: '',
                            version: 1,
                            priority: 'medium',
                            relevance: 'medium'
                        },
                        Operator: {
                            value: [],
                            source: 'manually',
                            modified: false,
                            created_timestamp: '',
                            updated_timestamp: '',
                            updated_by: '',
                            uid: '',
                            version: 1,
                            priority: 'medium',
                            relevance: 'medium'
                        },
                        Platform: {
                            value: [],
                            source: 'manually',
                            modified: false,
                            created_timestamp: '',
                            updated_timestamp: '',
                            updated_by: '',
                            uid: '',
                            version: 1,
                            priority: 'medium',
                            relevance: 'medium'
                        }
                    },
                    nodes: {
                        value: initialNodes,
                        source: 'copilot',
                        modified: false,
                        created_timestamp: '',
                        updated_timestamp: '',
                        updated_by: '',
                        uid: '',
                        version: 1,
                        priority: 'medium',
                        relevance: 'medium'
                    },
                    edges: {
                        value: initialEdges,
                        source: 'copilot',
                        modified: false,
                        created_timestamp: '',
                        updated_timestamp: '',
                        updated_by: '',
                        uid: '',
                        version: 1,
                        priority: 'medium',
                        relevance: 'medium'
                    }
                },
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: '',
                updated_by: '',
                uid: '',
                version: 1,
                priority: 'medium',
                relevance: 'medium'
            },
            components: {
                value: [
                    {
                        category: 'Operations',
                        components: [
                            { description: 'Operations: Systems, Services, and Tools', items: [] },
                            { description: 'Operations Management', items: [] },
                            { description: 'Operations Analytics', items: [] },
                            { description: 'Operations Platform', items: [] }
                        ]
                    },
                    {
                        category: 'Producer',
                        components: [
                            { description: 'Producer', items: [] },
                            { description: 'Producer Analytics', items: [] },
                            { description: 'Producer Management', items: [] },
                            { description: 'Producer Platform', items: [] }
                        ]
                    },
                    {
                        category: 'Consumer',
                        components: [
                            { description: 'Consumer', items: [] },
                            { description: 'Consumer Analytics', items: [] },
                            { description: 'Consumer Delivery', items: [] }, // Consumer Management
                            { description: 'Consumer Platform', items: [] }
                        ]                    
                    },
                    {
                        category: 'Regulator',
                        components: [
                            { description: 'Regulator', items: [] },
                            { description: 'Directives', items: [] },
                            { description: 'Reference Cases', items: [] },
                            { description: 'Standards', items: [] }
                        ]
                    },
                    {
                        category: 'Technical',
                        components: [
                            { description: 'Technical', items: [] },
                            { description: 'Application', items: [] },
                            { description: 'Platform', items: [] },
                            { description: 'Control', items: [] },
                            { description: 'Middleware', items: [] },
                            { description: 'Hardware', items: [] },
                        ]
                    },            
                ],
                source: 'manually',
                modified: false,
                created_timestamp: '',
                updated_timestamp: '',
                updated_by: '',
                uid: '',
                version: 1,
                priority: 'medium',
                relevance: 'medium'
            },
            history: {
                data: [],
                updated_by: [],
                uid: [],
                time_stamp: [],
            },
            // remove identifying information from the initialized fields: ucid and user and user_first_name
            /* => assigned in UC info
            ucid: {
              value: '',
              source: 'manually',
              modified: false,
              created_timestamp: getCurrentDateTime(),
              updated_timestamp: getCurrentDateTime(),
              updated_by: studyExpertData?.anonymous_id || '',
              uid: UserInformation().uid,
              version: 1,
              priority: 'normal',
              relevance: 'normal',
            },
            */
            user: {
              value: studyExpertData?.anonymous_id || 'No user name',
              source: 'manually',
              modified: false,
              created_timestamp: getCurrentDateTime(),
              updated_timestamp: getCurrentDateTime(),
              updated_by: studyExpertData?.anonymous_id || 'No user name',
              uid: UserInformation().uid,
              version: 1,
              priority: 'normal',
              relevance: 'normal',
            },
            user_first_name: {
              value: studyExpertData?.anonymous_id || 'No user name',
              source: 'manually',
              modified: false,
              created_timestamp: getCurrentDateTime(),
              updated_timestamp: getCurrentDateTime(),
              updated_by: studyExpertData?.anonymous_id || 'No user name',
              uid: UserInformation().uid,
              version: 1,
              priority: 'normal', 
              relevance: 'normal',
            }
          };
        });

        setShowModal(false);
        setShowSurvey(false);
        setShowInterview(false);
      } else if (location.state.mode === 'survey') {
        setShowSurvey(true);
        setShowInterview(false);        
      } else if (location.state.mode === 'interview') {
        setShowInterview(true);
        setShowSurvey(false);
      }
    }   

  }, [location.state, setUseCaseData, studyExpertData?.anonymous_id]);

  // ############################ START STUDY MODE HANDLING ############################

  //console.log('Expert data in uc form:', studyExpertData);

  const [studyStep, setStudyStep] = useState(1);
  const [showModalStudy, setShowModalStudy] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true); // State to track if the sidebar is expanded or collapsed
  const [isGuideExpanded, setIsGuideExpanded] = useState(true); // State to track if the guide is expanded or collapsed
  const [showModalEndStudy, setShowModalEndStudy] = useState(false); // State to control the modal visibility

  // save use case name and description from study mode two and then assigned to input value in study mode three
  const [useCaseName, setUseCaseName] = useState('');
  const [useCaseDescription, setUseCaseDescription] = useState('');
  const [studyPromptSupport, setStudyPromptSupport] = useState('');

  // store name and description temporarily to be used in study step 3

  useEffect(() => {

    if (studyExpertData) {

      if (studyStep === 2) {
        setUseCaseName(useCaseData?.title?.value || '');
        setUseCaseDescription(useCaseData?.description?.value || '');
      }

      // assign the name and description to Chat inputvalue for study step 3
      if (studyStep === 3) {

        setStudyPromptSupport(`Model the use case ${useCaseName} which description is: ${useCaseDescription}`);

      }

    }

  }, [studyStep, studyExpertData, useCaseData, useCaseName, useCaseDescription]);

  
  // handle study mode
  useEffect(() => {

    if (studyExpertData) {
      // add data to use case data
      setParticipantData((prevData) => {
        return {
          ...prevData,
          anonymous_id: {
            value: studyExpertData.anonymous_id,
            source: 'manually',
            modified: true,
            created_timestamp: getCurrentDateTime(),
            updated_timestamp: getCurrentDateTime(),
            updated_by: studyExpertData?.anonymous_id, //UserInformation().displayName,
            uid: UserInformation().uid,
            version: 1,
            priority: 'normal',
            relevance: 'normal',
          },
          mode: {
            value: 'study',
            source: 'manually',
            modified: true,
            created_timestamp: getCurrentDateTime(),
            updated_timestamp: getCurrentDateTime(),
            updated_by: studyExpertData?.anonymous_id, //UserInformation().displayName,
            uid: UserInformation().uid,
            version: 1,
            priority: 'normal',
            relevance: 'normal',
          },
          created_by: {
            value: studyExpertData.anonymous_id,
            source: 'manually',
            modified: true,
            created_timestamp: getCurrentDateTime(),
            updated_timestamp: getCurrentDateTime(),
            updated_by: studyExpertData?.anonymous_id, //UserInformation().displayName,
            uid: UserInformation().uid,
            version: 1,
            priority: 'normal',
            relevance: 'normal',
          },
          yearOfBirth: {
            value: studyExpertData.yearOfBirth,
            source: 'manually',
            modified: true,
            created_timestamp: getCurrentDateTime(),
            updated_timestamp: getCurrentDateTime(),
            updated_by: studyExpertData?.anonymous_id, //UserInformation().displayName,
            uid: UserInformation().uid,
            version: 1,
            priority: 'normal',
            relevance: 'normal',
          },
          role: {
            value: studyExpertData.role,
            source: 'manually',
            modified: true,
            created_timestamp: getCurrentDateTime(),
            updated_timestamp: getCurrentDateTime(),
            updated_by: studyExpertData?.anonymous_id, //UserInformation().displayName,
            uid: UserInformation().uid,
            version: 1,
            priority: 'normal',
            relevance: 'normal',
          },
          consent: {
            value: studyExpertData.consentGiven,
            source: 'manually',
            modified: true,
            created_timestamp: getCurrentDateTime(),
            updated_timestamp: getCurrentDateTime(),
            updated_by: studyExpertData?.anonymous_id, //UserInformation().displayName,
            uid: UserInformation().uid,
            version: 1,
            priority: 'normal',
            relevance: 'normal',
          },
          place: {
            value: studyExpertData.country,
            source: 'manually',
            modified: true,
            created_timestamp: getCurrentDateTime(),
            updated_timestamp: getCurrentDateTime(),
            updated_by: studyExpertData?.anonymous_id, //UserInformation().displayName,
            uid: UserInformation().uid,
            version: 1,
            priority: 'normal',
            relevance: 'normal',
          },
          experience: {
            value: studyExpertData.experience,
            source: 'manually',
            modified: true,
            created_timestamp: getCurrentDateTime(),
            updated_timestamp: getCurrentDateTime(),
            updated_by: studyExpertData?.anonymous_id, //UserInformation().displayName,
            uid: UserInformation().uid,
            version: 1,
            priority: 'normal',
            relevance: 'normal',
          },
          expert_field: {
            value: studyExpertData.expertField,
            source: 'manually',
            modified: true,
            created_timestamp: getCurrentDateTime(),
            updated_timestamp: getCurrentDateTime(),
            updated_by: studyExpertData?.anonymous_id, //UserInformation().displayName,
            uid: UserInformation().uid,
            version: 1,
            priority: 'normal',
            relevance: 'normal',
          },
          study_step: {
            value: studyStep,
            source: 'manually',
            modified: true,
            created_timestamp: getCurrentDateTime(),
            updated_timestamp: getCurrentDateTime(),
            updated_by: studyExpertData?.anonymous_id, //UserInformation().displayName,
            uid: UserInformation().uid,
            version: 1,
            priority: 'normal',
            relevance: 'normal',
          },
          study_step_start: {
            value: getCurrentDateTime(),
            source: 'manually',
            modified: true,
            created_timestamp: getCurrentDateTime(),
            updated_timestamp: getCurrentDateTime(),
            updated_by: studyExpertData?.anonymous_id, //UserInformation().displayName,
            uid: UserInformation().uid,
            version: 1,
            priority: 'normal',
            relevance: 'normal',
          },
        };
      });
    }

  }, [studyExpertData, setParticipantData, studyStep]);


  // update created_by field in use case data with anonymous id
  useEffect(() => {

    //console.log('study data', studyExpertData);

    if (studyExpertData) {

      setUseCaseData((prevData) => {
        return {
          ...prevData,
          ...participantData,
          /*
          created_by: {
            value: studyExpertData.anonymous_id,
            source: 'manually',
            modified: true,
            created_timestamp: getCurrentDateTime(),
            updated_timestamp: getCurrentDateTime(),
            updated_by: 'study',
            uid: 'study_no_id',
            version: 1,
            priority: 'normal',
            relevance: 'normal',
          },
          */
        };
      });

    };

  }, [studyExpertData, setUseCaseData, participantData]);

  // ############################ END STUDY MODE HANDLING ############################

  // parse the RDF data and build the sidebar items
  // used in the component modeling step and the chat to enhance the data by semantic information
  useEffect(() => {
    if (rdfData !== null) {

      //console.log('RDF data:', rdfData);

      const items = buildSidebarItems(rdfData);
      if (items.length > 0) {
          items[0].collapsed = false;
          setOntoName(items[0].title);
      }
      setSidebarItems(items);
    }
  }, [rdfData]);

  //const [useCaseDetails, setUseCaseDetails] = useState([]);
  // Retrieve the messages, input value, use case details, handle change and handle submit from the useChat hook
  const { 
    copilotSteps,
    messages,
    inputValue, 
    useCaseDetails, 
    handleChange, 
    handleSubmit,
    isLoading,
    loaderResponse 
  } = useChat(requestModes[0], sidebarItems, setIsPrompt, componentsSemanticEnrichment, studyPromptSupport); // requestMode not used at this moment; needed when for step specific requests

  // Handle the chat visualization inside the Journey steps
  const handleChat = () => setShowChat(!showChat);

  // Update the request modes
  /*
  useEffect(() => {
    if (!showModal) {
      setRequestMode(currentStep+1);
    }
  }, [requestMode, currentStep, showModal]);
  */

  // fetched use cases to help in the modeling process
  const [useCases, setUseCases] = useState();
  const [uniqueKeywords, setUniqueKeywords] = useState([]);

  // Fetch the use cases and extract the unique keywords
  useEffect(() => {

    let initialKeywords = [
      'Autonomous Mobility', 
      'Connected, Cooperative, and Automated Mobility (CCAM)',
      'Distributed AI (DAI)',
      'Platform Economy',
      'Automated Driving System (ADS)',
      'Automated Driving',
      'Mobility as a Service (MaaS)',
      '----------------',
    ]

    const getData = async () => {
      const data = await fetchUseCaseData();
      setUseCases(data);

      // Extract unique keywords
      //const allKeywords = data.flatMap(useCase => useCase.tags)
      // old and new structure
      const allKeywords = data.flatMap(useCase => {
        if (Array.isArray(useCase.tags)) {
          // Old structure
          return useCase.tags;
        } else if (useCase.tags?.value) {
          // New structure
          return useCase.tags.value;
        }
        return []; // Return an empty array if neither structure matches
      });

      const uniqueKeywordsSet = new Set(allKeywords);
      // append the unique keywords to the existing ones
      //setUniqueKeywords([...uniqueKeywords, ...uniqueKeywordsSet]);

      // Separate unique keywords from default ones
      const sortedUniqueKeywords = [...uniqueKeywordsSet]
      //.filter(keyword => !uniqueKeywords.includes(keyword)) // Exclude default keywords
      .sort(); // Sort alphabetically
      

      // Combine default keywords with the sorted unique keywords
      const combinedKeywords = [...initialKeywords, ...sortedUniqueKeywords];

      setUniqueKeywords(combinedKeywords);

      //setUniqueKeywords([...uniqueKeywordsSet]);
    };

    getData();  
  }, []);

  // setUseCaseData with the chat data (usecasedetails) if prompted (UCM Co-Pilot used)
  // FORMER IMPLEMENTATION
  /*
  useEffect(() => {

    if (!useCaseDetails || useCaseDetails.length === 0) return;

    // Extract the scenarios and conditions from the chat data
    const newScenarios = [
      {
        name: extractScenario(useCaseDetails[7]?.response || '')[0],
        description: extractScenario(useCaseDetails[7]?.response || '')[1]
      },
      {
        name: extractScenario(useCaseDetails[8]?.response || '')[0],
        description: extractScenario(useCaseDetails[8]?.response || '')[1]
      },
      {
        name: extractScenario(useCaseDetails[9]?.response || '')[0],
        description: extractScenario(useCaseDetails[9]?.response || '')[1]
      }
    ];

    // Extract the scenarios and conditions from the chat data
    const newConditions = [
      {
        scenario: "Basic Scenario",
        preConditions: extractCondition(useCaseDetails[10]?.response || '')[1],
        postConditions: extractCondition(useCaseDetails[11]?.response || '')[1],
        constraints: extractCondition(useCaseDetails[12]?.response || '')[1],
        assumptions: extractCondition(useCaseDetails[13]?.response || '')[1]
      },
      {
        scenario: "Advanced Scenario",
        preConditions: extractCondition(useCaseDetails[14]?.response || '')[1],
        postConditions: extractCondition(useCaseDetails[15]?.response || '')[1],
        constraints: extractCondition(useCaseDetails[16]?.response || '')[1],
        assumptions: extractCondition(useCaseDetails[17]?.response || '')[1]
      },
      {
        scenario: "Cutting-edge Scenario",
        preConditions: extractCondition(useCaseDetails[18]?.response || '')[1],
        postConditions: extractCondition(useCaseDetails[19]?.response || '')[1],
        constraints: extractCondition(useCaseDetails[20]?.response || '')[1],
        assumptions: extractCondition(useCaseDetails[21]?.response || '')[1]
      }
    ];

    // Extract the actors from the chat data
    const newNodes = [...initialNodes];
    const newEdges = [...initialEdges];

    const concatenatedResponses = [
      useCaseDetails[22]?.response,
      useCaseDetails[23]?.response,
      useCaseDetails[24]?.response,
      useCaseDetails[25]?.response,
      useCaseDetails[26]?.response,
      useCaseDetails[27]?.response
    ].filter(response => response)

    const categories = ['Consumer', 'Producer', 'Platform', 'Technical', 'Operator', 'Regulator' ];

    const categoryColors = {
        /*
        Commerce: { background: '#cfe2ff', border: '#3f8efc' },
        Governance: { background: '#d9efff', border: '#1c78f3' },
        Infrastructure: { background: '#e9f4ff', border: '#0056b3' },
        Public: { background: '#f4faff', border: '#004085' }
        
        Producer: { background: '#f4faff', border: '#004085' },
        Consumer: { background: '#f4faff', border: '#004085' },
        Regulator: { background: '#f4faff', border: '#004085' },
        Technical: { background: '#f4faff', border: '#004085' },
        Operator: { background: '#f4faff', border: '#004085' },
        Platform: { background: '#f4faff', border: '#004085' }                        
    };
    
    categories.forEach((category, index) => {
        const actors = concatenatedResponses[index] || '';
        actors.split(',').forEach(actor => {
          const trimmedActor = actor.trim();
          if (trimmedActor) {
            const nodeId = `${category}_${trimmedActor}`;
            newNodes.push({ 
                id: nodeId, 
                label: trimmedActor, 
                group: category,
                color: categoryColors[category] });
            newEdges.push({ from: category, to: nodeId });
          }
        });
    });

    const newActors = {
      list: categories.reduce((acc, category, index) => {
        acc[category] = concatenatedResponses[index] ? concatenatedResponses[index].split(',').map(actor => actor.trim()) : [];
        return acc;
      }, {}),
      nodes: newNodes,
      edges: newEdges,
    };

    // Extract the components from the chat data
    const selectedModel = domainModels.find(model => model.isSelected);
    const comp_categories = [
      { category: 'Operations', subCategories: ['Operations Management', 'Operations Analytics', 'Operations Platform'] },
      { category: 'Producer', subCategories: ['Producer Analytics', 'Producer Management', 'Producer Platform'] },
      { category: 'Consumer', subCategories: ['Consumer Analytics', 'Consumer Delivery', 'Consumer Platform'] },
      //{ category: 'Technical Layer', subCategories: ['Applications / Interfaces (proprietary, third-party, partially distributed)', 'Platform and Cloud (proprietary, third-party, partially distributed)', 'ADS: Automated Driving System', 'AI-Middleware and Edge (Devices)', 'Hardware (Vehicle, Infrastructure)'] },
      selectedModel.technicalLayer,
      { category: 'Regulator', subCategories: ['Directives', 'Reference Cases', 'Standards'] }
    ];
  
    // Initialize newComponents as an empty array
    const newComponents = comp_categories.map(categoryGroup => ({
      category: categoryGroup.category,
      components: categoryGroup.subCategories.map(subCategory => ({
        description: subCategory,
        items: []        
      }))
    }));
  
    // Fill the fields from the use case details
    let useCaseIndex = 28; // starting index for useCaseDetails
    comp_categories.forEach((categoryGroup, groupIndex) => {
      categoryGroup.subCategories.forEach((subCategory, subIndex) => {
        if (useCaseDetails[useCaseIndex]) {
          useCaseDetails[useCaseIndex].response.split(',').forEach(item => {
            const trimmedItem = item.trim();
            if (!newComponents[groupIndex].components[subIndex].items.includes(trimmedItem)) {
              newComponents[groupIndex].components[subIndex].items.push(trimmedItem);
            }
          });
        }
        useCaseIndex++; // increment the index for the next subCategory
      });
    });

    if (isPrompt) {
      setUseCaseData((prevData) => {
        return {
          ...prevData,
          // step 1
          title: useCaseDetails[0]?.response || '',
          acronym: useCaseDetails[1]?.response || '',
          maturity: useCaseDetails[2]?.response || '',
          application: useCaseDetails[3]?.response || '',
          status: useCaseDetails[4]?.response || '',
          tags: useCaseDetails[5]?.response.split(',') || [], // .map(tag => tag.trim())
          description: useCaseDetails[6]?.response || '',
          // step 2
          actions: newScenarios,
          // step 3
          conditions: newConditions,
          // step 4
          actors: newActors,
          // step 5
          components: newComponents,
        };
      });

      setIsPrompt(false);

    }

  }, [isPrompt, useCaseDetails, domainModels, setUseCaseData]);
  */

  // NEW STATE STRUCTURE HANDLING set use case data with the chat data (usecasedetails) if prompted (UCM Co-Pilot used)
  useEffect(() => {
    
    if ((!useCaseDetails || useCaseDetails.length === 0) && isPrompt ) return;
  
    // Function to update a field with tracking data
    const updateField = (prevField, newValue, source = 'copilot') => {
      if (prevField.value !== newValue) {
        return {
          value: newValue,
          source: source,
          modified: true,
          created_timestamp: prevField.created_timestamp || getCurrentDateTime(),
          updated_timestamp: getCurrentDateTime(),
          updated_by: studyExpertData
            ? studyExpertData?.anonymous_id // Use the anonymous ID if provided
            : UserInformation().displayName, // Default to user display name
          //updated_by: UserInformation().displayName,
          uid: UserInformation().uid,
          version: prevField.version || 1,
          priority: prevField.priority || 'normal',
          relevance: prevField.relevance || 'normal',
        };
      }
      return prevField;
    };
  
    // Prepare new scenarios without tracking data
    const newScenarios = [
      {
        name: extractScenario(useCaseDetails[7]?.response || '')[0],
        description: extractScenario(useCaseDetails[7]?.response || '')[1],
        modified: true,
        created_timestamp: getCurrentDateTime(),
        updated_timestamp: getCurrentDateTime(),
        updated_by: studyExpertData
          ? studyExpertData?.anonymous_id // Use the anonymous ID if provided
          : UserInformation().displayName, // Default to user display name
        //updated_by: UserInformation().displayName,
        uid: UserInformation().uid,
        version: 1,
        priority: 'normal',
        relevance: 'normal'
      },
      {
        name: extractScenario(useCaseDetails[8]?.response || '')[0],
        description: extractScenario(useCaseDetails[8]?.response || '')[1],
        modified: true,
        created_timestamp: getCurrentDateTime(),
        updated_timestamp: getCurrentDateTime(),
        updated_by: studyExpertData
          ? studyExpertData?.anonymous_id // Use the anonymous ID if provided
          : UserInformation().displayName, // Default to user display name
        //updated_by: UserInformation().displayName,
        uid: UserInformation().uid,
        version: 1,
        priority: 'normal',
        relevance: 'normal'
      },
      {
        name: extractScenario(useCaseDetails[9]?.response || '')[0],
        description: extractScenario(useCaseDetails[9]?.response || '')[1],
        modified: true,
        created_timestamp: getCurrentDateTime(),
        updated_timestamp: getCurrentDateTime(),
        updated_by: studyExpertData
          ? studyExpertData?.anonymous_id // Use the anonymous ID if provided
          : UserInformation().displayName, // Default to user display name
        //updated_by: UserInformation().displayName,
        uid: UserInformation().uid,
        version: 1,
        priority: 'normal',
        relevance: 'normal'
      }
    ];

    // Prepare new conditions with tracking data
    const newConditions = [
      {
        scenario: "Basic Scenario",
        preConditions: extractCondition(useCaseDetails[10]?.response || '')[1],
        postConditions: extractCondition(useCaseDetails[11]?.response || '')[1],
        constraints: extractCondition(useCaseDetails[12]?.response || '')[1],
        assumptions: extractCondition(useCaseDetails[13]?.response || '')[1],
        modified: true,
        created_timestamp: getCurrentDateTime(),
        updated_timestamp: getCurrentDateTime(),
        updated_by: studyExpertData
          ? studyExpertData?.anonymous_id // Use the anonymous ID if provided
          : UserInformation().displayName, // Default to user display name
        //updated_by: UserInformation().displayName,
        uid: UserInformation().uid,
        version: 1,
        priority: 'normal',
        relevance: 'normal'
      },
      {
        scenario: "Advanced Scenario",
        preConditions: extractCondition(useCaseDetails[14]?.response || '')[1],
        postConditions: extractCondition(useCaseDetails[15]?.response || '')[1],
        constraints: extractCondition(useCaseDetails[16]?.response || '')[1],
        assumptions: extractCondition(useCaseDetails[17]?.response || '')[1],
        modified: true,
        created_timestamp: getCurrentDateTime(),
        updated_timestamp: getCurrentDateTime(),
        updated_by: studyExpertData
          ? studyExpertData?.anonymous_id // Use the anonymous ID if provided
          : UserInformation().displayName, // Default to user display name
        //updated_by: UserInformation().displayName,
        uid: UserInformation().uid,
        version: 1,
        priority: 'normal',
        relevance: 'normal'
      },
      {
        scenario: "Cutting-edge Scenario",
        preConditions: extractCondition(useCaseDetails[18]?.response || '')[1],
        postConditions: extractCondition(useCaseDetails[19]?.response || '')[1],
        constraints: extractCondition(useCaseDetails[20]?.response || '')[1],
        assumptions: extractCondition(useCaseDetails[21]?.response || '')[1],
        modified: true,
        created_timestamp: getCurrentDateTime(),
        updated_timestamp: getCurrentDateTime(),
        updated_by: studyExpertData
          ? studyExpertData?.anonymous_id // Use the anonymous ID if provided
          : UserInformation().displayName, // Default to user display name
        //updated_by: UserInformation().displayName,
        uid: UserInformation().uid,
        version: 1,
        priority: 'normal',
        relevance: 'normal'
      }
    ];    

    // Extract the actors from the chat data
    const newNodes = [...initialNodes];
    const newEdges = [...initialEdges];

    const concatenatedResponses = [
      useCaseDetails[22]?.response,
      useCaseDetails[23]?.response,
      useCaseDetails[24]?.response,
      useCaseDetails[25]?.response,
      useCaseDetails[26]?.response,
      useCaseDetails[27]?.response
    ].filter(response => response)

    const categories = ['Consumer', 'Producer', 'Platform', 'Technical', 'Operator', 'Regulator' ];
    
    const categoryColors = {
      /*
      Commerce: { background: '#cfe2ff', border: '#3f8efc' },
      Governance: { background: '#d9efff', border: '#1c78f3' },
      Infrastructure: { background: '#e9f4ff', border: '#0056b3' },
      Public: { background: '#f4faff', border: '#004085' }
      */
      Producer: { background: '#f4faff', border: '#004085' },
      Consumer: { background: '#f4faff', border: '#004085' },
      Regulator: { background: '#f4faff', border: '#004085' },
      Technical: { background: '#f4faff', border: '#004085' },
      Operator: { background: '#f4faff', border: '#004085' },
      Platform: { background: '#f4faff', border: '#004085' }                        
    };
    
    categories.forEach((category, index) => {
        const actors = concatenatedResponses[index] || '';
        actors.split(',').forEach(actor => {
          const trimmedActor = actor.trim();
          if (trimmedActor) {
            const nodeId = `${category}_${trimmedActor}`;
            newNodes.push({ 
                id: nodeId, 
                label: trimmedActor, 
                group: category,
                color: categoryColors[category] });
            newEdges.push({ from: category, to: nodeId });
          }
        });
    });
  
    // Prepare new actors with tracking data
    const newActors = {
      list: categories.reduce((acc, category, index) => {
        acc[category] = updateField({ value: concatenatedResponses[index]?.split(',').map(actor => actor.trim()) || [] }, concatenatedResponses[index]?.split(',').map(actor => actor.trim()) || []);
        return acc;
      }, {}),
      nodes: {
        value: newNodes.map(node => ({
          ...node,
          source: 'copilot',
          modified: false,
          created_timestamp: getCurrentDateTime(),
          updated_timestamp: null,
          updated_by: '',
          version: 1,
          priority: 'normal',
          relevance: 'normal'
        })),
        source: 'copilot',
        modified: false,
        created_timestamp: getCurrentDateTime(),
        updated_timestamp: null,
        updated_by: '',
        version: 1,
        priority: 'normal',
        relevance: 'normal'
      },  
      edges: {
        value: newEdges.map(edge => ({
          ...edge,
          source: 'copilot',
          modified: false,
          created_timestamp: getCurrentDateTime(),
          updated_timestamp: null,
          updated_by: '',
          version: 1,
          priority: 'normal',
          relevance: 'normal'
        })),
        source: 'copilot',
        modified: false,
        created_timestamp: getCurrentDateTime(),
        updated_timestamp: null,
        updated_by: '',
        version: 1,
        priority: 'normal',
        relevance: 'normal'
      }
    };

    // Extract the components from the chat data
    //const selectedModel = domainModels.find(model => model.isSelected);

    const comp_categories = [
      { category: 'Operations', subCategories: ['Operations Management', 'Operations Analytics', 'Operations Platform'] },
      { category: 'Producer', subCategories: ['Producer Analytics', 'Producer Management', 'Producer Platform'] },
      { category: 'Consumer', subCategories: ['Consumer Analytics', 'Consumer Delivery', 'Consumer Platform'] },
      //{ category: 'Technical Layer', subCategories: ['Applications / Interfaces (proprietary, third-party, partially distributed)', 'Platform and Cloud (proprietary, third-party, partially distributed)', 'ADS: Automated Driving System', 'AI-Middleware and Edge (Devices)', 'Hardware (Vehicle, Infrastructure)'] },
      //selectedModel.technicalLayer,
      technicalLayer,
      { category: 'Regulator', subCategories: ['Directives', 'Reference Cases', 'Standards'] }
    ];

    // Prepare new components with tracking data
    const newComponents = comp_categories.map(categoryGroup => ({
      category: categoryGroup.category,
      components: categoryGroup.subCategories.map(subCategory => ({
        description: subCategory,
        items: [],
        type: 'component',        
      }))
    }));

    // Fill the fields from the use case details
    let useCaseIndex = 28; // starting index for useCaseDetails components
    comp_categories.forEach((categoryGroup, groupIndex) => {
      categoryGroup.subCategories.forEach((subCategory, subIndex) => {
        if (useCaseDetails[useCaseIndex]) {
          useCaseDetails[useCaseIndex].response.split(',').forEach(item => {
            const trimmedItem = item.trim();

            // Check if the item already exists in the components list
            const existingItem = newComponents[groupIndex].components[subIndex].items.find(
              existing => existing.name === trimmedItem
            );

            if (!existingItem) {
              // If the item does not exist, add it with metadata
              newComponents[groupIndex].components[subIndex].items.push(
                trimmedItem
                /*
                {
                name: trimmedItem,
                source: 'ucm_copilot', // or 'manual' if added manually
                modified: false,
                created_timestamp: getCurrentDate(),
                updated_timestamp: null, // Will be filled if/when the item is updated
                updated_by: '', // Will be filled if/when the item is updated
                version: 1, // Start with version 1
                priority: 'normal', // or 'high' if needed
                relevance: 'normal', // or 'high' if needed
                }*/
                );
            } else {
              // If the item already exists, update the metadata as needed
              //existingItem.modified = true;
              //existingItem.updated_timestamp = new Date().toISOString();
              //existingItem.updated_by = CurrentUser();
              //existingItem.version += 1;
            }
          });
        }
        useCaseIndex++; // increment the index for the next subCategory
      });
    });
  
    // Update useCaseData with the processed information
    if (isPrompt) {
      setUseCaseData((prevData) => {

        // Construct the new use case data
        const newUseCaseData = {
          // step 1
          title: updateField(prevData.title, useCaseDetails[0]?.response || ''),
          acronym: updateField(prevData.acronym, useCaseDetails[1]?.response || ''),
          maturity: updateField(prevData.maturity, useCaseDetails[2]?.response || ''),
          application: updateField(prevData.application, useCaseDetails[3]?.response || ''),
          status: updateField(prevData.status, useCaseDetails[4]?.response || ''),
          tags: updateField(prevData.tags, useCaseDetails[5]?.response.split(',').map(tag => tag.trim()) || []),
          description: updateField(prevData.description, useCaseDetails[6]?.response || ''),
          // step 2
          actions: updateField(prevData.actions, newScenarios),
          // step 3
          conditions: updateField(prevData.conditions, newConditions),
          // step 4
          actors: updateField(prevData.actors, newActors),
          // step 5
          components: updateField(prevData.components, newComponents),
          // metadata
          start_processing: updateField(prevData.start_processing, getCurrentDateTime()),          
          //created, see info page       
          prompt: updateField(prevData.prompt, messages[1].content),     
          prompt_response: updateField(prevData.prompt_response, messages[2].content), 
          copilot_messages: updateField(prevData.copilot_messages, useCaseDetails),
        };

        return {
          ...prevData,
          ...newUseCaseData, // Update the current use case data with new values

          // Store all of the automatically created data in the history object (for tracking)
          history: {
            ...prevData.history,
            data: [
              ...(prevData.history?.data || []), // Ensure there's no error if history or data is undefined
              newUseCaseData,
            ],
            time_stamp: [
              ...(prevData.history?.time_stamp || []),
              getCurrentDateTime(),
            ],
            updated_by: [
              ...(prevData.history?.updated_by || []),
              studyExpertData
                ? studyExpertData?.anonymous_id // Use the anonymous ID if provided
                : UserInformation().displayName, // Default to user display name
              //UserInformation().displayName,
            ],
            uid: [
              ...(prevData.history?.uid || []),
              UserInformation().uid,
            ],
          }
        };
      });
  
      setIsPrompt(false);
    }
  
  }, [isPrompt, useCaseDetails, domainModels, setUseCaseData, messages, technicalLayer, studyExpertData]);


  // Edit data of existing modeled use case "EDIT MODE" "TEMPLATE MODE"
  // Handle data passed from the use case list
  // Execute only if data is passed; meaning edit or template mode is selected
  useEffect(() => {    

    if (location.state && (location.state.template || location.state.selectedUseCase)) {

      //console.log('Data passed:', location.state);

      //console.log('Original selectedUseCase:', location.state.selectedUseCase);
      //const testDate = formatTimestamp(location.state.selectedUseCase.created?.value);
      //console.log('Formatted Timestamp:', testDate); // Should log a string like "2024-11-23 12:00:00"

      let formattedData = {};

      // edit mode
      if (location.state.selectedUseCase) {
        const { selectedUseCase } = location.state;

        // No need to transform, use the data as it is in the new structure
        formattedData = {
          ...selectedUseCase,
          // Ensure timestamps and other metadata are updated for edit mode
          created: {
            ...selectedUseCase.created,
            value: formatTimestamp(selectedUseCase.created?.value),
            created_timestamp: formatTimestamp(selectedUseCase.created?.value),
            updated_by: UserInformation().displayName,
          },
          updated: {
            ...selectedUseCase.updated,
            value: getCurrentDateTime(), //formatTimestamp(new Date().toISOString()),
            updated_by: UserInformation().displayName,
          },
          start_processing: {
            ...selectedUseCase.start_processing,
            value: getCurrentDateTime(),
          },
        };

        setUCMode('edit');
      }

      // template mode
      if (location.state.template) {
        const { template } = location.state;

        // Create a new formatted data object for template mode
        formattedData = {
          ...template,
          title: {
            ...template.title,
            value: 'TEMPLATE for ' + template.title.value,
          },
          created: {
            value: getCurrentDateTime(),
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDateTime(),
            updated_timestamp: null,
            updated_by: UserInformation().displayName,
          },
          updated: {
            value: getCurrentDateTime(),
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDateTime(),
            updated_timestamp: null,
            updated_by: UserInformation().displayName,
          },
          originally_created: {
            value: formatTimestamp(template.created.value),
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDateTime(),
            updated_timestamp: null,
            updated_by: UserInformation().displayName,
          },
          created_by: {
            value: UserInformation().displayName,
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDateTime(),
            updated_timestamp: null,
            updated_by: UserInformation().displayName,
          },
          originally_created_by: {
            value: template.created_by.value,
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDateTime(),
            updated_timestamp: null,
            updated_by: UserInformation().displayName,
          },
          start_processing: {
            value: getCurrentDateTime(),
            source: 'manual',
            modified: false,
            created_timestamp: getCurrentDateTime(),
            updated_timestamp: null,
            updated_by: UserInformation().displayName,
          },
        };

        setUCMode('template');
      }

      // Close modal and set use case data for editing or templating
      setShowModal(false);
      setShowModalEdit(true);
      setIsEdit(true);
      console.log('Formatted Data:', formattedData);
      setUseCaseData(formattedData);

    } else {
      console.log('No data passed.');
    }
    
  }, [location.state, setUseCaseData]); // setUseCaseData

  // Set initial request mode
  const handleModalClose = () => {
    //setShowModal(false);
    //setRequestMode(currentStep+1);
  }

  /** 
   * Sets the current step of the form.
   * @param {*} step 
   */
  const handleStepChange = (step) => {
  
    // Only allow to navigate to the next step if the current step is completed, meaning the user has entered data

    // Check if the current step is the first step
    if (step-1 === 0) {
      // Check if the fields are entered
      if ((!useCaseData.title.value || useCaseData.title.value === '')
        || !useCaseData.acronym.value || useCaseData.acronym.value === ''
        || !useCaseData.maturity.value || useCaseData.maturity.value === ''
        || !useCaseData.application.value || useCaseData.application.value === ''
        || !useCaseData.status.value || useCaseData.status.value === ''
        || !useCaseData.tags.value || useCaseData.tags.value.length === 0
        || !useCaseData.description.value || useCaseData.description.value === ''
        || !useCaseData.continents.value || useCaseData.continents.value.length === 0
        || !useCaseData.country.value || useCaseData.country.value.length === 0
        || !useCaseData.city.value || useCaseData.city.value.length === 0
      ) {
          alert('Please fill in all fields to advance with the next step.');
          return;
      }
    }

    setCurrentStep(step);
  };

  /**
   * Allows to navigate to the next step of the form.
   * @param {*} stepIndex 
   */
  const handleStepClick = (stepIndex) => {

    // Check if the current step is the first step
    if (stepIndex-1 === 0) {
      // Check if the fields are entered
      if ((!useCaseData.title.value || useCaseData.title.value === '')
        || !useCaseData.acronym.value || useCaseData.acronym.value === ''
        || !useCaseData.maturity.value || useCaseData.maturity.value === ''
        || !useCaseData.application.value || useCaseData.application.value === ''
        || !useCaseData.status.value || useCaseData.status.value === ''
        || !useCaseData.tags.value || useCaseData.tags.value.length === 0
        || !useCaseData.description.value || useCaseData.description.value === ''
        || !useCaseData.continents.value || useCaseData.continents.value.length === 0
        || !useCaseData.country.value || useCaseData.country.value.length === 0
        || !useCaseData.city.value || useCaseData.city.value.length === 0
      ) {
          alert('Please fill in all fields to advance with the next step.');
          return;
      }
    }

    setCurrentStep(stepIndex);
  };  

  // Handle the modal edit close
  const handleModalEditClose = () => {
    setShowModalEdit(false);
  }

  // Handle the save button click
  const handleSave = () => {
    //handleSubmitSave();
    setShowModalSave(true);
  }

  // Save the use case data to the database
  const handleSubmitSave = async () => {

    //console.log('UC Mode:', ucMode);
    if (ucMode === 'study') {
      // expand side bar... for the participant to see the current step change.
      setIsExpanded(true);
      setShowModalStudy(true);
    } else {
      setShowModalExecuteSave(true);
    }

    try {

      // Capture the current use case data
      const currentUseCaseData = { ...useCaseData };

      // Extract only the fields to store in the history
      const historyEntry = {
        ucid: currentUseCaseData?.ucid || '',
        acronym: currentUseCaseData?.acronym || '',
        title: currentUseCaseData?.title,
        description: currentUseCaseData?.description,
        status: currentUseCaseData?.status,
        application: currentUseCaseData?.application,
        actors: currentUseCaseData?.actors,
        actions: currentUseCaseData?.actions,
        conditions: currentUseCaseData?.conditions,
        components: currentUseCaseData?.components,
        tags: currentUseCaseData?.tags,
        maturity: currentUseCaseData?.maturity,
        continents: currentUseCaseData?.continents,
        country: currentUseCaseData?.country,
        country_code: currentUseCaseData?.country_code,
        country_flag: currentUseCaseData?.country_flag,  
        city: currentUseCaseData?.city,
        prompt: currentUseCaseData?.prompt,
        prompt_response: currentUseCaseData?.prompt_response,
        copilot_messages: currentUseCaseData?.copilot_messages,
        creation_mode: currentUseCaseData?.creation_mode,
        domain_model: currentUseCaseData?.domain_model,
        start_processing: currentUseCaseData?.start_processing,
        //end_processing: currentUseCaseData.end_processing,
        llm_model: currentUseCaseData?.llm_model,
        semantic_model: currentUseCaseData?.semantic_model,
        study_step: currentUseCaseData?.study_step,
        study_step_start: currentUseCaseData?.study_step_start,
        study_step_end: currentUseCaseData?.study_step_end,
        created_by: currentUseCaseData?.created_by,
        updated_by: studyExpertData
          ? studyExpertData?.anonymous_id
          : UserInformation().displayName,
        anonymous_id: studyExpertData
          ? studyExpertData?.anonymous_id
          : UserInformation().displayName,
        uid: UserInformation().uid,
        time_stamp: getCurrentDateTime(),
      };

      // Update the history object of the use case object and get the updated state
      const updatedUseCaseData = {
        ...currentUseCaseData,

        end_processing: {
          value: getCurrentDateTime(),
          source: 'manually',
          modified: true,
          created_timestamp: getCurrentDateTime(),
          updated_timestamp: getCurrentDateTime(),
          updated_by: studyExpertData ? studyExpertData?.anonymous_id : UserInformation().displayName,
          uid: UserInformation().uid,
        },

        // Add the current use case data to the history object
        history: {
          //...currentUseCaseData.history,
          data: [
            ...(currentUseCaseData.history?.data || []),
            historyEntry, //currentUseCaseData,
          ],
          time_stamp: [
            ...(currentUseCaseData.history?.time_stamp || []),
            getCurrentDateTime(),
          ],
          updated_by: [
            ...(currentUseCaseData.history?.updated_by || []),
            studyExpertData ? studyExpertData?.anonymous_id : UserInformation().displayName,
          ],
          uid: [
            ...(currentUseCaseData.history?.uid || []),
            UserInformation().uid,
          ]
        }

      };

      // handle additional flags and save data in the study mode
      let study_data = {};

      if (ucMode === 'study') {
        // add data to use case data
        study_data = {
          study_step_end: {
            value: getCurrentDateTime(),
            source: 'manually',
            modified: true,
            created_timestamp: getCurrentDateTime(),
            updated_timestamp: getCurrentDateTime(),
            updated_by: studyExpertData?.anonymous_id || 'No user name', //UserInformation().displayName,
            uid: UserInformation().uid,
            version: 1,
            priority: 'normal',
            relevance: 'normal',
          },
          study_step_duration: {
            value: getDuration(updatedUseCaseData.study_step_start.value, getCurrentDateTime()),
            source: 'manually',
            modified: true,
            created_timestamp: getCurrentDateTime(),
            updated_timestamp: getCurrentDateTime(),
            updated_by: studyExpertData?.anonymous_id || 'No user name', //UserInformation().displayName,
            uid: UserInformation().uid,
            version: 1,
            priority: 'normal',
            relevance: 'normal',
          },
        };
      }

      // Set the updated state
      if (ucMode === 'study') {
        setUseCaseData({...updatedUseCaseData, ...study_data});
      } else {
        // normal modes (edit, template, normal, copilot)
        setUseCaseData(updatedUseCaseData);
        // set the edit mode to true after saving in order for the user to further edit and save the same use case
        setIsEdit(true);
      }

      // Save the updated use case data (incl. history) to the database
      await saveUseCaseData(updatedUseCaseData);

      console.log('Use case data saved successfully!');
      
    } catch (error) {
      console.error('Error saving use case data:', error);
    }
  };

  const handleModalSaveClose = () => {
    setShowModalSave(false);
  }

  // Handle the save edit button click
  const handleSaveEdit = () => {
    //handleSubmitEdit();
    setShowModalSave(true);
  }

  // Update the use case data in the database
  const handleSubmitEdit = async () => {

    setShowModalExecuteSave(true);

    try {

      // Capture the current use case data
      const currentUseCaseData = { ...useCaseData };

      // Extract only the fields to store in the history
      const historyEntry = {
        ucid: currentUseCaseData?.ucid || '',
        acronym: currentUseCaseData?.acronym || '',
        title: currentUseCaseData?.title,
        description: currentUseCaseData?.description,
        status: currentUseCaseData?.status,
        application: currentUseCaseData?.application,
        actors: currentUseCaseData?.actors,
        actions: currentUseCaseData?.actions,
        conditions: currentUseCaseData?.conditions,
        components: currentUseCaseData?.components,
        tags: currentUseCaseData?.tags,
        maturity: currentUseCaseData?.maturity,
        continents: currentUseCaseData?.continents,
        country: currentUseCaseData?.country,
        country_code: currentUseCaseData?.country_code,
        country_flag: currentUseCaseData?.country_flag,  
        city: currentUseCaseData?.city,
        prompt: currentUseCaseData?.prompt,
        prompt_response: currentUseCaseData?.prompt_response,
        copilot_messages: currentUseCaseData?.copilot_messages,
        creation_mode: currentUseCaseData?.creation_mode,
        domain_model: currentUseCaseData?.domain_model,
        start_processing: currentUseCaseData?.start_processing,
        //end_processing: currentUseCaseData.end_processing,
        llm_model: currentUseCaseData?.llm_model,
        semantic_model: currentUseCaseData?.semantic_model,
        study_step: currentUseCaseData?.study_step,
        study_step_start: currentUseCaseData?.study_step_start,
        study_step_end: currentUseCaseData?.study_step_end,
        created_by: currentUseCaseData?.created_by,
        updated_by: studyExpertData
          ? studyExpertData?.anonymous_id
          : UserInformation().displayName,
        anonymous_id: studyExpertData
          ? studyExpertData?.anonymous_id
          : UserInformation().displayName,
        uid: UserInformation().uid,
        time_stamp: getCurrentDateTime(),
      };

      // Update the history object of the use case object and get the updated state
      const updatedUseCaseData = {
        ...currentUseCaseData,

        end_processing: {
          value: getCurrentDateTime(),
          source: 'manually',
          modified: true,
          created_timestamp: getCurrentDateTime(),
          updated_timestamp: getCurrentDateTime(),
          updated_by: studyExpertData ? studyExpertData?.anonymous_id : UserInformation().displayName,
          uid: UserInformation().uid,
        },

        // Add the updated data in the history object
        history: {
          //...currentUseCaseData.history,
          data: [
            ...(currentUseCaseData.history?.data || []),
            historyEntry, //currentUseCaseData,
          ],
          time_stamp: [
            ...(currentUseCaseData.history?.time_stamp || []),
            getCurrentDateTime(),
          ],
          updated_by: [
            ...(currentUseCaseData.history?.updated_by || []),
            studyExpertData ? studyExpertData?.anonymous_id : UserInformation().displayName,
            //UserInformation().displayName,
          ],
          uid: [
            ...(currentUseCaseData.history?.uid || []),
            UserInformation().uid,
          ]
        }
      };

      // Set the updated state
      setUseCaseData(updatedUseCaseData);

      console.log('Use case data updated successfully!', updatedUseCaseData);

      await updateUseCaseData(updatedUseCaseData);
      console.log('Use case data updated successfully!');
      // toggle edit for instant editing once use case is created and stored for the first time
      setIsEdit(true);
    } catch (error) {
      console.error('Error updating use case data:', error);
    }

  };  

  // Handle the completion button click
  const handleCompleteStudy = () => {
    
    setShowModalEndStudy(true);
    handleSubmitParticipantData();
  };

  // Handle Submit participant data for the entire study when the study is completed
  const handleSubmitParticipantData = async () => {
      
      try {
        // Save the participant data to the database
        await saveParticipantData(participantData);
        console.log('Participant data saved successfully!');
      } catch (error) {
        console.error('Error saving participant data:', error);
      }
  };  

  // Moving to the top of the page when changing steps
  useEffect(() => {
    setTimeout(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, 0);
  }, [currentStep]);

  return (
    <div>
      {!showSurvey && !showInterview && (
      <Container fluid>
        <h4>Create Use Case</h4>
        <Row align='center' className="mb-4">
          <JourneyBar steps={steps} currentStep={currentStep} onStepClick={handleStepClick} copilotSteps={copilotSteps} />
        </Row>
        <Row style={ { maxWidth: '1500px'} }>
          <Col>
            {/* Handle Call Association Guide / Co-Pilot visualization */}
            <div className="d-flex justify-content-end">
              {!showChat && (
              <Button 
                hidden={true}
                disabled //={currentStep === 1 || currentStep === 5}
                //className="btn btn-success" 
                style={{
                  backgroundColor: 'purple',
                  borderColor: 'purple',
                  color: 'white'
                }}
                onClick={handleChat}
                >
                <i className="bi bi-activity"></i> UCM Co-Pilot</Button>
              )}
            </div>
            
            {currentStep === 0 && <Info data={useCaseData} setData={setUseCaseData} useCaseDetails={useCaseDetails} uniqueKeywords={uniqueKeywords} ucMode={ucMode} setUCMode={setUCMode} />}
            {/*{currentStep === 1 && <Meta data={useCaseData} setData={setUseCaseData} />}*/}
            {currentStep === 1 && <Action data={useCaseData} setData={setUseCaseData} useCaseDetails={useCaseDetails} />}
            {currentStep === 2 && <Actors data={useCaseData} setData={setUseCaseData} useCaseDetails={useCaseDetails} />}
            {currentStep === 3 && <Components data={useCaseData} setData={setUseCaseData} useCaseDetails={useCaseDetails} sidebarItems={sidebarItems} setSidebarItems={setSidebarItems} onto_name={onto_name} />}
            {currentStep === 4 && <Model data={useCaseData} setData={setUseCaseData} useCaseDetails={useCaseDetails} isEdit={isEdit} setIsEdit={setIsEdit} useCases={useCases} handleSave={handleSave} handleSaveEdit={handleSaveEdit} />}

            {/* Journey step handling */}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
              {currentStep > 0 && (
                <Button variant='outline-primary' style={{ marginRight: 10 }}  onClick={() => handleStepChange(currentStep > 0 ? currentStep - 1 : currentStep)}>
                  Previous
                </Button>
              )}              
              {currentStep < 4 && (
                <Button variant='outline-primary' onClick={() => handleStepChange(currentStep < 4 ? currentStep + 1 : currentStep)}
                  //disabled={
                    //currentStep === 0 && (!useCaseData.title.value || useCaseData.title.value === '' || !useCaseData.acronym.value || useCaseData.acronym.value === '' || !useCaseData.maturity.value || useCaseData.maturity.value === '' || !useCaseData.application.value || useCaseData.application.value === '' || !useCaseData.status.value || useCaseData.status.value === '' || !useCaseData.tags.value || useCaseData.tags.value.length === 0 || !useCaseData.description.value || useCaseData.description.value === '' || !useCaseData.continents.value || useCaseData.continents.value.length === 0 || !useCaseData.country.value || useCaseData.country.value.length === 0 || !useCaseData.city.value || useCaseData.city.value.length === 0)}
                >
                  Next
                </Button>
              )}
              {currentStep === 4 && (
                <>
                {/*}
                <Button variant='outline-success' onClick={() => handleStepChange(currentStep < 5 ? currentStep + 1 : currentStep)}>
                  Start Ecosystem Analysis
                </Button>
                */}
                
                {!isEdit ? (
                  <>
                  {/*
                  <OverlayTrigger placement="top" overlay={<Tooltip>Save modeled use case in the repository</Tooltip>}>
                      <Button variant="success" onClick={handleSave}>
                          <i className="bi bi-floppy"></i> Save to Repository
                      </Button>
                  </OverlayTrigger>
                  */}
                  </>
                ) : (
                  <OverlayTrigger placement="top" overlay={<Tooltip>Update use case data and save to repository</Tooltip>}>
                      <Button variant="outline-success" onClick={handleSaveEdit}>
                          <i className="bi bi-pencil"></i> Update and Save to Repository
                      </Button>
                  </OverlayTrigger>
                )}
                </>
              )}
            </div>
          </Col>
          {/* Handle Association Guide visualization */}
          {showChat && (
            <Col  md={4} className="d-flex flex-column">
              <div className="d-flex justify-content-between align-items-center">
                <label className="form-label">UCM Co-Pilot</label>
                <button type="button" className="btn-close" aria-label="Close" onClick={handleChat}></button>
              </div>
              <Chat user={useCaseData.user_first_name} messages={messages} handleSubmit={handleSubmit} inputValue={inputValue} handleChange={handleChange} />
            </Col>
          )}
        </Row>
      </Container>
      )}
      
      {showSurvey && (
        <Survey 
          studyExpertData={studyExpertData}
          setShowModalStudy={setShowModalStudy}
        />
      )}

      {showInterview && (
        <Interview 
          studyExpertData={studyExpertData}
          setShowModalStudy={setShowModalStudy}
        />
      )}
      
      {/* Chat Modal (calling the association guide) applies to the entire journey and only initializes once at the start */}
      {showModal && (
        <ChatModal 
          domainModels={domainModels}
          setDomainModels={setDomainModels}
          data={useCaseData} 
          setData={setUseCaseData} 
          user={useCaseData.created_by.value} 
          messages={messages} 
          handleSubmit={handleSubmit} 
          inputValue={inputValue} 
          handleChange={handleChange} 
          onClose={handleModalClose} 
          mode={location.state.mode && 'study'}
        />
      )}

      {showModalEdit && (
        <ModelModalEdit 
          onClose={handleModalEditClose} 
          uc_name={useCaseData.title.value} />
      )}

      {showModalSave && (
        <ModelModalSave
            data={useCaseData}
            setData={setUseCaseData}
            onClose={handleModalSaveClose}
            uc_name={useCaseData.title.value}
            isEdit={isEdit}
            handleSubmitSave={handleSubmitSave}
            handleSubmitEdit={handleSubmitEdit}
        />
      )}

      {showModalExecuteSave && (
        <ModelModalExecuteSave
          onClose={() => setShowModalExecuteSave(false)}
          uc_name={useCaseData.title.value}
          isEdit={isEdit}
          handleSubmitSave={handleSubmitSave}
          handleSubmitEdit={handleSubmitEdit}
        />
      )}

      <LoadingModal 
        show={isLoading} 
        messages={loaderResponse} 
      />

      {/* Study Mode */}
      {ucMode === 'study' && (
        <StudyProgressSidebar 
          currentStep={studyStep} 
          setStudyStep={setStudyStep} 
          isExpanded={isExpanded} 
          setIsExpanded={setIsExpanded}
        />
      )}

      {ucMode === 'study' && (
        <StudyGuide 
          currentStep={studyStep}
          isGuideExpanded={isGuideExpanded}
          setIsGuideExpanded={setIsGuideExpanded}
        />
      )}

      {showModalStudy && (
        <ModelModalStudy
            onClose={() => setShowModalStudy(false)}
            studyStep={studyStep}
            anonymous_id={studyExpertData?.anonymous_id || 'N/A'}
            setStudyStep={setStudyStep}
            setCurrentStep={setCurrentStep}
            setIsGuideExpanded={setIsGuideExpanded}
            handleCompleteStudy={handleCompleteStudy}
        />
      )}

      {/* Completion Modal, see ModelModalStudy */}
      <StudyModalComplete showModal={showModalEndStudy} setShowModal={setShowModalEndStudy} />

    </div>
  );
};

// Modal Study: study step completed; initiate next step
const ModelModalStudy = ({ onClose, studyStep, anonymous_id, setStudyStep, setCurrentStep, setIsGuideExpanded, handleCompleteStudy }) => {

  const handleNextStep = () => {
    onClose();
    // set the study step to the next step
    setStudyStep(studyStep + 1);
    if (studyStep < 4) {
      setCurrentStep(0);
    }
    setIsGuideExpanded(true);
  }

  return (
    <Modal show={true} onHide={onClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Study Step {studyStep} is Completed !</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {studyStep < 4 && (
          <div align='center'>
            <p>Your modeled use case has been successfully added to the research sample.</p>
            <p><b>Let's proceed to the next step.</b></p>
          </div>
        )}
        {studyStep === 4 && (
          <div align='center'>
            <p>The survey has been successfully completed.</p>
            <p><b>Let's proceed to the last step, a short interview.</b></p>
          </div>
        )}
        {studyStep === 5 && (
          <div align='center'>
            <p>The interview has been successfully completed.</p>
            <p><b>Thank you for your participation in this research study!</b></p>
          </div>
        )}
        <hr />

        <p style={ { fontSize:'14px'} }>
          Please note that you won't be able to see use case insights in the study mode. 
          Feel free to note down your anonymized id <b>{anonymous_id}</b> to look up your modeled 
          use cases once the study is completed.
        </p>
      </Modal.Body>
      <Modal.Footer>
        {studyStep < 5 && (
          <Button variant="primary" onClick={handleNextStep}>Move to Step {studyStep+1}</Button>
        )}
        {studyStep === 5 && (          
          <Button
            variant="danger"
            className="btn-sm"
            onClick={() => {
              onClose();
              handleCompleteStudy();
            }}
            disabled={studyStep < 5}
          >
            Complete Study
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default UseCaseForm;