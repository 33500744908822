/**
 * Analytics Adoption
 *
 * Description: These charts mainly apply to insights for a SINGLE use case
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-09-21
 * 
 */

import React, { useState, useRef } from 'react';
import { Card, Button, Modal } from 'react-bootstrap';
import { Bar, Line } from 'react-chartjs-2';

import { 
  // helper
  normalizeString,

  buildUseCasePairs,
  filterUseCasesByStep,
 } from '../eco_analytics_handling/eco_analytics_prepare_charts';

// #########################################################################################################################
// ####################################  Section A just applies to a single use case #######################################

// #########################################################################################################################
// ####################################  General Fields Adoption Chart #####################################################
// #########################################################################################################################

const GENERAL_FIELDS = [
    "title", "acronym", "continents", "country", "city",
    "maturity", "application", "status", "tags", "description"
];


/**
 * Utility: returns the field's .value as a single, sorted string.
 * If it's an array => sort it, then join by comma.
 * If it's a string => use as is.
 * Otherwise => ""
 */
function getStringValue(uc, field) {
    const val = uc[field]?.value;
    if (Array.isArray(val)) {
        return val.slice().sort().join(",");  // Sort array before joining!
    } 
    if (typeof val === "string") {
        return val;
    }
    return "";
}
  
/**
 * Compare oldUC vs newUC for the 10 general fields. 
 * Now if .value is an array (like tags), we unify to a comma-joined string.
 */
function diffGeneralFields(oldUC = {}, newUC = {}) {
    let added = 0, removed = 0, changed = 0, unchanged = 0;
    let allAdded = [], allRemoved = [], allChanged = [], allUnchanged = [];
  
    const fieldDiffs = GENERAL_FIELDS.map(field => {
        const oldVal = getStringValue(oldUC, field);
        const newVal = getStringValue(newUC, field);

        //console.log(`[DEBUG] field=${field} => oldVal="${oldVal}", newVal="${newVal}" => same?`, oldVal === newVal);

        if (!oldVal && newVal) {
            added++;
            allAdded.push({ field, type: "added", detail: `${field}: ${newVal}` });
            return { field, type: "added", detail: `${field}: ${newVal}` };
        }
        if (oldVal && !newVal) {
            removed++;
            allRemoved.push({ field, type: "removed", detail: `${field}: ${oldVal}` });
            return { field, type: "removed", detail: `${field}: ${oldVal}` };
        }
        if (oldVal === newVal) {
            unchanged++;
            allUnchanged.push({ field, type: "unchanged", detail: `${field}: ${oldVal}` });
            return { field, type: "unchanged", detail: `${field}: ${oldVal}` };
        }

        changed++;
        allChanged.push({ field, type: "changed", detail: `${field}: ${oldVal} => ${newVal}` });
        return { field, type: "changed", detail: `${field}: ${oldVal} => ${newVal}` };
    });
  
    return {
      added, removed, changed, unchanged,
      allAdded, allRemoved, allChanged, allUnchanged,
      fieldDiffs
    };
}
  
/**
 * Compare each of the 10 general fields from oldUC to newUC.
 * Always returns an **array of 10 objects**:
 *   [ {field:'title', type:'added', detail:'title: Parking => New Value'}, ... ]
 */
function getGeneralFieldsDiff(oldUC = {}, newUC = {}) {

    return GENERAL_FIELDS.map(field => {

        //const oldVal = oldUC[field]?.value || "";
        //const newVal = newUC[field]?.value || "";

        const oldVal = getStringValue(oldUC, field);
        const newVal = getStringValue(newUC, field);
    
        //console.log(`[DEBUG UNCHANGED] field=${field} => oldVal="${oldVal}", newVal="${newVal}" => same?`, oldVal === newVal);

        if (!oldVal && newVal) {
            return { field, type: "added", detail: `${field}: ${newVal}` };
        }
        if (oldVal && !newVal) {
            return { field, type: "removed", detail: `${field}: ${oldVal}` };
        }
        if (oldVal === newVal) {        
            return { field, type: "unchanged", detail: `${field}: ${oldVal}` };
        }

        return { field, type: "changed", detail: `${field}: ${oldVal} => ${newVal}` };
    });
}
  
export function GeneralFieldsAdoptionChart({ oldData, newData }) {

    const chartRef = useRef(null);
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState("");
  
    // 1) Compute the per-field diffs (always an array of length 10)
    const fieldDiffs = getGeneralFieldsDiff(oldData, newData);
  
    // 2) Build 4 arrays (added, removed, changed, unchanged)
    const addedData = new Array(10).fill(0);
    const removedData = new Array(10).fill(0);
    const changedData = new Array(10).fill(0);
    const unchangedData = new Array(10).fill(0);
  
    // Store details for modal click
    const detailMatrix = {
      added: new Array(10).fill(""),
      removed: new Array(10).fill(""),
      changed: new Array(10).fill(""),
      unchanged: new Array(10).fill("")
    };
  
    // 3) Fill data arrays (1 where applicable, 0 otherwise)
    fieldDiffs.forEach((diff, i) => {
      if (diff.type === "added") {
        addedData[i] = 1;
        detailMatrix.added[i] = diff.detail;
      } else if (diff.type === "removed") {
        removedData[i] = 1;
        detailMatrix.removed[i] = diff.detail;
      } else if (diff.type === "changed") {
        changedData[i] = 1;
        detailMatrix.changed[i] = diff.detail;
      } else {
        unchangedData[i] = 1;
        detailMatrix.unchanged[i] = diff.detail;
      }
    });
  
    // 4) Prepare chart data
    const data = {
      labels: GENERAL_FIELDS, // X-axis: The 10 fields
      datasets: [
        { label: "Added", data: addedData, backgroundColor: "rgba(75,192,192,0.6)" },
        { label: "Removed", data: removedData, backgroundColor: "rgba(255,99,132,0.6)" },
        { label: "Changed", data: changedData, backgroundColor: "rgba(255,206,86,0.6)" },
        { label: "Unchanged", data: unchangedData, backgroundColor: "rgba(153,102,255,0.6)" }
      ]
    };
  
    // 5) Chart Options (Click => Modal, Hover => Pointer)
    const options = {
      responsive: true,
      scales: {
        x: { stacked: true },
        y: { stacked: true, beginAtZero: true }
      },
      plugins: { legend: { position: "top" } },
      onHover: (evt, elements) => {
        if (elements?.length) {
          evt.native.target.style.cursor = "pointer";
        } else {
          evt.native.target.style.cursor = "default";
        }
      },
      onClick: (evt, elements) => {
        if (!elements?.length) return;
        const { datasetIndex, index } = elements[0];
  
        const datasetLabel = data.datasets[datasetIndex].label; // "Added"/"Removed"/...
        const fieldName = data.labels[index]; // e.g. "title" or "acronym"
  
        let detailText = detailMatrix[datasetLabel.toLowerCase()][index] || "(no detail)";
  
        setModalTitle(`${datasetLabel} - ${fieldName}`);
        setModalContent(detailText);
        setModalShow(true);
      }
    };
  
    return (
      <>
        <Bar ref={chartRef} data={data} options={options} style={{ maxHeight: 400 }} />
  
        {/* Modal to Show Details */}
        <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{modalContent}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalShow(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}

// #########################################################################################################################
// ####################################  Scenario and Conditions Adoption Chart ############################################
// #########################################################################################################################

/**
 * Compares scenario names (and descriptions) between old and new use cases
 * but only categorizes them as Added, Removed, or Unchanged.
 */
/*
function diffScenarios(oldUC, newUC) {
    const oldScenarios = Array.isArray(oldUC?.actions?.value) ? oldUC.actions.value : [];
    const newScenarios = Array.isArray(newUC?.actions?.value) ? newUC.actions.value : [];
  
    // Maps of scenarioName -> description
    const oldMap = new Map(oldScenarios.map(s => [s.name, s.description]));
    const newMap = new Map(newScenarios.map(s => [s.name, s.description]));
  
    let totalAdded = 0;
    let totalRemoved = 0;
    let totalUnchanged = 0;
  
    const allAdded = [];
    const allRemoved = [];
    const allUnchanged = [];
  
    // Check new scenarios (detect "added" or "unchanged")
    newMap.forEach((newDesc, scenarioName) => {
      if (!oldMap.has(scenarioName)) {
        // Scenario doesn't exist in old => ADDED
        totalAdded++;
        allAdded.push(`${scenarioName}: ${newDesc}`);
      } else {
        // Scenario name is in old => UNCHANGED
        totalUnchanged++;
        allUnchanged.push(`${scenarioName}: ${newDesc}`);
      }
    });
  
    // Check old scenarios (detect "removed")
    oldMap.forEach((oldDesc, scenarioName) => {
      if (!newMap.has(scenarioName)) {
        totalRemoved++;
        allRemoved.push(`${scenarioName}: ${oldDesc}`);
      }
    });
  
    return {
      totalAdded,
      totalRemoved,
      totalUnchanged,
      allAdded,
      allRemoved,
      allUnchanged
    };
}
*/
  
/**
 * Compares scenario names (and descriptions) between oldUC and newUC
 * while ignoring trivial punctuation differences (like commas) and case.
 * It categorizes scenarios as Added, Removed, or Unchanged.
 */
function diffScenarios(oldUC, newUC) {
    const oldScenarios = Array.isArray(oldUC?.actions?.value) ? oldUC.actions.value : [];
    const newScenarios = Array.isArray(newUC?.actions?.value) ? newUC.actions.value : [];
  
    // Create maps using normalized scenario names as keys.
    const oldMap = new Map(oldScenarios.map(s => [normalizeString(s.name), s.description]));
    const newMap = new Map(newScenarios.map(s => [normalizeString(s.name), s.description]));
  
    let totalAdded = 0;
    let totalRemoved = 0;
    let totalUnchanged = 0;
  
    const allAdded = [];
    const allRemoved = [];
    const allUnchanged = [];
  
    // Check new scenarios (detect "added" or "unchanged")
    newMap.forEach((newDesc, normName) => {
      if (!oldMap.has(normName)) {
        totalAdded++;
        allAdded.push(`${normName}: ${newDesc}`);
      } else {
        totalUnchanged++;
        allUnchanged.push(`${normName}: ${newDesc}`);
      }
    });
  
    // Check old scenarios (detect "removed")
    oldMap.forEach((oldDesc, normName) => {
      if (!newMap.has(normName)) {
        totalRemoved++;
        allRemoved.push(`${normName}: ${oldDesc}`);
      }
    });
  
    return {
      totalAdded,
      totalRemoved,
      totalUnchanged,
      allAdded,
      allRemoved,
      allUnchanged
    };
}
  
/**
 * Component for Scenario Adoption Chart (Added/Removed/Unchanged).
 */
export function ScenarioAdoptionChart({ oldData, newData }) {
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalItems, setModalItems] = useState([]);
  
    //console.log("[DEBUG] oldData", oldData);
    //console.log("[DEBUG] newData", newData);

    const diff = diffScenarios(oldData, newData);
    //console.log(diff);
  
    const labels = ["Scenarios"];
  
    // For that single column, we place a data value for each status.
    const datasetAdded = {
      label: "Added",
      data: [diff.totalAdded],           // one bar (the first/only column)
      backgroundColor: "rgba(75,192,192,0.6)", // teal
    };
    const datasetRemoved = {
      label: "Removed",
      data: [diff.totalRemoved],
      backgroundColor: "rgba(255,99,132,0.6)", // red
    };
    const datasetUnchanged = {
      label: "Unchanged",
      data: [diff.totalUnchanged],
      backgroundColor: "rgba(153,102,255,0.6)", // purple
    };
  
    const data = {
      labels, // ["Scenarios"]
      datasets: [datasetAdded, datasetRemoved, datasetUnchanged],
    };
  
    const options = {
      responsive: true,
      plugins: {
        legend: { position: "top" },
      },
      scales: {
        x: { stacked: true },
        y: { stacked: true, beginAtZero: true },
      },
      onClick: (evt, elements) => {
        if (!elements?.length) return;
  
        // elements[0] => { index, datasetIndex }
        // index => which column (always 0 here, because we have only 1 label)
        // datasetIndex => which status dataset (0=Added, 1=Removed, 2=Unchanged)
        const { datasetIndex } = elements[0];
        const labelMap = ["Added", "Removed", "Unchanged"];
        const clickedStatus = labelMap[datasetIndex];
        openModal(clickedStatus);
      },
    };
  
    function openModal(status) {
      setModalTitle(`${status} Scenarios`);
      let items = [];
      if (status === "Added") items = diff.allAdded;
      else if (status === "Removed") items = diff.allRemoved;
      else if (status === "Unchanged") items = diff.allUnchanged;
      setModalItems(items);
      setModalShow(true);
    }
  
    return (
      <>
        <Bar data={data} options={options} />
  
        <Modal show={modalShow} onHide={() => setModalShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalItems.length === 0 ? (
              <p>No items found.</p>
            ) : (
              <ul className="small">
                {modalItems.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalShow(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}
  
const CONDITION_FIELDS = ["preConditions","postConditions","constraints","assumptions"];

/**
 * Compares condition items between old and new use cases.
 * Each field is only classified as added, removed, or unchanged
 * 
 * Treated as a set which is not needed; below treated as a list
 */
/*
function diffScenarioConditions(oldUC, newUC) {
    const oldConditions = Array.isArray(oldUC?.conditions?.value) ? oldUC.conditions.value : [];
    const newConditions = Array.isArray(newUC?.conditions?.value) ? newUC.conditions.value : [];
  
    let totalAdded = 0;
    let totalRemoved = 0;
    let totalUnchanged = 0;
  
    const categoryDiffs = {}; 
    // { preConditions: { added: [], removed: [], unchanged: [] }, ... }
  
    CONDITION_FIELDS.forEach(field => {
      // Flatten all old/new values for the specific field
      const oldValues = oldConditions.map(cond => cond[field] || "").flat();
      const newValues = newConditions.map(cond => cond[field] || "").flat();
  
      const oldSet = new Set(oldValues);
      const newSet = new Set(newValues);
  
      const added = [...newSet].filter(x => !oldSet.has(x));
      const removed = [...oldSet].filter(x => !newSet.has(x));
      const unchanged = [...oldSet].filter(x => newSet.has(x));
  
      totalAdded += added.length;
      totalRemoved += removed.length;
      totalUnchanged += unchanged.length;
  
      categoryDiffs[field] = { added, removed, unchanged };
    });
  
    return {
      totalAdded,
      totalRemoved,
      totalUnchanged,
      categoryDiffs
    };
}
*/
function diffScenarioConditions(oldUC, newUC) {
    const oldConditions = Array.isArray(oldUC?.conditions?.value)
      ? oldUC.conditions.value
      : [];
    const newConditions = Array.isArray(newUC?.conditions?.value)
      ? newUC.conditions.value
      : [];
  
    let totalAdded = 0;
    let totalRemoved = 0;
    let totalUnchanged = 0;
  
    const categoryDiffs = {}; 
    // CONDITION_FIELDS should be defined globally, e.g.:
    // const CONDITION_FIELDS = ["preConditions", "postConditions", "constraints", "assumptions"];
  
    CONDITION_FIELDS.forEach(field => {
      // Flatten all old/new values for the field
      const oldValues = oldConditions.map(cond => cond[field] || "").flat();
      const newValues = newConditions.map(cond => cond[field] || "").flat();
  
      // Build frequency maps
      const oldFreq = {};
      oldValues.forEach(item => {
        oldFreq[item] = (oldFreq[item] || 0) + 1;
      });
      const newFreq = {};
      newValues.forEach(item => {
        newFreq[item] = (newFreq[item] || 0) + 1;
      });
  
      // For each unique condition (even if duplicated)
      const addedArr = [];
      const removedArr = [];
      const unchangedArr = [];
  
      const allKeys = new Set([...Object.keys(oldFreq), ...Object.keys(newFreq)]);
      allKeys.forEach(item => {
        const oldCount = oldFreq[item] || 0;
        const newCount = newFreq[item] || 0;
        const unchangedCount = Math.min(oldCount, newCount);
        const addedCount = newCount - unchangedCount;
        const removedCount = oldCount - unchangedCount;
  
        for (let i = 0; i < unchangedCount; i++) {
          unchangedArr.push(item);
        }
        for (let i = 0; i < addedCount; i++) {
          addedArr.push(item);
        }
        for (let i = 0; i < removedCount; i++) {
          removedArr.push(item);
        }
      });
  
      totalAdded += addedArr.length;
      totalRemoved += removedArr.length;
      totalUnchanged += unchangedArr.length;
  
      categoryDiffs[field] = { added: addedArr, removed: removedArr, unchanged: unchangedArr };
    });
  
    return {
      totalAdded,
      totalRemoved,
      totalUnchanged,
      categoryDiffs
    };
}  

/**
 * Stacked bar showing how many condition items were added, removed, or unchanged
 * across the four condition fields (preConditions, postConditions, constraints, assumptions).
 */
export function ScenarioConditionsAdoptionChart({ oldData, newData }) {
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalItems, setModalItems] = useState([]);
  
    //console.log("[DEBUG] oldData", oldData);
    //console.log("[DEBUG] newData", newData);

    const diff = diffScenarioConditions(oldData, newData);
    //console.log(diff);
  
    // The x-axis = each condition field
    const labels = CONDITION_FIELDS;
  
    // For each condition field, we find how many items were added/removed/unchanged
    // from diff.categoryDiffs[field].added, removed, unchanged
    const addedData = [];
    const removedData = [];
    const unchangedData = [];
  
    labels.forEach((field) => {
      const cat = diff.categoryDiffs[field];
      addedData.push(cat.added.length);
      removedData.push(cat.removed.length);
      unchangedData.push(cat.unchanged.length);
    });
  
    // Build 3 datasets, one for each status
    const data = {
      labels, // e.g. ["preConditions", "postConditions", "constraints", "assumptions"]
      datasets: [
        {
          label: "Added",
          data: addedData,
          backgroundColor: "rgba(75,192,192,0.6)", // teal
        },
        {
          label: "Removed",
          data: removedData,
          backgroundColor: "rgba(255,99,132,0.6)", // red
        },
        {
          label: "Unchanged",
          data: unchangedData,
          backgroundColor: "rgba(153,102,255,0.6)", // purple
        },
      ],
    };
  
    const options = {
      responsive: true,
      plugins: {
        legend: { position: "top" },
      },
      scales: {
        x: { stacked: true },
        y: { stacked: true, beginAtZero: true },
      },
      onClick: (evt, elements) => {
        if (!elements || elements.length === 0) return;
        const { index, datasetIndex } = elements[0];
        // index => which condition field (0=preConditions, 1=postConditions, etc.)
        // datasetIndex => which status (0=Added, 1=Removed, 2=Unchanged)
        const clickedField = labels[index];
        const labelMap = ["Added", "Removed", "Unchanged"];
        const clickedStatus = labelMap[datasetIndex];
        openModal(clickedField, clickedStatus);
      },
    };
  
    function openModal(field, status) {
      setModalTitle(`${status} for ${field}`);
      const cat = diff.categoryDiffs[field];
      let items = [];
      if (status === "Added") items = cat.added;
      else if (status === "Removed") items = cat.removed;
      else items = cat.unchanged;
      setModalItems(items);
      setModalShow(true);
    }
  
    return (
      <>
        <Bar data={data} options={options} style={{ cursor: "pointer" }} />
  
        <Modal show={modalShow} onHide={() => setModalShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalItems.length === 0 ? (
              <p>No items found.</p>
            ) : (
              <ul className="small">
                {modalItems.map((item, i) => <li key={i}>{item}</li>)}
              </ul>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalShow(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}
  
/**
 * Compare the new scenario list to the old scenario list (by name).
 * Also compare condition objects for each scenario.
 *
 * Track:
 *   - "Added" (new scenario or new condition text not in old)
 *   - "Removed" (old scenario or old condition not in new)
 *   - "Unchanged" (both old and new exist, so we label it unchanged)
 */
function diffScenarioWithConditions(oldUC, newUC) {
    // Array of scenarios
    const oldScenarios = Array.isArray(oldUC?.actions?.value)
      ? oldUC.actions.value
      : [];
    const newScenarios = Array.isArray(newUC?.actions?.value)
      ? newUC.actions.value
      : [];
    // Array of condition objects
    const oldConditions = Array.isArray(oldUC?.conditions?.value)
      ? oldUC.conditions.value
      : [];
    const newConditions = Array.isArray(newUC?.conditions?.value)
      ? newUC.conditions.value
      : [];
  
    let totalAdded = 0;
    let totalRemoved = 0;
    let totalUnchanged = 0;
  
    const allAdded = [];
    const allRemoved = [];
    const allUnchanged = [];
  
    const maxLen = Math.max(oldScenarios.length, newScenarios.length);
  
    // Helper to format scenario+conditions info for the modal
    const formatScenarioBlock = (scenario, condition, indexLabel) => {
      const name = scenario?.name ?? "(No scenario name)";
      const desc = scenario?.description ?? "(No scenario description)";
      const pre = condition?.preConditions ?? "(No preConditions)";
      const post = condition?.postConditions ?? "(No postConditions)";
      const cons = condition?.constraints ?? "(No constraints)";
      const ass = condition?.assumptions ?? "(No assumptions)";
      return (
        `Scenario [#${indexLabel}]\n` +
        `  Name: ${name}\n` +
        `  Description: ${desc}\n` +
        `  preConditions: ${pre}\n` +
        `  postConditions: ${post}\n` +
        `  constraints: ${cons}\n` +
        `  assumptions: ${ass}`
      );
    };
  
    for (let i = 0; i < maxLen; i++) {
      const oldScenario = oldScenarios[i];
      const newScenario = newScenarios[i];
      const oldCond = oldConditions[i];
      const newCond = newConditions[i];
      const indexLabel = i + 1;
  
      // CASE 1: Old is missing, new is present => "Added"
      if (!oldScenario && newScenario) {
        totalAdded++;
        allAdded.push(formatScenarioBlock(newScenario, newCond, indexLabel));
        continue;
      }
      // CASE 2: New is missing, old is present => "Removed"
      if (oldScenario && !newScenario) {
        totalRemoved++;
        allRemoved.push(formatScenarioBlock(oldScenario, oldCond, indexLabel));
        continue;
      }
      // CASE 3: Both exist => compare normalized names
      if (oldScenario && newScenario) {
        const oldNameNorm = normalizeString(oldScenario.name);
        const newNameNorm = normalizeString(newScenario.name);
        if (oldNameNorm === newNameNorm) {
          totalUnchanged++;
          allUnchanged.push(formatScenarioBlock(newScenario, newCond, indexLabel));
        } else {
          // Treat as removed and added separately.
          totalRemoved++;
          allRemoved.push(formatScenarioBlock(oldScenario, oldCond, indexLabel));
          totalAdded++;
          allAdded.push(formatScenarioBlock(newScenario, newCond, indexLabel));
        }
      }
    }
  
    return {
      totalAdded,
      totalRemoved,
      totalUnchanged,
      allAdded,
      allRemoved,
      allUnchanged
    };
}

/**
 * Component that shows a single bar chart summarizing:
 *   - # new scenarios or condition sets added
 *   - # old scenarios or condition sets removed
 *   - # scenarios/conditions unchanged
 * 
 */
export function ScenarioConditionCombinedChart({ oldData, newData }) {
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalItems, setModalItems] = useState([]);
  
    const diff = diffScenarioWithConditions(oldData, newData);
  
    // Only 1 column: "Scenarios & Conditions"
    const labels = ["Scenarios & Conditions"];
  
    // 3 datasets: "Added", "Removed", "Unchanged"
    const data = {
      labels,
      datasets: [
        {
          label: "Added",
          data: [diff.totalAdded],
          backgroundColor: "rgba(75,192,192,0.6)", // teal
        },
        {
          label: "Removed",
          data: [diff.totalRemoved],
          backgroundColor: "rgba(255,99,132,0.6)", // red
        },
        {
          label: "Unchanged",
          data: [diff.totalUnchanged],
          backgroundColor: "rgba(153,102,255,0.6)", // purple
        }
      ]
    };
  
    const options = {
      responsive: true,
      plugins: {
        legend: { position: "top" },
      },
      scales: {
        x: { stacked: true },
        y: { stacked: true, beginAtZero: true },
      },
      onClick: (evt, elements) => {
        if (!elements || elements.length === 0) return;
        const { datasetIndex } = elements[0];
        const labelMap = ["Added", "Removed", "Unchanged"];
        const clickedStatus = labelMap[datasetIndex];
        openModal(clickedStatus);
      }
    };
  
    function openModal(status) {
      setModalTitle(`${status} Scenarios & Conditions`);
      let items = [];
      if (status === "Added") items = diff.allAdded;
      else if (status === "Removed") items = diff.allRemoved;
      else items = diff.allUnchanged;
      setModalItems(items);
      setModalShow(true);
    }
  
    return (
      <>
        <Bar data={data} options={options} style={{ cursor: "pointer" }} />
  
        <Modal show={modalShow} onHide={() => setModalShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalItems.length === 0 ? (
              <p>No items found.</p>
            ) : (
              <ul style={{ whiteSpace: "pre-wrap" }} className="small">
                {modalItems.map((item, i) => <li key={i}>{item}</li>)}
              </ul>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalShow(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}

// #########################################################################################################################
// ####################################  Actor Adoption Chart ##############################################################
// #########################################################################################################################
  
/**
 * Compares the actor lists between oldUC and newUC, both at
 * oldUC.actors?.value?.list and newUC.actors?.value?.list.
 *
 * This function:
 *  - Gathers *all* categories (the keys in .list).
 *  - For each category:
 *    * oldActors = oldList[cat].value || []
 *    * newActors = newList[cat].value || []
 *    * added = items in newActors not in oldActors
 *    * removed = items in oldActors not in newActors
 *    * unchanged = items in both sets (the same text)
 *    * changed = items in both sets but at different indices => reorder detection
 *
 *    consider reorder as "changed", remove that block.
 */
export function diffActorLists(oldUC, newUC) {
    const oldList = oldUC?.actors?.value?.list || {};
    const newList = newUC?.actors?.value?.list || {};
  
    // Gather all categories (both old and new)
    const allCategories = new Set([
      ...Object.keys(oldList),
      ...Object.keys(newList),
    ]);
  
    let totalAdded = 0,
      totalRemoved = 0,
      totalChanged = 0,
      totalUnchanged = 0;
  
    const categoryDiffs = {}; // { categoryName: { added:[], removed:[], changed:[], unchanged:[] } }
  
    for (const category of allCategories) {
      const oldActors = Array.isArray(oldList[category]?.value)
        ? oldList[category].value
        : [];
      const newActors = Array.isArray(newList[category]?.value)
        ? newList[category].value
        : [];
  
      const oldSet = new Set(oldActors);
      const newSet = new Set(newActors);
  
      // Actors that exist in newSet but not in oldSet => ADDED
      const added = [...newSet].filter((x) => !oldSet.has(x));
  
      // Actors that exist in oldSet but not in newSet => REMOVED
      const removed = [...oldSet].filter((x) => !newSet.has(x));
  
      // Actors in both sets => either UNCHANGED or CHANGED (if index differs)
      const intersection = [...oldSet].filter((x) => newSet.has(x));
      const unchanged = [];
      const changed = [];
  
      intersection.forEach((actor) => {
        // "changed" means it appears at a different index in old vs new
        const oldIndex = oldActors.indexOf(actor);
        const newIndex = newActors.indexOf(actor);
        if (oldIndex !== newIndex) {
          changed.push(actor);
        } else {
          unchanged.push(actor);
        }
      });
  
      // Update totals
      totalAdded += added.length;
      totalRemoved += removed.length;
      totalChanged += changed.length;
      totalUnchanged += unchanged.length;
  
      categoryDiffs[category] = { added, removed, changed, unchanged };
    }
  
    return {
      totalAdded,
      totalRemoved,
      totalChanged,
      totalUnchanged,
      categoryDiffs,
    };
}

/**
 * Component for displaying the Actor Adoption Chart.
 */
export function ActorAdoptionChart({ oldData, newData }) {
    const chartRef = useRef(null);
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalItems, setModalItems] = useState([]);
  
    // 1) Perform the diff to get results
    const diff = diffActorLists(oldData, newData);
  
    // 2) Build an array of category names (x-axis)
    const categories = Object.keys(diff.categoryDiffs); // e.g. ["Consumer", "Producer", "Technical", ...]
  
    // 3) For each category, we have 4 statuses: added, removed, changed, unchanged.
    //    We'll build a data array for each status (one dataset per status).
    const addedData = [];
    const removedData = [];
    const changedData = [];
    const unchangedData = [];
  
    categories.forEach((cat) => {
      const catDiff = diff.categoryDiffs[cat]; 
      addedData.push(catDiff.added.length);
      removedData.push(catDiff.removed.length);
      changedData.push(catDiff.changed.length);
      unchangedData.push(catDiff.unchanged.length);
    });
  
    // 4) Chart data: 
    //    - x-axis => categories
    //    - 4 datasets => (Added, Removed, Changed, Unchanged),
    //      each with a distinct color
    const data = {
      labels: categories,
      datasets: [
        {
          label: "Added",
          data: addedData,
          backgroundColor: "rgba(75,192,192,0.6)", // teal-ish
        },
        {
          label: "Removed",
          data: removedData,
          backgroundColor: "rgba(255,99,132,0.6)", // red-ish
        },
        {
          label: "Changed",
          data: changedData,
          backgroundColor: "rgba(255,206,86,0.6)", // yellow
        },
        {
          label: "Unchanged",
          data: unchangedData,
          backgroundColor: "rgba(153,102,255,0.6)", // purple-ish
        },
      ],
    };
  
    // 5) Chart options
    const options = {
      responsive: true,
      plugins: {
        legend: { position: "top" },
      },
      scales: {
        x: { stacked: true },
        y: { stacked: true, beginAtZero: true },
      },
      onClick: (evt, elements) => {
        if (!elements || elements.length === 0) return;
  
        // elements[0]: { index, datasetIndex }
        // "index" => which category column was clicked
        // "datasetIndex" => which of the 4 statuses was clicked
        const { index, datasetIndex } = elements[0];
        const clickedCategory = categories[index];
        const labelMap = ["Added", "Removed", "Changed", "Unchanged"];
        const clickedStatus = labelMap[datasetIndex];
        
        openModal(clickedCategory, clickedStatus);
      },
    };
  
    /**
     * 6) openModal
     *    Show the actors that are e.g. "Added" in "Consumer," or "Removed" in "Producer," etc.
     */
    function openModal(category, status) {
      setModalTitle(`${status} Actors in "${category}"`);
      const catDiff = diff.categoryDiffs[category];
      
      // catDiff => { added: [...], removed: [...], changed: [...], unchanged: [...] }
      let items = [];
      switch (status) {
        case "Added":
          items = catDiff.added;
          break;
        case "Removed":
          items = catDiff.removed;
          break;
        case "Changed":
          items = catDiff.changed;
          break;
        case "Unchanged":
          items = catDiff.unchanged;
          break;
        default:
          items = [];
      }
  
      setModalItems(items);
      setModalShow(true);
    }
  
    return (
      <>
        <Bar ref={chartRef} data={data} options={options} style={{ cursor: "pointer" }} />
  
        <Modal show={modalShow} onHide={() => setModalShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalItems.length === 0 ? (
              <p>No items found.</p>
            ) : (
              <ul>
                {modalItems.map((actor, i) => (
                  <li key={i}>{actor}</li>
                ))}
              </ul>
            )}
          </Modal.Body>
          <Modal.Footer>
            {modalTitle.includes("Changed") && (
              <div className="me-auto text-muted">
                <small>
                  * "Changed" typically means the same actor is present in old & new
                  but re-ordered (different index). If you don't care about ordering,
                  you can remove that logic.
                </small>
              </div>
            )}
            <Button variant="secondary" onClick={() => setModalShow(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}  
  
// #########################################################################################################################
// ####################################  Component Adoption Chart ##########################################################
// #########################################################################################################################

/**
 * Compares the component items in oldUC.components.value and newUC.components.value.
 * Match subcomponents by `description`. For each subcomponent, diff its items.
 *
 * "changed" means the *same item* is found in both old and new,
 * but at a different array index (i.e. a reorder).
 */
export function diffComponentItems(oldUC, newUC) {
    const oldComps = Array.isArray(oldUC?.components?.value)
      ? oldUC.components.value
      : [];
    const newComps = Array.isArray(newUC?.components?.value)
      ? newUC.components.value
      : [];
  
    // Build a map: categoryName -> array of subcomponent objects
    // Each subcomponent object is { description, items: [...] }
    function toCategoryMap(compArray) {
      const map = {};
      for (const catObj of compArray) {
        const catName = catObj.category || "Unknown";
        if (!map[catName]) {
          map[catName] = [];
        }
        // catObj.components is presumably an array like
        // [{ description: "Subcomponent A", items: [...] }, ...]
        map[catName].push(...(catObj.components || []));
      }
      return map;
    }
  
    const oldMap = toCategoryMap(oldComps);
    const newMap = toCategoryMap(newComps);
  
    const allCategories = new Set([
      ...Object.keys(oldMap),
      ...Object.keys(newMap),
    ]);
  
    let totalAdded = 0;
    let totalRemoved = 0;
    let totalChanged = 0;
    let totalUnchanged = 0;
  
    const allAdded = [];
    const allRemoved = [];
    const allChanged = [];
    const allUnchanged = [];
  
    // We'll store the diffs in: categoryDiffs[catName] => array of
    //   {
    //     description,
    //     added: [...],
    //     removed: [...],
    //     changed: [...],
    //     unchanged: [...]
    //   }
    const categoryDiffs = {};
  
    for (const category of allCategories) {
      const oldSubcomps = oldMap[category] || [];
      const newSubcomps = newMap[category] || [];
  
      // Build description => items map for old
      const descToOld = {};
      for (const s of oldSubcomps) {
        const desc = s.description || "(no desc)";
        descToOld[desc] = s.items || [];
      }
  
      // Build description => items map for new
      const descToNew = {};
      for (const s of newSubcomps) {
        const desc = s.description || "(no desc)";
        descToNew[desc] = s.items || [];
      }
  
      const subcomponentKeys = new Set([
        ...Object.keys(descToOld),
        ...Object.keys(descToNew),
      ]);
  
      const catResults = [];
  
      for (const desc of subcomponentKeys) {
        const oldItems = descToOld[desc] || [];
        const newItems = descToNew[desc] || [];
  
        const oldSet = new Set(oldItems);
        const newSet = new Set(newItems);
  
        // Items that appear in new but not old => ADDED
        const added = [...newSet].filter((x) => !oldSet.has(x));
  
        // Items that appear in old but not new => REMOVED
        const removed = [...oldSet].filter((x) => !newSet.has(x));
  
        // Items in both sets => either unchanged or changed (if re-ordered)
        const intersection = [...oldSet].filter((x) => newSet.has(x));
        const unchanged = [];
        const changed = [];
  
        intersection.forEach((item) => {
          const oldIndex = oldItems.indexOf(item);
          const newIndex = newItems.indexOf(item);
          if (oldIndex === newIndex) {
            unchanged.push(item);
          } else {
            changed.push(item);
          }
        });
  
        // Tally up
        totalAdded += added.length;
        totalRemoved += removed.length;
        totalChanged += changed.length;
        totalUnchanged += unchanged.length;
  
        allAdded.push(...added);
        allRemoved.push(...removed);
        allChanged.push(...changed);
        allUnchanged.push(...unchanged);
  
        catResults.push({
          description: desc,
          added,
          removed,
          changed,
          unchanged,
        });
      }
  
      categoryDiffs[category] = catResults;
    }
  
    return {
      totalAdded,
      totalRemoved,
      totalChanged,
      totalUnchanged,
      categoryDiffs,
      allAdded,
      allRemoved,
      allChanged,
      allUnchanged,
    };
}
  
/**
 * Component for displaying the Components Adoption Chart.
 */
export function ComponentsAdoptionChart({ oldData, newData }) {
    const chartRef = useRef(null);
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalStructured, setModalStructured] = useState([]);
  
    // 1) Do the diff
    const diff = diffComponentItems(oldData, newData);
  
    // 2) Build the list of categories (x-axis)
    const categories = Object.keys(diff.categoryDiffs);
  
    // 3) For each category, sum up how many items were added, removed, changed, unchanged
    //    across all subcomponents.
    const addedData = [];
    const removedData = [];
    const changedData = [];
    const unchangedData = [];
  
    categories.forEach((cat) => {
      const subcomps = diff.categoryDiffs[cat]; // array of { description, added, removed, changed, unchanged }
  
      const sumAdded = subcomps.reduce((acc, sc) => acc + sc.added.length, 0);
      const sumRemoved = subcomps.reduce((acc, sc) => acc + sc.removed.length, 0);
      const sumChanged = subcomps.reduce((acc, sc) => acc + sc.changed.length, 0);
      const sumUnchanged = subcomps.reduce((acc, sc) => acc + sc.unchanged.length, 0);
  
      addedData.push(sumAdded);
      removedData.push(sumRemoved);
      changedData.push(sumChanged);
      unchangedData.push(sumUnchanged);
    });
  
    // 4) Prepare the stacked bar data:
    //    4 datasets (one for each status),
    //    and each dataset has data for each category on the x-axis.
    const data = {
      labels: categories, // x-axis: each category is a column
      datasets: [
        {
          label: "Added",
          data: addedData,
          backgroundColor: "rgba(75,192,192,0.6)"  // teal
        },
        {
          label: "Removed",
          data: removedData,
          backgroundColor: "rgba(255,99,132,0.6)"  // red
        },
        {
          label: "Changed",
          data: changedData,
          backgroundColor: "rgba(255,206,86,0.6)"  // yellow
        },
        {
          label: "Unchanged",
          data: unchangedData,
          backgroundColor: "rgba(153,102,255,0.6)" // purple
        }
      ]
    };
  
    // 5) Chart.js config
    const options = {
      responsive: true,
      plugins: {
        legend: { position: "top" },
      },
      scales: {
        x: { stacked: true },
        y: { stacked: true, beginAtZero: true },
      },
      onClick: (evt, elements) => {
        if (!elements || elements.length === 0) return;
  
        // Chart.js gives us [element], which has index + datasetIndex
        const { index, datasetIndex } = elements[0];
        // index => which category column was clicked
        // datasetIndex => which status (0=Added, 1=Removed, 2=Changed, 3=Unchanged)
  
        const labelMap = ["Added", "Removed", "Changed", "Unchanged"];
        const clickedStatus = labelMap[datasetIndex];
        const clickedCategory = categories[index];
  
        openModal(clickedCategory, clickedStatus);
      },
    };
  
    /**
     * 6) openModal(category, status)
     *    build a structured list of subcomponents that have the relevant items
     *    under that status. E.g. show exactly what was "Added" for that category.
     */
    function openModal(category, status) {
      setModalTitle(`${status} in "${category}"`);
      const subcomps = diff.categoryDiffs[category] || [];

      const structured = subcomps.map((sc) => {
        let items = [];
        switch (status) {
          case "Added":
            items = sc.added;
            break;
          case "Removed":
            items = sc.removed;
            break;
          case "Changed":
            items = sc.changed;
            break;
          case "Unchanged":
            items = sc.unchanged;
            break;
          default:
            items = [];
        }
        return {
          description: sc.description,
          items
        };
      }).filter((sc) => sc.items.length > 0);
  
      setModalStructured(structured);
      setModalShow(true);
    }
  
    return (
      <>
        <Bar ref={chartRef} data={data} options={options} style={{ cursor: "pointer" }} />
  
        <Modal show={modalShow} onHide={() => setModalShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalStructured.length === 0 ? (
              <p>No items found.</p>
            ) : (
              modalStructured.map((sc, idx) => (
                <div key={idx} style={{ marginBottom: "1em" }}>
                  <strong>Subcomponent: {sc.description}</strong>
                  <ul>
                    {sc.items.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                </div>
              ))
            )}
          </Modal.Body>
          <Modal.Footer>
            {modalTitle.includes("Changed") && (
              <div className="me-auto text-muted">
                <small>
                  * "Changed" typically means the same item was found in both old
                  and new, but in different positions (i.e. reordered).
                </small>
              </div>
            )}
            <Button variant="secondary" onClick={() => setModalShow(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}
  
// #########################################################################################################################
// ####################################  Stepwise Adoption Chart ###########################################################
// #########################################################################################################################

/**
 * Stacked bar chart showing how many items were added, removed, changed, or unchanged
 * for each step in the adoption process.
 */
export function StepwiseAdoptionChart({ oldData, newData }) {
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalItems, setModalItems] = useState([]);

    // Perform the diff calculations for each step
    const gf = diffGeneralFields(oldData, newData);
    const sc = diffScenarios(oldData, newData);
    const cond = diffScenarioConditions(oldData, newData);
    const act = diffActorLists(oldData, newData);
    const comp = diffComponentItems(oldData, newData);
    //console.log(gf, sc, cond, act, comp);

    const steps = [
        {
            step: "General Fields",
            added: gf.added,
            removed: gf.removed,
            changed: gf.changed,
            unchanged: gf.unchanged,
            details: gf
        },
        {
            step: "Scenarios",
            added: sc.totalAdded,
            removed: sc.totalRemoved,
            changed: sc.totalChanged,
            unchanged: sc.totalUnchanged,
            details: sc
        },
        {
            step: "Conditions",
            added: cond.totalAdded,
            removed: cond.totalRemoved,
            changed: cond.totalChanged,
            unchanged: cond.totalUnchanged,
            details: cond
        },
        {
            step: "Actors",
            added: act.totalAdded,
            removed: act.totalRemoved,
            changed: act.totalChanged,
            unchanged: act.totalUnchanged,
            details: act
        },
        {
            step: "Components",
            added: comp.totalAdded,
            removed: comp.totalRemoved,
            changed: comp.totalChanged,
            unchanged: comp.totalUnchanged,
            details: comp
        }
    ];

    const data = {
        labels: steps.map(s => s.step),
        datasets: [
            {
                label: "Added",
                data: steps.map(s => s.added),
                backgroundColor: "rgba(75,192,192,0.6)",
            },
            {
                label: "Removed",
                data: steps.map(s => s.removed),
                backgroundColor: "rgba(255,99,132,0.6)",
            },
            {
                label: "Changed",
                data: steps.map(s => s.changed),
                backgroundColor: "rgba(255,206,86,0.6)",
            },
            {
                label: "Unchanged",
                data: steps.map(s => s.unchanged),
                backgroundColor: "rgba(153,102,255,0.6)",
            }
        ]
    };

    const options = {
        responsive: true,
        plugins: { legend: { position: "top" } },
        scales: {
            x: { stacked: true },
            y: { stacked: true, beginAtZero: true }
        },
        onClick: (evt, elements) => {
            if (elements.length > 0) {
                const index = elements[0].index;
                const clickedStep = steps[index];
                const details = clickedStep.details;
    
                let modalContent = [];
    
                // Format function to convert objects into readable text
                function formatItems(items, label) {
                    if (!items || items.length === 0) return "";
                    return `${label}:\n${items.map(item => 
                        typeof item === "object" ? JSON.stringify(item, null, 2) : item
                    ).join("\n")}`;
                }
    
                // Generate modal details
                modalContent.push(formatItems(details.allAdded, "🟢 Added"));
                modalContent.push(formatItems(details.allRemoved, "🔴 Removed"));
                modalContent.push(formatItems(details.allChanged, "🟡 Changed"));
                modalContent.push(formatItems(details.allUnchanged, "⚪ Unchanged"));
    
                // Filter out empty sections
                modalContent = modalContent.filter(section => section.length > 0);
    
                setModalTitle(`Details for ${clickedStep.step}`);
                setModalItems(modalContent.length > 0 ? modalContent : ["No changes detected."]);
                setModalShow(true);
            }
        }
    };

    return (
        <>
            <Bar data={data} options={options} style={{ cursor: "pointer" }} />
    
            {/* Modal for detailed step view */}
            <Modal show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: "400px", overflowY: "auto" }}>
                    {modalItems.length === 0 ? (
                        <p>No items found.</p>
                    ) : (
                        <pre style={{ whiteSpace: "pre-wrap" }}>{modalItems.join("\n\n")}</pre>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalShow(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
    
}

/**
 * Stacked bar chart showing the accumulated total of items added, removed, changed, or unchanged
 */
export function StepwiseAccumulatedAdoptionChart({ oldData, newData }) {
    // Perform diff calculations for each step.
    const gf = diffGeneralFields(oldData, newData);
    const sc = diffScenarios(oldData, newData);
    const cond = diffScenarioConditions(oldData, newData);
    const act = diffActorLists(oldData, newData);
    const comp = diffComponentItems(oldData, newData);

    //console.log(gf, sc, cond, act, comp);
  
    // Build an array for each section.
    const steps = [
      {
        step: "General Fields",
        added: gf.added,
        removed: gf.removed,
        changed: gf.changed,
        unchanged: gf.unchanged,
        details: gf
      },
      {
        step: "Scenarios",
        added: sc.totalAdded,
        removed: sc.totalRemoved,
        changed: 0, // sc.totalChanged,
        unchanged: sc.totalUnchanged,
        details: sc
      },
      {
        step: "Conditions",
        added: cond.totalAdded,
        removed: cond.totalRemoved,
        changed: 0, //cond.totalChanged,
        unchanged: cond.totalUnchanged,
        details: cond
      },
      {
        step: "Actors",
        added: act.totalAdded,
        removed: act.totalRemoved,
        changed: act.totalChanged,
        unchanged: act.totalUnchanged,
        details: act
      },
      {
        step: "Components",
        added: comp.totalAdded,
        removed: comp.totalRemoved,
        changed: comp.totalChanged,
        unchanged: comp.totalUnchanged,
        details: comp
      }
    ];
  
    // For each step, accumulate total modifications (all values are positive)
    const accumulatedSteps = steps.map(s => ({
      step: s.step,
      total: s.added + s.removed + s.changed + s.unchanged,
      details: s.details
    }));
  
    const chartData = {
      labels: accumulatedSteps.map(s => s.step),
      datasets: [
        {
          label: "Total Modifications",
          data: accumulatedSteps.map(s => s.total),
          backgroundColor: ["#36A2EB", "#FF6384", "#FFCE56", "#4BC0C0", "#9B59B6"],
          borderColor: ["#36A2EB", "#FF6384", "#FFCE56", "#4BC0C0", "#9B59B6"],
          borderWidth: 1,
        },
      ],
    };
  
    const options = {
      responsive: true,
      plugins: { legend: { display: false } },
      scales: { y: { beginAtZero: true } },
      // You can add onClick logic for modals if needed.
    };
  
    return (
      <>
        <Bar data={chartData} options={options} style={{ maxHeight: 400 }} />
      </>
    );
}
  
/**
 * Stacked bar chart showing the accumulated total of items added, removed, changed, or unchanged
 * with negative values for removed items.
 */
export function StepwiseAccumulatedAdoptionChartCleaned({ oldData, newData }) {
    const chartRef = useRef(null);
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState("");
  
    // Perform the diff calculations for each step.
    const gf = diffGeneralFields(oldData, newData);
    const sc = diffScenarios(oldData, newData);
    const cond = diffScenarioConditions(oldData, newData);
    const act = diffActorLists(oldData, newData);
    const comp = diffComponentItems(oldData, newData);
  
    // Build an array for each section.
    const steps = [
      {
        step: "General Fields",
        added: gf.added,
        removed: gf.removed,
        changed: gf.changed,
        unchanged: gf.unchanged,
        details: gf
      },
      {
        step: "Scenarios",
        added: sc.totalAdded,
        removed: sc.totalRemoved,
        changed: 0, //sc.totalChanged,
        unchanged: sc.totalUnchanged,
        details: sc
      },
      {
        step: "Conditions",
        added: cond.totalAdded,
        removed: cond.totalRemoved,
        changed: 0, //cond.totalChanged,
        unchanged: cond.totalUnchanged,
        details: cond
      },
      {
        step: "Actors",
        added: act.totalAdded,
        removed: act.totalRemoved,
        changed: act.totalChanged,
        unchanged: act.totalUnchanged,
        details: act
      },
      {
        step: "Components",
        added: comp.totalAdded,
        removed: comp.totalRemoved,
        changed: comp.totalChanged,
        unchanged: comp.totalUnchanged,
        details: comp
      }
    ];
  
    // For each section, compute positive and negative values.
    // Positive: added + changed + unchanged.
    // Negative: removed (as negative number).
    const accumulatedSteps = steps.map(s => ({
      step: s.step,
      pos: s.added + s.changed + s.unchanged,
      neg: -s.removed,
      details: s.details
    }));
  
    const labels = accumulatedSteps.map(s => s.step);
    const posData = accumulatedSteps.map(s => s.pos);
    const negData = accumulatedSteps.map(s => s.neg);
  
    const chartData = {
      labels,
      datasets: [
        {
          label: "Positive (Added + Changed + Unchanged)",
          data: posData,
          backgroundColor: "#36A2EB",
          borderWidth: 1,
        },
        {
          label: "Removed",
          data: negData,
          backgroundColor: "#FF6384",
          borderWidth: 1,
        },
      ],
    };
  
    const options = {
      responsive: true,
      plugins: { legend: { position: "top" } },
      scales: {
        x: { stacked: true },
        y: { stacked: true, beginAtZero: true },
      },
      onClick: (evt, elements) => {
        if (!elements || elements.length === 0) return;
        const { index } = elements[0];
        const clickedStep = accumulatedSteps[index];
        // Build a detail text summary (for example purposes)
        const detailText = `
  Step: ${clickedStep.step}
  Positive (Added+Changed+Unchanged): ${clickedStep.pos}
  Removed: ${-clickedStep.neg}
  Net Total: ${clickedStep.pos - clickedStep.neg}
        `;
        setModalTitle(`Details for ${clickedStep.step}`);
        setModalContent(detailText);
        setModalShow(true);
      },
    };
  
    return (
      <>
        <Bar ref={chartRef} data={chartData} options={options} style={{ cursor: "pointer", maxHeight: 400 }} />
        <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <pre style={{ whiteSpace: "pre-wrap" }}>{modalContent}</pre>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalShow(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}  

/**
 * Component for displaying the Accumulated Stepwise Adoption Chart.
 * This chart shows the total count of added, removed, changed, and unchanged items
 * across all steps in the adoption process. 
 */
export function AccumulatedAdoptionChart({ oldData, newData }) {
    // Perform the diff calculations for each step
    const gf = diffGeneralFields(oldData, newData);
    const sc = diffScenarios(oldData, newData);
    const cond = diffScenarioConditions(oldData, newData);
    const act = diffActorLists(oldData, newData);
    const comp = diffComponentItems(oldData, newData);

    // Aggregate counts across all steps
    const totalAdded = gf.added + sc.totalAdded + cond.totalAdded + act.totalAdded + comp.totalAdded;
    const totalRemoved = gf.removed + sc.totalRemoved + cond.totalRemoved + act.totalRemoved + comp.totalRemoved;
    const totalChanged = gf.changed + act.totalChanged + comp.totalChanged; // not considered: + sc.totalChanged + cond.totalChanged
    const totalUnchanged = gf.unchanged + sc.totalUnchanged + cond.totalUnchanged + act.totalUnchanged + comp.totalUnchanged;

    // Data for the bar chart (not stacked)
    const data = {
        labels: ["Added", "Removed", "Changed", "Unchanged"],
        datasets: [
            {
                label: "Total Count",
                data: [totalAdded, totalRemoved, totalChanged, totalUnchanged],
                backgroundColor: [
                    "rgba(75,192,192,0.6)",   // Added - Teal
                    "rgba(255,99,132,0.6)",   // Removed - Red
                    "rgba(255,206,86,0.6)",   // Changed - Yellow
                    "rgba(153,102,255,0.6)"   // Unchanged - Purple
                ],
                borderColor: [
                    "rgba(75,192,192,1)",
                    "rgba(255,99,132,1)",
                    "rgba(255,206,86,1)",
                    "rgba(153,102,255,1)"
                ],
                borderWidth: 1
            }
        ]
    };

    const options = {
        responsive: true,
        plugins: { legend: { display: false } }, // No legend needed for a single dataset
        scales: {
            y: { beginAtZero: true }
        }
    };

    return (
        <>
            <Bar data={data} options={options} style={{ maxHeight: 400 }} />
        </>
    );
}

/**
 * Component for displaying the Accumulated Stepwise Adoption Chart.
 * This chart shows the total count of added, removed, changed, and unchanged items
 * across all steps in the adoption process.
 */
export function AccumulatedAdoptionChartCleaned({ oldData, newData }) {
    // 1) Perform the diff calculations for each section
    const gf = diffGeneralFields(oldData, newData);
    const sc = diffScenarios(oldData, newData);
    const cond = diffScenarioConditions(oldData, newData);
    const act = diffActorLists(oldData, newData);
    const comp = diffComponentItems(oldData, newData);
  
    // 2) Aggregate counts across all sections.
    // (Adjust the keys if your aggregated objects differ.)
    const totalAdded =
      gf.added + sc.totalAdded + cond.totalAdded + act.totalAdded + comp.totalAdded;
    const totalRemoved =
      gf.removed + sc.totalRemoved + cond.totalRemoved + act.totalRemoved + comp.totalRemoved;
    const totalChanged =
      gf.changed + act.totalChanged + comp.totalChanged; // example: scenarios and conditions not included in "changed"
    const totalUnchanged =
      gf.unchanged + sc.totalUnchanged + cond.totalUnchanged + act.totalUnchanged + comp.totalUnchanged;
  
    // 3) Build the chart data.
    // For the "Removed" value, we multiply by -1 to show it as a negative value.
    const data = {
      labels: ["Added", "Removed", "Changed", "Unchanged"],
      datasets: [
        {
          label: "Total Count",
          data: [totalAdded, -totalRemoved, totalChanged, totalUnchanged],
          backgroundColor: [
            "rgba(75,192,192,0.6)", // Added - teal
            "rgba(255,99,132,0.6)", // Removed - red
            "rgba(255,206,86,0.6)", // Changed - yellow
            "rgba(153,102,255,0.6)" // Unchanged - purple
          ],
          borderColor: [
            "rgba(75,192,192,1)",
            "rgba(255,99,132,1)",
            "rgba(255,206,86,1)",
            "rgba(153,102,255,1)"
          ],
          borderWidth: 1,
        },
      ],
    };
  
    // 4) Chart options: the y-axis will display negative values for removed.
    const options = {
      responsive: true,
      plugins: { legend: { display: false } },
      scales: {
        y: {
          beginAtZero: true,
          // Ticks will show negative values
          ticks: {
            callback: function (value) {
              return value;
            },
          },
        },
      },
    };
  
    return (
      <>
        <Bar data={data} options={options} style={{ maxHeight: 400 }} />
      </>
    );
}

// ############################### Deep Diff Table #######################################

const FIELD_CLUSTERS = {
    Timestamps: ["created_timestamp", "updated_timestamp"],
    UpdateInfo: ["source", "modified", "updated_by"],
    Misc: ["version", "priority", "relevance", "uid"],
};
  
function isPlainObject(o) {
    return !!o && typeof o === 'object' && !Array.isArray(o);
}
  
/**
 * Recursively diffs two data structures, returning an array of diff objects:
 *   { type, path, oldValue, newValue }
 * where `type` is "added", "removed", "changed", or "unchanged".
 */
function deepDiffDetailed(oldData, newData, path = []) {
    // If they are strictly the same reference or primitive
    if (oldData === newData) {
      return [{
        type: 'unchanged',
        path,
        oldValue: oldData,
        newValue: newData
      }];
    }
  
    // If one is null/undefined and the other is not => added or removed
    if ((oldData == null) !== (newData == null)) {
      if (oldData == null && newData != null) {
        return [{
          type: 'added',
          path,
          oldValue: undefined,
          newValue: newData
        }];
      } else {
        return [{
          type: 'removed',
          path,
          oldValue: oldData,
          newValue: undefined
        }];
      }
    }
  
    // If they differ in type => changed
    if (typeof oldData !== typeof newData) {
      return [{
        type: 'changed',
        path,
        oldValue: oldData,
        newValue: newData
      }];
    }
  
    // If both are arrays
    if (Array.isArray(oldData) && Array.isArray(newData)) {
      let result = [];
      const maxLen = Math.max(oldData.length, newData.length);
  
      for (let i = 0; i < maxLen; i++) {
        const subPath = [...path, i];
        if (i >= oldData.length) {
          // purely added
          result.push({
            type: 'added',
            path: subPath,
            oldValue: undefined,
            newValue: newData[i]
          });
        } else if (i >= newData.length) {
          // purely removed
          result.push({
            type: 'removed',
            path: subPath,
            oldValue: oldData[i],
            newValue: undefined
          });
        } else {
          // compare each index recursively
          const childDiffs = deepDiffDetailed(oldData[i], newData[i], subPath);
          result.push(...childDiffs);
        }
      }
      return result;
    }
  
    // If both are plain objects
    if (isPlainObject(oldData) && isPlainObject(newData)) {
      let result = [];
      const oldKeys = Object.keys(oldData);
      const newKeys = Object.keys(newData);
      const allKeys = new Set([...oldKeys, ...newKeys]);
  
      let anyChange = false;
      for (let key of allKeys) {
        const subPath = [...path, key];
        if (!newKeys.includes(key)) {
          // removed
          result.push({
            type: 'removed',
            path: subPath,
            oldValue: oldData[key],
            newValue: undefined
          });
          anyChange = true;
        } else if (!oldKeys.includes(key)) {
          // added
          result.push({
            type: 'added',
            path: subPath,
            oldValue: undefined,
            newValue: newData[key]
          });
          anyChange = true;
        } else {
          // recursively diff
          const childDiffs = deepDiffDetailed(oldData[key], newData[key], subPath);
          result.push(...childDiffs);
          if (childDiffs.some(d => d.type !== 'unchanged')) {
            anyChange = true;
          }
        }
      }
  
      // If no changes among children, we can optionally mark the entire object as unchanged
      if (!anyChange) {
        return [{
          type: 'unchanged',
          path,
          oldValue: oldData,
          newValue: newData
        }];
      }
      return result;
    }
  
    // Otherwise => different references => 'changed'
    return [{
      type: 'changed',
      path,
      oldValue: oldData,
      newValue: newData
    }];
}

// Decide which "step" (1..4) or "Meta" a path belongs to
function getStepForPath(path) {
    // The top-level key is often path[0].
    const root = path[0];
  
    // Some examples:
    if (!root) return "Step 1: Description"; // fallback if path is empty
  
    // Step 1: "title", "acronym", "country", "city", "tags", "description", etc.
    const step1Keys = ["title", "acronym", "continents", "country", "city", "tags", "description"];
    if (step1Keys.includes(root)) {
      return "Step 1: Description";
    }
  
    // If it's "actions" or "scenarios", assume Step 2
    if (["actions", "scenarios", "conditions"].includes(root)) {
      return "Step 2: Scenario";
    }
  
    // If it's "actors", Step 3
    if (root === "actors") {
      return "Step 3: Actors";
    }
  
    // If it's "components", Step 4
    if (root === "components") {
      return "Step 4: Components";
    }
  
    // Otherwise fallback
    return "Meta";
}
  
// Returns the cluster name, e.g. "Timestamps", or "General" if not matched
function getClusterName(path) {
    const lastKey = path[path.length - 1];
    if (!lastKey) return "General";
  
    // If it’s exactly "created_timestamp" or "updated_timestamp", we classify as Timestamps...
    for (let [clusterName, fields] of Object.entries(FIELD_CLUSTERS)) {
      if (fields.includes(lastKey)) {
        return clusterName;
      }
    }
    return "General";
}
  

function truncateJSON(jsonStr, maxLen = 100) {
    if (!jsonStr || jsonStr.length <= maxLen) return jsonStr;
    return jsonStr.slice(0, maxLen) + "...";
}
  
export function UseCaseDeepDiffView({ oldData, newData }) {
    const [showUnchanged, setShowUnchanged] = useState(false);
    const [expandedRows, setExpandedRows] = useState({}); // rowIndex -> bool
    const [clusterOpen, setClusterOpen] = useState({});   // stepName + clusterName -> bool
  
    // 1) Compute all diffs
    const allDiffs = deepDiffDetailed(oldData, newData);
  
    // 2) Sort diffs by path
    allDiffs.sort((a,b) => a.path.join(".").localeCompare(b.path.join(".")));
  
    // 3) Group by Step, then by Cluster
    //    => stepMap[stepName][clusterName] = array of diffs
    const stepMap = {}; 
    // e.g.: {
    //   "Step 1: Description": {
    //      "Timestamps": [{index, type, path, oldValue, newValue}, ...],
    //      "UpdateInfo": [...],
    //      "General": [...]
    //   },
    //   "Step 2: Scenario": { ... },
    //   ...
    // }
  
    for (let i = 0; i < allDiffs.length; i++) {
      const diff = allDiffs[i];
      const stepName = getStepForPath(diff.path);
      const clusterName = getClusterName(diff.path);
  
      if (!stepMap[stepName]) {
        stepMap[stepName] = {};
      }
      if (!stepMap[stepName][clusterName]) {
        stepMap[stepName][clusterName] = [];
      }
      stepMap[stepName][clusterName].push({ index: i, ...diff });
    }
  
    // 4) We'll define an order for the steps. Anything not in these keys is appended last
    const stepOrder = [
      "Step 1: Description",
      "Step 2: Scenario",
      "Step 3: Actors",
      "Step 4: Components",
      "Meta"
    ];
  
    // Build a sorted array of step names we actually have
    const sortedSteps = Object.keys(stepMap).sort((a, b) => {
      const idxA = stepOrder.indexOf(a);
      const idxB = stepOrder.indexOf(b);
      if (idxA === -1 && idxB === -1) {
        // both not in the array => sort alphabetically
        return a.localeCompare(b);
      }
      if (idxA === -1) return 1; // a is not in stepOrder => push it last
      if (idxB === -1) return -1;
      return idxA - idxB;
    });
  
    function toggleRowExpanded(rowIdx) {
      setExpandedRows(prev => ({ ...prev, [rowIdx]: !prev[rowIdx] }));
    }
  
    function toggleClusterOpen(step, cluster) {
      const key = step + "||" + cluster;
      setClusterOpen(prev => ({ ...prev, [key]: !prev[key] }));
    }
  
    // 5) Render
    return (
      <div style={{ maxHeight: "1000px", overflowY: "auto", border: "1px solid #ccc", padding: "1rem" }}>
        <h5>Detailed Comparison - UCM Co-Pilot Adoption</h5>
        {/* Global show/hide unchanged */}
        <div className="mb-2">
          <label>
            <input
              type="checkbox"
              style={{ marginRight: "0.5rem" }}
              checked={showUnchanged}
              onChange={(e) => setShowUnchanged(e.target.checked)}
            />
            Show Unchanged Rows
          </label>
        </div>
  
        {sortedSteps.map((stepName) => {
          const clusterMap = stepMap[stepName];
          // clusterMap => { Timestamps: [diffs], UpdateInfo: [diffs], ... }
  
          // Sort cluster names to a known order (Timestamps, UpdateInfo, Misc, General)
          const clusterOrder = ["Timestamps", "UpdateInfo", "Misc", "General"];
          const sortedClusterNames = Object.keys(clusterMap).sort((a, b) => {
            const idxA = clusterOrder.indexOf(a);
            const idxB = clusterOrder.indexOf(b);
            if (idxA === -1 && idxB === -1) return a.localeCompare(b);
            if (idxA === -1) return 1;
            if (idxB === -1) return -1;
            return idxA - idxB;
          });
  
          return (
            <div key={stepName} style={{ marginBottom: "1rem" }}>
              <h4>{stepName}</h4>
              
              {sortedClusterNames.map(clusterName => {
                const diffsInCluster = clusterMap[clusterName];
                // Filter out unchanged if user doesn't want them
                const displayed = showUnchanged
                  ? diffsInCluster
                  : diffsInCluster.filter(d => d.type !== "unchanged");
  
                if (displayed.length === 0) {
                  return null; // no rows to show in this cluster
                }
  
                const cardKey = stepName + "||" + clusterName;
                const isOpen = !!clusterOpen[cardKey];
                const totalCount = diffsInCluster.length;
                const visibleCount = displayed.length;
                const label = `${clusterName} (${visibleCount} / ${totalCount} diffs)`;
  
                return (
                  <Card className="mb-2" key={clusterName}>
                    <Card.Header 
                      style={{ cursor: "pointer" }}
                      onClick={() => toggleClusterOpen(stepName, clusterName)}
                    >
                      <strong>{label}</strong>
                      <span style={{ float: "right" }}>
                        {isOpen ? "[-]" : "[+]"}
                      </span>
                    </Card.Header>
                    {isOpen && (
                      <Card.Body style={{ padding: 0 }}>
                        <table className="table table-sm table-bordered mb-0">
                          <thead>
                            <tr>
                              <th style={{ width: "30%" }}>Path</th>
                              <th style={{ width: "35%" }}>Old</th>
                              <th style={{ width: "35%" }}>New</th>
                              <th style={{ width: "1%" }}></th>
                            </tr>
                          </thead>
                          <tbody>
                            {displayed.map(({ index, type, path, oldValue, newValue }) => {
                              const pathString = path.join(".");
                              let bgColor = "#ffffff";
                              if (type === "removed") bgColor = "#ffcccc"; 
                              else if (type === "added") bgColor = "#ccffcc";
                              else if (type === "changed") bgColor = "#fff2cc";
                              else if (type === "unchanged") bgColor = "#f8f9fa";
  
                              const oldStr = oldValue !== undefined
                                ? JSON.stringify(oldValue, null, 2)
                                : "";
                              const newStr = newValue !== undefined
                                ? JSON.stringify(newValue, null, 2)
                                : "";
  
                              const rowExpanded = !!expandedRows[index];
                              const displayOld = rowExpanded ? oldStr : truncateJSON(oldStr);
                              const displayNew = rowExpanded ? newStr : truncateJSON(newStr);
  
                              return (
                                <tr key={index} style={{ backgroundColor: bgColor }}>
                                  <td>
                                    <code>{pathString}</code>
                                  </td>
                                  <td style={{ whiteSpace: "pre-wrap", fontSize: "smaller" }}>
                                    {displayOld}
                                  </td>
                                  <td style={{ whiteSpace: "pre-wrap", fontSize: "smaller" }}>
                                    {displayNew}
                                  </td>
                                  <td>
                                    {(oldStr.length > 100 || newStr.length > 100) && (
                                      <Button
                                        variant="link"
                                        size="sm"
                                        onClick={() => toggleRowExpanded(index)}
                                      >
                                        {rowExpanded ? "Collapse" : "Expand"}
                                      </Button>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </Card.Body>
                    )}
                  </Card>
                );
              })}
            </div>
          );
        })}
      </div>
    );
}

// ##########################################################################################
// ############################### Section B applies to multiple use cases ##################

// ##########################################################################################
// ############################### Compare multiple use cases ###############################
// ##########################################################################################

function compareMultipleUseCases(oldUseCases, newUseCases) {
    // Initialize accumulators
    // We have 5 steps: General, Scenarios, Conditions, Actors, Components
    let general = initDiffAccumulator();
    let scenarios = initDiffAccumulator();
    let conditions = initDiffAccumulator();
    let actors = initDiffAccumulator();
    let components = initDiffAccumulator();
  
    const length = Math.min(oldUseCases.length, newUseCases.length);
  
    for (let i = 0; i < length; i++) {
      const oldUC = oldUseCases[i];
      const newUC = newUseCases[i];
  
      // 1) General Fields
      const gf = diffGeneralFields(oldUC, newUC);
      accumulateDiffResult(general, gf);
  
      // 2) Scenarios
      const sc = diffScenarios(oldUC, newUC);
      // Note sc doesn't produce "changed" – let's artificially add sc.totalChanged=0
      sc.totalChanged = sc.totalChanged || 0;
      sc.allChanged = sc.allChanged || [];
      accumulateStepResult(scenarios, sc, "total");
  
      // 3) Conditions
      const cond = diffScenarioConditions(oldUC, newUC);
      // same pattern: "changed" not returned by diffScenarioConditions
      cond.totalChanged = cond.totalChanged || 0;
      cond.allChanged = cond.allChanged || [];
      accumulateStepResult(conditions, cond, "total");
  
      // 4) Actors
      const act = diffActorLists(oldUC, newUC);
      // This returns {totalAdded, totalRemoved, totalChanged, totalUnchanged, allAdded, allRemoved, allChanged, allUnchanged}
      accumulateStepResult(actors, act, "total");
  
      // 5) Components
      const comp = diffComponentItems(oldUC, newUC);
      accumulateStepResult(components, comp, "total");
    }
  
    return { general, scenarios, conditions, actors, components };
}
  
/** Initialize an accumulator for a step. */
function initDiffAccumulator() {
    return {
      added: 0,
      removed: 0,
      changed: 0,
      unchanged: 0,
      allAdded: [],
      allRemoved: [],
      allChanged: [],
      allUnchanged: []
    };
}

/**
 * Merges the results from a single diff call into the aggregator.
 * This is for the “general fields” style object that has:
 *   gf.added, gf.removed, gf.changed, gf.unchanged
 *   gf.allAdded, gf.allRemoved, gf.allChanged, gf.allUnchanged
 */
function accumulateDiffResult(acc, single) {
    acc.added += single.added;
    acc.removed += single.removed;
    acc.changed += single.changed;
    acc.unchanged += single.unchanged;
  
    acc.allAdded.push(...single.allAdded);
    acc.allRemoved.push(...single.allRemoved);
    acc.allChanged.push(...single.allChanged);
    acc.allUnchanged.push(...single.allUnchanged);
}
  
/**
 * Merges results from a “scenario” style object
 *   sc => { totalAdded, totalRemoved, totalChanged, totalUnchanged,
 *           allAdded, allRemoved, allChanged, allUnchanged }
 *
 * The “keyPrefix” can be “total” or something else (just to unify the naming).
 */
function accumulateStepResult(acc, single, keyPrefix = "total") {
    acc.added += single[`${keyPrefix}Added`] || 0;
    acc.removed += single[`${keyPrefix}Removed`] || 0;
    acc.changed += single[`${keyPrefix}Changed`] || 0;
    acc.unchanged += single[`${keyPrefix}Unchanged`] || 0;
  
    if (single.allAdded) acc.allAdded.push(...single.allAdded);
    if (single.allRemoved) acc.allRemoved.push(...single.allRemoved);
    if (single.allChanged) acc.allChanged.push(...single.allChanged);
    if (single.allUnchanged) acc.allUnchanged.push(...single.allUnchanged);
}

export function MultiUseCaseStepwiseAdoptionChart({ data, step }) {
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalItems, setModalItems] = useState([]);

    // For each pair of old vs new use cases
    const { oldUseCases, newUseCases } = buildUseCasePairs(filterUseCasesByStep(data, step));
  
    // 1) Compare all oldUseCases vs newUseCases
    const {
      general,
      scenarios,
      conditions,
      actors,
      components
    } = compareMultipleUseCases(oldUseCases, newUseCases);
  
    // 2) Build the “steps” array for the chart
    const steps = [
      {
        step: "General Fields",
        added: general.added,
        removed: general.removed,
        changed: general.changed,
        unchanged: general.unchanged,
        details: general
      },
      {
        step: "Scenarios",
        added: scenarios.added,
        removed: scenarios.removed,
        changed: scenarios.changed,
        unchanged: scenarios.unchanged,
        details: scenarios
      },
      {
        step: "Conditions",
        added: conditions.added,
        removed: conditions.removed,
        changed: conditions.changed,
        unchanged: conditions.unchanged,
        details: conditions
      },
      {
        step: "Actors",
        added: actors.added,
        removed: actors.removed,
        changed: actors.changed,
        unchanged: actors.unchanged,
        details: actors
      },
      {
        step: "Components",
        added: components.added,
        removed: components.removed,
        changed: components.changed,
        unchanged: components.unchanged,
        details: components
      }
    ];
  
    // 3) Prepare bar chart data
    const chartData = {
      labels: steps.map((s) => s.step),
      datasets: [
        {
          label: "Added",
          data: steps.map((s) => s.added),
          backgroundColor: "rgba(75,192,192,0.6)",
        },
        {
          label: "Removed",
          data: steps.map((s) => s.removed),
          backgroundColor: "rgba(255,99,132,0.6)",
        },
        {
          label: "Changed",
          data: steps.map((s) => s.changed),
          backgroundColor: "rgba(255,206,86,0.6)",
        },
        {
          label: "Unchanged",
          data: steps.map((s) => s.unchanged),
          backgroundColor: "rgba(153,102,255,0.6)",
        }
      ]
    };
  
    // 4) Chart options
    const options = {
      responsive: true,
      plugins: {
        legend: { position: "top" },
      },
      scales: {
        x: { stacked: true },
        y: { stacked: true, beginAtZero: true },
      },
      onClick: (evt, elements) => {
        if (!elements || elements.length === 0) return;
  
        const index = elements[0].index;
        const clickedStep = steps[index];
        const details = clickedStep.details;
  
        // build a big text block for the modal
        let modalContent = [];
  
        function formatSection(label, arr) {
          if (!arr || arr.length === 0) return "";
          return `${label}:\n${arr
            .map((item) =>
              typeof item === "object"
                ? JSON.stringify(item, null, 2)
                : String(item)
            )
            .join("\n")}`;
        }
  
        modalContent.push(formatSection("🟢 Added", details.allAdded));
        modalContent.push(formatSection("🔴 Removed", details.allRemoved));
        modalContent.push(formatSection("🟡 Changed", details.allChanged));
        modalContent.push(formatSection("⚪ Unchanged", details.allUnchanged));
  
        // remove empty sections
        modalContent = modalContent.filter((sec) => sec.length > 0);
  
        setModalTitle(`Details for ${clickedStep.step}`);
        setModalItems(modalContent.length > 0 ? modalContent : ["No changes detected."]);
        setModalShow(true);
      },
    };
  
    return (
      <>
        <Bar data={chartData} options={options} style={{ cursor: "pointer" }} />
  
        <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: "400px", overflowY: "auto" }}>
            {modalItems.length === 0 ? (
              <p>No items found.</p>
            ) : (
              <pre style={{ whiteSpace: "pre-wrap" }}>{modalItems.join("\n\n")}</pre>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalShow(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}

/**
 * Accumulated Multi-Use-Case Stepwise Adoption Chart
 * 
 * For a given set of use cases (data) and a step filter,
 * this component builds pairs of (old,new) states and then
 * aggregates modifications from each section (General Fields,
 * Scenarios, Conditions, Actors, Components).
 * It then displays one bar per section where the height equals
 * the sum of modifications (added+removed+changed+unchanged).
 * 
 * Clicking a bar shows a modal with a text summary.
 */
export function AccumulatedMultiUseCaseStepwiseAdoptionChart({ data, step }) {
    const chartRef = useRef(null);
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState("");
  
    // 1) Filter use cases by step and build old/new pairs
    const filtered = filterUseCasesByStep(data, step);
    const { oldUseCases, newUseCases } = buildUseCasePairs(filtered);
  
    // 2) Get aggregated diff results across all pairs
    const {
      general,
      scenarios,
      conditions,
      actors,
      components
    } = compareMultipleUseCases(oldUseCases, newUseCases);

    //console.log('general', general)
    //console.log('scenario', scenarios)
  
    // 3) Create an array with one entry per section.
    // For sections with "general", we assume the keys are: added, removed, changed, unchanged.
    // For the others, we assume they use "totalAdded", "totalRemoved", etc.
    const steps = [
      {
        step: "General Fields",
        added: general.added,
        removed: general.removed,
        changed: general.changed,
        unchanged: general.unchanged,
        details: general
      },
      {
        step: "Scenarios",
        added: scenarios.added,
        removed: scenarios.removed,
        changed: scenarios.changed,
        unchanged: scenarios.unchanged,
        details: scenarios
      },
      {
        step: "Conditions",
        added: conditions.added,
        removed: conditions.removed,
        changed: conditions.changed,
        unchanged: conditions.unchanged,
        details: conditions
      },
      {
        step: "Actors",
        added: actors.added,
        removed: actors.removed,
        changed: actors.changed,
        unchanged: actors.unchanged,
        details: actors
      },
      {
        step: "Components",
        added: components.added,
        removed: components.removed,
        changed: components.changed,
        unchanged: components.unchanged,
        details: components
      }
    ];
  
    // 4) For each section, calculate the accumulated total modifications.
    const accumulatedSteps = steps.map(s => ({
      step: s.step,
      total: s.added + s.removed + s.changed + s.unchanged,
      details: s.details
    }));

    //console.log(accumulatedSteps)
  
    // 5) Prepare the chart data: one bar per section.
    const chartData = {
      labels: accumulatedSteps.map(s => s.step),
      datasets: [
        {
          label: "Total Modifications",
          data: accumulatedSteps.map(s => s.total),
          backgroundColor: ["#36A2EB", "#FF6384", "#FFCE56", "#4BC0C0", "#9B59B6"],
          borderColor: ["#36A2EB", "#FF6384", "#FFCE56", "#4BC0C0", "#9B59B6"],
          borderWidth: 1,
        },
      ],
    };
  
    const options = {
      responsive: true,
      plugins: { legend: { display: false } },
      scales: { y: { beginAtZero: true } },
      onClick: (evt, elements) => {
        if (!elements || elements.length === 0) return;
        const { index } = elements[0];
        const clickedStep = accumulatedSteps[index];
        // Prepare a simple detail text from the aggregated details.
        // For example, if details.allAdded exists, use its length; otherwise, use details.added.
        const detailText = `
  Step: ${clickedStep.step}
  Total Modifications: ${clickedStep.total}
  Breakdown:
    Added: ${clickedStep.details.allAdded ? clickedStep.details.allAdded.length : clickedStep.details.added}
    Removed: ${clickedStep.details.allRemoved ? clickedStep.details.allRemoved.length : clickedStep.details.removed}
    Changed: ${clickedStep.details.allChanged ? clickedStep.details.allChanged.length : clickedStep.details.changed}
    Unchanged: ${clickedStep.details.allUnchanged ? clickedStep.details.allUnchanged.length : clickedStep.details.unchanged}
        `;
        setModalTitle(`Details for ${clickedStep.step}`);
        setModalContent(detailText);
        setModalShow(true);
      },
    };
  
    return (
      <>
        <Bar ref={chartRef} data={chartData} options={options} style={{ cursor: "pointer", maxHeight: 400 }} />
        <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <pre style={{ whiteSpace: "pre-wrap" }}>{modalContent}</pre>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalShow(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}

/**
 * Accumulated Multi-Use-Case Stepwise Adoption Chart (Cleaned)
 */
export function AccumulatedMultiUseCaseStepwiseAdoptionChartCleaned({ data, step }) {
    const chartRef = useRef(null);
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState("");
  
    // 1) Filter use cases by step and build old/new pairs
    const filtered = filterUseCasesByStep(data, step);
    const { oldUseCases, newUseCases } = buildUseCasePairs(filtered);
  
    // 2) Get aggregated diff results across all pairs.
    // Assume compareMultipleUseCases returns objects like:
    // {
    //   general: { added, removed, changed, unchanged, allAdded, ... },
    //   scenarios: { added: X, removed: Y, changed: Z, unchanged: W, ... },
    //   ... etc.
    const { general, scenarios, conditions, actors, components } =
      compareMultipleUseCases(oldUseCases, newUseCases);
  
    // 3) Build an array with one entry per section.
    const stepsArr = [
      {
        step: "General Fields",
        added: general.added,
        removed: general.removed,
        changed: general.changed,
        unchanged: general.unchanged,
        details: general
      },
      {
        step: "Scenarios",
        added: scenarios.added,
        removed: scenarios.removed,
        changed: scenarios.changed,
        unchanged: scenarios.unchanged,
        details: scenarios
      },
      {
        step: "Conditions",
        added: conditions.added,
        removed: conditions.removed,
        changed: conditions.changed,
        unchanged: conditions.unchanged,
        details: conditions
      },
      {
        step: "Actors",
        added: actors.added,
        removed: actors.removed,
        changed: actors.changed,
        unchanged: actors.unchanged,
        details: actors
      },
      {
        step: "Components",
        added: components.added,
        removed: components.removed,
        changed: components.changed,
        unchanged: components.unchanged,
        details: components
      }
    ];
  
    // 4) For each section, calculate:
    //    pos = added + changed + unchanged
    //    neg = - removed
    const accumulatedSteps = stepsArr.map(s => ({
      step: s.step,
      pos: s.added + s.changed + s.unchanged,
      neg: -s.removed,
      details: s.details
    }));
  
    // 5) Prepare the chart data.
    // We'll display two datasets: one for the positive (above 0) and one for removed (negative).
    const labels = accumulatedSteps.map(s => s.step);
    const posData = accumulatedSteps.map(s => s.pos);
    const negData = accumulatedSteps.map(s => s.neg);
  
    const chartData = {
      labels,
      datasets: [
        {
          label: "Positive (Added + Changed + Unchanged)",
          data: posData,
          backgroundColor: "#36A2EB",
          borderWidth: 1
        },
        {
          label: "Removed",
          data: negData,
          backgroundColor: "#FF6384",
          borderWidth: 1
        }
      ]
    };
  
    const options = {
      responsive: true,
      plugins: { legend: { position: "top" } },
      scales: {
        x: { stacked: true },
        y: { stacked: true, beginAtZero: true }
      },
      onClick: (evt, elements) => {
        if (!elements || elements.length === 0) return;
        const { index } = elements[0];
        const clickedStep = accumulatedSteps[index];
        const details = clickedStep.details;
        // Build a detail summary:
        const detailText = `
  Step: ${clickedStep.step}
  Positive (Added + Changed + Unchanged): ${clickedStep.pos}
  Removed: ${-clickedStep.neg}
  Total Modifications: ${clickedStep.pos - clickedStep.neg}
  Breakdown:
    Added: ${details.allAdded ? details.allAdded.length : details.added}
    Removed: ${details.allRemoved ? details.allRemoved.length : details.removed}
    Changed: ${details.allChanged ? details.allChanged.length : details.changed}
    Unchanged: ${details.allUnchanged ? details.allUnchanged.length : details.unchanged}
        `;
        setModalTitle(`Details for ${clickedStep.step}`);
        setModalContent(detailText);
        setModalShow(true);
      }
    };
  
    return (
      <>
        <Bar ref={chartRef} data={chartData} options={options} style={{ cursor: "pointer", maxHeight: 400 }} />
        <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <pre style={{ whiteSpace: "pre-wrap" }}>{modalContent}</pre>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalShow(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}

/**
 * Accumulated Multi-Use-Case Adoption Chart:
 * Shows four separate bars (Added, Removed, Changed, Unchanged)
 * with all counts as positive numbers.
 */
export function AccumulatedMultiUseCaseAdoptionChart({ data, step }) {
    const chartRef = useRef(null);
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState("");
  
    // 1) Filter use cases by step and build old/new pairs.
    const filtered = filterUseCasesByStep(data, step);
    const { oldUseCases, newUseCases } = buildUseCasePairs(filtered);
  
    // 2) Get aggregated diff results across all pairs.
    // Expected to return objects for each section with keys: added, removed, changed, unchanged and arrays: allAdded, etc.
    const { general, scenarios, conditions, actors, components } =
      compareMultipleUseCases(oldUseCases, newUseCases);
  
    // 3) Aggregate counts (across sections)
    const totalAdded =
      general.added + scenarios.added + conditions.added + actors.added + components.added;
    const totalRemoved =
      general.removed + scenarios.removed + conditions.removed + actors.removed + components.removed;
    const totalChanged =
      general.changed + scenarios.changed + conditions.changed + actors.changed + components.changed;
    const totalUnchanged =
      general.unchanged +
      scenarios.unchanged +
      conditions.unchanged +
      actors.unchanged +
      components.unchanged;
  
    // 4) Prepare chart data.
    // Note: We use one dataset with 4 data points so that the clicked element's index identifies the status.
    const chartData = {
      labels: ["Added", "Removed", "Changed", "Unchanged"],
      datasets: [
        {
          label: "Total Modifications",
          data: [totalAdded, totalRemoved, totalChanged, totalUnchanged],
          backgroundColor: [
            "rgba(75,192,192,0.6)", // teal
            "rgba(255,99,132,0.6)", // red
            "rgba(255,206,86,0.6)", // yellow
            "rgba(153,102,255,0.6)" // purple
          ],
          borderColor: [
            "rgba(75,192,192,1)",
            "rgba(255,99,132,1)",
            "rgba(255,206,86,1)",
            "rgba(153,102,255,1)"
          ],
          borderWidth: 1,
        },
      ],
    };
  
    const options = {
      responsive: true,
      plugins: { legend: { display: false } },
      scales: { y: { beginAtZero: true } },
      // Use the clicked element's index (not datasetIndex, because there's one dataset).
      onClick: (evt, elements) => {
        if (!elements || elements.length === 0) return;
        const { index } = elements[0];
        const statusLabels = ["Added", "Removed", "Changed", "Unchanged"];
        const clickedStatus = statusLabels[index];
        // Concatenate details from all sections.
        let details = [];
        if (clickedStatus === "Added") {
          details = general.allAdded
            .concat(scenarios.allAdded, conditions.allAdded, actors.allAdded, components.allAdded);
        } else if (clickedStatus === "Removed") {
          details = general.allRemoved
            .concat(scenarios.allRemoved, conditions.allRemoved, actors.allRemoved, components.allRemoved);
        } else if (clickedStatus === "Changed") {
          details = general.allChanged
            .concat(scenarios.allChanged, conditions.allChanged, actors.allChanged, components.allChanged);
        } else if (clickedStatus === "Unchanged") {
          details = general.allUnchanged
            .concat(scenarios.allUnchanged, conditions.allUnchanged, actors.allUnchanged, components.allUnchanged);
        }
        // Format each detail (if object, try to use its "detail" property)
        const formattedDetails = details.map(item =>
          typeof item === "object" && item.detail ? item.detail : String(item)
        );
        const detailText = `Status: ${clickedStatus}\nCount: ${formattedDetails.length}\nDetails:\n${formattedDetails.join("\n")}`;
        setModalTitle(`Details for ${clickedStatus}`);
        setModalContent(detailText);
        setModalShow(true);
      },
    };
  
    return (
      <>
        <Bar ref={chartRef} data={chartData} options={options} style={{ maxHeight: 400, cursor: "pointer" }} />
        <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <pre style={{ whiteSpace: "pre-wrap" }}>{modalContent}</pre>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalShow(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}
  
/**
 * Accumulated Multi-Use-Case Adoption Chart (Cleaned):
 * The "Removed" count is shown as a negative value,
 * so that the bar for removed modifications goes downward.
 */
export function AccumulatedMultiUseCaseAdoptionChartCleaned({ data, step }) {
    const chartRef = useRef(null);
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState("");
  
    // 1) Filter use cases by step and build old/new pairs.
    const filtered = filterUseCasesByStep(data, step);
    const { oldUseCases, newUseCases } = buildUseCasePairs(filtered);
  
    // 2) Get aggregated diff results across all pairs.
    const { general, scenarios, conditions, actors, components } =
      compareMultipleUseCases(oldUseCases, newUseCases);
  
    // 3) Aggregate counts across sections.
    const totalAdded =
      general.added + scenarios.added + conditions.added + actors.added + components.added;
    const totalRemoved =
      general.removed + scenarios.removed + conditions.removed + actors.removed + components.removed;
    const totalChanged =
      general.changed + scenarios.changed + conditions.changed + actors.changed + components.changed;
    const totalUnchanged =
      general.unchanged +
      scenarios.unchanged +
      conditions.unchanged +
      actors.unchanged +
      components.unchanged;
  
    // 4) Prepare chart data.
    // Multiply the removed count by -1 to show it as a negative bar.
    const chartData = {
      labels: ["Added", "Removed", "Changed", "Unchanged"],
      datasets: [
        {
          label: "Total Modifications",
          data: [totalAdded, -totalRemoved, totalChanged, totalUnchanged],
          backgroundColor: [
            "rgba(75,192,192,0.6)", // teal
            "rgba(255,99,132,0.6)", // red
            "rgba(255,206,86,0.6)", // yellow
            "rgba(153,102,255,0.6)" // purple
          ],
          borderColor: [
            "rgba(75,192,192,1)",
            "rgba(255,99,132,1)",
            "rgba(255,206,86,1)",
            "rgba(153,102,255,1)"
          ],
          borderWidth: 1,
        },
      ],
    };
  
    const options = {
      responsive: true,
      plugins: { legend: { display: false } },
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            callback: value => value, // show negative ticks as-is
          },
        },
      },
      onClick: (evt, elements) => {
        if (!elements || elements.length === 0) return;
        const { index } = elements[0]; // use index to identify the clicked bar
        const statusLabels = ["Added", "Removed", "Changed", "Unchanged"];
        const clickedStatus = statusLabels[index];
        let details = [];
        if (clickedStatus === "Added") {
          details = general.allAdded
            .concat(scenarios.allAdded, conditions.allAdded, actors.allAdded, components.allAdded);
        } else if (clickedStatus === "Removed") {
          details = general.allRemoved
            .concat(scenarios.allRemoved, conditions.allRemoved, actors.allRemoved, components.allRemoved);
        } else if (clickedStatus === "Changed") {
          details = general.allChanged
            .concat(scenarios.allChanged, conditions.allChanged, actors.allChanged, components.allChanged);
        } else if (clickedStatus === "Unchanged") {
          details = general.allUnchanged
            .concat(scenarios.allUnchanged, conditions.allUnchanged, actors.allUnchanged, components.allUnchanged);
        }
        const formattedDetails = details.map(item =>
          typeof item === "object" && item.detail ? item.detail : String(item)
        );
        const detailText = `Status: ${clickedStatus}\nCount: ${formattedDetails.length}\nDetails:\n${formattedDetails.join("\n")}`;
        setModalTitle(`Details for ${clickedStatus}`);
        setModalContent(detailText);
        setModalShow(true);
      },
    };
  
    return (
      <>
        <Bar ref={chartRef} data={chartData} options={options} style={{ maxHeight: 400, cursor: "pointer" }} />
        <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <pre style={{ whiteSpace: "pre-wrap" }}>{modalContent}</pre>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalShow(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}


// #########################################################################################
// ############################### Stacked Bar Chart Step 1 General ########################
// #########################################################################################

function initGeneralFieldsAccumulator() {
    return {
      added: 0,
      removed: 0,
      changed: 0,
      unchanged: 0,
      allAdded: [],
      allRemoved: [],
      allChanged: [],
      allUnchanged: [],
    };
}
  
function compareMultipleUseCasesGeneralFields(oldUseCases, newUseCases) {
    const agg = initGeneralFieldsAccumulator();
  
    const length = Math.min(oldUseCases.length, newUseCases.length);
  
    for (let i = 0; i < length; i++) {
      const oldUC = oldUseCases[i];
      const newUC = newUseCases[i];
      const diff = diffGeneralFields(oldUC, newUC); // your existing function
  
      // Merge:
      agg.added += diff.added;
      agg.removed += diff.removed;
      agg.changed += diff.changed;
      agg.unchanged += diff.unchanged;
  
      agg.allAdded.push(...diff.allAdded);
      agg.allRemoved.push(...diff.allRemoved);
      agg.allChanged.push(...diff.allChanged);
      agg.allUnchanged.push(...diff.allUnchanged);
    }
  
    return agg;
}

/**
 * Compare multiple pairs of old/new states and produce 
 * a stacked chart aggregator for the 10 general fields.
 */
function compareMultiUseCasesGeneralFields(oldUseCases, newUseCases) {
    const length = Math.min(oldUseCases.length, newUseCases.length);
  
    // We'll accumulate integer counts in arrays of length 10
    const addedCounts = new Array(10).fill(0);
    const removedCounts = new Array(10).fill(0);
    const changedCounts = new Array(10).fill(0);
    const unchangedCounts = new Array(10).fill(0);
  
    for (let i=0; i<length; i++) {
      const oldUC = oldUseCases[i];
      const newUC = newUseCases[i];
      const fieldDiffs = getGeneralFieldsDiff(oldUC, newUC); 
      // or you can do your "diffGeneralFields" if you prefer. 
      // "getGeneralFieldsDiff" returns an array of 10 objects
  
      // fieldDiffs is an array of length 10
      fieldDiffs.forEach((diff, index) => {
        // "diff.type" in ["added","removed","changed","unchanged"]
        if (diff.type === "added") addedCounts[index]++;
        else if (diff.type === "removed") removedCounts[index]++;
        else if (diff.type === "changed") changedCounts[index]++;
        else if (diff.type === "unchanged") unchangedCounts[index]++;
      });
    }
  
    return {
      addedCounts,
      removedCounts,
      changedCounts,
      unchangedCounts,
    };
}
  
export function MultiUseCaseGeneralFieldsStackedBar({ data, step }) {

    // For each pair of old vs new use cases
    const { oldUseCases, newUseCases } = buildUseCasePairs(filterUseCasesByStep(data, step));

    const { addedCounts, removedCounts, changedCounts, unchangedCounts } =
        compareMultiUseCasesGeneralFields(oldUseCases, newUseCases);
  
    // Build the chart data
    const chartData = {
      labels: GENERAL_FIELDS, 
      datasets: [
        {
          label: "Added",
          data: addedCounts,
          backgroundColor: "rgba(75,192,192,0.6)"
        },
        {
          label: "Removed",
          data: removedCounts,
          backgroundColor: "rgba(255,99,132,0.6)"
        },
        {
          label: "Changed",
          data: changedCounts,
          backgroundColor: "rgba(255,206,86,0.6)"
        },
        {
          label: "Unchanged",
          data: unchangedCounts,
          backgroundColor: "rgba(153,102,255,0.6)"
        }
      ]
    };
  
    const options = {
      responsive: true,
      scales: {
        x: { stacked: true },
        y: { stacked: true, beginAtZero: true }
      }
    };
  
    return <Bar data={chartData} options={options} />;
}


export function MultiUseCaseGeneralFieldsAdoptionChart({ data, step }) {
    const chartRef = useRef(null);
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState("");

    // For each pair of old vs new use cases
    const { oldUseCases, newUseCases } = buildUseCasePairs(filterUseCasesByStep(data, step));
  
    // 1) We call the aggregator
    const agg = compareMultipleUseCasesGeneralFields(oldUseCases, newUseCases);
  
    // 2) Build an array of length 10, each 0 or 1, for each of added/removed/changed/unchanged
    // Because your original chart does 10 bars (one for each field).
    // But note: We do NOT have the field-by-field detail anymore if we just aggregate.
    // 
    // If you want to do a field-by-field approach, you'd have to do a deeper merging
    // that sums changes on each field across all pairs. For simplicity, let's do an
    // overall single bar with 4 stacked segments. 
    //
    // If you want 10 bars, each bar representing the aggregated "added" for that field,
    // we must do a deeper aggregator. For now let's do a single bar with 4 segments.
    
    // We'll do 1 label => "General Fields"
    // So the X-axis has 1 column, and 4 stacked segments for added/removed/changed/unchanged:
    const labels = ["General Fields"];
    
    const datasetAdded = {
      label: "Added",
      data: [agg.added],
      backgroundColor: "rgba(75,192,192,0.6)",
    };
    const datasetRemoved = {
      label: "Removed",
      data: [agg.removed],
      backgroundColor: "rgba(255,99,132,0.6)",
    };
    const datasetChanged = {
      label: "Changed",
      data: [agg.changed],
      backgroundColor: "rgba(255,206,86,0.6)",
    };
    const datasetUnchanged = {
      label: "Unchanged",
      data: [agg.unchanged],
      backgroundColor: "rgba(153,102,255,0.6)",
    };
  
    const chartData = {
      labels,
      datasets: [datasetAdded, datasetRemoved, datasetChanged, datasetUnchanged]
    };
  
    const options = {
      responsive: true,
      scales: {
        x: { stacked: true },
        y: { stacked: true, beginAtZero: true },
      },
      plugins: { legend: { position: "top" } },
      onClick: (evt, elements) => {
        if (!elements?.length) return;
        const { datasetIndex } = elements[0];
        const labelMap = ["Added","Removed","Changed","Unchanged"];
        const status = labelMap[datasetIndex];
        openModal(status);
      }
    };
  
    function openModal(status) {
      setModalTitle(`${status} (All Use Cases)`);
      let details;
      if (status === "Added") details = agg.allAdded.map((obj) => obj.detail);
      else if (status === "Removed") details = agg.allRemoved.map((obj) => obj.detail);
      else if (status === "Changed") details = agg.allChanged.map((obj) => obj.detail);
      else details = agg.allUnchanged.map((obj) => obj.detail);
  
      if (!details || !details.length) {
        setModalContent("No items found.");
      } else {
        setModalContent(details.join("\n"));
      }
      setModalShow(true);
    }
  
    return (
      <>
        <Bar ref={chartRef} data={chartData} options={options} style={{ maxHeight: 400 }} />
  
        <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <pre style={{ whiteSpace: "pre-wrap" }}>{modalContent}</pre>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalShow(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}

// #########################################################################################
// ############################### Stacked Bar Chart Step 2 Scenarios and Conditions #######
// #########################################################################################
  
function compareMultipleUseCasesScenarios(oldUseCases, newUseCases) {
    let added=0, removed=0, unchanged=0;
    let allAdded=[], allRemoved=[], allUnchanged=[];
  
    const length = Math.min(oldUseCases.length, newUseCases.length);
    for (let i=0; i<length; i++) {
      const diff = diffScenarios(oldUseCases[i], newUseCases[i]);
      added += diff.totalAdded;
      removed += diff.totalRemoved;
      unchanged += diff.totalUnchanged;
      allAdded.push(...diff.allAdded);
      allRemoved.push(...diff.allRemoved);
      allUnchanged.push(...diff.allUnchanged);
    }
  
    return { added, removed, unchanged, allAdded, allRemoved, allUnchanged };
}

export function MultiUseCaseScenariosAdoptionChart({ data, step}) {
    
    const chartRef = useRef(null);
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState("");

    // For each pair of old vs new use cases
    const { oldUseCases, newUseCases } = buildUseCasePairs(filterUseCasesByStep(data, step));

    const {
        added,
        removed,
        unchanged,
        allAdded,
        allRemoved,
        allUnchanged,
    } = compareMultipleUseCasesScenarios(oldUseCases, newUseCases);
    
    const labels = ["Scenarios"];
    const datasetAdded = {
        label: "Added",
        data: [added],
        backgroundColor: "rgba(75,192,192,0.6)",
    };

    const datasetRemoved = {
        label: "Removed",
        data: [removed],
        backgroundColor: "rgba(255,99,132,0.6)",
    };

    const datasetUnchanged = {
        label: "Unchanged",
        data: [unchanged],
        backgroundColor: "rgba(153,102,255,0.6)",
    };

    const chartData = {
        labels,
        datasets: [datasetAdded, datasetRemoved, datasetUnchanged],
    };

    const options = {
        responsive: true,
        scales: {
            x: { stacked: true },
            y: { stacked: true, beginAtZero: true },
        },
        plugins: { legend: { position: "top" } },
        onClick: (evt, elements) => {
            if (!elements?.length) return;
            const { datasetIndex } = elements[0];
            const labelMap = ["Added", "Removed", "Unchanged"];
            const status = labelMap[datasetIndex];
            openModal(status);
        },
    };

    function openModal(status) {
        setModalTitle(`${status} Scenarios (All Use Cases)`);
        let details = [];
        if (status === "Added") details = allAdded;
        else if (status === "Removed") details = allRemoved;
        else details = allUnchanged;
        // Format details: if an item is an object with a 'detail' property, use that.
        const formatted = details.map(item => {
          if (typeof item === "string") return item;
          if (item.detail) return item.detail;
          return JSON.stringify(item, null, 2);
        });
        setModalContent(formatted.join("\n\n"));
        setModalShow(true);
    }

    return (
        <>
            <Bar ref={chartRef} data={chartData} options={options} style={{ maxHeight: 400 }} />

            <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <pre style={{ whiteSpace: "pre-wrap" }}>{modalContent}</pre>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalShow(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

function compareMultipleUseCasesConditions(oldUseCases, newUseCases) {

    let added = 0, removed = 0, unchanged = 0;
    let allAdded = [], allRemoved = [], allUnchanged = [];

    /*
    let categoryDiffs = {
        added: [],
        removed: [],
        unchanged: [],
    };
    */

    const length = Math.min(oldUseCases.length, newUseCases.length);
    for (let i = 0; i < length; i++) {
        const diff = diffScenarioConditions(oldUseCases[i], newUseCases[i]);        
        added += diff.totalAdded;
        removed += diff.totalRemoved;
        unchanged += diff.totalUnchanged;

        // Iterate over each condition field to accumulate details.
        CONDITION_FIELDS.forEach(field => {
            if (diff.categoryDiffs && diff.categoryDiffs[field]) {
                allAdded.push(...diff.categoryDiffs[field].added);
                allRemoved.push(...diff.categoryDiffs[field].removed);
                allUnchanged.push(...diff.categoryDiffs[field].unchanged);
            }
        });        
    }

    return { added, removed, unchanged, allAdded, allRemoved, allUnchanged };
}

export function MultiUseCaseConditionsAdoptionChart({ data, step }) {

    const chartRef = useRef(null);
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState("");

        // For each pair of old vs new use cases
        const { oldUseCases, newUseCases } = buildUseCasePairs(filterUseCasesByStep(data, step));
    
    const {
        added,
        removed,
        unchanged,
        allAdded,
        allRemoved,
        allUnchanged,
    } = compareMultipleUseCasesConditions(oldUseCases, newUseCases);

    const labels = ["Conditions"];
    const datasetAdded = {
        label: "Added",
        data: [added],
        backgroundColor: "rgba(75,192,192,0.6)",
    };

    const datasetRemoved = {
        label: "Removed",
        data: [removed],
        backgroundColor: "rgba(255,99,132,0.6)",
    };

    const datasetUnchanged = {
        label: "Unchanged",
        data: [unchanged],
        backgroundColor: "rgba(153,102,255,0.6)",
    };

    const chartData = {
        labels,
        datasets: [datasetAdded, datasetRemoved, datasetUnchanged],
    };

    const options = {
        responsive: true,
        scales: {
            x: { stacked: true },
            y: { stacked: true, beginAtZero: true },
        },
        plugins: { legend: { position: "top" } },
        onClick: (evt, elements) => {
            if (!elements?.length) return;
            const { datasetIndex } = elements[0];
            const labelMap = ["Added", "Removed", "Unchanged"];
            const status = labelMap[datasetIndex];
            openModal(status);
        },
    };

    function openModal(status) {
        setModalTitle(`${status} Conditions (All Use Cases)`);
        let details = [];
        if (status === "Added") details = allAdded;
        else if (status === "Removed") details = allRemoved;
        else details = allUnchanged;

        const formatted = details.map(item => {
          if (typeof item === "string") return item;
          if (item.detail) return item.detail;
          return JSON.stringify(item, null, 2);
        });
        setModalContent(formatted.join("\n\n"));
        setModalShow(true);
    }

    return (
        <>
            <Bar ref={chartRef} data={chartData} options={options} style={{ maxHeight: 400 }} />

            <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <pre style={{ whiteSpace: "pre-wrap" }}>{modalContent}</pre>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalShow(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

/**
 * Compares multiple pairs of old/new use cases for their
 * Scenarios *and* Conditions, then aggregates into a single
 * (Added/Removed/Unchanged) count + arrays for all pairs.
 *
 * Steps:
 *  - For i in [0..minLen-1], do:
 *     1) scenarioDiff = diffScenarios(oldUseCases[i], newUseCases[i]);
 *     2) conditionDiff = diffScenarioConditions(oldUseCases[i], newUseCases[i]);
 *     3) sum scenarioDiff + conditionDiff into aggregator.
 *
 * Returns: {
 *   added, removed, unchanged,
 *   allAdded, allRemoved, allUnchanged
 * }
 */
function compareMultipleUseCasesScenariosAndConditions(oldUseCases, newUseCases) {
    let added = 0, removed = 0, unchanged = 0;
    let allAdded = [], allRemoved = [], allUnchanged = [];
  
    const length = Math.min(oldUseCases.length, newUseCases.length);
    for (let i = 0; i < length; i++) {
      const scenarioDiff = diffScenarios(oldUseCases[i], newUseCases[i]);
      const conditionDiff = diffScenarioConditions(oldUseCases[i], newUseCases[i]);
  
      // aggregator
      added += (scenarioDiff.totalAdded || 0) + (conditionDiff.totalAdded || 0);
      removed += (scenarioDiff.totalRemoved || 0) + (conditionDiff.totalRemoved || 0);
      unchanged += (scenarioDiff.totalUnchanged || 0) + (conditionDiff.totalUnchanged || 0);
  
      allAdded.push(...(scenarioDiff.allAdded || []), ...(conditionDiff.allAdded || []));
      allRemoved.push(...(scenarioDiff.allRemoved || []), ...(conditionDiff.allRemoved || []));
      allUnchanged.push(...(scenarioDiff.allUnchanged || []), ...(conditionDiff.allUnchanged || []));
    }
  
    return { added, removed, unchanged, allAdded, allRemoved, allUnchanged };
}

  
export function MultiUseCaseScenariosAndConditionsAdoptionChart({ data, step }) {
    const chartRef = useRef(null);
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState("");
  
    // 1) Filter use cases by step, build pairs
    const filtered = filterUseCasesByStep(data, step); 
    const { oldUseCases, newUseCases } = buildUseCasePairs(filtered);
  
    // 2) Compare aggregator
    const {
      added,
      removed,
      unchanged,
      allAdded,
      allRemoved,
      allUnchanged
    } = compareMultipleUseCasesScenariosAndConditions(oldUseCases, newUseCases);
  
    // 3) Build the chart data => 1 column labeled "Scenarios & Conditions"
    const labels = ["Scenarios & Conditions"];
    const datasetAdded = {
      label: "Added",
      data: [added],
      backgroundColor: "rgba(75,192,192,0.6)"
    };
    const datasetRemoved = {
      label: "Removed",
      data: [removed],
      backgroundColor: "rgba(255,99,132,0.6)"
    };
    const datasetUnchanged = {
      label: "Unchanged",
      data: [unchanged],
      backgroundColor: "rgba(153,102,255,0.6)"
    };
  
    const chartData = {
      labels,
      datasets: [datasetAdded, datasetRemoved, datasetUnchanged]
    };
  
    const options = {
      responsive: true,
      scales: {
        x: { stacked: true },
        y: { stacked: true, beginAtZero: true },
      },
      plugins: { legend: { position: "top" } },
      onClick: (evt, elements) => {
        if (!elements?.length) return;
        const { datasetIndex } = elements[0];
        const labelMap = ["Added", "Removed", "Unchanged"];
        const clickedStatus = labelMap[datasetIndex];
        openModal(clickedStatus);
      },
    };
  
    function openModal(status) {
      setModalTitle(`${status} (All Use Cases)`);
      let details = [];
      if (status === "Added") details = allAdded;
      else if (status === "Removed") details = allRemoved;
      else if (status === "Unchanged") details = allUnchanged;
  
      if (!details.length) {
        setModalContent("No items found.");
      } else {
        // If each item is a string, just join them
        // If each item is an object, adapt as needed
        setModalContent(details.join("\n"));
      }
      setModalShow(true);
    }
  
    return (
      <>
        <Bar ref={chartRef} data={chartData} options={options} style={{ maxHeight: 400 }} />
  
        <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <pre style={{ whiteSpace: "pre-wrap" }}>{modalContent}</pre>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalShow(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}

// #########################################################################################
// ############################### Stacked Bar Chart Step 3 Actors #########################

/**
 * Aggregates actor diffs across multiple pairs of old/new use cases.
 */
function compareMultipleUseCasesActors(oldUseCases, newUseCases) {
    const length = Math.min(oldUseCases.length, newUseCases.length);
    const aggregator = {}; // aggregator[catName] => { added:[], removed:[], changed:[], unchanged:[] }
  
    for (let i = 0; i < length; i++) {
      const diff = diffActorLists(oldUseCases[i], newUseCases[i]); 
      // => { totalAdded, totalRemoved, totalChanged, totalUnchanged, categoryDiffs:{ catName:{ added:[],...} } }
  
      // Merge each category
      Object.keys(diff.categoryDiffs).forEach((catName) => {
        if (!aggregator[catName]) {
          aggregator[catName] = {
            added: [],
            removed: [],
            changed: [],
            unchanged: []
          };
        }
        const catDiff = diff.categoryDiffs[catName];
        aggregator[catName].added.push(...catDiff.added);
        aggregator[catName].removed.push(...catDiff.removed);
        aggregator[catName].changed.push(...catDiff.changed);
        aggregator[catName].unchanged.push(...catDiff.unchanged);
      });
    }
  
    return aggregator;
}  


export function MultiUseCaseActorAdoptionChart({ data, step }) {
    const chartRef = useRef(null);
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalItems, setModalItems] = useState([]);

    // For each pair of old vs new use cases
    const { oldUseCases, newUseCases } = buildUseCasePairs(filterUseCasesByStep(data, step));
  
    // 1) Build aggregator
    const aggregator = compareMultipleUseCasesActors(oldUseCases, newUseCases);
    // aggregator => { "Consumer": { added:[], removed:[], changed:[], unchanged:[] }, "Producer":{...}, ... }
  
    // 2) X-axis categories
    const categories = Object.keys(aggregator);
  
    // 3) For each category, we do aggregator[cat].added.length, etc.
    const addedData = categories.map(cat => aggregator[cat].added.length);
    const removedData = categories.map(cat => aggregator[cat].removed.length);
    const changedData = categories.map(cat => aggregator[cat].changed.length);
    const unchangedData = categories.map(cat => aggregator[cat].unchanged.length);
  
    // 4) Build chart data
    const chartData = {
      labels: categories,
      datasets: [
        {
          label: "Added",
          data: addedData,
          backgroundColor: "rgba(75,192,192,0.6)",
        },
        {
          label: "Removed",
          data: removedData,
          backgroundColor: "rgba(255,99,132,0.6)",
        },
        {
          label: "Changed",
          data: changedData,
          backgroundColor: "rgba(255,206,86,0.6)",
        },
        {
          label: "Unchanged",
          data: unchangedData,
          backgroundColor: "rgba(153,102,255,0.6)",
        },
      ],
    };
  
    // 5) Chart options
    const options = {
      responsive: true,
      plugins: { legend: { position: "top" } },
      scales: {
        x: { stacked: true },
        y: { stacked: true, beginAtZero: true },
      },
      onClick: (evt, elements) => {
        if (!elements?.length) return;
        const { index, datasetIndex } = elements[0];
        const clickedCategory = categories[index];
        const labelMap = ["Added","Removed","Changed","Unchanged"];
        const clickedStatus = labelMap[datasetIndex];
        openModal(clickedCategory, clickedStatus);
      },
    };
  
    function openModal(category, status) {
      setModalTitle(`${status} Actors in "${category}"`);
      const catRecord = aggregator[category];
      let items = [];
      switch (status) {
        case "Added":
          items = catRecord.added;
          break;
        case "Removed":
          items = catRecord.removed;
          break;
        case "Changed":
          items = catRecord.changed;
          break;
        case "Unchanged":
          items = catRecord.unchanged;
          break;
        default:
          items = [];
      }
      setModalItems(items);
      setModalShow(true);
    }
  
    return (
      <>
        <Bar ref={chartRef} data={chartData} options={options} style={{ cursor: "pointer" }} />
  
        <Modal show={modalShow} onHide={() => setModalShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalItems.length === 0 ? (
              <p>No items found.</p>
            ) : (
              <ul>
                {modalItems.map((actor, i) => (
                  <li key={i}>{actor}</li>
                ))}
              </ul>
            )}
          </Modal.Body>
          <Modal.Footer>
            {modalTitle.includes("Changed") && (
              <div className="me-auto text-muted">
                <small>
                  * "Changed" typically means the same actor is present in old & new
                  but re-ordered (different index).
                </small>
              </div>
            )}
            <Button variant="secondary" onClick={() => setModalShow(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
} 

// #########################################################################################
// ############################### Stacked Bar Chart Step 4 Components #####################
// #########################################################################################

/**
 * Aggregates component diffs across multiple old/new pairs.
 */
function compareMultipleUseCasesComponents(oldUseCases, newUseCases) {
    const length = Math.min(oldUseCases.length, newUseCases.length);
    const aggregator = {};
  
    for (let i = 0; i < length; i++) {
      const diff = diffComponentItems(oldUseCases[i], newUseCases[i]);
      // => { categoryDiffs: { catName: [ { description, added, removed, changed, unchanged} ]}, ...}
  
      Object.keys(diff.categoryDiffs).forEach((catName) => {
        // aggregator[catName] => { added:[], removed:[], changed:[], unchanged:[] } 
        if (!aggregator[catName]) {
          aggregator[catName] = { added:[], removed:[], changed:[], unchanged:[] };
        }
        const subcomps = diff.categoryDiffs[catName];
  
        // subcomps is array of { description, added, removed, changed, unchanged }
        subcomps.forEach((sc) => {
          aggregator[catName].added.push(...sc.added);
          aggregator[catName].removed.push(...sc.removed);
          aggregator[catName].changed.push(...sc.changed);
          aggregator[catName].unchanged.push(...sc.unchanged);
        });
      });
    }
  
    return aggregator;
}
  
export function MultiUseCaseComponentAdoptionChart({ data, step }) {
    const chartRef = useRef(null);
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalItems, setModalItems] = useState([]);

    // For each pair of old vs new use cases
    const { oldUseCases, newUseCases } = buildUseCasePairs(filterUseCasesByStep(data, step));
  
    // 1) aggregator
    const aggregator = compareMultipleUseCasesComponents(oldUseCases, newUseCases);
    // aggregator => { "Operations":{ added:[], removed:[], changed:[], unchanged:[] }, "Technical":{...}, ... }
  
    // 2) categories
    const categories = Object.keys(aggregator);
  
    // 3) sum up array lengths
    const addedData = categories.map(cat => aggregator[cat].added.length);
    const removedData = categories.map(cat => aggregator[cat].removed.length);
    const changedData = categories.map(cat => aggregator[cat].changed.length);
    const unchangedData = categories.map(cat => aggregator[cat].unchanged.length);
  
    const chartData = {
      labels: categories,
      datasets: [
        {
          label: "Added",
          data: addedData,
          backgroundColor: "rgba(75,192,192,0.6)"
        },
        {
          label: "Removed",
          data: removedData,
          backgroundColor: "rgba(255,99,132,0.6)"
        },
        {
          label: "Changed",
          data: changedData,
          backgroundColor: "rgba(255,206,86,0.6)"
        },
        {
          label: "Unchanged",
          data: unchangedData,
          backgroundColor: "rgba(153,102,255,0.6)"
        }
      ]
    };
  
    const options = {
      responsive: true,
      plugins: {
        legend: { position: "top" },
      },
      scales: {
        x: { stacked: true },
        y: { stacked: true, beginAtZero: true },
      },
      onClick: (evt, elements) => {
        if (!elements?.length) return;
        const { index, datasetIndex } = elements[0];
        const catName = categories[index];
        const labelMap = ["Added","Removed","Changed","Unchanged"];
        const status = labelMap[datasetIndex];
        openModal(catName, status);
      }
    };
  
    function openModal(catName, status) {
      setModalTitle(`${status} Items in "${catName}"`);
      const record = aggregator[catName]; 
      let items = [];
      switch (status) {
        case "Added":
          items = record.added;
          break;
        case "Removed":
          items = record.removed;
          break;
        case "Changed":
          items = record.changed;
          break;
        case "Unchanged":
          items = record.unchanged;
          break;
        default:
          items = [];
      }
      setModalItems(items);
      setModalShow(true);
    }
  
    return (
      <>
        <Bar ref={chartRef} data={chartData} options={options} style={{ cursor: "pointer" }} />
  
        <Modal show={modalShow} onHide={() => setModalShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalItems.length === 0 ? (
              <p>No items found.</p>
            ) : (
              <ul>
                {modalItems.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            )}
          </Modal.Body>
          <Modal.Footer>
            {modalTitle.includes("Changed") && (
              <div className="me-auto text-muted">
                <small>
                  * "Changed" typically means the same item is in both old & new but re-ordered.
                </small>
              </div>
            )}
            <Button variant="secondary" onClick={() => setModalShow(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}  

// ############################### Other ####################################################

/**
 * Adaptation Over Time Chart and across all history entries of the use case in question
 * @param {*} param0 
 * @returns 
 */
export const AdaptationOverTimeChart = ({ useCaseData }) => {
    const fields = [
        'title', 
        'acronym', 
        'maturity', 
        'application', 
        'status',
        'tags',
        'description', 
        'actions',
        'conditions',
        'actors',
        'components',
    ];

    // Get all history entries and the timestamps
    const historyData = useCaseData.history?.data || [];
    const timestamps = useCaseData.history?.time_stamp || [];

    // Track the number of field modifications between consecutive history objects
    const modificationCounts = historyData.map((history, index) => {
        if (index === 0) return 0;  // Skip the first entry, as there's nothing to compare it with

        const previousHistory = historyData[index - 1];

        // Count the number of fields that have changed compared to the previous entry
        return fields.reduce((count, field) => {
            const previousValue = previousHistory[field]?.value;
            const currentValue = history[field]?.value;
            return previousValue !== currentValue ? count + 1 : count;
        }, 0);
    });

    const data = {
      labels: timestamps.map(ts => new Date(ts).toLocaleDateString()),  // Format the timestamps for x-axis
      datasets: [
        {
          label: 'Number of Field Modifications Over Time',
          data: modificationCounts,
          fill: false,
          backgroundColor: '#36A2EB',
          borderColor: '#36A2EB',
          tension: 0.1,  // Optional: make the line smooth
        },
      ],
    };

    const options = {
      scales: {
        x: {
          title: {
            display: true,
            text: 'Timestamp',
          },
        },
        y: {
          title: {
            display: true,
            text: 'Number of Modifications',
          },
          beginAtZero: true,
        },
      },
    };

    return <Line data={data} options={options} />;
};

/**
 * Shows the timeline of changes and updates, such as when new versions were created and how long each version persisted.
 * @param {*} param0 
 * @returns 
 */
export const VersionHistoryTimeline = ({ useCaseData }) => {
    const historyData = useCaseData.history?.data || [];
    const timestamps = useCaseData.history?.time_stamp || [];

    // Create labels for each version
    const labels = historyData.map((_, idx) => `Version ${idx + 1}`);

    // Format the timestamps to be displayed on the y-axis (e.g., "YYYY-MM-DD HH:mm")
    const formattedTimestamps = timestamps.map(ts => {
        const date = new Date(ts);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    });

    const data = {
        labels, // Version labels (Version 1, Version 2, etc.)
        datasets: [
            {
                label: 'Version History',
                data: Array(timestamps.length).fill(1), // Fill with 1 for each version, since we only need the timeline
                backgroundColor: '#4BC0C0',
                borderColor: '#4BC0C0',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        scales: {
            y: {
                type: 'category',  // Ensure the y-axis is categorical (display formatted dates)
                labels: formattedTimestamps, // Show formatted dates as y-axis labels
                title: {
                    display: true,
                    text: 'Version Timestamps',
                },
            },
        },
    };

    return <Bar data={data} options={options} />;
};

/**
 * Show the trend of how much the user relies on the co-pilot’s suggestions versus manual input over time.
 * @param {*} param0 
 * @returns 
 */
export const UserEditingBehaviorChart = ({ useCaseData }) => {
    const fields = ['title', 'acronym', 'maturity', 'application', 'status', 'tags', 'description', 'actions', 'conditions', 'actors', 'components'];
    const historyData = useCaseData.history?.data || [];
    const timestamps = useCaseData.history?.time_stamp || [];

    const copilotSuggestions = historyData.map((history) => {
        return fields.filter(field => history[field]?.source === 'ucm_copilot').length;
    });

    const manualChanges = historyData.map((history) => {
        return fields.filter(field => history[field]?.source === 'manually').length;
    });

    const data = {
        labels: timestamps.map(ts => new Date(ts).toLocaleDateString()),
        datasets: [
            {
                label: 'Co-pilot Suggestions',
                data: copilotSuggestions,
                backgroundColor: '#36A2EB',
            },
            {
                label: 'Manual Changes',
                data: manualChanges,
                backgroundColor: '#FF6384',
            },
        ],
    };

    return <Line data={data} />;
};