/**
 *  
 * Use Case Analysis: focusing on the three steps given in the study setup
 * 
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2025-02-08
 * 
 */

import React from 'react';
import { Row, Col, Card, Button, Badge, Accordion, Alert } from 'react-bootstrap';

import { StatisticsModeledUCs } from '../../eco_analytics_handling/eco_analytics_charts';

import { 
    // Processing Time Analysis
    calculateProcessingTimes,
    aggregateProcessingStats,
    ProcessingTimesChart, // Average processing duration per task and use case
    ProcessingTimesTable, // Average processing times per use case
    DescriptiveStatsTable, // Descriptive statistics for processing times
    ProcessingBoxPlot, // Box plot for processing times
    DurationHistogram, // Distribution histogram for processing times

    // Expert Prompt Analysis
    extractExpertPrompts,
    countWordFrequencies,
    groupPromptsBy,
    AllExpertPromptsTable,
    KeywordFrequencyTable,
    PromptsGroupedByExpertField,
    PromptsGroupedByRole,
    ExpertMatrixTable,

    // Recurrent Patterns Analysis
    
  } from './study_use_case_charts';

  import {
    // Model Adoption - General Fields, Scenarios, Actors, Components
    MultiUseCaseGeneralFieldsStackedBar,
    MultiUseCaseGeneralFieldsAdoptionChart,
    MultiUseCaseScenariosAdoptionChart,
    MultiUseCaseConditionsAdoptionChart,
    MultiUseCaseScenariosAndConditionsAdoptionChart,
    MultiUseCaseActorAdoptionChart,
    MultiUseCaseComponentAdoptionChart,

    // Model Adoption - Stepwise and Accumulated
    MultiUseCaseStepwiseAdoptionChart,
    AccumulatedMultiUseCaseStepwiseAdoptionChart,
    AccumulatedMultiUseCaseStepwiseAdoptionChartCleaned,

    AccumulatedMultiUseCaseAdoptionChart,
    AccumulatedMultiUseCaseAdoptionChartCleaned,

  } from '../../analytics_handling/analytics_adoption';


/**
 * Expert prompt analysis for the study steps 1 and 3
 */
const ExpertPromptInputAccordion = ({ data, step, chartContainerStyle }) => {

    const prompts = extractExpertPrompts(data);
    const frequencyData = countWordFrequencies(prompts.map((p) => p.prompt));
    const groupedByExpert = groupPromptsBy(prompts, "expertField");
    const groupedByRole = groupPromptsBy(prompts, "role");

    return (
        <Row style={{ marginTop: '20px' }}>
            <Col md={12}>
                <Card className="h-100">
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        <span>Expert Prompt Input - Step {step}</span>
                        <Button variant="outline-primary" className="btn-sm" disabled>
                            <i className="bi bi-info-circle"></i>
                        </Button>
                    </Card.Header>
                    <Card.Body>
                        <Accordion alwaysOpen>
                            {/* Expert Prompt Input */}
                            <Accordion.Item eventKey={`expert-prompt-${step}`}>
                                <Accordion.Header>
                                    <Badge bg="info" className="me-2">Expert Prompt Input</Badge>
                                    <span>Step {step} - {step === 1 ? "Smart Parking UCM Co-Pilot Modeling" : "Custom UCM Co-Pilot Modeling"}</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col md={12}>
                                            <Card className="h-100">
                                                <Card.Header>
                                                    Expert Prompts Table - Step {step}
                                                </Card.Header>
                                                <Card.Body>
                                                    <AllExpertPromptsTable prompts={prompts} />
                                                </Card.Body>
                                            </Card>
                                        </Col>                                       
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            {/* Expert Prompt Keyword Frequency */}
                            <Accordion.Item eventKey={`keyword-frequency-${step}`}>
                                <Accordion.Header>
                                    <Badge bg="info" className="me-2">Expert Prompt Input</Badge>
                                    <span>Step {step} - Keyword Frequency</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col md={12}>
                                            <Card className="h-100">
                                                <Card.Header>
                                                    Keyword Frequency Table - Step {step}
                                                </Card.Header>
                                                <Card.Body>
                                                    <KeywordFrequencyTable frequencyData={frequencyData} />
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            {/* Expert Prompt Grouped by Expert Field */}
                            <Accordion.Item eventKey={`grouped-expert-field-${step}`}>
                                <Accordion.Header>
                                    <Badge bg="info" className="me-2">Expert Prompt Input</Badge>
                                    <span>Step {step} - Grouped by Expert Field</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col md={12}>
                                            <Card className="h-100">
                                                <Card.Header>
                                                    Prompts Grouped by Expert Field - Step {step}
                                                </Card.Header>
                                                <Card.Body>
                                                    <PromptsGroupedByExpertField groupedByExpert={groupedByExpert} />
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            {/* Expert Prompt Grouped by Role */}
                            <Accordion.Item eventKey={`grouped-role-${step}`}>
                                <Accordion.Header>
                                    <Badge bg="info" className="me-2">Expert Prompt Input</Badge>
                                    <span>Step {step} - Grouped by Role</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col md={12}>
                                            <Card className="h-100">
                                                <Card.Header>
                                                    Prompts Grouped by Role - Step {step}
                                                </Card.Header>
                                                <Card.Body>
                                                    <PromptsGroupedByRole groupedByRole={groupedByRole} />
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            {/* Expert Matrix Table */}
                            <Accordion.Item eventKey={`expert-matrix-${step}`}>
                                <Accordion.Header>
                                    <Badge bg="info" className="me-2">Expert Prompt Input</Badge>
                                    <span>Step {step} - Expert Matrix Table</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col md={12}>
                                            <Card className="h-100">
                                                <Card.Header>
                                                    Expert Matrix Table - Step {step}
                                                </Card.Header>
                                                <Card.Body>
                                                    <ExpertMatrixTable prompts={prompts} />
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );

};

/**
 * Calls the adoption analysis charts for each step of the study
 * 
 * @param {Object} data - the data to be analyzed
 * @param {Number} step - the step of the study
 * @param {Object} chartContainerStyle - the style for the chart container
 * 
 * @returns {JSX.Element} - the model adoption accordion
 *  
 */
const ModelAdoptionAccordion = ({ data, step, chartContainerStyle }) => {
    return (
        <Row style={{ marginTop: '20px' }}>
            <Col md={12}>
                <Card className="h-100">
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        <span>Model Adoption - Step {step}</span>
                        <Button variant="outline-primary" className="btn-sm" disabled>
                            <i className="bi bi-info-circle"></i>
                        </Button>
                    </Card.Header>
                    <Card.Body>

                        <Accordion alwaysOpen> {/* Ensures multiple sections can be open at once */}
                            
                            {/* General Fields, Scenarios, Actors, Components */}
                            <Accordion.Item eventKey={`general-${step}`}>
                                <Accordion.Header>
                                    <Badge bg="info" className="me-2">Model Adoption</Badge>
                                    <span>
                                        Step {step} - {step === 1 ? "Smart Parking UCM Co-Pilot Modeling" :
                                            step === 2 ? "Custom Manual Modeling" :
                                            "Custom UCM Co-Pilot Modeling"}
                                    </span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>Key statistical insights for Step {step}:</p>

                                    {/* General Fields Stacked Bar */}
                                    <Row style={{ marginTop: '20px' }}>
                                        <Col md={12}>
                                            <Card className="h-100">
                                                <Card.Header>
                                                    General Fields Adoption Analysis - Step {step}
                                                </Card.Header>
                                                <Card.Body>
                                                    <div style={chartContainerStyle}>
                                                        <MultiUseCaseGeneralFieldsStackedBar 
                                                            data={data} 
                                                            step={step}
                                                        />
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>

                                    {/* General Fields */}
                                    <Row style={{ marginTop: '20px' }}>
                                        <Col md={6}>
                                            <Card className="h-100">
                                                <Card.Header>
                                                    General Fields Adoption Analysis - Step {step}
                                                </Card.Header>
                                                <Card.Body>
                                                    <div style={chartContainerStyle}>
                                                        <MultiUseCaseGeneralFieldsAdoptionChart
                                                            data={data}
                                                            step={step}
                                                        />
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>

                                        <Col md={6}>
                                            <Card className="h-100">
                                                <Card.Header>
                                                    Scenarios Adoption Analysis - Step {step}
                                                </Card.Header>
                                                <Card.Body>
                                                    <div style={chartContainerStyle}>
                                                        <MultiUseCaseScenariosAdoptionChart
                                                            data={data}
                                                            step={step}
                                                        />
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>

                                    {/* Scenarios and Conditions Adoption Analysis */}
                                    <Row style={{ marginTop: '20px' }}>
                                        <Col md={6}>
                                            <Card className="h-100">
                                                <Card.Header>
                                                    Conditions Adoption Analysis - Step {step}
                                                </Card.Header>
                                                <Card.Body>
                                                    <div style={chartContainerStyle}>
                                                        <MultiUseCaseConditionsAdoptionChart
                                                            data={data}
                                                            step={step}
                                                        />
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col md={6}>
                                            <Card className="h-100">
                                                <Card.Header>
                                                    Scenarios and Conditions Adoption Analysis - Step {step}
                                                </Card.Header>
                                                <Card.Body>
                                                    <div style={chartContainerStyle}>
                                                        <MultiUseCaseScenariosAndConditionsAdoptionChart
                                                            data={data}
                                                            step={step}
                                                        />
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>

                                    {/* Actors and Components Adoption Analysis */}
                                    <Row style={{ marginTop: '20px' }}>
                                        <Col md={6}>
                                            <Card className="h-100">
                                                <Card.Header>
                                                    Actors Adoption Analysis - Step {step}
                                                </Card.Header>
                                                <Card.Body>
                                                    <div style={chartContainerStyle}>
                                                        <MultiUseCaseActorAdoptionChart
                                                            data={data}
                                                            step={step}
                                                        />
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col md={6}>
                                            <Card className="h-100">
                                                <Card.Header>
                                                    Components Adoption Analysis - Step {step}
                                                </Card.Header>
                                                <Card.Body>
                                                    <div style={chartContainerStyle}>
                                                        <MultiUseCaseComponentAdoptionChart
                                                            data={data}
                                                            step={step}
                                                        />
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>

                                </Accordion.Body>
                            </Accordion.Item>

                            {/* Stepwise Adoption Analysis */}
                            <Accordion.Item eventKey={`stepwise-${step}`}>
                                <Accordion.Header>
                                    <Badge bg="info" className="me-2">Model Adoption</Badge>
                                    <span>Step {step} - Stepwise Adoption Analysis</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col md={12}>
                                            <Card className="h-100">
                                                <Card.Header>
                                                    Stepwise Adoption Analysis - Step {step}
                                                </Card.Header>
                                                <Card.Body>
                                                    <div style={chartContainerStyle}>
                                                        <MultiUseCaseStepwiseAdoptionChart
                                                            data={data}
                                                            step={step}
                                                        />
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>

                            {/* Accumulated Stepwise Adoption Analysis */}
                            <Accordion.Item eventKey={`accumulated1-${step}`}>
                                <Accordion.Header>
                                    <Badge bg="info" className="me-2">Model Adoption</Badge>
                                    <span>Step {step} - Accumulated Stepwise Adoption Analysis</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col md={6}>
                                            <Card className="h-100">
                                                <Card.Header>
                                                    Accumulated Stepwise Adoption Analysis - Step {step}
                                                </Card.Header>
                                                <Card.Body>
                                                    <div style={chartContainerStyle}>
                                                        <AccumulatedMultiUseCaseStepwiseAdoptionChart
                                                            data={data}
                                                            step={step}
                                                        />
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col md={6}>
                                            <Card className="h-100">
                                                <Card.Header>
                                                    Accumulated Stepwise Adoption Analysis - Step {step} (Cleaned)
                                                </Card.Header>
                                                <Card.Body>
                                                    <div style={chartContainerStyle}>
                                                        <AccumulatedMultiUseCaseStepwiseAdoptionChartCleaned
                                                            data={data}
                                                            step={step}
                                                        />
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>

                            {/* Accumulated Adoption Analysis */}
                            <Accordion.Item eventKey={`accumulated2-${step}`}>
                                <Accordion.Header>
                                    <Badge bg="info" className="me-2">Model Adoption</Badge>
                                    <span>Step {step} - Accumulated Adoption Analysis</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    {/* Accumulated Adoption */}
                                    <Row>
                                        <Col md={6}>
                                            <Card className="h-100">
                                                <Card.Header>
                                                    Accumulated Adoption - Step {step}
                                                </Card.Header>
                                                <Card.Body>
                                                    <div style={chartContainerStyle}>
                                                        <AccumulatedMultiUseCaseAdoptionChart
                                                            data={data}
                                                            step={step}
                                                        />
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        {/* Accumulated Adoption Cleaned */}
                                        <Col md={6}>
                                            <Card className="h-100">
                                                <Card.Header>   
                                                    Accumulated Adoption - Step {step} (Cleaned)
                                                </Card.Header>
                                                <Card.Body>
                                                    <div style={chartContainerStyle}>
                                                        <AccumulatedMultiUseCaseAdoptionChartCleaned
                                                            data={data}
                                                            step={step}
                                                        />
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>

                        </Accordion>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

/**
 * Modeling processing time per task and use case
 */
const ModelingProcessingTimeAccordion = ({ data, step, chartContainerStyle }) => {
    
    // Compute processing times for each use case.
    const processingTimes = calculateProcessingTimes(data);
    //console.log(processingTimes);

    // Aggregate descriptive statistics.
    const stats = aggregateProcessingStats(processingTimes);
    //console.log(stats);

    return (
        <Row style={{ marginTop: '20px' }}>
            <Col md={12}>
                <Card className="h-100">
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        <span>Modeling Processing Time - Step {step}</span>
                        <Button variant="outline-primary" className="btn-sm" disabled>
                            <i className="bi bi-info-circle"></i>
                        </Button>
                    </Card.Header>
                    <Card.Body>

                        <Accordion alwaysOpen>

                            {/* Processing Time */}
                            <Accordion.Item eventKey={`processing-time-${step}`}>
                                <Accordion.Header>
                                    <Badge bg="info" className="me-2">Modeling Processing Time</Badge>
                                    <span>Step {step} - {step === 1 ? "Smart Parking UCM Co-Pilot Modeling" : "Custom UCM Co-Pilot Modeling"}</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div style={chartContainerStyle}>
                                        <ProcessingTimesChart 
                                            stats={stats}
                                        />
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>

                            {/* Processing Times Table */}
                            <Accordion.Item eventKey={`processing-times-table-${step}`}>
                                <Accordion.Header>
                                    <Badge bg="info" className="me-2">Modeling Processing Time</Badge>
                                    <span>Step {step} - Processing Times Table</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <ProcessingTimesTable
                                        processingTimes={processingTimes}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>

                            {/* Descriptive Statistics */}
                            <Accordion.Item eventKey={`descriptive-stats-${step}`}>
                                <Accordion.Header>
                                    <Badge bg="info" className="me-2">Modeling Processing Time</Badge>
                                    <span>Step {step} - Descriptive Statistics</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <DescriptiveStatsTable
                                        stats={stats}
                                    />                               
                                </Accordion.Body>
                            </Accordion.Item>

                            {/* Box Plot */}
                            <Accordion.Item eventKey={`box-plot-${step}`}>
                                <Accordion.Header>
                                    <Badge bg="info" className="me-2">Modeling Processing Time</Badge>
                                    <span>Step {step} - Box Plot</span>
                                </Accordion.Header>
                                <Accordion.Body>

                                    <ProcessingBoxPlot
                                        processingTimes={processingTimes}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                            
                            {/* Duration Histogram */}
                            <Accordion.Item eventKey={`duration-histogram-${step}`}>
                                <Accordion.Header>  
                                    <Badge bg="info" className="me-2">Modeling Processing Time</Badge>
                                    <span>Step {step} - Duration Histogram</span>
                                </Accordion.Header>
                                <Accordion.Body>                                                                    

                                    <DurationHistogram
                                        data={processingTimes.map(pt => pt.processingDuration)}
                                        label="Processing Durations"
                                        binSize={1}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>                                

                        </Accordion>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

/**
 * Main component for the Use Case Analysis
 * Applies at the specific steps of the study
 * 
 * @param {Object} data - the data to be analyzed
 * @param {Function} handleClear - the function to clear the current view
 * 
 * @returns {JSX.Element} - the use case analysis component
 *
 */
export const UseCasesAnalysis = ({ data, handleClear }) => {

    const chartContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '300px'
    };

    return (
        <Row>
            <Col md={12} style={{ textAlign: 'left' }} id="content">

                {/* Header */}
                <Row style={{ marginBottom: '20px', marginTop: '20px' }}>
                    <Col>
                        <span>SP Co-Pilot (Task 1), Custom Manual (Task 2), Custom Co-Pilot (Task 3)</span>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <Button variant="outline-primary" className="btn-sm" onClick={handleClear}>
                            <i className="bi bi-arrow-left"></i> Go back
                        </Button>
                    </Col>
                </Row>
                
                <Accordion defaultActiveKey="0">

                    {/* Task 1, 2 and 3 Section - general statistics as for INSIGHTS section */}
                    {[1, 2, 3].map((step, index) => (
                        <Accordion.Item eventKey={step.toString()} key={step}>
                            <Accordion.Header>
                                <Badge bg="primary" className="me-2">Task {step}</Badge>
                                <span>{step === 1 ? "Smart Parking UCM Co-Pilot Modeling" : step === 2 ? "Custom Manual Modeling" : "Custom UCM Co-Pilot Modeling"}</span>
                            </Accordion.Header>
                            <Accordion.Body>

                                {/* Statistics Modeled UCs Section */}
                                <StatisticsModeledUCs 
                                    data={data.filter(u => u.study_step?.value === step)} 
                                    handleClear={handleClear} 
                                />

                                {/* Expert Prompt Input Section */}
                                {step === 1 || step === 3 ? (
                                <ExpertPromptInputAccordion 
                                    data={data.filter(u => u.study_step?.value === step)}
                                    step={step}
                                    chartContainerStyle={chartContainerStyle}
                                />
                                ) : 
                                (
                                    <Alert variant="info" style={{ marginTop: '20px' }}>
                                        <Alert.Heading>Expert Prompt Input</Alert.Heading>
                                        <p>Step {step} does not have expert prompts as only manual modeling using the UCM method applies.</p>
                                    </Alert>
                                )}
                                
                                {/* Model Adoption Section */}
                                <ModelAdoptionAccordion 
                                    data={data.filter(u => u.study_step?.value === step)}
                                    step={step}
                                    chartContainerStyle={chartContainerStyle}
                                />

                                {/* Processing (modeling) time per task and use case */}
                                <ModelingProcessingTimeAccordion
                                    data={data.filter(u => u.study_step?.value === step)}
                                    step={step}
                                    chartContainerStyle={chartContainerStyle}
                                />

                            </Accordion.Body>
                        </Accordion.Item>
                    ))}

                    {/* Findings: Study Composition Section */}
                    <Accordion.Item eventKey="8">
                        <Accordion.Header>
                            <Badge bg="success" className="me-2">Composition of Results</Badge>
                            <span>Cross-Section Findings of the conducted study</span>
                        </Accordion.Header>
                        <Accordion.Body>

                            <p>
                                This section provides a cross-section of the findings of the conducted study across all tasks 1 to 3.
                                Given the nature of task 1 being disconnected from the manual modeling tasks 2 and 3, the composition is performed
                                accordingly. A comparision between applied components is made between task 2 and task 3.
                            </p>            

                            <p>
                                <b>General Insights including description, scenarios, actors and components for all use cases are provided in the 'Overall Use Case' Section.</b>
                            </p>

                            <ul>
                                <li>
                                    <Badge bg="info" className="me-2">General Insights</Badge> <b>Applicable to all steps</b>
                                    <ul className='small'>
                                        <li>General + Description</li>
                                        <li>Scenarios + Conditions</li>
                                        <li>Actors</li>
                                        <li>Components</li>
                                    </ul>
                                </li>
                                <li>
                                    <Badge bg="info" className="me-2">Model Adoption</Badge> <b>Applicable to Step 1 and Step 3</b>
                                    <ul className='small'>
                                        <li>General + Description</li>
                                        <li>Scenarios + Conditions</li>
                                        <li>Actors</li>
                                        <li>Components</li>
                                    </ul>
                                </li>
                                <li>
                                    <Badge bg="info" className="me-2">Modeling Processing Time</Badge> <b>Applicable to all steps</b>
                                    <ul className='small'>
                                        <li>Step 1 - Smart Parking UCM Co-Pilot Modeling</li>
                                        <li>Step 2 - Custom Manual Modeling</li>
                                        <li>Step 3 - Custom UCM Co-Pilot Modeling</li>
                                    </ul>
                                </li>
                                <li>
                                    <Badge bg="info" className="me-2">Expert Prompt Input</Badge> <b>Applicable to Step 1 and Step 3</b>
                                    <ul className='small'>
                                        <li>Step 1 - Smart Parking UCM Co-Pilot Modeling</li>
                                        <li>Step 3 - Custom UCM Co-Pilot Modeling</li>
                                    </ul>
                                </li>                                
                            </ul>                

                            {/* General statistics across all tasks: i.e. processing time */}
                            <ModelingProcessingTimeAccordion
                                data={data}
                                step={'All'}
                                chartContainerStyle={chartContainerStyle}
                            />

                        </Accordion.Body>
                    </Accordion.Item>

                    {/* Analysis: Recurrent Patterns Section */}
                    <Accordion.Item eventKey="9">
                        <Accordion.Header>
                            <Badge bg="success" className="me-2">Pattern Analysis</Badge>
                            <span>Recurrent Patterns</span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>Recurrent patterns in the use cases:</p>
                            <p>Coming soon...</p>
                        </Accordion.Body>
                    </Accordion.Item>

                    {/* Analysis: Generalized PE-AM Model */}
                    <Accordion.Item eventKey="100">
                        <Accordion.Header>
                            <Badge bg="success" className="me-2">Generalization</Badge>
                            <span>Generalized PE-AM Model</span>
                        </Accordion.Header>
                        <Accordion.Body>
                            ...
                        </Accordion.Body>
                    </Accordion.Item>

                    {/* Analysis: Heatmap of acquired data per UCM step: general, actions (scenarios + conditions), actors and components */}
                    <Accordion.Item eventKey="11">
                        <Accordion.Header>
                            <Badge bg="success" className="me-2">Heatmap</Badge>
                            <span>Heatmap of acquired data per UCM step</span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>Heatmap of acquired data per UCM step:</p>
                            <p>Coming soon...</p>
                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion>
                        
            </Col>
        </Row>
    );
};