/**
 * Prepares data for ecosystem analytics visualization
 *
 * Description: Provides the necessary functions to prepare the data for comparison and visualization.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-07-15, updated on 2025-02-26
 * 
 */

// #########################################################################################################################
// #################################### Helper for analytics_adoption and study_use_case_charts ############################
// #########################################################################################################################

// Helper: Normalize text by removing commas, extra spaces, and lowercasing.
export function normalizeString(str) {
  if (!str) return "";
  // Remove commas and then collapse multiple spaces into one.
  return str
      .replace(/[,]+/g, "")
      .replace(/\s+/g, " ")
      .trim()
      .toLowerCase();
}

// #########################################################################################################################

/**
 * Set of blue/green/teal shades with semi-transparent fills.
 */
export const BLUE_GREEN_PALETTE = [
  "rgb(26, 188, 156)",  // Turquoise
  "rgb(46, 204, 113)",  // Emerald
  "rgb(52, 152, 219)",  // Peter River
  "rgb(22, 160, 133)",  // Darker Turquoise
  "rgb(39, 174, 96)",   // Darker Emerald
  "rgb(41, 128, 185)",  // Belize Hole
  "rgb(0, 172, 193)",   // Bright Teal
  "rgb(26, 188, 156)",  // Repeat or add more
  "rgb(46, 204, 113)",
  "rgb(52, 152, 219)",

  "rgba(75, 192, 192, 0.6)", // Lighter teal
  "rgba(75, 192, 192, 1)",    // Opaque teal
];

export function getBlueGreenColors(count) {
  const backgrounds = [];
  const borders = [];

  for (let i = 0; i < count; i++) {
    // pick from palette in round-robin fashion
    const bgColor = BLUE_GREEN_PALETTE[i % BLUE_GREEN_PALETTE.length];
    // set border color to alpha=1 (solid)
    const borderColor = bgColor.replace(/0?\.\d+\)/, "1)");

    backgrounds.push(bgColor);
    borders.push(borderColor);
  }
  return { backgrounds, borders };
}

export function getColorForItem(itemName, topItems) {
  // If itemName is in topItems, we pick indexOf
  const idx = topItems.indexOf(itemName);
  if (idx === -1) {
    // fallback color for items not in top N
    return "rgba(128,128,128,0.5)";
  }
  // clamp to palette size
  const colorIndex = idx % BLUE_GREEN_PALETTE.length;
  const base = BLUE_GREEN_PALETTE[colorIndex];
  // optionally add alpha:
  return base.replace(/^rgb\((.*?)\)/, "rgba($1, 0.8)");
}

// A module-level counter that increments each time you request a color.
// This ensures a consistent color distribution across calls.
let colorIndex = 0;

export function getNextBlueGreenColor() {
  const color = BLUE_GREEN_PALETTE[colorIndex % BLUE_GREEN_PALETTE.length];
  colorIndex++;
  return color;
}

/**
 * CoOccurrenceHeatmap
 * Renders a matrix chart for keyword co-occurrence.
 */
export function getBlueGreenColor(val) {
  // 0 => white
  if (val === 0) return "rgba(255,255,255,1)";
  // clamp to palette length
  const idx = Math.min(val - 1, BLUE_GREEN_PALETTE.length - 1);
  const base = BLUE_GREEN_PALETTE[idx];
  // convert "rgb(r,g,b)" => "rgba(r,g,b,0.8)"
  return base.replace(/^rgb\((.*?)\)/, "rgba($1, 0.8)");
}

/** Function to generate analytics data
 * @param {*} data
 * @returns 
 */
export const generateAnalyticsData = (data) => {
  const totalUseCases = data.length;
  
  const application = data.reduce((acc, useCase) => {
    acc[useCase.application.value] = (acc[useCase.application.value] || 0) + 1;
    return acc;
  }, {});

  const maturityLevels = data.reduce((acc, useCase) => {
    acc[useCase.maturity.value] = (acc[useCase.maturity.value] || 0) + 1;
    return acc;
  }, {});

  const status = data.reduce((acc, useCase) => {
    acc[useCase.status.value] = (acc[useCase.status.value] || 0) + 1;
    return acc;
  }, {});

  const totalActors = data.reduce((acc, useCase) => acc + useCase.actors.value.nodes.value.length, 0);
  const totalComponents = data.reduce((acc, useCase) => acc + useCase.components.value.length, 0);
  const totalConditions = data.reduce((acc, useCase) => acc + useCase.conditions.value.length, 0);

  // Prepare time series data for component use
  const timeSeries = data.map((useCase) => {
    return {
      date: useCase.created.value,
      componentUsage: useCase.components.value.length,
    };
  });

  // Generate tag frequency data
  const tagFrequency = generateTagFrequency(data);
  //console.log('tagFrequency', tagFrequency);

  return {
    totalUseCases,
    application,
    maturityLevels,
    status,
    totalActors, // not used as not accurate...
    totalComponents,
    totalConditions,
    timeSeries,
    tagFrequency
  };
};
    
/** Function to prepare chart data
 * @param {*} data
 * @param {*} label
 * @returns 
 */
export const prepareChartData0 = (data = {}, label) => {
    const colors = [
      'rgba(75,192,192,0.4)', 'rgba(75,192,192,0.6)', 'rgba(75,192,192,0.8)', 'rgba(75,192,192,1)',
      'rgba(153,102,255,0.4)', 'rgba(153,102,255,0.6)', 'rgba(153,102,255,0.8)', 'rgba(153,102,255,1)',
      'rgba(255,159,64,0.4)', 'rgba(255,159,64,0.6)', 'rgba(255,159,64,0.8)', 'rgba(255,159,64,1)'
    ];
    
    return {
      labels: Object.keys(data),
      datasets: [{
        label: label,
        data: Object.values(data),
        backgroundColor: colors,
        borderColor: colors.map(color => color.replace('0.4', '1').replace('0.6', '1').replace('0.8', '1')),
        borderWidth: 1,
      }]
    };
};

export function prepareChartData(data = {}, label = "Dataset") {
  // data might look like { "Foo": 10, "Bar": 20, ... }
  const labels = Object.keys(data);
  const values = Object.values(data);

  // Retrieve color arrays sized for however many data points we have
  const { backgrounds, borders } = getBlueGreenColors(labels.length);

  return {
    labels,
    datasets: [
      {
        label,
        data: values,
        backgroundColor: backgrounds,
        borderColor: borders,
        borderWidth: 1,
      },
    ],
  };
}

export const META_FIELDS = new Set([
  'created_timestamp', 'updated_timestamp', 'source', 'uid',
  'priority', 'relevance', 'modified', 'version', 'updated_by', 'created_by'
]);

const UCM_STEPS = {
  step1_general: ['title', 'acronym', 'continents', 'country', 'city', 'maturity', 'application', 'status', 'tags', 'description'],
  step2_actions: ['actions'], // Actions are scenarios
  step2_conditions: ['preConditions', 'postConditions', 'constraints', 'assumptions'], // Conditions exist inside scenarios
  step3_actors: ['Consumer', 'Operator', 'Platform', 'Producer', 'Regulator', 'Technical'],
  step4_components: ['components']
};

/**
 * Returns the total number of predefined editable fields before any user input.
 */
export const countDefaultFields = () => {
  return {
    step1_general: UCM_STEPS.step1_general.length, // All fields in step 1 are required
    step2_actions: 0,  // No actions (scenarios) exist by default
    step2_conditions: 0, // No conditions exist by default
    step3_actors: 0, // No actors exist by default; only the given baseline categories
    step4_components: 0 // No components exist by default; only the given baseline categories
  };
};

/**
 * Recursively counts all valid fields inside an object per UCM step.
 * Ignores meta fields and skips empty (`''`), `undefined`, or `null` values.
 * 
 * @param {Object|Array} obj - The object to analyze.
 * @param {Array} validKeys - The specific keys to count.
 * @returns {number} - The total count of fields in this category.
 */
const countFieldsForStep = (obj, validKeys) => {
  if (!obj || typeof obj !== 'object') return 0;
  
  let count = 0;
  
  if (Array.isArray(obj)) {
    obj.forEach(item => count += countFieldsForStep(item, validKeys));
  } else {
    Object.keys(obj).forEach(key => {
      if (!META_FIELDS.has(key) && validKeys.includes(key) && obj[key] !== '' && obj[key] !== null && obj[key] !== undefined) {
        count++;  // Count main field
        count += countFieldsForStep(obj[key], validKeys); // Count nested fields
      }
    });
  }
  
  return count;
};

/**
 * not used anymore
 * 
 * Counts fields categorized by UCM steps
 * counts how many valid fields from validKeys appear in obj.
 * It ignores meta fields and empty values (null, '', undefined).
 * 
 * @param {Array} useCases - List of use case objects
 * @returns {Object} - Count per UCM step
 */
export const countFieldsGivenSetofUseCases = (useCases) => {

  let totals = {
    step1_general: 0,
    step2_actions: 0,
    step2_conditions: 0,
    step3_actors: 0,
    step4_components: 0
  };

  useCases.forEach(useCase => {
    // Step 1: General Fields (fixed fields)
    totals.step1_general += countFieldsForStep(useCase, UCM_STEPS.step1_general);
    
    // Step 2: Actions (Scenarios)
    if (useCase.actions?.value) {
      totals.step2_actions += useCase.actions.value.filter(action => action !== '' && action !== null && action !== undefined).length;
      
      // Count conditions inside each action (scenario)
      useCase.actions.value.forEach(action => {
        if (action?.conditions?.value) {
          action.conditions.value.forEach(conditionObj => {
            UCM_STEPS.step2_conditions.forEach(conditionField => {
              if (conditionObj[conditionField] !== '' && conditionObj[conditionField] !== null && conditionObj[conditionField] !== undefined) {
                totals.step2_conditions++;
              }
            });
          });
        }
      });
    }

    // Step 2: Conditions (directly inside "conditions" field, not actions)
    if (useCase.conditions?.value) {
      useCase.conditions.value.forEach(conditionObj => {
        UCM_STEPS.step2_conditions.forEach(conditionField => {
          if (conditionObj[conditionField] !== '' && conditionObj[conditionField] !== null && conditionObj[conditionField] !== undefined) {
            totals.step2_conditions++;
          }
        });
      });
    }

    // Step 3: Actors (checking inside the nested "list" object)
    if (useCase.actors?.value?.list) {
      Object.values(useCase.actors.value.list).forEach(actor => {
        if (actor.value) {
          totals.step3_actors += actor.value.filter(act => act !== '' && act !== null && act !== undefined).length;
        }
      });
    }

    // Step 4: Components (inside the nested structure)
    if (useCase.components?.value) {
      useCase.components.value.forEach(category => {
        if (category.components) {
          const validComponents = category.components.filter(comp => comp !== '' && comp !== null && comp !== undefined);
          totals.step4_components += validComponents.length;

          // Count items inside each component
          validComponents.forEach(component => {
            if (component.items) {
              totals.step4_components += component.items.filter(item => item !== '' && item !== null && item !== undefined).length;
            }
          });
        }
      });
    }
  });

  return totals;
};

/**
 * aggregateData
 * 
 * Produces a structured object with:
 * 1) stepCounts (step1_general, step2_actions, etc.)
 * 2) actorSummary (edges, nodes, categories, repeated actors)
 * 3) scenario/action (actions, conditions, etc.)
 * 4) componentSummary (category counts, total items, distinct items, subcomponent merges)
 */
export function aggregateData(useCases) {

  // 1) Step-based aggregator: track how many fields are filled in each UCM step
  const stepCounts = {
    step1_general: 0,
    step2_actions: 0,
    step2_conditions: 0,
    step3_actors: 0,
    step4_components: 0
  };

  // 2) Actor aggregator
  let totalEdgeConnections = 0;   // sum of edges
  let totalActorNodes = 0;        // sum of .nodes
  let actorCategories = {};       // e.g. { Consumer: 5, Producer: 7, ... }
  let actorCategoriesCount = {};  // e.g. { Consumer: 5, Producer: 7, ... }
  let totalActorsInList = 0;      // sum of all list-based actors
  let actorList = {};             // List of all given actors per category
  let actorUsage = {};            // e.g. { "Driver": 3, "Passenger": 8, ... }
  let allNodes = [];              // flatten nodes for e.g. top connected nodes
  let allEdges = [];                 
  let totalActorEntries = 0;      // references from .list across categories
  //let uniqueActorIDs = new Set(); // distinct node IDs

  // 3) Action and condition aggregator
  let totalActions = 0;
  let totalConditions = 0;
  let totalRelations = 0;         // edges among actors
  
  //let standardActorLabels = new Set([]); // 'Consumer','Platform','Producer','Regulator','Operator','Technical'

  // 4) Component aggregator

  //    a) "flat" aggregator at category level
  let componentCategories = {};
  let totalComponentItems = 0;
  let uniqueComponentItems = new Set();
  let componentItemUsage = {};    // frequency map: item => usage count

  //    b) For subcomponent merges
  let componentCategoryDetails = {};        // { catName: { total, items:[] } }
  let componentCategoryDetailsNested = {};  // { catName: { subMap: {}, subcomponents:[] } }

  
  // Loop through all use cases
  useCases.forEach((useCase) => {

    // -----------------------------------------
    // (A) Step-based aggregator

    // Step 1: general fields
    stepCounts.step1_general += countFieldsForStep(useCase, UCM_STEPS.step1_general);

    // Step 2: actions
    if (useCase.actions?.value) {
      const validActions = useCase.actions.value.filter(a => a && a.trim?.() !== '');
      stepCounts.step2_actions += validActions.length;
      // also conditions inside each action
      validActions.forEach(action => {
        if (action?.conditions?.value) {
          action.conditions.value.forEach(condObj => {
            UCM_STEPS.step2_conditions.forEach(condKey => {
              const val = condObj[condKey];
              if (val && val.trim() !== '') {
                stepCounts.step2_conditions++;
              }
            });
          });
        }
      });
    }

    // Step 2: conditions => step2_conditions
    if (useCase.conditions?.value && Array.isArray(useCase.conditions.value)) {
      useCase.conditions.value.forEach(condObj => {
        UCM_STEPS.step2_conditions.forEach(condKey => {
          const val = condObj[condKey];
          if (val && val.trim() !== '') {
            stepCounts.step2_conditions++;
          }
        });
      });
    }

    // Step 3: actors => actor list
    if (useCase.actors?.value?.list) {
      Object.values(useCase.actors.value.list).forEach(actorObj => {
        if (actorObj?.value && Array.isArray(actorObj.value)) {
          stepCounts.step3_actors += actorObj.value.filter(a => a && a.trim() !== '').length;
        }
      });
    }

    // Step 4: components => subcomponent counting
    if (useCase.components?.value) {

      useCase.components.value.forEach(cat => {
        if (cat.components && Array.isArray(cat.components)) {
          const validSubcomps = cat.components.filter(sc => sc !== null && sc !== undefined);
          // each subcomp => +1
          stepCounts.step4_components += validSubcomps.length;
          validSubcomps.forEach(sc => {
            if (sc.items && Array.isArray(sc.items)) {
              stepCounts.step4_components += sc.items.filter(it => it && it.trim() !== '').length;
            }
          });
        }
      });
    }  

    // -----------------------------------------
    // (B) Overall aggregator for actors, actions, conditions, etc.

    // 1) Overall aggregator => actions, conditions
    if (useCase.actions?.value && Array.isArray(useCase.actions.value)) {
      const validScenarios = useCase.actions.value.filter(a => a && a.trim?.() !== '');
      totalActions += validScenarios.length;
      // scenario-based conditions
      validScenarios.forEach(sc => {
        if (sc?.conditions?.value && Array.isArray(sc.conditions.value)) {
          sc.conditions.value.forEach(condObj => {
            ['preConditions','postConditions','constraints','assumptions'].forEach(field => {
              if (condObj[field] && condObj[field].trim() !== '') {
                totalConditions++;
              }
            });
          });
        }
      });
    }

    // top-level conditions
    if (useCase.conditions?.value && Array.isArray(useCase.conditions.value)) {
      useCase.conditions.value.forEach(condObj => {
        ['preConditions','postConditions','constraints','assumptions'].forEach(field => {
          if (condObj[field] && condObj[field].trim() !== '') {
            totalConditions++;
          }
        });
      });
    }

    // 2) Overall aggregator => actors
    if (useCase.actors?.value) {

      // a) Edges
      if (useCase.actors.value.edges?.value && Array.isArray(useCase.actors.value.edges.value)) {
        totalRelations += useCase.actors.value.edges.value.length;
        totalEdgeConnections += useCase.actors.value.edges.value.length;
      }

      // b) Nodes => totalActorNodes
      if (useCase.actors.value.nodes?.value && Array.isArray(useCase.actors.value.nodes.value)) {
        totalActorNodes += useCase.actors.value.nodes.value.length;
      }
      
      // c) Process actor list with normalization (only one combined loop)
      //   - actorCategories, actorUsage, actorList
      if (useCase.actors.value.list) {
        Object.entries(useCase.actors.value.list).forEach(([catName, actorObj]) => {
          if (actorObj?.value && Array.isArray(actorObj.value)) {
            // Normalize each actor string (lowercase and trim)
            const validActors = actorObj.value
              .filter(a => a && a.trim() !== '')
              .map(a => a.trim().toLowerCase());
            const count = validActors.length;
            actorCategories[catName] = (actorCategories[catName] || 0) + count;
            totalActorsInList += count;
            validActors.forEach(actorStr => {
              totalActorEntries++;
              actorUsage[actorStr] = (actorUsage[actorStr] || 0) + 1;
            });
            if (!actorList[catName]) {
              actorList[catName] = [];
            }
            actorList[catName].push(...validActors);
          }
        });
      }

      // d) For visualization: accumulate nodes and edges
      allNodes.push(...(useCase.actors?.value?.nodes?.value || []));
      allEdges.push(...(useCase.actors?.value?.edges?.value || []));

    }

    // Step 4: Components aggregation (your existing logic)
    if (useCase.components?.value && Array.isArray(useCase.components.value)) {
      useCase.components.value.forEach(componentCategory => {
        const catName = componentCategory.category || 'Unknown';
        const comps = componentCategory.components || [];
        if (!componentCategories[catName]) {
          componentCategories[catName] = 0;
        }
        if (!componentCategoryDetails[catName]) {
          componentCategoryDetails[catName] = { total: 0, items: [] };
        }
        if (!componentCategoryDetailsNested[catName]) {
          componentCategoryDetailsNested[catName] = { subMap: {}, subcomponents: [] };
        }
        comps.forEach(comp => {
          const desc = comp.description || '(no desc)';
          if (!componentCategoryDetailsNested[catName].subMap[desc]) {
            componentCategoryDetailsNested[catName].subMap[desc] = {
              description: desc, items: []
            };
          }
          const subObj = componentCategoryDetailsNested[catName].subMap[desc];
          if (Array.isArray(comp.items)) {
            comp.items.forEach(item => {
              if (item && item.trim() !== '') {
                totalComponentItems++;
                uniqueComponentItems.add(item);
                componentCategories[catName] += 1;
                componentCategoryDetails[catName].total += 1;
                componentCategoryDetails[catName].items.push(item);
                subObj.items.push(item);
                componentItemUsage[item] = (componentItemUsage[item] || 0) + 1;
              }
            });
          }
        });
      });
    }

    // 3) Components aggregator
    /*
    if (useCase.components?.value && Array.isArray(useCase.components.value)) {
      useCase.components.value.forEach(componentCategory => {
        const catName = componentCategory.category || 'Unknown';
        const comps = componentCategory.components || [];

        // increment subcomponent definitions
        //componentCategories[catName] = (componentCategories[catName] || 0) + comps.length;

        // entry for catName in `componentCategories`
        if (!componentCategories[catName]) {
          componentCategories[catName] = 0; 
        }

        // ensure "flat" aggregator entry
        if (!componentCategoryDetails[catName]) {
          componentCategoryDetails[catName] = { total: 0, items: [] };
        }
        // ensure nested aggregator entry
        if (!componentCategoryDetailsNested[catName]) {
          componentCategoryDetailsNested[catName] = { subMap: {}, subcomponents: [] };
        }

        // loop subcomps
        comps.forEach(comp => {
          const desc = comp.description || '(no desc)';
          if (!componentCategoryDetailsNested[catName].subMap[desc]) {
            componentCategoryDetailsNested[catName].subMap[desc] = {
              description: desc, items: []
            };
          }
          const subObj = componentCategoryDetailsNested[catName].subMap[desc];

          // gather items
          if (Array.isArray(comp.items)) {
            comp.items.forEach(item => {
              if (item && item.trim() !== '') {

                // Increase global counter
                totalComponentItems++;
                uniqueComponentItems.add(item);

                // Accumulate items at the category level
                componentCategories[catName] += 1;

                // Increase `flat` aggregator
                componentCategoryDetails[catName].total += 1;
                componentCategoryDetails[catName].items.push(item);

                // Increase nested aggregator
                subObj.items.push(item);

                // track usage for repeated detection
                componentItemUsage[item] = (componentItemUsage[item] || 0) + 1;
              }
            });
          }
        });
      });
    }
    */

  });

  // repeated actors
  const repeatedActors = Object.keys(actorUsage).filter(str => actorUsage[str] > 1);
  // total number of repeated actors
  const totalRepeatedCount = repeatedActors.reduce((sum, actor) => sum + (actorUsage[actor] || 0), 0);
  // {totalActorsEntries -totalRepeatedCount + repeatedActors.length}
  const uniqueActorCount = totalActorEntries - totalRepeatedCount + repeatedActors.length;


  // finalize subMap => subcomponents
  Object.keys(componentCategoryDetailsNested).forEach(catName => {
    const catData = componentCategoryDetailsNested[catName];
    catData.subcomponents = Object.values(catData.subMap);
  });

  // repeated component logic
  const repeatedComponents = Object.keys(componentItemUsage).filter(comp => componentItemUsage[comp] > 1);
  const totalRepeatedComponentUsage = repeatedComponents.reduce(
    (sum, comp) => sum + componentItemUsage[comp],
    0
  );

  const repeatedComponentCount = repeatedComponents.length;

  //const absoluteComponents = totalComponentItems - totalRepeatedComponentUsage + repeatedComponentCount;

  //console.log('repeatedComponents:', repeatedComponents);
  //console.log('totalRepeatedComponentUsage:', totalRepeatedComponentUsage);
  //console.log('repeatedComponentCount:', repeatedComponentCount);
  //console.log('absoluteComponents:', absoluteComponents);

  return {

    // General

      totalUseCases: useCases.length,

    // Step-based aggregator

      stepCounts,

    // Actor aggregator

      allNodes,
      allEdges,
      actorList,

      totalEdgeConnections,
      totalActorNodes,
      actorCategories,        // e.g. { Consumer: 5, Producer: 7, ... }
      actorCategoriesCount,  // e.g. { Consumer: 5, Producer: 7, ... }
      totalActorsInList,     // sum of all .list references
      repeatedActors,        // actor strings repeated in usage
      totalRepeatedCount,    // total # of repeated actors
      distinctActors: uniqueActorCount,
      actorUsage,            // map of each actor => usage count

      totalRelations,
      totalActorEntries,     // how many references from actorObj.value across categories


    // Conditions aggregator

      totalActions,
      totalConditions,

    // Component aggregator

      // e.g. how many subcomponent definitions if you want
      componentCategories,
      // simple counting items
      componentCategoryCount: Object.keys(componentCategoryDetails).reduce((acc, cat) => {
        // sum of items in the "flat aggregator"
        acc[cat] = componentCategoryDetails[cat].total || 0;
        return acc;
      }, {}),
      totalComponents: totalComponentItems, // distinct component items
      totalComponentItems,            // sum of all item references
      distinctComponents: uniqueComponentItems.size, // # distinct item strings
      componentCategoryDetails,       // cat => { total, items:[] }
      componentCategoryDetailsNested,  // cat => { subMap:{desc => {description, items:[]}}, subcomponents:[] }
      repeatedComponentCount,         // total # of repeated components 
      totalRepeatedComponentUsage,    // total # of repeated component usages
    
  };
}

/**
 * Calculate the frequency of tags in the use cases
 * @param {*} text
 * @returns 
 */
const generateTagFrequency = (useCases) => {
  const tagCount = {};

  useCases.forEach(useCase => {
    useCase.tags.value.forEach(tag => {
      if (tagCount[tag]) {
        tagCount[tag] += 1;
      } else {
        tagCount[tag] = 1;
      }
    });
  });

  return Object.keys(tagCount).map(tag => ({ text: tag, value: tagCount[tag] }));
};

// #########################################################################################################################
// ####################################  use case pairs and filtering for meta analysis ####################################
// #########################################################################################################################

/**
 * Builds arrayOfOldUCs and arrayOfNewUCs
 * from your entire "allUseCases" array.
 * 
 * For each useCase:
 *   - step=1, history.data.length >=2 => old= data[0], new= data[1]
 *   - step=2, history.data.length ===1 => old={}, new= data[0]
 *   - step=3, history.data.length >=3 => old= data[1], new= data[2]
 *   - otherwise skip
 *
 * Returns { oldUseCases, newUseCases }
 */
export function buildUseCasePairs(allUseCases) {
  const oldUseCases = [];
  const newUseCases = [];

  for (const uc of allUseCases) {
      const step = uc.study_step?.value;
      const historyData = uc.history?.data || [];
  
      if (step === 1 && historyData.length >= 2) {
          oldUseCases.push(historyData[0]);
          newUseCases.push(historyData[1]);
      } 
      else if (step === 2 && historyData.length === 1) {
          // old empty object, new = data[0]
          oldUseCases.push({});
          newUseCases.push(historyData[0]);
      }
      else if (step === 3 && historyData.length >= 3) {
          oldUseCases.push(historyData[1]);
          newUseCases.push(historyData[2]);
      }
  }

  return { oldUseCases, newUseCases };
}

/**
* Filters allUseCases to only those that have study_step.value === stepValue
*/
export function filterUseCasesByStep(allUseCases, stepValue) {
  return allUseCases.filter((uc) => uc.study_step?.value === stepValue);
}
