/**
 * Chart elements for the analytics page
 *
 * Description: Provides the necessary chart elements for the analytics page.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-07-14
 * 
 */

import React from 'react';
import { Row, Col, Card, Table, ListGroup } from 'react-bootstrap';

import { Bar, Pie } from 'react-chartjs-2';

import { prepareBarChartData, preparePieChartData, prepareBarData, prepareOverlapData, prepareBarChartDataComponents, prepareBarChartDataComponentsNested, prepareBarChartActor } from '../analytics_handling/analytics_prepare_data';

// Bar Chart: Use Case Comparison
export const BarChart = ({ comparisonData, attribute, selectedUseCase, handleChartClick, handleChartHover }) => {

  const cmp_data = prepareBarChartData(comparisonData, attribute, selectedUseCase);

  const chartData = {
      labels: cmp_data.map(d => d[attribute]),
      datasets: [
          {
              label: attribute || 'Number of Use Cases',
              data: cmp_data.map(d => d.count),  // Always use the actual count for the bar height
              backgroundColor: cmp_data.map(d => d.isSelected ? 'rgba(255,99,132,0.6)' : 'rgba(75,192,192,0.6)'),
              borderColor: cmp_data.map(d => d.isSelected ? 'rgba(255,99,132,1)' : 'rgba(75,192,192,1)'),
              borderWidth: 1,
          }
      ]
  };

  return (
      <Bar
          data={chartData}
          options={{
              onClick: (evt, elems) => handleChartClick(evt, elems, chartData, attribute),
              onHover: (evt, elems) => handleChartHover(evt, elems),
          }}
      />
  );
};

// Pie Chart: Use Case Comparison
export const PieChart = ({ comparisonData, attribute }) => {

    const cmp_data = preparePieChartData(comparisonData, attribute);

    const chartData = {
        labels: cmp_data.map(d => d[attribute]),
        datasets: [
        {
            label: attribute || 'Number of Use Cases',
            data: cmp_data.map(d => d.count),
            backgroundColor: [
            'rgba(75,192,192,0.6)',
            'rgba(192,75,75,0.6)',
            'rgba(75,75,192,0.6)',
            ],
            borderColor: 'rgba(255,255,255,1)',
            borderWidth: 1,
        }
        ]
    };

  return <Pie data={chartData} />;
};

// Component List Table - currently used in the report
export const ComponentListTable = ({ components }) => {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Category</th>
          <th>Component</th>
          <th>Items</th>
        </tr>
      </thead>
      <tbody>
        {components.map((componentCategory, index) => (
          <React.Fragment key={index}>
            {componentCategory.components.map((component, i) => (
              <tr key={`${index}-${i}`}>
                {i === 0 && (
                  <td rowSpan={componentCategory.components.length}>
                    {componentCategory.category}
                  </td>
                )}
                <td>{component.description || component.id}</td>
                <td>
                  {component.items && component.items.map((item, j) => (
                    <span key={j} className="badge bg-light text-dark me-1">
                      {item}
                    </span>
                  ))}
                </td>
              </tr>
            ))}
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  );
};

// Component Bar Chart - currently used in the report
export const ComponentBarChart = ({ components, handleChartClick, handleChartHover }) => {
  const chartData = prepareBarChartDataComponents(components);

  return (
    <div>
      <Bar 
        data={chartData} 
        options={{ 
          //responsive: true, 
          //maintainAspectRatio: false,
          scales: {
            x: { title: { display: true, text: 'Categories' } },
            y: { title: { display: true, text: 'Number of Items' } }
          },          
          onClick: (evt, elems) => handleChartClick(evt, elems, chartData, 'Components per Category'),
          onHover: (evt, elems) => handleChartHover(evt, elems),
        }}
      />
    </div>
  );
};

// Component Bar Chart Nested - currently used in the report
export const ComponentBarChartNested = ({ components, handleChartClick, handleChartHover }) => {
  const chartData = prepareBarChartDataComponentsNested(components);

  return (
    <div>
      <Bar 
        data={chartData} 
        options={{ 
          //responsive: true, 
          //maintainAspectRatio: false,
          scales: {
            x: { 
              title: { display: true, text: 'Subcategories' },
              ticks: {
                autoSkip: false,
                maxRotation: 90,
                minRotation: 45
              }
            },
            y: { 
              title: { display: true, text: 'Number of Items' },
              beginAtZero: true,
              ticks: {
                stepSize: 1,
                min: 0,
              }
            }
          },        
          onClick: (evt, elems) => handleChartClick(evt, elems, chartData, 'Components per Category'),
          onHover: (evt, elems) => handleChartHover(evt, elems),
        }}
      />
    </div>
  );
};

// Actor List Table - currently used in the report
export const ActorListTable = ({ list }) => {
  
  const categories = Object.keys(list);
  
  return (
      <Table striped bordered hover>
      <thead>
          <tr>
          <th>Category</th>
          <th>Actors</th>
          </tr>
      </thead>
      <tbody>
          {categories.map((category, index) => (
          <tr key={index}>
              <td>{category}</td>
              <td>
              {list[category].value.map((actor, i) => (
                  <span key={i} className="badge bg-light text-dark me-1">
                  {actor}
                  </span>
              ))}
              </td>
          </tr>
          ))}
      </tbody>
      </Table>
  );
};

// Actor Bar Chart - currently used in the report
export const ActorBarChart = ({ list, handleChartClick, handleChartHover }) => {
  const chartData = prepareBarChartActor(list);

  return (
    <div>
      <Bar
        data={chartData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: { title: { display: true, text: 'Categories' } },
            y: { title: { display: true, text: 'Number of Actors' }, beginAtZero: true }
          },
          onClick: (evt, elems) => handleChartClick(evt, elems, chartData, 'Actors per Category'),
          onHover: (evt, elems) => handleChartHover(evt, elems),
        }}
      />
    </div>
  );
};

// Node centrality - currently not used
export const NodeCentrality = ({ nodes, edges }) => {
  const centralityScores = calculateCentrality(nodes, edges);

  const barData = {
    labels: centralityScores.map(score => score.node.label),
    datasets: [
      {
        label: 'Centrality',
        data: centralityScores.map(score => score.value),
        backgroundColor: 'rgba(153,102,255,0.4)',
        borderColor: 'rgba(153,102,255,1)',
        borderWidth: 1,
      },
    ],
  };

  return (
    <Bar data={barData} /> //options={{ responsive: true, maintainAspectRatio: false }} />
  );
};

// Calculate centrality scores - currently not used
const calculateCentrality = (nodes, edges) => {

  // Create a map to store centrality scores for each node
  const centralityMap = {};

  // Initialize the map with each node having a centrality score of 0
  nodes.value.forEach(node => {
    centralityMap[node.id] = 0;
  });

  // Iterate over the edges and increase the centrality score for each connected node
  edges.value.forEach(edge => {
    centralityMap[edge.from]++;
    centralityMap[edge.to]++;
  });

  // Return centrality scores for each node
  return nodes.value.map(node => ({
    node,
    value: centralityMap[node.id] || 0, // Use 0 if no edges are connected
  }));

};

// Top connected nodes - currently not used
export const TopConnectedNodes = ({ nodes, edges }) => {
  const nodeDegrees = nodes.value.map(node => {
    const degree = edges.value.filter(edge => edge.from === node.id || edge.to === node.id).length;
    return { ...node, degree };
  });

  const sortedNodes = nodeDegrees.sort((a, b) => b.degree - a.degree).slice(0, 5);

  return (
    <ListGroup>
      {sortedNodes.map(node => (
        <ListGroup.Item key={node.id}>
          <strong>{node.label}</strong> - {node.degree} connections
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

// -----------------------------

// Comparison Charts

export const ComparisonCharts = ({ analysisResults, selectedUseCase }) => {
  return (
    <div>
      <Row style={{ marginTop: '20px' }}>
      <Col md={6}>
        <Card className="h-100">
          <Card.Header>Similar Use Cases</Card.Header>
          <Card.Body>
            <Bar data={prepareBarData(analysisResults.similarUseCases)} options={{ responsive: true, maintainAspectRatio: false }} />
          </Card.Body>
        </Card>
      </Col>
      <Col md={6}>
        <Card className="h-100">
          <Card.Header>Distinct Use Cases</Card.Header>
          <Card.Body>
            <Bar data={prepareBarData(analysisResults.distinctUseCases)} options={{ responsive: true, maintainAspectRatio: false }} />
          </Card.Body>
        </Card>
      </Col>
      </Row>
      <Row style={{ marginTop: '20px' }}>
        <Col md={6}>
          <Card className="h-100">
            <Card.Header>Overlapping Actors</Card.Header>
            <Card.Body>
              <Bar data={prepareOverlapData(analysisResults.overlaps)} options={{ responsive: true, maintainAspectRatio: false }} />
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="h-100">
            <Card.Header>Overlapping Components</Card.Header>
            <Card.Body>
              <Bar data={prepareOverlapData(analysisResults.overlaps)} options={{ responsive: true, maintainAspectRatio: false }} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
