/**
 * ...
 *
 * Description: ...
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-06-16
 * 
 */

import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Accordion, OverlayTrigger, Tooltip, Modal, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { UserInformation } from '../auth_mgmt/auth_handling';
import { prepareComparisonData, analyzeUseCases } from '../analytics_handling/analytics_prepare_data';

import { UseCaseDetails } from '../analytics_handling/analytics_uc_details';
import { UCInsight } from '../analytics_handling/analytics_uc_insight';
import { EcoAnalyticsUCCompare } from '../analytics_handling/analytics_uc_compare';
import { BPMNModel } from '../analytics_handling/analytics_bpmn';

import { Report } from '../report_handling/uc_report';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Legend,
  ArcElement,
} from 'chart.js';

// Register the required components with Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  PointElement,
  LineElement,  
  ArcElement
);

/**
 * Primary function to compare use cases
 * Provides the sidebar and interaction for user to select a use case and filter the data
 * @param {*} param0 
 * @returns 
 */
const EcoUCDashboard = ({ mode, data, selectedUseCase, setSelectedUseCase, comparisonType, setComparisonType, selectedComparisonUseCases, setSelectedComparisonUseCases, 
  isEdit, handleSave, handleSaveEdit }) => {

  // sidebar states
  const [isUCDetails, setIsUCDetails] = useState(false);
  const [isUpdateSearch, setIsUpdateSearch] = useState(false);
  const [isTools, setIsTools] = useState(true);
  const [activeKey, setActiveKey] = useState('0');

  // analysis states
  const [isInsight, setIsInsight] = useState(false);
  const [isCompare, setIsCompare] = useState(true); // Default
  const [isBPMN, setIsBPMN] = useState(false);

  // analysis data
  const [comparisonData, setComparisonData] = useState([]);

  // reporting states
  const [isGeneralReport, setIsGeneralReport] = useState(false);
  const [isEcosystemReport, setIsEcosystemReport] = useState(false);
  const [isBPMNReport, setIsBPMNReport] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [showModalMeta, setShowModalMeta] = useState(false);
  const [modalDataMeta, setModalDataMeta] = useState(null);

  const [analysisResults, setAnalysisResults] = useState({
    similarUseCases: {},
    distinctUseCases: {},
    overlaps: {}
  });

  useEffect(() => {

    // Set the default mode when called from the dashboard
    if (mode === 'Dashboard' || mode === 'Compile Use Case Model') {
      setIsInsight(true);
      setIsCompare(false);
    }

  }, [mode]);

  useEffect(() => {

    const comparisonData = prepareComparisonData(data, selectedUseCase, comparisonType, selectedComparisonUseCases);
    setComparisonData(comparisonData);

    const results = analyzeUseCases(selectedUseCase, comparisonData);
    setAnalysisResults(results);

  }, [data, selectedUseCase, comparisonType, selectedComparisonUseCases]);
  
  // handle sidebar interactions

  const navigate = useNavigate();
  
  // Navigate to the Create page with the data of the current use case
  const handleUCEdit = () => {
    //console.log('Data:', data);
    navigate('/Create', { state: { selectedUseCase } });
  };

  const handleUCDetails = () => {
    setIsUCDetails(!isUCDetails);

    setIsUpdateSearch(false);
    setIsTools(false);
    
  };

  const handleUpdateSearch = () => {
    setIsUpdateSearch(!isUpdateSearch);
    setIsUCDetails(false);
    setIsTools(false);
  };

  const handleAnalysisTools = () => {
    setIsTools(!isTools);

    setIsUpdateSearch(false);
    setIsUCDetails(false);
  };

  const handleComparisonTypeChange = (e) => {
    const type = e.target.value;
    setComparisonType(type);
    if (type === 'specific') {
      openAccordionItem('2');
    }
  };

  const handleSpecificUseCasesChange = (e) => {
    const useCaseId = e.target.value;
    const useCase = data.find((uc) => uc.id === useCaseId);
    if (useCase) {
      setSelectedComparisonUseCases((prev) => {
        if (prev.find((uc) => uc.id === useCase.id)) {
          return prev.filter((uc) => uc.id !== useCase.id); // Deselect
        } else {
          return [...prev, useCase]; // Select
        }
      });
    }
  };

  const handleBadgeClick = (useCaseId) => {
    setSelectedComparisonUseCases((prev) => prev.filter((uc) => uc.id !== useCaseId));
  };

  const openAccordionItem = (key) => {
    setActiveKey(key);
  };

  // handle sidebar use case selection
  
  const handleUseCaseChange = (e) => {
    const useCaseId = e.target.value;
    const useCase = data.find((uc) => uc.id === useCaseId);
    setSelectedUseCase(useCase);
  };

  // handle analysis interactions

  const handleInsight = () => {
    setIsInsight(true);
    setIsCompare(false);
    setIsBPMN(false);
    setIsEcosystemReport(false);
    setIsGeneralReport(false);
  }

  const handleCompare = () => {
    setIsCompare(true);
    setIsInsight(false);
    setIsBPMN(false);
    setIsEcosystemReport(false);
    setIsGeneralReport(false);

    // Request the comparison data explicitly if called from the dashboard
    if (mode === 'Dashboard' && comparisonType === '') {
      setIsUpdateSearch(true);
      setIsTools(false);
    }

  }

  const handleBPMN = () => {
    setIsBPMN(true);
    setIsInsight(false);
    setIsCompare(false);
    setIsEcosystemReport(false);
    setIsGeneralReport(false);
  }

  // handle reporting interactions

  const handleGeneralReport = () => {
    setIsGeneralReport(true);
    setIsEcosystemReport(false);
    setIsBPMNReport(false);

    setIsBPMN(false);
    setIsInsight(false);
    setIsCompare(false);
  }

  const handleEcosystemReport = () => {
    setIsEcosystemReport(true);
    setIsGeneralReport(false);
    setIsBPMNReport(false);

    setIsBPMN(false);
    setIsInsight(false);
    setIsCompare(false);
    setIsGeneralReport(false);
  }

  const handleBPMNReport = () => {
    setIsBPMNReport(true);
    setIsGeneralReport(false);
    setIsEcosystemReport(false);

    setIsBPMN(false);
    setIsInsight(false);
    setIsCompare(false);
  }

  //console.log('...', selectedUseCase.user.value)
  // check if use case was in study mode
  // console.log('mode ', selectedUseCase)

  return (
    <div>
    <Row style={ { marginTop: '20px', marginBottom:'30px', textAlign:'left' } }>
      {/* Sidebar */}     
      <Col md={3} style={{ paddingRight: '30px', backgroundBlendMode: 'gray' }}>
          <div>
            <h3 className="text-start">{mode}</h3>
            <h6 className="text-start">Tools and Search Parameter</h6>
          </div>
          <hr />
          {/* Sidebar / Toolbar for ecosystem analysis */}
          <div className="sidebar">
              <Row className="sidebar" style={{ marginTop:'15px', maxHeight: '100vh', overflowY: 'auto' }}>

                <h6>Search parameter</h6>

                <span><small>Selected: </small> <small><b>{selectedUseCase.title.value}</b></small></span>
                <span><small>Comparison Type: </small> <small><b>{comparisonType}</b></small></span>                  
                {/* Display selected use cases */}
                {comparisonType === 'specific' && (
                <>                  
                  <span><small>Selected Use Cases:</small></span> 
                  <div className="d-flex flex-wrap" style={ { marginTop:'5px'} }>
                    {selectedComparisonUseCases.map((useCase) => (
                      <Badge
                        key={useCase.id}
                        //className="bg-light text-dark me-1"
                        className='bg-dark text-white me-1'
                        onClick={() => handleBadgeClick(useCase.id)}
                        style={{ cursor: 'pointer', marginBottom: '5px'}}
                      >
                        {useCase.title.value}
                      </Badge>
                    ))}
                  </div>
                </>
                )}      

                {/* Trigger update or advanced search */}
                <div align="right" style={ { marginBottom:'15px', marginTop:'15px' } }>
                  <OverlayTrigger placement="top" overlay={<Tooltip>Get detailed information about the selected use case</Tooltip>}>
                    <Button className={`btn-sm ${isUCDetails ? 'active' : ''}`} variant="outline-primary" onClick={handleUCDetails}>
                      <i className="bi bi-info-circle"></i>
                    </Button>
                  </OverlayTrigger>
                  {((selectedUseCase.user.value === UserInformation().displayName) &&
                  (selectedUseCase.mode?.value !== 'study'))
                  && 
                  (
                  <OverlayTrigger placement="top" overlay={<Tooltip>Edit use case</Tooltip>}>
                    <Button className="btn-sm" variant="outline-primary" style={ { marginLeft:'10px' } } onClick={handleUCEdit}>
                      <i className="bi bi-pencil-square"></i>
                    </Button>
                  </OverlayTrigger>
                  )}
                  <OverlayTrigger placement="top" overlay={<Tooltip>Analysis tools</Tooltip>}>
                    <Button className={`btn-sm ${isTools ? 'active' : ''}`} variant="outline-primary" style={ { marginLeft:'10px' } } onClick={handleAnalysisTools}>
                      <i className="bi bi-tools"></i>                  
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={<Tooltip>Update search parameter</Tooltip>}>
                    <Button className={`btn-sm ${isUpdateSearch ? 'active' : ''}`} variant="outline-primary" style={ { marginLeft:'10px' } } onClick={handleUpdateSearch}>
                      <i className="bi bi-code-square"></i>                  
                    </Button>
                  </OverlayTrigger>
                  <hr />
                </div>                

                {/* Use Case Details */}
                {isUCDetails && (
                  <UseCaseDetails data={selectedUseCase} />
                )}

                {/* Update search parameter */}
                {isUpdateSearch && (
                <>
                <h6>Update search parameter</h6>

                <Accordion activeKey={activeKey} onSelect={setActiveKey} style={{ marginTop: '15px' }}>
                  
                  {/* Select use case in question */}
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Select use case in question</Accordion.Header>
                    <Accordion.Body>
                      <Form.Group controlId="useCaseSelect">
                        <Form.Label>Select Use Case</Form.Label>
                        <Form.Control as="select" onChange={handleUseCaseChange}>
                          <option value=''>-- Select use case --</option>
                          {data.map((useCase) => (
                            <option key={useCase.id} value={useCase.id}>
                              {useCase.title.value}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* Select whether the selected use case is compared to all modeled use cases or specific ones */}
                  {selectedUseCase && (
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Compare to all or specific use cases</Accordion.Header>
                      <Accordion.Body>
                        <Form.Group controlId="comparisonTypeSelect">
                          <Form.Label>Compare <i>{selectedUseCase.title.value}</i> to</Form.Label>
                          <Form.Control as="select" onChange={handleComparisonTypeChange}>
                            <option value=''>-- Compare to --</option>
                            <option value='none'>None</option>
                            <option value='all'>All use cases</option>
                            <option value='specific'>Specific use cases</option>
                          </Form.Control>
                        </Form.Group>
                      </Accordion.Body>
                    </Accordion.Item>
                  )}

                  {/* Select specific use cases to compare to */}
                  {comparisonType === 'specific' && (
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Select specific use cases</Accordion.Header>
                      <Accordion.Body>
                        <Form.Group controlId="specificUseCasesSelect">
                          <Form.Label>Select Use Cases</Form.Label>
                          <Form.Control as="select" multiple onChange={handleSpecificUseCasesChange}>
                            {data.map((useCase) => (
                              <option key={useCase.id} value={useCase.id}>
                                {useCase.title.value}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                        {/* Add selected as badges */}
                        {selectedComparisonUseCases.length > 0 && (
                          <div style={ { marginTop:'15px'} }>
                            <span><small>Added</small></span>
                            <div className="d-flex flex-wrap" style={ { marginTop:'15px'} }>
                              {selectedComparisonUseCases.map((useCase) => (
                                <Badge
                                  key={useCase.id}
                                  className="bg-light text-dark me-1"
                                  onClick={() => handleBadgeClick(useCase.id)}
                                  style={{ cursor: 'pointer', marginBottom: '5px'}}
                                >
                                  {useCase.title.value}
                                </Badge>
                              ))}
                            </div>
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                </Accordion>
                </>
                )} 

                {/* Analysis tools */}
                {isTools && (
                  <>
                  <h6>Advanced tools</h6>
                  <Accordion activeKey={activeKey} onSelect={setActiveKey} style={{ marginTop: '15px' }}>
                    {/* Select use case in question */}
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Apply analysis tools</Accordion.Header>
                      <Accordion.Body>
                        <div className="d-grid gap-2" style={{ textAlign: 'left' }}>
                          <OverlayTrigger placement="top" overlay={<Tooltip>Look up key facts about the use case.</Tooltip>}>
                          <Button variant="outline-secondary" className={`btn-sm ${isInsight ? 'active' : ''}`} onClick={handleInsight}>
                              <i className="bi bi-graph-up"></i> Use Case Insights
                          </Button>
                          </OverlayTrigger>                          
                          <OverlayTrigger placement="top" overlay={<Tooltip>Analyze how the use case classifies in the larger ecosystem.</Tooltip>}>
                          <Button variant="outline-secondary" className={`btn-sm ${isCompare ? 'active' : ''}`} onClick={handleCompare}>
                            <i className="bi bi-bar-chart"></i> Compare with ecosystem
                          </Button>
                          </OverlayTrigger>
                          <OverlayTrigger placement="top" overlay={<Tooltip>Generate a BPMN model of the use case.</Tooltip>}>
                          <Button variant="outline-secondary" className={`btn-sm ${isBPMN ? 'active' : ''}`} onClick={handleBPMN}>
                              <i className="bi bi-diagram-3"></i> BPMN handling<Badge bg="warning" style={ { marginLeft:'5px'} }>experimental</Badge>
                          </Button>
                          </OverlayTrigger>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    {/* Reporting tools */}
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Reporting tools</Accordion.Header>
                      <Accordion.Body>
                        <div className="d-grid gap-2" style={{ textAlign: 'left' }}>
                          <OverlayTrigger placement="top" overlay={<Tooltip>Generate Use Case Report</Tooltip>}>
                              <Button variant="outline-secondary" className="btn-sm" onClick={handleGeneralReport}>
                                  <i className="bi bi-file-bar-graph"></i> Generate Report
                              </Button>
                          </OverlayTrigger>
                          <OverlayTrigger placement="top" overlay={<Tooltip>Analyze use case within its ecosystem of use cases.</Tooltip>}>
                              <Button variant="outline-secondary" className="btn-sm" onClick={handleEcosystemReport} disabled>
                                  <i className="bi bi-rocket-takeoff"></i> Analyze Ecosystem
                              </Button>
                          </OverlayTrigger>
                          <OverlayTrigger placement="top" overlay={<Tooltip>BPMN processes of the modeled use case.</Tooltip>}>
                              <Button variant="outline-secondary" className="btn-sm" onClick={handleBPMNReport} disabled>
                                  <i className="bi bi-hdd-network"></i> BPMN Process Landscape
                              </Button>
                          </OverlayTrigger>                               
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>     
                  </>
                )}       
              </Row>
          </div>
      </Col>
      {/* Main content */}
      <Col md={9} style={{ textAlign: 'left' }}>           
        <div>
        {/* Label for the selected use case; also handle save button depending on where it was opened */}      
        <Row style={{ marginBottom: '20px', marginTop: '20px' }}>
          <Col md="12">
            <div className="d-flex justify-content-between align-items-center">
              <OverlayTrigger placement="top" overlay={<Tooltip>Map the selected use case in the larger ecosystem.</Tooltip>}>
                <h5 className="text-start m-0"><i className="bi bi-layers"></i> Analysis of the selected use case: <b>{selectedUseCase.title.value}</b></h5>
              </OverlayTrigger>

              <div>
                {mode === 'Compile Use Case Model' && !isEdit && (
                  <OverlayTrigger placement="top" overlay={<Tooltip>Save to Repository</Tooltip>}>
                    <Button style={{ marginLeft: '10px' }} variant="outline-success" onClick={handleSave}>
                      <i className="bi bi-floppy"></i>
                    </Button>
                  </OverlayTrigger>
                )}
                { mode === 'Compile Use Case Model' && isEdit && (
                  <OverlayTrigger placement="top" overlay={<Tooltip>Save Update</Tooltip>}>
                    <Button style={{ marginLeft: '10px' }} variant="outline-success" onClick={handleSaveEdit}>
                      <i className="bi bi-pencil"></i>
                    </Button>
                  </OverlayTrigger>
                )}
              </div>
            </div>
          </Col>
        </Row>

        {selectedUseCase && (

          <div style={{ marginTop: '0px' }}>
              
              {/* Use case insight */}
              {isInsight && (              
                <UCInsight
                  data={data}
                  selectedUseCase={selectedUseCase}
                  comparisonData={comparisonData}
                  setShowModal={setShowModal}
                  setModalData={setModalData}                                  
                  setShowModalMeta={setShowModalMeta}
                  setModalDataMeta={setModalDataMeta}
                />
              )}      

              {/* Compare use cases */}
              {isCompare  && (
                <EcoAnalyticsUCCompare
                  data={data}
                  selectedUseCase={selectedUseCase}
                  comparisonType={comparisonType}
                  setComparisonType={setComparisonType}
                  selectedComparisonUseCases={selectedComparisonUseCases}
                  setSelectedComparisonUseCases={setSelectedComparisonUseCases}
                  comparisonData={comparisonData}
                  analysisResults={analysisResults}
                  setShowModal={setShowModal}
                  setModalData={setModalData}
                />
              )}

              {/* BPMN Model */}
              {isBPMN && (
                <BPMNModel data={selectedUseCase} />
              )}

              {/* Reporting tools */}
              {isGeneralReport && (
                <Report data={data} comparisonData={comparisonData} selectedUseCase={selectedUseCase} analysisResults={analysisResults} /> 
              )}

              {/* Ecosystem Report */}
              {isEcosystemReport && (
              <>
                <h6>Generate Ecosystem Report</h6>
                <p>WIP</p>
              </>
              )}  
              
              {/* BPMN Report */}
              {isBPMNReport && (
              <>
                <h6>Generate BPMN Report</h6>
                <p>WIP</p>
              </>
              )}

              {modalData && <DetailModal show={showModal} handleClose={() => setShowModal(false)} data={modalData} />}

              {modalDataMeta && <DetailModalMeta show={showModalMeta} handleClose={() => setShowModalMeta(false)} data={modalDataMeta} />}
              
          </div>
        )}      
      </div>
      </Col>
    </Row>
    
    </div>
  );
};

// Detail Modal
const DetailModal = ({ show, handleClose, data }) => (

  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>{data.topic}: {data.category}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <span>List of use cases matching: <b>{data.category}</b> (Total: <b>{data.res.length}</b>)</span>
      {data.topic === 'Actors per Category' && (
        <ul>
          {data.res.map((actor, index) => (
            <li key={index}>{actor.label}</li>
          ))}
        </ul>
      )}     

      {(data.topic === 'Components per Category') && (
        <ul>
          {data.res.map((component, index) => (
            <li key={index}>{component}</li>
          ))}
        </ul>        
      )}
      
      {(data.topic === 'status' || data.topic === 'maturiy' || data.topic === 'application') && (
        <ul>
          {data.res.map((res, index) => (
            <li key={index}>{res}</li>
          ))}
        </ul>
      )}
      {data.topic === 'Summary' && (
        <ul>
          {data.res.map((res, index) => (
            <li key={index}>{res}</li>
          ))}
        </ul>      
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
);

// Detail Modal
const DetailModalMeta = ({ show, handleClose, data }) => (

  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>{data.topic}: {data.category}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <span>Tracked values: <b>{data.category}</b> (Total: <b>{data.res.length}</b>)</span>
      {data.topic === 'adoption' && (
        <ul>
          {data.res.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      )}     

      {(data.topic === 'adaptation') && (
        <ul>
          <li key={0}>{data.res}</li>
        </ul>        
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
);

export default EcoUCDashboard;
