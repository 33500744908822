/**
 * Ecosystem Analytics Adoption
 *
 * Description: 
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-09-21
 * 
 */

import React, { useState } from 'react';

import { Badge, Table, OverlayTrigger, Tooltip, Button, Modal, Card, Form, Row, Col } from 'react-bootstrap';

import '@sgratzl/chartjs-chart-boxplot';
import { Bar, Chart } from 'react-chartjs-2';

import {
    Chart as ChartJS,
    registerables
  } from 'chart.js';

import {
    BoxPlotController,
    BoxAndWiskers,
    // ViolinController, etc. if you also want violin plots
  } from '@sgratzl/chartjs-chart-boxplot';

import { 
  // helper
  normalizeString,
  
} from '../../eco_analytics_handling/eco_analytics_prepare_charts';

//import deepEqual from 'fast-deep-equal';
//import {variance } from 'simple-statistics'; //  mean, median, mode, standardDeviation, interquartileRange

ChartJS.register(...registerables, BoxPlotController, BoxAndWiskers);


// #########################################################################################
// ############################### Expert Input Prompt Analysis ############################
// #########################################################################################

// ############################### Prompt Analysis for a single use case ###################

/**
 * Provides the initial prompt if applicable
 */
export const VisualizeUserPrompt = ( { useCaseData }) => {
    
  return (
      <div 
          style={{ 
              width: '90%', 
              maxHeight: '200px',  // height
              overflowY: 'auto',   // vertical scrolling
          }}
      >
          {useCaseData.prompt && (
          <h4 style={{ color: 'gray' }}>{useCaseData.prompt.value}</h4>
          )}
          <p style={{ color: 'green' }}><i>Initial prompt</i></p>
      </div>
  );
}

/**
* Provides the initial prompt if applicable
*/
export const VisualizePromptResponse = ({ useCaseData }) => {
  
  const renderPromptResponse = () => {
      if (!useCaseData.prompt_response) return null;

      const responseText = useCaseData.prompt_response.value;

      // Split into sections by headings (###) or numbered list items (1., 2., etc.)
      const sections = responseText.split(/(?=###|^\d+\.\s)/gm);

      return sections.map((section, index) => {
          if (section.startsWith('###')) {
              return (
                  <h5 key={index} style={{ marginTop: '1rem', color: '#007bff' }}>
                      {section.replace('###', '').trim()}
                  </h5>
              );
          } else if (section.match(/^\d+\.\s/)) {
              // Handle numbered lists with nested elements
              const lines = section.split(/\n/).filter(line => line.trim() !== '');

              return (
                  <div key={index} style={{ marginBottom: '1rem' }}>
                      {lines.map((line, idx) => {
                          if (line.match(/^\d+\.\s/)) {
                              // Main numbered item
                              return (
                                  <p key={idx} style={{ fontWeight: 'bold', marginTop: '0.5rem' }}>
                                      {line.trim()}
                                  </p>
                              );
                          } else if (line.match(/^-\s/)) {
                              // Handle bullet points inside numbered items
                              return (
                                  <ul key={idx} style={{ paddingLeft: '1.5rem' }}>
                                      <li>{line.replace(/^-\s/, '').trim()}</li>
                                  </ul>
                              );
                          } else {
                              // Normal text within a numbered list
                              return (
                                  <p key={idx} style={{ lineHeight: '1.6', textAlign: 'justify', marginLeft: '1.5rem' }}>
                                      {line.trim()}
                                  </p>
                              );
                          }
                      })}
                  </div>
              );
          } else {
              return (
                  <p key={index} style={{ lineHeight: '1.6', textAlign: 'justify', fontSize: '1rem', color: '#333' }}>
                      {section.trim()}
                  </p>
              );
          }
      });
  };

  return (
      <div>
          {useCaseData.prompt_response && (
              <Card className="shadow-sm p-4 my-4" style={{ maxHeight: '600px', overflowY: 'auto', borderRadius: '10px', backgroundColor: '#f9f9f9' }}>
                  <Card.Body>
                      {renderPromptResponse()}
                  </Card.Body>
              </Card>
          )}
      </div>
  );
};

/**
* Provides the automatic prompt responses if applicable
*/
export const VisualizeAutomaticPromptsResponses = ({ useCaseData }) => {
  return (
      <div>
          <p>Below are the details of the prompts processed and their corresponding responses to generate the use case.</p>
          <Row>
              {useCaseData.copilot_messages?.value?.map((message, index) => (                    
                  <Col key={index} md={6} lg={4} className="mb-4">
                      <Card className="shadow-sm">
                          <Card.Body>
                              <Card.Title className="text-primary">Prompt {index + 1}</Card.Title>
                              <Card.Text>
                                  <strong>Prompt:</strong> {message.prompt}
                              </Card.Text>
                              <Card.Text>
                                  <strong>Response:</strong> {message.response}
                              </Card.Text>
                          </Card.Body>
                      </Card>
                  </Col>
              ))}
          </Row>
      </div>
  );
};

// ############################### Prompt Analysis for multiple use cases ###################

/**
* Extract expert prompt data from an array of use cases.
* Returns an array of objects with:
* { prompt, promptResponse, expertField, role, yearOfBirth, experience, uid }
*/
export function extractExpertPrompts(useCases) {
  return useCases
    .filter((uc) => uc.prompt && uc.prompt.value)
    .map((uc) => ({
      prompt: uc.prompt.value,
      promptResponse: uc.prompt_response?.value || "",
      expertField: (uc.expert_field?.value || "Unknown").trim(),
      role: (uc.role?.value || "Unknown").trim(),
      yearOfBirth: uc.yearOfBirth?.value || "N/A",
      experience: uc.experience?.value || "N/A",
      uid: uc.uid || ""
    }));
}

/**
* Remove common stopwords from a string.
*/
function removeStopwords(text, stopwords = 
  [
      "a", "an", "and", "are", "as", "at", "be", "but", "by", "for", "if", "in",
      "into", "is", "it", "no", "not", "of", "on", "or", "such", "that", "the",
      "their", "then", "there", "these", "they", "this", "to", "was", "will", "with",
      "can", "get", "make", "more", "other", "some", "new", "like", "need", "have",
      "want", "how", "why", "when", "where", "which", "who", "whom", "please", "should", "it.",
      "through", "provides", "using", "using", "used", "used.", "used,", "used:", "used;", "usage.",
      "allow", "allows", "allows.", "allows,", "allows:", "allows;", "allows-", "allows_", "allows(",
      "between", "incorporate", "incorporates", "incorporates.", "incorporates,", "incorporates:", "incorporates;",
      "me."
  ]
) {
  return text
    .split(" ")
    .filter((word) => !stopwords.includes(word))
    .join(" ");
}

/**
* Extract keywords from a prompt.
* @param {string} prompt 
* @param {number} minLength Minimum word length to include.
*/
export function extractKeywords(prompt, minLength = 3) {
  if (!prompt) return [];
  const normalized = normalizeString(prompt);
  const cleaned = removeStopwords(normalized);
  return cleaned.split(" ").filter((word) => word.length >= minLength);
}

/**
* Count keyword frequencies from an array of prompt strings.
*/
export function countWordFrequencies(prompts, minLength = 3) {
  const freq = {};
  prompts.forEach((prompt) => {
    const keywords = extractKeywords(prompt, minLength);
    keywords.forEach((word) => {
      freq[word] = (freq[word] || 0) + 1;
    });
  });
  return freq;
}

/**
* Group an array of prompt objects by a given key (e.g. expertField or role)
*/
export function groupPromptsBy(prompts, key) {
  return prompts.reduce((acc, promptObj) => {
    const group = (promptObj[key] || "Unknown").trim();
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(promptObj);
    return acc;
  }, {});
}

/**
* AllExpertPromptsTable:
* Displays a searchable table of expert prompts with columns for expert field, role, year of birth, experience, and the prompt.
*/
export function AllExpertPromptsTable({ prompts }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [selectedResponse, setSelectedResponse] = useState("");

  const filtered = prompts.filter((p) =>
    p.prompt.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <Form.Control
        type="text"
        placeholder="Search prompts..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{ marginBottom: "1rem" }}
      />
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Expert Field</th>
            <th>Role</th>
            <th>Year of Birth</th>
            <th>Experience</th>
            <th>Prompt</th>
            <th>Initial Response</th>
          </tr>
        </thead>
        <tbody>
          {filtered.map((p, idx) => (
            <tr key={idx}>
              <td>{p.expertField}</td>
              <td>{p.role}</td>
              <td>{p.yearOfBirth}</td>
              <td>{p.experience}</td>
              <td>{p.prompt}</td>
              <td>
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={() => {
                    setSelectedResponse(p.promptResponse);
                    setModalShow(true);
                  }}
                >
                  View Response
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg">
        <Modal.Header closeButton style={{ backgroundColor: "#f8f9fa" }}>
          <Modal.Title>Initial Response</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "300px", overflowY: "auto" }}>
          <pre style={{ whiteSpace: "pre-wrap" }}>{selectedResponse}</pre>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

/**
* KeywordFrequencyTable:
* Displays a table of keyword frequencies.
* A dropdown allows the user to choose top 5, 10, 20, or all keywords.
*/
export function KeywordFrequencyTable({ frequencyData }) {
  const [topN, setTopN] = useState(10);
  const entries = Object.entries(frequencyData).sort((a, b) => b[1] - a[1]);
  const displayEntries = topN === Infinity ? entries : entries.slice(0, topN);
  return (
    <div>
      <Form.Select
          value={topN}
          onChange={(e) =>
          setTopN(e.target.value === "all" ? Infinity : parseInt(e.target.value))
        }
        style={{ width: "auto", marginBottom: "1rem" }}
      >
        <option value="5">Top 5</option>
        <option value="10">Top 10</option>
        <option value="20">Top 20</option>
        <option value="all">All</option>
      </Form.Select>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Word</th>
            <th>Frequency</th>
          </tr>
        </thead>
        <tbody>
          {displayEntries.map(([word, count], idx) => (
            <tr key={idx}>
              <td>{word}</td>
              <td>{count}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

/**
* PromptsGroupedByExpertField:
* Displays a table of expert prompts grouped by expert field.
* For each field, it shows the number of prompts and the top 5 keywords.
* Clicking a keyword badge opens a modal with the full prompts.
*/
export function PromptsGroupedByExpertField({ groupedByExpert }) {
  const [selectedContent, setSelectedContent] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  const handleBadgeClick = (group, word, count) => {
    // Build a modal text showing all prompts for that expert field
    const prompts = groupedByExpert[group].map((p) => p.prompt).join("\n---\n");
    setSelectedContent(`Expert Field: ${group}\nKeyword: ${word} (${count})\n\nPrompts:\n${prompts}`);
    setModalShow(true);
  };

  return (
    <div>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Expert Field</th>
            <th># Prompts</th>
            <th>Top Keywords</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(groupedByExpert).map(([field, prompts], idx) => {
            const allText = prompts.map((p) => p.prompt);
            const freq = countWordFrequencies(allText);
            const topKeywords = Object.entries(freq)
              .sort((a, b) => b[1] - a[1])
              .slice(0, 5);
            return (
              <tr key={idx}>
                <td>{field}</td>
                <td>{prompts.length}</td>
                <td>
                  {topKeywords.map(([word, count], i) => (
                    <Badge
                      key={i}
                      bg="info"
                      style={{ cursor: "pointer", marginRight: "5px" }}
                      onClick={() => handleBadgeClick(field, word, count)}
                    >
                      {word} ({count})
                    </Badge>
                  ))}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg">
        <Modal.Header closeButton style={{ backgroundColor: "#f8f9fa" }}>
          <Modal.Title>Expert Field Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <pre style={{ whiteSpace: "pre-wrap" }}>{selectedContent}</pre>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

/**
* PromptsGroupedByRole:
* Similar to PromptsGroupedByExpertField but groups by role.
*/
export function PromptsGroupedByRole({ groupedByRole }) {
  const [selectedContent, setSelectedContent] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  const handleBadgeClick = (group, word, count) => {
    const prompts = groupedByRole[group].map((p) => p.prompt).join("\n---\n");
    setSelectedContent(`Role: ${group}\nKeyword: ${word} (${count})\n\nPrompts:\n${prompts}`);
    setModalShow(true);
  };

  return (
    <div>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Role</th>
            <th># Prompts</th>
            <th>Top Keywords</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(groupedByRole).map(([role, prompts], idx) => {
            const allText = prompts.map((p) => p.prompt);
            const freq = countWordFrequencies(allText);
            const topKeywords = Object.entries(freq)
              .sort((a, b) => b[1] - a[1])
              .slice(0, 5);
            return (
              <tr key={idx}>
                <td>{role}</td>
                <td>{prompts.length}</td>
                <td>
                  {topKeywords.map(([word, count], i) => (
                    <Badge
                      key={i}
                      bg="warning"
                      style={{ cursor: "pointer", marginRight: "5px" }}
                      onClick={() => handleBadgeClick(role, word, count)}
                    >
                      {word} ({count})
                    </Badge>
                  ))}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg">
        <Modal.Header closeButton style={{ backgroundColor: "#f8f9fa" }}>
          <Modal.Title>Role Prompt Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <pre style={{ whiteSpace: "pre-wrap" }}>{selectedContent}</pre>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

/**
* ExpertMatrixTable:
* Builds a matrix table (rows: expert fields, columns: roles)
* showing the count of prompts in each combination.
*/
export function ExpertMatrixTable({ prompts }) {
  const fieldsSet = new Set();
  const rolesSet = new Set();
  prompts.forEach((p) => {
    fieldsSet.add(p.expertField);
    rolesSet.add(p.role);
  });
  const fields = Array.from(fieldsSet);
  const roles = Array.from(rolesSet);

  // Build a matrix: for each expert field and role, count prompts
  const matrix = fields.map((field) =>
    roles.map((role) =>
      prompts.filter((p) => p.expertField === field && p.role === role).length
    )
  );

  return (
    <div>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Expert Field / Role</th>
            {roles.map((role, i) => (
              <th key={i}>{role}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {fields.map((field, i) => (
            <tr key={i}>
              <td>{field}</td>
              {matrix[i].map((count, j) => (
                <td key={j}>{count}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

// #########################################################################################
// ############################### Processing Time Analysis ################################
// #########################################################################################

// ############################### Timestamp and duration helpers ##########################

/**
 * Header for a table column, with a tooltip.
 */
function StatHeader({ text, tooltipText }) {
  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`tooltip-${text}`}>{tooltipText}</Tooltip>}
    >
      <th style={{ cursor: "help" }}>{text}</th>
    </OverlayTrigger>
  );
}

/**
 * If ts is an object with { value: { seconds, nanoseconds } }, convert to Date.
 * If ts.value is a string, parse as Date.
 * Otherwise, parse directly as string.
 */
export function parseTimestamp(ts) {
  if (!ts) return null;
  if (typeof ts === "object") {
    // Firestore style: { value: { seconds, nanoseconds } }
    if (ts.value && typeof ts.value === "object" && ts.value.seconds !== undefined) {
      return new Date(ts.value.seconds * 1000);
    }
    // If ts.value is a string
    if (typeof ts.value === "string") {
      ts = ts.value;
    }
  }
  const date = new Date(ts);
  return isNaN(date.getTime()) ? null : date;
}

/**
 * Returns the duration in seconds between two timestamps, never negative.
 * If either timestamp is invalid or missing, returns 0.
 */
export function getDuration(start, end) {
  const startDate = parseTimestamp(start);
  const endDate = parseTimestamp(end);
  if (!startDate || !endDate) return 0;
  const diff = (endDate - startDate) / 1000; // convert ms -> sec
  return Math.max(0, diff);
}

/**
 * Format seconds into "h hours m minutes s seconds" style (or "m min s sec").
 */
export function formatDuration(seconds) {
  if (seconds == null || isNaN(seconds)) return "";
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);
  return (h > 0 ? `${h}h ` : "") + `${m}m ${s}s`;
}

/**
 * For each use case, fix the timestamps based on study_step:
 *  - step 1: if study_step_end is missing => use created
 *  - step 2: if start_processing is missing => use study_step_start as processingStart,
 *            and use created as study_step_end if study_step_end is to close to study_step_start
 *  - step 3: always use created in place of study_step_end
 * Then compute studyDuration and processingDuration. 
 * 
 */
export function calculateProcessingTimes(useCases) {

  return useCases.map((uc, i) => {
    const rowIndex = i + 1; // for the table
    const studyStep = uc.study_step.value; // 1,2,3 or "1","2","3"
    const creationMode = uc.creation_mode?.value || uc.creation_mode || "Unknown Mode";

    // Convert all to timestamps
    const created = parseTimestamp(uc.created);
    let studyStart = parseTimestamp(uc.study_step_start);
    let studyEnd = parseTimestamp(uc.study_step_end);
    let procStart = parseTimestamp(uc.start_processing);
    let procEnd = parseTimestamp(uc.end_processing);

    // Adjust based on step
    if (studyStep === 1 || studyStep === "1") {
      if (!studyEnd) {
        studyEnd = created;
      }
    } else if (studyStep === 2 || studyStep === "2") {
      // If there's no start_processing, use studyStepStart
      if (!procStart) {
        procStart = studyStart;
      }
      // If there's no end_processing, use studyStepEnd
      if (!procEnd) {
        procEnd = studyEnd;
      }
      // If studyStepEnd is too close to studyStepStart, use created instead
      if (getDuration(studyStart, studyEnd) < 10) {
        studyEnd = created;
      }
    } else if (studyStep === 3 || studyStep === "3") {
      // use created in place of studyStepEnd
      studyEnd = created;
    }

    // For table track which fields are actually used

    const studyEndUsedField = (studyStep === 1 && !uc.study_step_end.value) || studyStep === 3
      ? "created"
      : "study_step_end";

    const procStartUsedField = (studyStep === 2 && !uc.start_processing.value)
      ? "study_step_start"
      : "start_processing";

    const procEndUsedField = (studyStep === 2 && !uc.end_processing.value)
      ? "study_step_end"
      : "end_processing";

    return {
      rowIndex,
      studyStep,
      creationMode,
      anonymous_id: uc.anonymous_id?.value || uc.anonymous_id || "N/A",
      title: uc.title?.value || uc.title || "Untitled",

      // store the final timestamps
      created,
      studyStepStart: studyStart,
      studyStepEnd: studyEnd,
      processingStart: procStart,
      processingEnd: procEnd,

      // store which fields were used (for tooltip)
      usedFields: {
        studyEndUsedField,
        procStartUsedField,
        procEndUsedField
      },

      studyDuration: getDuration(studyStart, studyEnd),
      processingDuration: getDuration(procStart, procEnd),
    };
  });
}

/**
 * Aggregates an array of processing time objects to compute descriptive statistics.
 * Optionally, zero or negative durations (which may indicate missing or invalid data) are filtered out.
 */
export function aggregateProcessingStats(processingTimes) {
  const validStudy = processingTimes.map(pt => pt.studyDuration).filter(d => d > 0);
  const validProc = processingTimes.map(pt => pt.processingDuration).filter(d => d > 0);

  const meanValue = arr => arr.reduce((sum, v) => sum + v, 0) / (arr.length || 1);
  const medianValue = arr => {
    if (!arr.length) return 0;
    const sorted = [...arr].sort((a,b)=>a-b);
    const mid = Math.floor(sorted.length/2);
    return (sorted.length % 2 === 0)
      ? (sorted[mid-1] + sorted[mid]) / 2
      : sorted[mid];
  };
  const stdDev = arr => {
    const m = meanValue(arr);
    const variance = meanValue(arr.map(x => (x - m)**2));
    return Math.sqrt(variance);
  };
  const statsFor = arr => {
    if(!arr.length) return { mean:0, median:0, stdDev:0, min:0, max:0 };
    return {
      mean: meanValue(arr),
      median: medianValue(arr),
      stdDev: stdDev(arr),
      min: Math.min(...arr),
      max: Math.max(...arr)
    };
  };

  return {
    study: statsFor(validStudy),
    processing: statsFor(validProc)
  };
}


/**
 * ProcessingTimesTable:
 * Displays a table of key timestamps, durations, plus additional identifiers (anonymous_id, title).
 * Each cell includes an OverlayTrigger tooltip to clarify which field was used.
 */
export function ProcessingTimesTable({ processingTimes }) {

  // A small helper to produce a tooltip + color-coded badge or cell
  const renderTimestampCell = (dateVal, usedLabel, fallbackLabel = "") => {
    if (!dateVal) {
      return "N/A";
    }
    // pick a color based on whether usedLabel is "created", "study_step_end", etc.
    let variant = "secondary";
    if (usedLabel === "created") variant = "info";
    else if (usedLabel === "study_step_end") variant = "success";
    else if (usedLabel === "study_step_start") variant = "warning";
    else if (usedLabel === "start_processing") variant = "primary";
    else if (usedLabel === "end_processing") variant = "danger";

    const text = `${usedLabel}${fallbackLabel ? ` or ${fallbackLabel}` : ""}`;

    const overlay = (
      <Tooltip id={`tooltip-${usedLabel}`}>
        {`Field used: ${text}`}
      </Tooltip>
    );

    return (
      <OverlayTrigger placement="top" overlay={overlay}>
        <Badge bg={variant} style={{ cursor: "pointer" }}>
          {dateVal.toLocaleString()}
        </Badge>
      </OverlayTrigger>
    );
  };

  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>#</th>
          <th>Anon ID</th>
          <th>Title</th>
          <th>Creation Mode</th>
          <th>Created</th>
          <th>Study Start</th>
          <th>Study End</th>
          <th>Processing Start</th>
          <th>Processing End</th>
          <th>Study Duration</th>
          <th>Processing Duration</th>
        </tr>
      </thead>
      <tbody>
        {processingTimes.map((pt, idx) => {
          const { 
            rowIndex, 
            anonymous_id, 
            title, 
            creationMode,
            created, 
            studyStepStart, 
            studyStepEnd, 
            processingStart, 
            processingEnd,
            usedFields,
            studyDuration,
            processingDuration 
          } = pt;

          return (
            <tr key={idx}>
              <td>{rowIndex}</td>
              <td>{anonymous_id}</td>
              <td>{title}</td>
              <td>{creationMode}</td>

              <td>
                {created
                  ? created.toLocaleString()
                  : "N/A"
                }
              </td>

              <td>
                {studyStepStart
                  ? studyStepStart.toLocaleString()
                  : "N/A"
                }
              </td>

              {/* "studyEndUsedField" says which field we used: "created" or "study_step_end" */}
              <td>
                {renderTimestampCell(
                  studyStepEnd, 
                  usedFields.studyEndUsedField
                )}
              </td>

              <td>
                {renderTimestampCell(
                  processingStart,
                  usedFields.procStartUsedField
                )}
              </td>

              <td>
                {renderTimestampCell(
                  processingEnd,
                  usedFields.procEndUsedField
                )}
              </td>

              <td>{formatDuration(studyDuration)}</td>
              <td>{formatDuration(processingDuration)}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

/**
* Displays a bar chart showing the average durations (in seconds) aggregated over all use cases.
*/
export function ProcessingTimesChart({ stats }) {
  // Convert from seconds to minutes
  const studyMeanMin = stats.study.mean / 60;
  const procMeanMin = stats.processing.mean / 60;

  const chartData = {
    labels: ["Study Duration", "Processing Duration"],
    datasets: [
      {
        label: "Average Duration (minutes)",
        data: [studyMeanMin, procMeanMin],
        backgroundColor: ["#36A2EB", "#FF6384"],
        borderColor: ["#36A2EB", "#FF6384"],
        borderWidth: 1
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    plugins: { legend: { position: "top" } },
    scales: {
      y: { beginAtZero: true }
    },
  };

  return <Bar data={chartData} options={chartOptions} />;
}

/**
* Descriptive statistics for processing times.
*/
export function DescriptiveStatsTable({ stats }) {
  // stats.study = { mean, median, stdDev, min, max }, but in SECONDS.
  // Convert to minutes for display below if you like.
  // We can define a local function that does the conversion + formatting:
  const toMinutesString = (secVal) => {
    if (!secVal || isNaN(secVal)) return "";
    const minVal = secVal / 60;
    // We can show it as e.g. "xx.x min"
    return `${minVal.toFixed(2)} min`;
  };

  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>Metric</th>
          <StatHeader
            text="Mean"
            tooltipText="Average value: sum of durations / count"
          />
          <StatHeader
            text="Median"
            tooltipText="Middle value when durations are sorted"
          />
          <StatHeader
            text="Std Dev"
            tooltipText="Standard Deviation: measure of spread around the mean"
          />
          <StatHeader
            text="Min"
            tooltipText="Minimum (lowest) duration in the dataset"
          />
          <StatHeader
            text="Max"
            tooltipText="Maximum (largest) duration in the dataset"
          />
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Study Duration</td>
          <td>{toMinutesString(stats.study.mean)}</td>
          <td>{toMinutesString(stats.study.median)}</td>
          <td>{toMinutesString(stats.study.stdDev)}</td>
          <td>{toMinutesString(stats.study.min)}</td>
          <td>{toMinutesString(stats.study.max)}</td>
        </tr>
        <tr>
          <td>Processing Duration</td>
          <td>{toMinutesString(stats.processing.mean)}</td>
          <td>{toMinutesString(stats.processing.median)}</td>
          <td>{toMinutesString(stats.processing.stdDev)}</td>
          <td>{toMinutesString(stats.processing.min)}</td>
          <td>{toMinutesString(stats.processing.max)}</td>
        </tr>
      </tbody>
    </Table>
  );
}

/**
 * Renders a box plot with two box/distributions:
 *  - Study Durations (minutes)
 *  - Processing Durations (minutes)
 */
export function ProcessingBoxPlot({ processingTimes }) {
  // 1) Extract arrays in seconds
  const studySeconds = processingTimes.map(pt => pt.studyDuration);
  const procSeconds = processingTimes.map(pt => pt.processingDuration);

  // 2) Convert to minutes
  const studyMinutes = studySeconds.map(s => s / 60);
  const procMinutes = procSeconds.map(s => s / 60);

  // 3) Build chart data: 2 distributions => [ studyMinutes, procMinutes ]
  const chartData = {
    labels: ["Study", "Processing"],
    datasets: [
      {
        label: "Durations (minutes)",
        data: [studyMinutes, procMinutes], 
        backgroundColor: "#FFD700",
        borderColor: "#333",
        borderWidth: 1,
        outlierColor: "#999999"
      }
    ]
  };

  // 4) Options: show outliers, custom tooltip
  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: function(ctx) {
            // ctx.raw => if it's a box, it's [min, q1, median, q3, max]
            // if it's an outlier => single number
            const val = ctx.raw;
            if (Array.isArray(val)) {
              return (
                `Min=${val[0].toFixed(2)} | ` +
                `Q1=${val[1].toFixed(2)} | ` +
                `Median=${val[2].toFixed(2)} | ` +
                `Q3=${val[3].toFixed(2)} | ` +
                `Max=${val[4].toFixed(2)} (minutes)`
              );
            } else {
              return `Outlier: ${val.toFixed(2)} min`;
            }
          }
        }
      }
    },
    scales: {
      y: {
        title: { display: true, text: "Minutes" }
      }
    },
    boxplot: {
      outlierRadius: 4
    }
  };

  return (
    <Chart type="boxplot" data={chartData} options={options} />
  );
}

/**
 * Build a histogram from an array of numeric values (in minutes).
 * binSize => the width of each bin (e.g., 5 means 0-4.99, 5-9.99, etc.)
 * returns { labels, counts } for the bins
 */
function buildHistogram(arr, binSize) {
  if (!arr.length) return { labels: [], counts: [] };
  const maxVal = Math.max(...arr);
  const binsCount = Math.ceil(maxVal / binSize);

  const bins = new Array(binsCount).fill(0);
  arr.forEach((val) => {
    const binIndex = Math.floor(val / binSize);
    if (binIndex < binsCount) bins[binIndex]++;
  });

  const labels = [];
  for (let i = 0; i < binsCount; i++) {
    const rangeStart = i * binSize;
    const rangeEnd = rangeStart + binSize;
    labels.push(`${rangeStart}-${rangeEnd} min`);
  }

  return { labels, counts: bins };
}

/**
 * Show a simple histogram for one distribution (study or processing)
 */
export function DurationHistogram({ data, label, binSize = 5 }) {
  // data => array of durations in SECONDS
  // convert to minutes
  const durationsMin = data.map(s => s / 60);
  const { labels, counts } = buildHistogram(durationsMin, binSize);

  const chartData = {
    labels,
    datasets: [
      {
        label: label || "Distribution",
        data: counts,
        backgroundColor: "#36A2EB"
      }
    ]
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        title: { display: true, text: `Bins of ${binSize} min` }
      },
      y: {
        title: { display: true, text: "Count" }
      }
    }
  };

  return <Bar data={chartData} options={options} />;
}

// #########################################################################################
// ############################### Recurrent Pattern Analysis ##############################
// #########################################################################################

