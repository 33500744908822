/**
 * Visualizes the study interview
 *
 * Description: Provides an interface to analyze the interview data.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-27
 */

import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Row, Col, ButtonGroup, Button, Card, Table, Form, Dropdown, DropdownButton, Modal, Accordion, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';

import { getBlueGreenColors } from '../../eco_analytics_handling/eco_analytics_prepare_charts';

import { StatisticsCard } from '../../eco_analytics_handling/eco_stats';

import RecordedAudioTable from './study_recorded_answers'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    RadialLinearScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    Tooltip as ChartTooltip,
    Legend
} from 'chart.js';

import { Bar, Pie, Radar, PolarArea, Doughnut, Line } from 'react-chartjs-2'; // Scatter

// Register necessary components for all chart types
ChartJS.register(
    CategoryScale,
    LinearScale,
    RadialLinearScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    ChartTooltip,
    Legend
);

const chartTypes = {
    Bar: Bar,
    Pie: Pie,
    Radar: Radar,
    //Scatter: Scatter,
    Polar: PolarArea,
    Doughnut: Doughnut,
    Line: Line,
    Table: Table,
};

// Aggregate survey data for chart preparation and calculations
const aggregateInterviewData = ( interviewData ) => {

    // General demographics
    let totalParticipants = interviewData.length;
    const experienceData = {};
    const expertFieldData = {};
    const roleData = {};
    const placeData = {};

    interviewData.forEach((data) => {

        // Aggregate years of work experience
        experienceData[data.experience?.value] = (experienceData[data.experience?.value] || 0) + 1;

        // Aggregate `expert_field` responses
        expertFieldData[data.expert_field?.value] = (expertFieldData[data.expert_field?.value] || 0) + 1;

        // Aggregate `role` responses
        roleData[data.role?.value] = (roleData[data.role?.value] || 0) + 1;

        // Aggregate `place` responses
        placeData[data.place?.value] = (placeData[data.place?.value] || 0) + 1;
    });

    return { 
        // General demographics
        expertFieldData,
        roleData, 
        placeData, 
        experienceData, 
        totalParticipants, 
    };
};


////////////////////////////////////////////////////////////////////////////////
// 1) Default synonyms & stop words
////////////////////////////////////////////////////////////////////////////////
const DEFAULT_THEME_SYNONYMS = {
  'ux': 'User Experience',
  'usability': 'User Experience',
  'autonomous vehicle': 'AV',
  'self-driving': 'AV',
};
const DEFAULT_THEME_STOP_WORDS = ['general', 'progress', 'you', 'think', 'opinions', 'available'];

// Example synonyms for keywords (singular <-> plural, etc.)
const DEFAULT_KEYWORD_SYNONYMS = {
    'systems': 'system',
    'system.': 'system',
    'use case': 'use case',
    'use cases': 'use case',
    'use case.': 'use case',
};

////////////////////////////////////////////////////////////////////////////////
// 2) Keyword Normalization
//    - Removes trailing punctuation
//    - Strips trailing 's' if obviously plural
//    - Applies synonyms
////////////////////////////////////////////////////////////////////////////////
function normalizeKeyword(raw) {
    if (!raw) return '';
    // Lowercase
    let kw = raw.trim().toLowerCase();
    // Remove trailing punctuation (periods, commas, question marks, etc.)
    kw = kw.replace(/[.,!?]+$/, '');
  
    // If it ends with 's' and not too short (avoid removing 's' from "bus")
    if (kw.length > 3 && kw.endsWith('s')) {
      kw = kw.slice(0, -1);
    }
    // Now apply synonyms if any
    if (DEFAULT_KEYWORD_SYNONYMS[kw]) {
      kw = DEFAULT_KEYWORD_SYNONYMS[kw];
    }
  
    return kw.trim();
}

////////////////////////////////////////////////////////////////////////////////
// 3) Theme Normalization (similarly remove punctuation, apply synonyms)
////////////////////////////////////////////////////////////////////////////////
function normalizeTheme(raw) {
    let t = raw.trim().toLowerCase();
    t = t.replace(/[.,!?]+$/, '');
    if (DEFAULT_THEME_SYNONYMS[t]) {
      t = DEFAULT_THEME_SYNONYMS[t];
    }
    return t.trim();
}

/**
 * Data Analysis component
 */
export const DataAnalysis = ({ interviewData }) => {

    // --------------------------------
    // A) States for user filtering
    // --------------------------------
    const [selectedField, setSelectedField] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [selectedExperience, setSelectedExperience] = useState('');
    const [selectedQuestion, setSelectedQuestion] = useState('All');
  
    // For top-N Themes & top-N Keywords
    const [topN, setTopN] = useState(10);
    const [topNKeywords, setTopNKeywords] = useState(10);
    const [topNContextualTags, setTopNContextualTags] = useState(10);
    const [topNField, setTopNField] = useState(10);
  
    // For advanced search on keywords
    const [themeSearch, setThemeSearch] = useState('');
    const [keywordSearch, setKeywordSearch] = useState('');
    const [contextualTagsSearch, setContextualTagsSearch] = useState('');
    const [fieldSearch, setFieldSearch] = useState('');
  
    // --------------------------------
    // B) Chart Types
    // --------------------------------
    const [themeChartType, setThemeChartType] = useState('Bar');
    const [keywordChartType, setKeywordChartType] = useState('Bar');
    const [contextualTagsChartType, setContextualTagsChartType] = useState('Bar');
    const [fieldChartType, setFieldChartType] = useState('Bar');
    const [sentimentChartType, setSentimentChartType] = useState('Doughnut');
    const [languageChartType, setLanguageChartType] = useState('Bar');
    const [durationChartType, setDurationChartType] = useState('Bar');
  
    // --------------------------------
    // C) Processed data states
    // --------------------------------
    // Themes
    const [themeEntries, setThemeEntries] = useState([]); // [ [theme, count], ... ]
    // Keywords
    //const [keywordCounts, setKeywordCounts] = useState({});
    const [keywordEntries, setKeywordEntries] = useState([]);   // array of [keyword, count]
    // Contextual Tags
    const [contextualTags, setContextualTags] = useState({});
    const [fieldTags, setFieldTags] = useState({});
    // Others
    const [sentimentCounts, setSentimentCounts] = useState({
      positive: 0,
      neutral: 0,
      negative: 0,
    });
    const [averageSentimentScore, setAverageSentimentScore] = useState(0);
    const [languageCounts, setLanguageCounts] = useState({});
    const [averageDuration, setAverageDuration] = useState(0);
    const [durationStats, setDurationStats] = useState({ min: 0, max: 0 });
    const [durationsCounts, setDurationsCounts] = useState({});
    const [durationDistribution, setDurationDistribution] = useState({});
    const [durationDistributionMinutes, setDurationDistributionMinutes] = useState({});

    const [selectDurationView, setSelectDurationView] = useState('count');
  
    // --------------------------------
    // D) Build sets for Field/Role/Exp
    // --------------------------------
    const allFields = new Set();
    const allRoles = new Set();
    const allExperiences = new Set();
    const allQuestions = new Set();
  
    interviewData.forEach((item) => {
      if (item?.expert_field?.value) {
        allFields.add(item.expert_field.value);
      }
      if (item?.role?.value) {
        allRoles.add(item.role.value);
      }
      if (item?.experience?.value) {
        allExperiences.add(item.experience.value);
      }
      if (item?.questions) {
        Object.keys(item.questions).forEach((questionSetKey) => {
          Object.keys(item.questions[questionSetKey]).forEach((questionKey) => {
            allQuestions.add(questionKey);
          });
        });
      }
    });
  
    const fieldOptions = Array.from(allFields).sort();
    const roleOptions = Array.from(allRoles).sort();
    const experienceOptions = Array.from(allExperiences).sort();
    const questionOptions = Array.from(allQuestions).sort();
  
    // --------------------------------
    // E) Filter the data by Expert/Role/Experience
    // --------------------------------
    const filteredData = useMemo(() => {
      return interviewData.filter((item) => {
        const fieldVal = item?.expert_field?.value || '';
        const roleVal = item?.role?.value || '';
        const expVal = item?.experience?.value || '';
        //const questionVal = item?.questions || {};
        //const questionVal = getFilteredQuestions(interviewData, roleVal, fieldVal);
  
        if (selectedField && fieldVal !== selectedField) return false;
        if (selectedRole && roleVal !== selectedRole) return false;
        if (selectedExperience && expVal !== selectedExperience) return false;

        if (selectedQuestion && selectedQuestion !== 'All') {
            let hasQuestion = false;
            const questions = item.questions || {};
            // Loop over question sets (e.g. general_knowledge, platform_economy, etc.)
            for (const setKey of Object.keys(questions)) {
            if (questions[setKey]?.[selectedQuestion]) {
                hasQuestion = true;
                break;
            }
            }
            if (!hasQuestion) return false;
        }
        
        return true;
      });
    }, [interviewData, selectedField, selectedRole, selectedExperience, selectedQuestion]);
  
    // --------------------------------
    // F) Extract & process data
    // --------------------------------
    /* Functional without question filtering
    useEffect(() => {

        if (!filteredData || filteredData.length === 0) {
            // If no data, reset states
            setThemeEntries([]);
            setKeywordEntries([]);
            //setKeywordCounts({});
            setSentimentCounts({ positive: 0, neutral: 0, negative: 0 });
            setAverageSentimentScore(0);
            setLanguageCounts({});
            setAverageDuration(0);

            // reset states
            setAverageDuration(0);
            setDurationStats({ min: 0, max: 0 });
            // plus any other states
            setDurationsCounts({});
            setDurationDistribution({});
            setDurationDistributionMinutes({});

            return;
        }
  
        // 1) Themes
        const newThemeEntries = extractThemes(
            filteredData,
            DEFAULT_THEME_STOP_WORDS
        );
        setThemeEntries(newThemeEntries);
    
        // 2) Keywords & Sentiments
        const { keywordCounts, sentimentCounts } = analyzeKeywordsAndSentiments(filteredData);
        //setKeywordCounts(keywordCounts);
        setSentimentCounts(sentimentCounts);
    
        // Sort keywords
        const sortedKeywordArr = Object.entries(keywordCounts).sort((a, b) => b[1] - a[1]);
        setKeywordEntries(sortedKeywordArr);

        // 3) Contextual Tags
        const contextualTags = extractContextualTags(filteredData);

        // Sort by descending count
        const sortedArrayDesc = Object.entries(contextualTags).sort((a, b) => b[1] - a[1]);

        // Or sort by alphabetical key
        //const sortedArrayAlpha = Object.entries(contextualTags).sort((a, b) => a[0].localeCompare(b[0]));

        const sortedObjDesc = Object.fromEntries(sortedArrayDesc);
        //const sortedObjAlpha = Object.fromEntries(sortedArrayAlpha);

        setContextualTags(sortedObjDesc);

        // 4) Field Tags
        const fieldTags = extractFieldTags(filteredData);

        // Sort by descending count
        const sortedFieldArrayDesc = Object.entries(fieldTags).sort((a, b) => b[1] - a[1]);

        const sortedFieldObjDesc = Object.fromEntries(sortedFieldArrayDesc);

        setFieldTags(sortedFieldObjDesc);
  
        // 3) Average Sentiment Score
        const avgSentScore = calculateAverageSentimentScore(filteredData);
        setAverageSentimentScore(avgSentScore);
    
        // 4) Languages
        const langs = extractOriginalDetectedLanguage(filteredData);
        setLanguageCounts(langs);
    
        // 5) Durations
        const durationCalc = calculateAverageDuration(filteredData);
        setAverageDuration(durationCalc.average);
        setDurationStats(durationCalc);

        // 2) Durcation counts
        const durationCounts = extractDurationCounts(filteredData);
        setDurationsCounts(durationCounts);

        // 2) Build the distribution object
        const distribution = extractDurationDistribution(filteredData);
        setDurationDistribution(distribution);

        // 2) Distribution in minutes
        const distributionMinutes = extractDurationDistributionMinutes(filteredData);
        setDurationDistributionMinutes(distributionMinutes);
  
    }, [filteredData]);
    */

    useEffect(() => {

        if (!filteredData || filteredData.length === 0) {
          // Reset states if no data
          setThemeEntries([]);
          setKeywordEntries([]);
          setSentimentCounts({ positive: 0, neutral: 0, negative: 0 });
          setAverageSentimentScore(0);
          setLanguageCounts({});
          setAverageDuration(0);
          setDurationStats({ min: 0, max: 0 });
          setDurationsCounts({});
          setDurationDistribution({});
          setDurationDistributionMinutes({});
          return;
        }
      
        // 1) Call analyzeByQuestionAcrossInterviews
        const questionAnalysis = analyzeByQuestionAcrossInterviews(
          filteredData,
          selectedRole && selectedRole !== '' ? selectedRole : null,
          selectedField && selectedField !== '' ? selectedField : null,
          selectedQuestion && selectedQuestion !== 'All' ? selectedQuestion : null
        );
      
        // 2) If user picked a single question
        if (selectedQuestion && selectedQuestion !== 'All') {
          // e.g. "q1" or "q2" is the user’s choice
          const singleQ = questionAnalysis[selectedQuestion];
          if (singleQ) {
            // singleQ has the structure:
            // {
            //   themes: {},
            //   keywordCounts: {},
            //   contextualTags: {},
            //   field: {},
            //   sentimentCounts: {positive, neutral, negative},
            //   durations: [ ... in seconds ],
            //   languageCounts: {},
            //   sentimentScores: [...],
            //   averageSentimentScore: '...',
            //   averageDuration: '...',
            //   minDuration: '...',
            //   maxDuration: '...',
            // }
      
            // THEMES
            // singleQ.themes is an object: { "No theme identified": 1, "xyz": 2, ... }
            const sortedThemes = Object.entries(singleQ.themes).sort((a, b) => b[1] - a[1]);
            setThemeEntries(sortedThemes);
      
            // KEYWORDS
            // singleQ.keywordCounts is { "foo": 2, "bar": 5, ... }
            const sortedKeywords = Object.entries(singleQ.keywordCounts).sort((a, b) => b[1] - a[1]);
            setKeywordEntries(sortedKeywords);

            // CONTEXTUAL TAGS
            const sortedContextual = Object.entries(singleQ.contextualTags).sort((a, b) => b[1] - a[1]);
            setContextualTags(Object.fromEntries(sortedContextual));

            // FIELD
            const sortedField = Object.entries(singleQ.field).sort((a, b) => b[1] - a[1]);
            setFieldTags(Object.fromEntries(sortedField));
      
            // SENTIMENT
            setSentimentCounts(singleQ.sentimentCounts || { positive: 0, neutral: 0, negative: 0 });
            setAverageSentimentScore(singleQ.averageSentimentScore || 0);
      
            // LANGUAGE
            setLanguageCounts(singleQ.languageCounts || {});
      
            // DURATIONS

            // 1) Average, min, max
            const durationStats = calcAverageDurationFromSeconds(singleQ.durations);
            setAverageDuration(durationStats.average);
            setDurationStats({
                min: durationStats.min,
                max: durationStats.max
            });

            // 2) Duration count object
            const durCountsObj = extractDurationCountsFromSeconds(singleQ.durations);
            setDurationsCounts(durCountsObj);

            // 3) Distribution in seconds
            const distSec = extractDurationDistributionFromSeconds(singleQ.durations);
            setDurationDistribution(distSec);

            // 4) Distribution in mm:ss
            const distMin = extractDurationDistributionMinutesFromSeconds(singleQ.durations);
            setDurationDistributionMinutes(distMin);
      
          } else {
            // This means no data for that question in questionAnalysis
            // reset states
            setThemeEntries([]);
            setKeywordEntries([]);
            setSentimentCounts({ positive: 0, neutral: 0, negative: 0 });
            setAverageSentimentScore(0);
            setLanguageCounts({});
            setAverageDuration(0);
            setDurationStats({ min: 0, max: 0 });
            setDurationsCounts({});
            setDurationDistribution({});
            setDurationDistributionMinutes({});
          }
        } else {
          // 3) ELSE -> user selected "All" or no specific question
          // Keep your old approach here
          // e.g. extractThemes(...), analyzeKeywordsAndSentiments(...), etc.
      
            // THEMES
            const newThemeEntries = extractThemes(filteredData, DEFAULT_THEME_STOP_WORDS);
            setThemeEntries(newThemeEntries);
        
            // KEYWORDS & Sentiments
            const { keywordCounts, sentimentCounts } = analyzeKeywordsAndSentiments(filteredData);
            setSentimentCounts(sentimentCounts);
            const sortedKeywordArr = Object.entries(keywordCounts).sort((a, b) => b[1] - a[1]);
            setKeywordEntries(sortedKeywordArr);
      
            // Contextual tags
            const contextualTags = extractContextualTags(filteredData);

            // Sort by descending count
            const sortedArrayDesc = Object.entries(contextualTags).sort((a, b) => b[1] - a[1]);

            // Or sort by alphabetical key
            //const sortedArrayAlpha = Object.entries(contextualTags).sort((a, b) => a[0].localeCompare(b[0]));

            const sortedObjDesc = Object.fromEntries(sortedArrayDesc);
            //const sortedObjAlpha = Object.fromEntries(sortedArrayAlpha);

            setContextualTags(sortedObjDesc);

            // Field tag
            const fieldTags = extractFieldTags(filteredData);

            // Sort by descending count
            const sortedFieldArrayDesc = Object.entries(fieldTags).sort((a, b) => b[1] - a[1]);

            const sortedFieldObjDesc = Object.fromEntries(sortedFieldArrayDesc);

            setFieldTags(sortedFieldObjDesc);
      
            // Average sentiment
            const avgSentScore = calculateAverageSentimentScore(filteredData);
            setAverageSentimentScore(avgSentScore);
        
            // Language
            const langs = extractOriginalDetectedLanguage(filteredData);
            setLanguageCounts(langs);
        
            // Durations
            const durationCalc = calculateAverageDuration(filteredData);
            setAverageDuration(durationCalc.average);
            setDurationStats(durationCalc);
        
            const durationCounts = extractDurationCounts(filteredData);
            setDurationsCounts(durationCounts);
        
            const distribution = extractDurationDistribution(filteredData);
            setDurationDistribution(distribution);
        
            const distributionMinutes = extractDurationDistributionMinutes(filteredData);
            setDurationDistributionMinutes(distributionMinutes);
        }
      }, [
        filteredData,
        selectedQuestion,
        selectedRole,
        selectedField,
    ]);      
  
    // --------------------------------
    // G) Displayed / Searched Themes
    // --------------------------------
    // Filter by search
    const searchedThemeEntries = themeEntries.filter(([theme]) => {
        if (!themeSearch) return true;
        return theme.toLowerCase().includes(themeSearch.toLowerCase());
    });

    const displayedThemeEntries = searchedThemeEntries.slice(0, topN);
    const themeObj = Object.fromEntries(displayedThemeEntries);
  
    // --------------------------------
    // H) Displayed / Searched Keywords
    // --------------------------------
    // 1) Filter by search
    const searchedKeywordEntries = keywordEntries.filter(([kw]) => {
      if (!keywordSearch) return true;
      return kw.toLowerCase().includes(keywordSearch.toLowerCase());
    });
  
    // 2) Slice top N
    const displayedKeywordEntries = searchedKeywordEntries.slice(0, topNKeywords);
    const displayedKeywordObj = Object.fromEntries(displayedKeywordEntries);

    // --------------------------------
    // Display Contextual Tags
    // --------------------------------
    // Filter by search
    const searchedContextualTags = Object.entries(contextualTags).filter(([tag]) => {
        if (!contextualTagsSearch) return true;
        return tag.toLowerCase().includes(contextualTagsSearch.toLowerCase());
    });

    const displayedContextualTagsEntries = searchedContextualTags.slice(0, topNContextualTags);
    const displayedContextualTagsObj = Object.fromEntries(displayedContextualTagsEntries);

    // --------------------------------
    // Display Field Tags
    // --------------------------------
    // Filter by search
    const searchedFieldTags = Object.entries(fieldTags).filter(([tag]) => {
        if (!fieldSearch) return true;
        return tag.toLowerCase().includes(fieldSearch.toLowerCase());
    });

    const displayedFieldTagsEntries = searchedFieldTags.slice(0, topNField);
    const displayedFieldTagsObj = Object.fromEntries(displayedFieldTagsEntries);

    const getQuestionLabel = (data, questionKey) => {
        for (const interview of data) {
            for (const questionSet of Object.values(interview.questions || {})) {
                if (questionSet[questionKey]?.question) {
                    return questionSet[questionKey].question;
                }
            }
        }
        return questionKey; // Fallback to key if label is not found
    };
  
    return (
      <div>
        {/* ----------------------------------
          (1) FILTERS for Field, Role, Exp, Question Q1, Q2, ...
        ---------------------------------- */}
        <Row className="mb-3">
            <Col md={4}>
                <Form.Group controlId="fieldSelect">
                <Form.Label>Expert Field</Form.Label>
                <Form.Control
                    as="select"
                    value={selectedField}
                    onChange={(e) => setSelectedField(e.target.value)}
                >
                    <option value="">All Fields</option>
                    {fieldOptions.map((f) => (
                    <option key={f} value={f}>{f}</option>
                    ))}
                </Form.Control>
                </Form.Group>
            </Col>
    
            <Col md={4}>
                <Form.Group controlId="roleSelect">
                <Form.Label>Role</Form.Label>
                <Form.Control
                    as="select"
                    value={selectedRole}
                    onChange={(e) => setSelectedRole(e.target.value)}
                >
                    <option value="">All Roles</option>
                    {roleOptions.map((r) => (
                    <option key={r} value={r}>{r}</option>
                    ))}
                </Form.Control>
                </Form.Group>
            </Col>
    
            <Col md={2}>
                <Form.Group controlId="experienceSelect">
                    <Form.Label>Experience (Years)</Form.Label>
                    <Form.Control
                        as="select"
                        value={selectedExperience}
                        onChange={(e) => setSelectedExperience(e.target.value)}
                    >
                        <option value="">All</option>
                        {experienceOptions.map((exp) => (
                            <option key={exp} value={exp}>{exp}</option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </Col>

            <Col md={2}>
                <Form.Group controlId="questionSelect">
                    <Form.Label>Question</Form.Label>
                    <Form.Control
                        as="select"
                        value={selectedQuestion}
                        onChange={(e) => setSelectedQuestion(e.target.value)}
                    >
                        <option value="">All</option>
                        {questionOptions.map((q) => (
                            <option key={q} value={q}>{q}</option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </Col>

            <Row style={{marginTop:'15px'}}>
            {/* Display Selected Question Analysis */}
            {selectedQuestion && selectedQuestion !== 'All' ? (
                <>
                    <h5>Question: {getQuestionLabel(interviewData, selectedQuestion)}</h5>
                    <h6 style={{ color:'blue'}}>
                        <b>
                        The participant(s) `{selectedRole}` in the 
                        sample with expert field `{selectedField}`
                        for question `{selectedQuestion}` provide the following insights:
                        </b>
                    </h6>
                </>
            ) : (
                <p>All questions are considered in the analysis.</p>
            )
            }
            </Row>

        </Row>

        <hr />
  
        {/* ----------------------------------
          (2) THEME CHART
        ---------------------------------- */}
        {/* Top N Themes */}
        <Row className="mb-3">
          <Col md={3}>
            <Form.Label>Top N Themes:</Form.Label>
            <Form.Control
              type="number"
              min={1}
              value={topN}
              onChange={(e) => setTopN(Number(e.target.value))}
            />
          </Col>
          <Col md={3}>
            <Form.Label>Search Keywords:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Type to filter..."
              value={themeSearch}
              onChange={(e) => setThemeSearch(e.target.value)}
            />
          </Col>
          <Col md={6}>
            <Form.Label>Theme Chart Type</Form.Label>
            <ButtonGroup className="d-block">
              {Object.keys(chartTypes).map((ct) => (
                <Button
                  key={ct}
                  variant="outline-secondary"
                  onClick={() => setThemeChartType(ct)}
                  active={themeChartType === ct}
                >
                  {ct}
                </Button>
              ))}
            </ButtonGroup>
          </Col>
        </Row>
        {/* Theme Chart */}
        <Row>
          <Col md={12}>
            <Card>
              <Card.Header>Themes (Top {topN})</Card.Header>
              <Card.Body>
                <GenericChart
                  chartType={themeChartType}
                  obj={themeObj}
                  caption={'Theme Frequency'}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
  
        {/* ----------------------------------
          (3) KEYWORDS CHART
        ---------------------------------- */}
        {/* Filter */}
        <Row className="mb-3" style={ { marginTop: '20px' } }>
          <Col md={3}>
            <Form.Label>Top N Keywords:</Form.Label>
            <Form.Control
              type="number"
              min={1}
              value={topNKeywords}
              onChange={(e) => setTopNKeywords(Number(e.target.value))}
            />
          </Col>
  
          <Col md={3}>
            <Form.Label>Search Keywords:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Type to filter..."
              value={keywordSearch}
              onChange={(e) => setKeywordSearch(e.target.value)}
            />
          </Col>
          <Col md={6}>
            <Form.Label>Keyword Chart Type</Form.Label>
            <ButtonGroup className="d-block mb-2">
              {Object.keys(chartTypes).map((ct) => (
                <Button
                  key={ct}
                  variant="outline-secondary"
                  onClick={() => setKeywordChartType(ct)}
                  active={keywordChartType === ct}
                >
                  {ct}
                </Button>
              ))}
            </ButtonGroup>
          </Col>
        </Row>
        {/* Keyword Chart */}
        <Row className="mt-4">
            <Col md={12}>
                <Card>
                    <Card.Header>Keyword Frequency</Card.Header>
                    <Card.Body>
                        <GenericChart
                            chartType={keywordChartType}
                            obj={displayedKeywordObj}
                            caption={'Keyword Frequency'}
                        />
                    </Card.Body>
                </Card>
            </Col>
        </Row>

        {/* ----------------------------------}
            (4) CONTEXTUAL TAGS
        ---------------------------------- */}
        {/* Filter */}
        <Row className="mb-3" style={ { marginTop: '20px' } }>
          <Col md={3}>
            <Form.Label>Top N Contextual Tags:</Form.Label>
            <Form.Control
              type="number"
              min={1}
              value={topNContextualTags}
              onChange={(e) => setTopNContextualTags(Number(e.target.value))}
            />
          </Col>
  
          <Col md={3}>
            <Form.Label>Search Contextual Tags:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Type to filter..."
              value={contextualTagsSearch}
              onChange={(e) => setContextualTagsSearch(e.target.value)}
            />
          </Col>
          <Col md={6}>
            <Form.Label>Contextual Tag Chart Type</Form.Label>
            <ButtonGroup className="d-block mb-2">
              {Object.keys(chartTypes).map((ct) => (
                <Button
                  key={ct}
                  variant="outline-secondary"
                  onClick={() => setContextualTagsChartType(ct)}
                  active={contextualTagsChartType === ct}
                >
                  {ct}
                </Button>
              ))}
            </ButtonGroup>
          </Col>
        </Row>
        {/* Contextual Tag Chart */}
        <Row className="mt-4">
            <Col md={12}>
                <Card>
                    <Card.Header>Contextual Tag Frequency</Card.Header>
                    <Card.Body>
                        <GenericChart
                            chartType={contextualTagsChartType}
                            obj={displayedContextualTagsObj}
                            caption={'Contextual Tag Frequency'}
                        />
                    </Card.Body>
                </Card>
            </Col>
        </Row>

        {/* ----------------------------------}
            (5) FIELD TAGS
        ---------------------------------- */}
        {/* Filter */}
        <Row className="mb-3" style={ { marginTop: '20px' } }>
            <Col md={3}>
                <Form.Label>Top N Field Tags:</Form.Label>
                <Form.Control
                    type="number"   
                    min={1}
                    value={topNField}
                    onChange={(e) => setTopNField(Number(e.target.value))}
                />
            </Col>
            
            <Col md={3}>
                <Form.Label>Search Field Tags:</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Type to filter..."
                    value={fieldSearch}
                    onChange={(e) => setFieldSearch(e.target.value)}
                />
            </Col>
            <Col md={6}>
                <Form.Label>Field Tag Chart Type</Form.Label>
                <ButtonGroup className="d-block mb-2">
                    {Object.keys(chartTypes).map((ct) => (
                        <Button
                            key={ct}
                            variant="outline-secondary"
                            onClick={() => setFieldChartType(ct)}
                            active={fieldChartType === ct}
                        >
                            {ct}
                        </Button>
                    ))}
                </ButtonGroup>
            </Col>
        </Row>
        {/* Field Tag Chart */}
        <Row className="mt-4">
            <Col md={12}>
                <Card>
                    <Card.Header>Field Tag Frequency</Card.Header>
                    <Card.Body>
                        <GenericChart
                            chartType={fieldChartType} 
                            obj={displayedFieldTagsObj}
                            caption={'Field Tag Frequency'}
                        />
                    </Card.Body>
                </Card>
            </Col>
        </Row>

        {/* ----------------------------------
          (4) SENTIMENT
        ---------------------------------- */}
        <Row className="mt-4">
            {/* Sentiment */}
            <Col md={6}>
                <Form.Label>Sentiment Chart Type</Form.Label>
                <ButtonGroup className="d-block mb-2">
                {Object.keys(chartTypes).map((ct) => (
                    <Button
                    key={ct}
                    variant="outline-secondary"
                    onClick={() => setSentimentChartType(ct)}
                    active={sentimentChartType === ct}
                    >
                    {ct}
                    </Button>
                ))}
                </ButtonGroup>
    
                <Card>
                    <Card.Header>
                        Sentiment Analysis (Average Score: {averageSentimentScore})
                    </Card.Header>
                    <Card.Body>
                        <GenericChart
                            chartType={sentimentChartType}  
                            obj={sentimentCounts}
                            caption={'Sentiment Frequency'}
                        />
                    </Card.Body>
                </Card>
            </Col>
    
            {/* Language */}
            <Col md={6}>
                <Form.Label>Language Chart Type</Form.Label>
                <ButtonGroup className="d-block mb-2">
                {Object.keys(chartTypes).map((ct) => (
                    <Button
                    key={ct}
                    variant="outline-secondary"
                    onClick={() => setLanguageChartType(ct)}
                    active={languageChartType === ct}
                    >
                    {ct}
                    </Button>
                ))}
                </ButtonGroup>
    
                <Card>
                <Card.Header>Original Detected Language</Card.Header>
                    <Card.Body>
                        <GenericChart
                            chartType={languageChartType}
                            obj={languageCounts}
                            caption={'Language Frequency'}
                        />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
  
        {/* ----------------------------------
          (5) AUDIO DURATION
        ---------------------------------- */}
        <Row className="mt-4">
            {/* Distribution chart */}
            <Col md={12}>
                <Form.Label>Duration Distribution Chart Type: Avg: {averageDuration}, Min: {durationStats.min}, Max: {durationStats.max}</Form.Label>
                {/* Switch between duration count, distributionin sec and min */}
                <Row>
                    <Col xs="auto"> 
                    <ButtonGroup className="d-block mb-2">
                        {Object.keys(chartTypes).map((ct) => (
                            <Button
                            key={ct}
                            variant="outline-secondary"
                            onClick={() => setDurationChartType(ct)}
                            active={durationChartType === ct}
                            >
                            {ct}
                            </Button>
                        ))}
                    </ButtonGroup>
                    </Col>
                    <Col xs="auto" align='right'> 
                        {/* Switch between duration count, distributionin sec and min */}
                        <ButtonGroup className="d-block mb-2">
                            <Button
                                variant="outline-secondary"
                                onClick={() => setSelectDurationView('count')}
                                active={selectDurationView === 'count'}
                            >
                                Duration Count
                            </Button>
                            <Button
                                variant="outline-secondary"
                                onClick={() => setSelectDurationView('distributionSec')}
                                active={selectDurationView === 'distributionSec'}
                            >
                                Duration (sec)
                            </Button>
                            <Button
                                variant="outline-secondary"
                                onClick={() => setSelectDurationView('distributionMin')}
                                active={selectDurationView === 'distributionMin'}
                            >
                                Duration (min)
                            </Button>
                        </ButtonGroup>
                    </Col> 
                </Row>
                {/* Chart */}
                <Card>
                    {selectDurationView === 'count' && (
                        <>
                        <Card.Header>Audio Duration Count </Card.Header>
                        <Card.Body>
                            <GenericChart
                            chartType={durationChartType}
                            obj={durationsCounts}
                            caption={'# of Responses by Duration (sec)'}
                            />
                        </Card.Body>
                        </>
                    )}
                    {selectDurationView === 'distributionSec' && (
                        <>
                        <Card.Header>Audio Duration Distribution (sec)</Card.Header>
                        <Card.Body>
                            <GenericChart
                            chartType={durationChartType}
                            obj={durationDistribution} 
                            caption={'# of Responses by Duration Range (sec)'}
                            />
                        </Card.Body>
                        </>
                    )}
                    {selectDurationView === 'distributionMin' && (
                        <>
                        <Card.Header>Audio Duration Distribution (min)</Card.Header>
                        <Card.Body>
                            <GenericChart
                            chartType={durationChartType}
                            obj={durationDistributionMinutes}
                            caption={'# of Responses by Duration Range (min)'}
                            />
                        </Card.Body>
                        </>
                    )}
                </Card>
            </Col>
        </Row>

      </div>
    );
};
  
// Generic Chart component
/*
const GenericChart = ({ chartType, obj, caption }) => {

    if (!obj || Object.keys(obj).length === 0) {
      return <div>No data</div>;
    }

    const ChartCmp = chartTypes[chartType] || Bar;
  
    const data = {
      labels: Object.keys(obj),
      datasets: [
        {
          label: caption,
          data: Object.values(obj),
          backgroundColor: "rgb(26, 188, 156)",  // Turquoise 'rgba(75, 192, 192, 0.6)',
          borderColor: "rgb(46, 204, 113)",  // Emerald 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    };
  
    return (
      <div style={{ height: '400px' }}>
        {chartType !== 'Table' ? (
            <ChartCmp
                data={data}
                options={{ maintainAspectRatio: false }}
                height={300}
            />
        ) : (
            <TableFrequency object={obj} header={caption} />
        )}
      </div>
    );
};
*/

const GenericChart = ({ chartType, obj, caption }) => {
    // 1) Always create your ref unconditionally:
    const chartRef = useRef(null);
  
    // 2) If there's no data, return early
    if (!obj || Object.keys(obj).length === 0) {
      return <div>No data</div>;
    }
  
    // 3) Decide which chart component to render
    const ChartCmp = chartTypes[chartType] || Bar;
  
    // The # of data points
    const count = Object.keys(obj).length;
    // Retrieve color arrays sized for however many data points we have
    const { backgrounds, borders } = getBlueGreenColors(count);

    const data = {
      labels: Object.keys(obj),
      datasets: [
        {
          label: caption,
          data: Object.values(obj),
          backgroundColor: backgrounds, // 'rgb(26, 188, 156)', 
          borderColor: borders, // 'rgb(46, 204, 113)',
          borderWidth: 1,
        },
      ],
    };
  
    const options = {
      // If you want higher resolution image exports:
      // This forces the canvas to render at 2x DPI
      //devicePixelRatio: 2,
      devicePixelRatio: 4,
      maintainAspectRatio: false,
      // other chart.js options...
    };
  
    /**
     * Called when user clicks "Download PNG"
     */
    const handleDownloadPNG = () => {
      // (A) See if we have a chart ref
      if (!chartRef.current) {
        console.log('No chartRef.current');
        return;
      }
      // (B) In react-chartjs-2 v4, the chart instance is the ref itself
      const chartInstance = chartRef.current;
      if (!chartInstance.toBase64Image) {
        console.log('Chart instance found, but no toBase64Image()');
        return;
      }
  
      // (C) Convert chart to base64
      const base64Image = chartInstance.toBase64Image();
      // (D) Download it
      const link = document.createElement('a');
      link.download = caption +  '.png';
      link.href = base64Image;
      link.click();
    };
  
    return (
      <div style={{ height: '400px' }}>
        {chartType !== 'Table' ? (
          <div>
            {/* 
              (4) The "ref" is assigned directly to the chart component. 
              react-chartjs-2 v4 sets ref.current to the Chart.js instance.
            */}
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Download Image</Tooltip>}
            >
            <Button
                align='right'
                variant='outline-secondary'
                onClick={handleDownloadPNG}
            >
                <i className="bi bi-card-image"></i>
            </Button>
            </OverlayTrigger>
            <div>
            <ChartCmp 
              ref={chartRef}
              data={data} 
              options={options}
              height={300}
            />
            </div>
          </div>
        ) : (
            <TableFrequency object={obj} header={caption} />
        )}
      </div>
    );
};
  
// Helper functions for data analysis
  
/**
 *  THEMES Extraction:
 *    - Splits comma-separated themes
 *    - Removes punctuation
 *    - Excludes stop words
 *    - Returns sorted [ [theme, count], ... ]
 */
function extractThemes(data, stopWords) {
    const themeCounter = {};
  
    data.forEach((interview) => {
      const questions = interview?.questions || {};
      Object.values(questions).forEach((questionSet) => {
        Object.values(questionSet || {}).forEach((question) => {
          // Possibly "UX, Safety"
          const rawTheme = question?.theme || '';
          const parts = rawTheme.split(',')
            .map((p) => p.trim())
            .filter((p) => p !== '');
  
          parts.forEach((part) => {
            const normalized = normalizeTheme(part);
            if (!normalized) return; // skip empty
            if (stopWords.includes(normalized)) return; // skip stop word
            // increment
            themeCounter[normalized] = (themeCounter[normalized] || 0) + 1;
          });
        });
      });
    });
  
    // Convert to array & sort desc
    return Object.entries(themeCounter).sort((a, b) => b[1] - a[1]);
}
  
/**
 *  KEYWORDS & SENTIMENT
 */
function analyzeKeywordsAndSentiments(data) {
    const keywordFreq = {};
    const sentimentFreq = { positive: 0, neutral: 0, negative: 0 };
  
    data.forEach((interview) => {
      const questions = interview?.questions || {};
      Object.values(questions).forEach((questionSet) => {
        Object.values(questionSet || {}).forEach((question) => {
          // Keywords
          let kws = [];
          if (typeof question?.keywords === 'string') {
            kws = question.keywords
              .split(',')
              .map((kw) => kw.trim())
              .filter((kw) => kw !== '');
          } else if (Array.isArray(question?.keywords)) {
            kws = question.keywords.map((kw) => kw.trim());
          }
  
          kws.forEach((raw) => {
            const norm = normalizeKeyword(raw);
            if (!norm) return;
            keywordFreq[norm] = (keywordFreq[norm] || 0) + 1;
          });
  
          // Sentiment
          const sentiment = question?.sentiment
            ? question.sentiment.toLowerCase()
            : 'neutral';
          if (sentimentFreq[sentiment] !== undefined) {
            sentimentFreq[sentiment] += 1;
          } else {
            sentimentFreq['neutral'] += 1;
          }
        });
      });
    });
  
    return { keywordCounts: keywordFreq, sentimentCounts: sentimentFreq };
}

/**
 * Extract contextual tags from the interview data
 */
function extractContextualTags(data) {

    const contextualTags = {};

    data.forEach((interview) => {
        const questions = interview?.questions || {};
        Object.values(questions).forEach((questionSet) => {
            Object.values(questionSet || {}).forEach((question) => {
                const tags = question?.contextual_tags || [];
                tags.forEach((tag) => {
                    contextualTags[tag] = (contextualTags[tag] || 0) + 1;
                });
            });
        });
    });

    return contextualTags;
}

/**
 * Extract field (tags) from the interview data
 */
function extractFieldTags(data) {

    const fieldTags = {};

    data.forEach((interview) => {
        const questions = interview?.questions || {};
        Object.values(questions).forEach((questionSet) => {
            Object.values(questionSet || {}).forEach((question) => {
                const tags = question?.field || [];
                tags.forEach((tag) => {
                    fieldTags[tag] = (fieldTags[tag] || 0) + 1;
                });
            });
        });
    });

    return fieldTags;

}
  
/**
 *  AVERAGE SENTIMENT SCORE
 */
function calculateAverageSentimentScore(data) {
    let totalScore = 0;
    let count = 0;
  
    data.forEach((interview) => {
      const questions = interview?.questions || {};
      Object.values(questions).forEach((questionSet) => {
        Object.values(questionSet || {}).forEach((question) => {
          if (typeof question?.sentiment_score === 'number') {
            totalScore += question.sentiment_score;
            count++;
          }
        });
      });
    });
  
    if (count === 0) return 0;
    return parseFloat((totalScore / count).toFixed(2));
}
  
/**
 *  LANGUAGE
 */
function extractOriginalDetectedLanguage(data) {
    const languages = {};
    data.forEach((interview) => {
      const questions = interview?.questions || {};
      Object.values(questions).forEach((questionSet) => {
        Object.values(questionSet || {}).forEach((question) => {
          const lang = question?.audio_detected_original_language || 'Unknown';
          languages[lang] = (languages[lang] || 0) + 1;
        });
      });
    });
    return languages;
}

/**
 * extractDurationCounts
 * ---------------------
 * Returns an object where each key is an integer "totalSec",
 * and each value is how many question-responses had exactly that duration.
 *
 * e.g. { 30: 2, 45: 1, 75: 3 }
 * means:
 *   - 2 question responses had a duration of 30 seconds
 *   - 1 question response had a duration of 45 seconds
 *   - 3 question responses had a duration of 75 seconds
 */
function extractDurationCounts(data) {
    const durationCounts = {};
  
    data.forEach((interview) => {
      const questions = interview?.questions || {};
      Object.values(questions).forEach((questionSet) => {
        Object.values(questionSet || {}).forEach((question) => {
          const durStr = question?.audio_duration;
          // Check for "mm:ss" format
          if (durStr && /^\d{2}:\d{2}$/.test(durStr)) {
            const [mm, ss] = durStr.split(':').map(Number);
            const totalSec = mm * 60 + ss;
            if (!isNaN(totalSec)) {
              durationCounts[totalSec] = (durationCounts[totalSec] || 0) + 1;
            }
          }
        });
      });
    });
  
    return durationCounts;
}  

/**
 * Converts all audio durations into numeric seconds,
 * then bins them into ranges like 0-15s, 16-30s, etc.
 * Returns an object, e.g. {
 *   "0-15": 2,
 *   "16-30": 5,
 *   "31-45": 7, ...
 * }
 */
function extractDurationDistribution(data) {
    // 1) Collect all durations in seconds
    const durations = [];
  
    data.forEach((interview) => {
      const questions = interview?.questions || {};
      Object.values(questions).forEach((questionSet) => {
        Object.values(questionSet || {}).forEach((question) => {
          // Parse "mm:ss" into total seconds
          const durStr = question?.audio_duration;
          if (durStr && /^\d{2}:\d{2}$/.test(durStr)) {
            const [mm, ss] = durStr.split(':').map(Number);
            const totalSec = mm * 60 + ss;
            durations.push(totalSec);
          }
        });
      });
    });
  
    // 2) Define bin edges. Example: [0,15,30,45,60,120,300,600, 999999]
    // Means group durations: 0-15s, 16-30s, 31-45s, 46-60s, 61-120s, 121-300s, 301-600s, 601+ ...
    const binEdges = [0, 15, 30, 45, 60, 120, 300, 600, 999999];
  
    // 3) Initialize bin counts
    const binCounts = Array(binEdges.length - 1).fill(0);
  
    // 4) For each duration, find which bin it belongs in
    durations.forEach((dur) => {
      for (let i = 0; i < binEdges.length - 1; i++) {
        if (dur >= binEdges[i] && dur <= binEdges[i + 1]) {
          binCounts[i]++;
          break;
        }
      }
    });
  
    // 5) Convert binCounts to an object with readable labels
    // e.g. "0-15", "16-30", ...
    const distObj = {};
    for (let i = 0; i < binCounts.length; i++) {
      const lower = binEdges[i] + 1;   // e.g. 16
      const upper = binEdges[i + 1];  // e.g. 30
      // For the first bin, we handle "0-15" specially
      if (i === 0) {
        distObj[`0-${binEdges[1]}`] = binCounts[0];
      } else {
        // check if it's the last bin
        if (upper === 999999) {
            distObj[`${lower}+`] = binCounts[i];
        } else {
            distObj[`${lower}-${upper}`] = binCounts[i];
        }
      }
    }
  
    return distObj;
}

/**
 * Audio Duration in Minutes
 */
/**
 * Convert total seconds -> "mm:ss" (with zero-padding)
 */
function formatSecondsToMMSS(sec) {
    const m = Math.floor(sec / 60);
    const s = sec % 60;
    // zero-pad e.g. "02:07"
    const mm = m.toString().padStart(2, '0');
    const ss = s.toString().padStart(2, '0');
    return `${mm}:${ss}`;
}
  
/**
 * Bins durations by second-based edges, but produces "mm:ss" labels
 */
function extractDurationDistributionMinutes(data) {
    const durations = [];
  
    // 1) Collect all durations in seconds
    data.forEach((interview) => {
      const questions = interview?.questions || {};
      Object.values(questions).forEach((questionSet) => {
        Object.values(questionSet || {}).forEach((question) => {
          const durStr = question?.audio_duration;
          // Expecting "mm:ss" format
          if (durStr && /^\d{2}:\d{2}$/.test(durStr)) {
            const [mm, ss] = durStr.split(':').map(Number);
            const totalSec = mm * 60 + ss;
            durations.push(totalSec);
          }
        });
      });
    });
  
    // 2) Define bin edges in seconds
    // e.g. 0,15,30,45,60,120,300,600 => 0-15s,16-30s,31-45s, etc.
    const binEdges = [0, 15, 30, 45, 60, 120, 300, 600, 999999];
  
    // 3) Initialize bin counts
    const binCounts = Array(binEdges.length - 1).fill(0);
  
    // 4) For each duration, increment the appropriate bin
    durations.forEach((sec) => {
      for (let i = 0; i < binEdges.length - 1; i++) {
        if (sec >= binEdges[i] && sec <= binEdges[i + 1]) {
          binCounts[i]++;
          break;
        }
      }
    });
  
    // 5) Build an object with range labels in "mm:ss"
    const distObj = {};
  
    for (let i = 0; i < binCounts.length; i++) {
      const count = binCounts[i];
      const lowerEdge = binEdges[i];
      const upperEdge = binEdges[i + 1];
  
      // For user-friendly labeling
      const lowerLabel = formatSecondsToMMSS(lowerEdge);
      const upperLabel = upperEdge === 999999
        ? '10:00+'
        : formatSecondsToMMSS(upperEdge);
  
      // e.g. "00:00-00:15" or "00:16-00:30"
      const rangeLabel = i === 0
        ? `${lowerLabel}-${formatSecondsToMMSS(upperEdge)}` // "00:00-00:15"
        : `${formatSecondsToMMSS(lowerEdge + 1)}-${upperLabel}`;
  
      distObj[rangeLabel] = count;
    }
  
    return distObj;
}  
  
/**
 *  AUDIO DURATION
 */
function calculateAverageDuration(data) {

    let totalDuration = 0;
    const durations = [];
  
    data.forEach((interview) => {
      const questions = interview?.questions || {};
      Object.values(questions).forEach((questionSet) => {
        Object.values(questionSet || {}).forEach((question) => {
          if (question?.audio_duration) {
            const parts = question.audio_duration.split(':');
            if (parts.length === 2) {
              const minutes = parseFloat(parts[0]) || 0;
              const seconds = parseFloat(parts[1]) || 0;
              const durSec = minutes * 60 + seconds;
              durations.push(durSec);
              totalDuration += durSec;
            }
          }
        });
      });
    });
  
    if (durations.length === 0) {
      return { average: 0, min: 0, max: 0 };
    }
    const avg = totalDuration / durations.length;
    return {
      average: parseFloat(avg.toFixed(2)),
      min: parseFloat(Math.min(...durations).toFixed(2)),
      max: parseFloat(Math.max(...durations).toFixed(2)),
    };
}

// ---

/**
 * calcAverageDurationFromSeconds
 * ------------------------------
 * durations: array of numbers, e.g. [30, 60, 75, ...]
 * returns { average, min, max } in seconds
 */
function calcAverageDurationFromSeconds(durations) {
    if (!durations.length) {
      return { average: 0, min: 0, max: 0 };
    }
    const total = durations.reduce((acc, val) => acc + val, 0);
    const avg = total / durations.length;
    const minVal = Math.min(...durations);
    const maxVal = Math.max(...durations);
    return {
      average: parseFloat(avg.toFixed(2)),
      min: parseFloat(minVal.toFixed(2)),
      max: parseFloat(maxVal.toFixed(2)),
    };
}
  
/**
 * extractDurationCountsFromSeconds
 * --------------------------------
 * durations: array of seconds, e.g. [30, 30, 45, 75]
 * returns { '30': 2, '45': 1, '75': 1 }
 */
function extractDurationCountsFromSeconds(durations) {
    const durationCounts = {};
    durations.forEach((sec) => {
      durationCounts[sec] = (durationCounts[sec] || 0) + 1;
    });
    return durationCounts;
}
  
/**
 * extractDurationDistributionFromSeconds
 * --------------------------------------
 * Same logic as 'extractDurationDistribution(...)' but operates
 * on an array of seconds, e.g. [30, 60, 75, 120].
 */
function extractDurationDistributionFromSeconds(durations) {
    const binEdges = [0, 15, 30, 45, 60, 120, 300, 600, 999999];
    const binCounts = Array(binEdges.length - 1).fill(0);
  
    durations.forEach((sec) => {
      for (let i = 0; i < binEdges.length - 1; i++) {
        if (sec >= binEdges[i] && sec <= binEdges[i + 1]) {
          binCounts[i]++;
          break;
        }
      }
    });
  
    const distObj = {};
    for (let i = 0; i < binCounts.length; i++) {
      const count = binCounts[i];
      if (i === 0) {
        distObj[`0-${binEdges[1]}`] = count;
      } else {
        if (binEdges[i + 1] === 999999) {
          distObj[`${binEdges[i] + 1}+`] = count;
        } else {
          distObj[`${binEdges[i] + 1}-${binEdges[i + 1]}`] = count;
        }
      }
    }
    return distObj;
}
  
/**
 * extractDurationDistributionMinutesFromSeconds
 * --------------------------------------------
 * Like 'extractDurationDistributionMinutes(...)' but expects an
 * array of raw durations in seconds. Returns an object with "mm:ss" bins.
 */
function extractDurationDistributionMinutesFromSeconds(durations) {

    const binEdges = [0, 15, 30, 45, 60, 120, 300, 600, 999999];
    const binCounts = Array(binEdges.length - 1).fill(0);
  
    durations.forEach((sec) => {
      for (let i = 0; i < binEdges.length - 1; i++) {
        if (sec >= binEdges[i] && sec <= binEdges[i + 1]) {
          binCounts[i]++;
          break;
        }
      }
    });
  
    const distObj = {};
    for (let i = 0; i < binCounts.length; i++) {
      const count = binCounts[i];
      const lowerEdge = binEdges[i];
      const upperEdge = binEdges[i + 1];
  
      if (i === 0) {
        distObj[`00:00-${formatSecondsToMMSS(upperEdge)}`] = count;
      } else {
        if (upperEdge === 999999) {
          distObj[`${formatSecondsToMMSS(lowerEdge + 1)}+`] = count;
        } else {
          distObj[`${formatSecondsToMMSS(lowerEdge + 1)}-${formatSecondsToMMSS(upperEdge)}`] = count;
        }
      }
    }
    return distObj;
}

// --------------------------------

// Helper Table with header, and object data
const TableFrequency = ({ object, header }) => (

    <div style={{ overflowY: 'auto', maxHeight: '400px' }}>
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>{header}</th>
                    <th>Frequency</th>
                </tr>
            </thead>
            <tbody>
                {Object.entries(object).map(([obj, count]) => (
                    <tr key={obj}>
                        <td>{obj}</td>
                        <td>{count}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    </div>
);

// --------------------------------
// Data Analysis by Question
// --------------------------------

/**
 * parseDateTimeToSeconds
 * ----------------------
 * dateTimeStr example: "2025-03-14 15:35:26"
 * returns a UNIX timestamp in seconds, or null if invalid
 */
function parseDateTimeToSeconds(dateTimeStr) {
    if (!dateTimeStr) return null;
  
    // Split date/time
    // e.g. "2025-03-14 15:35:26" => ["2025-03-14", "15:35:26"]
    const [datePart, timePart] = dateTimeStr.split(' ');
    if (!datePart || !timePart) return null;
  
    // datePart => "2025-03-14" => [ "2025", "03", "14" ]
    const [year, month, day] = datePart.split('-').map(Number);
  
    // timePart => "15:35:26" => [ "15", "35", "26" ]
    const [hour, minute, second] = timePart.split(':').map(Number);
  
    // JS Date => note month is zero-based
    const dateObj = new Date(year, (month || 1) - 1, day || 1, hour || 0, minute || 0, second || 0);
    if (isNaN(dateObj.getTime())) {
      return null; // invalid date
    }
  
    // Convert to seconds
    return Math.floor(dateObj.getTime() / 1000);
}

// Conducts analysis per question across all interviews, with filtering by role and expert_field.
const analyzeByQuestionAcrossInterviews = (data, roleFilter, expertFieldFilter, questionKey = null) => {
    const questionAnalysis = {};

    data.forEach(interview => {
        const { role, expert_field } = interview;

        // Apply filters for role and expert_field; only skip if filter is specified and does not match
        if ((roleFilter && role.value !== roleFilter) || (expertFieldFilter && expert_field.value !== expertFieldFilter)) {
            return;
        }

        Object.entries(interview.questions || {}).forEach(([questionSetKey, questionSet]) => {
            Object.entries(questionSet || {}).forEach(([currentQuestionKey, question]) => {
                // If a specific question is selected, skip non-matching questions
                if (questionKey && currentQuestionKey !== questionKey) return;

                // Initialize question analysis if not already present
                if (!questionAnalysis[questionKey || currentQuestionKey]) {
                    questionAnalysis[questionKey || currentQuestionKey] = {
                        themes: {},
                        keywordCounts: {},
                        contextualTags: {},
                        field: {},
                        sentimentCounts: { positive: 0, neutral: 0, negative: 0 },
                        durations: [],
                        languageCounts: {},
                        sentimentScores: [],
                    };
                }

                const analysis = questionAnalysis[questionKey || currentQuestionKey];

                // Aggregate themes
                const theme = question.theme || 'No theme identified';
                analysis.themes[theme] = (analysis.themes[theme] || 0) + 1;

                // Aggregate keywords
                const keywords = typeof question.keywords === 'string' ? question.keywords.split(', ') : [];
                keywords.forEach(keyword => {
                    analysis.keywordCounts[keyword] = (analysis.keywordCounts[keyword] || 0) + 1;
                });

                // CONTEXTUAL TAGS
                const cTags = Array.isArray(question.contextual_tags)
                ? question.contextual_tags
                : typeof question.contextual_tags === 'string'
                    ? question.contextual_tags.split(',').map(t => t.trim()).filter(Boolean)
                    : [];

                cTags.forEach((tag) => {
                analysis.contextualTags[tag] = (analysis.contextualTags[tag] || 0) + 1;
                });

                // FIELD
                const fieldArr = Array.isArray(question.field)
                ? question.field
                : typeof question.field === 'string'
                    ? question.field.split(',').map(t => t.trim()).filter(Boolean)
                    : [];

                fieldArr.forEach((f) => {
                analysis.field[f] = (analysis.field[f] || 0) + 1;
                });

                // Aggregate sentiments
                const sentiment = question.sentiment ? question.sentiment.toLowerCase() : 'neutral';
                analysis.sentimentCounts[sentiment]++;

                // Aggregate sentiment scores
                if (typeof question.sentiment_score === 'number') {
                    analysis.sentimentScores.push(question.sentiment_score);
                }

                // Aggregate language counts
                const language = question.audio_detected_original_language || 'Unknown';
                analysis.languageCounts[language] = (analysis.languageCounts[language] || 0) + 1;

                // Aggregate durations
                if (question.audio_duration && question.audio_duration !== "NaN:NaN") {
                    const [minutes, seconds] = question.audio_duration.split(':').map(Number);
                    const durationInSeconds = minutes * 60 + seconds;
                    analysis.durations.push(durationInSeconds);
                } else {
                    // fallback: parse from audio_time_start and audio_time_end
                    const startSec = parseDateTimeToSeconds(question.audio_time_start);
                    
                    const endSec = parseDateTimeToSeconds(question.audio_time_end);
                    if (startSec !== null && endSec !== null && endSec > startSec) {
                        const durationInSeconds = endSec - startSec;
                        analysis.durations.push(durationInSeconds);
                        //console.log(durationInSeconds)
                    }
                }
            });
        });
    });

    // Calculate averages for each question in questionAnalysis
    Object.entries(questionAnalysis).forEach(([questionKey, analysis]) => {
        analysis.averageSentimentScore = (
            analysis.sentimentScores.reduce((a, b) => a + b, 0) / (analysis.sentimentScores.length || 1)
        ).toFixed(2);
        analysis.averageDuration = (
            analysis.durations.reduce((a, b) => a + b, 0) / (analysis.durations.length || 1)
        ).toFixed(2);
        analysis.minDuration = Math.min(...analysis.durations).toFixed(2);
        analysis.maxDuration = Math.max(...analysis.durations).toFixed(2);
    });

    return questionAnalysis;
};

const InterviewDataDisplay = ({ interviewData }) => {

    const [roleFilter, setRoleFilter] = useState('All');
    const [expertFieldFilter, setExpertFieldFilter] = useState('All');
    const [placeFilter, setPlaceFilter] = useState('All');

    const uniqueRoles = ['All', ...new Set(interviewData.map((interview) => interview.role?.value).filter(Boolean))];
    const uniqueExpertFields = ['All', ...new Set(interviewData.map((interview) => interview.expert_field?.value).filter(Boolean))];
    const uniquePlaces = ['All', ...new Set(interviewData.map((interview) => interview.place?.value).filter(Boolean))];

    const handleFilterChange = (setter) => (eventKey) => {
        setter(eventKey);
    };

    const filteredData = interviewData.filter(interview => 
        (roleFilter === 'All' || interview.role?.value === roleFilter) &&
        (expertFieldFilter === 'All' || interview.expert_field?.value === expertFieldFilter) &&
        (placeFilter === 'All' || interview.place?.value === placeFilter)
    );

    return (
        <div>            
            {/* Filter Controls */}
            <Row className="mb-4">
                <Col xs="auto">
                    <DropdownButton title={`Expert Field: ${expertFieldFilter}`} onSelect={handleFilterChange(setExpertFieldFilter)} variant="outline-secondary">
                        {uniqueExpertFields.map((field) => (
                            <Dropdown.Item key={field} eventKey={field}>{field}</Dropdown.Item>
                        ))}
                    </DropdownButton>
                </Col>
                <Col xs="auto">
                    <DropdownButton title={`Role: ${roleFilter}`} onSelect={handleFilterChange(setRoleFilter)} variant="outline-secondary">
                        {uniqueRoles.map((role) => (
                            <Dropdown.Item key={role} eventKey={role}>{role}</Dropdown.Item>
                        ))}
                    </DropdownButton>
                </Col>
                <Col xs="auto">
                    <DropdownButton title={`Place: ${placeFilter}`} onSelect={handleFilterChange(setPlaceFilter)} variant="outline-secondary">
                        {uniquePlaces.map((place) => (
                            <Dropdown.Item key={place} eventKey={place}>{place}</Dropdown.Item>
                        ))}
                    </DropdownButton>
                </Col>
            </Row>

            <Accordion> {/* defaultActiveKey="0" */}
                {filteredData.map((interview, index) => (
                    <Accordion.Item key={index} eventKey={`${index}`}>
                        <Accordion.Header>
                            <span>
                                <b>Participant:</b> {interview.place?.anonymous_id || 'No ID available'}
                            </span>
                            <span className="small text-muted ms-3">
                                Role: {interview.role?.value || 'No role available'} | 
                                Expert field: {interview.expert_field?.value || 'No field available'} | 
                                Experience: {(interview.experience?.value + ' years') || 'No experience available'}
                            </span>
                        </Accordion.Header>
                        
                        <Accordion.Body>
                            <p className="small mb-3">
                                Place: {interview.place?.value || 'No place available'} |
                                Mode: {interview.mode?.value || 'No mode available'}
                            </p>

                            {/* Show table or question cards */}


                            <RecordedAudioTable interviewData={interview} />

                            {/*
                            <h3 className="mt-4">Interview Questions</h3>
                            
                            {Object.entries(interview.questions || {}).map(([questionSetKey, questions]) => (
                                <div key={questionSetKey} className="mb-4">
                                    <h5 className="font-weight-bold mt-4 mb-2">
                                        Set: {questionSetKey.replace(/_/g, ' ')}
                                    </h5>
                                    
                                    <Row>
                                        {Object.entries(questions || {}).map(([qKey, question]) => (
                                            <Col md={4} key={qKey} style={{ marginBottom: '15px' }}>
                                                <Card className="h-100">
                                                    <Card.Header>{question.question || 'No question available'}</Card.Header>
                                                    <Card.Body>
                                                        <p className="small mb-2"><b>Summary:</b> {question.summary || 'No summary available'}</p>
                                                        <p className="small mb-2"><b>Theme:</b> {question.theme || 'No theme identified'}</p>
                                                        <p className="small mb-2"><b>Keywords:</b> {question.keywords || 'No keyword data'}</p>
                                                        <p className="small mb-2"><b>Sentiment:</b> {question.sentiment || 'No sentiment data'}</p>
                                                        <p className="small"><b>Key Findings:</b></p>
                                                        <ul className="small">
                                                            {question.findings && question.findings.length > 0 ? (
                                                                question.findings.map((finding, idx) => (
                                                                    <li key={idx}>{finding}</li>
                                                                ))
                                                            ) : (
                                                                <li>No findings available</li>
                                                            )}
                                                        </ul>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            ))}
                            */}
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </div>
    );
};

const LongResponsePopover = ({ summary, answer }) => {
    const [show, setShow] = useState(false);
    //const [target, setTarget] = useState(null);

    const handleClick = (event) => {
        setShow(!show);
        //setTarget(event.target);
    };

    return (
        <>
            <OverlayTrigger
                show={show}
                placement="top"
                overlay={
                    <Popover id={`popover-${summary}`} style={{ maxWidth: '800px' }}>
                        <Popover.Header as="h1">
                            Full Response
                            <Button 
                                variant="outline-danger" 
                                size="sm" 
                                style={{ float: 'right', border:'0px' }} 
                                onClick={() => setShow(false)}
                            >
                                ✕
                            </Button>
                        </Popover.Header>
                        <Popover.Body style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                            {answer}
                        </Popover.Body>
                    </Popover>
                }
            >
                <Button 
                    variant="link" 
                    onClick={handleClick} 
                    style={{ textDecoration: 'underline', color: 'black', textAlign:'left' }}
                >
                    {summary}
                </Button>
            </OverlayTrigger>
        </>
    );
};

/**
 * QuestionsAccordionView
 * ----------------------
 * This component loops over *all* question keys found in `interviewData`.
 * For each question key, it creates an Accordion.Item with:
 *  - The question label as the header
 *  - A table of responses (one row per participant's answer) in the body.
 *
 * Requirements / Setup:
 * - Pass in already-filtered data (by role, field, etc.) if needed.
 * - The code looks for the question text at:
 *      interview.questions[questionSetKey][questionKey].question
 * - Also displays themes, keywords, sentiment, etc.
 */
function QuestionsAccordionView({ interviewData }) {
    // -----------------------------------------------------------------
    // A) Build structure: questionMap
    // -----------------------------------------------------------------
    const questionMap = useMemo(() => {
      const map = {};
  
      interviewData.forEach((interview) => {
        const role = interview?.role?.value || 'No role';
        const field = interview?.expert_field?.value || 'No field';
        const exp = interview?.experience?.value || 'No experience';
  
        const questions = interview?.questions || {};
        Object.entries(questions).forEach(([questionSetKey, questionSetObj]) => {
          if (!questionSetObj) return;
          Object.entries(questionSetObj).forEach(([qKey, qData]) => {
            const questionLabel = qData.question || qKey;
            if (!map[qKey]) {
              map[qKey] = {
                label: questionLabel,
                data: [],
              };
            }
            map[qKey].data.push({
              interview,
              questionData: qData,
              questionSetKey,
              role,
              field,
              exp,
            });
          });
        });
      });
  
      return map;
    }, [interviewData]);
  
    // -----------------------------------------------------------------
    // B) State for the "details" modal
    // -----------------------------------------------------------------
    const [showModal, setShowModal] = useState(false);
    const [modalQuestion, setModalQuestion] = useState(null); // the data for the currently open row
  
    const handleOpenModal = (questionData, interview) => {
      setModalQuestion({ questionData, interview });
      setShowModal(true);
    };
  
    const handleCloseModal = () => {
      setShowModal(false);
      setModalQuestion(null);
    };
  
    // sort the question keys to have a stable order
    const questionKeys = Object.keys(questionMap).sort();
  
    return (
      <>
        <Accordion defaultActiveKey="">
          {questionKeys.map((qKey) => {
            const { label, data } = questionMap[qKey];
  
            return (
              <Accordion.Item eventKey={qKey} key={qKey}>
                <Accordion.Header>
                    <b style={{ marginRight:'15px'}}> {qKey.toUpperCase()}</b> {label}
                </Accordion.Header>
  
                <Accordion.Body>
                  <Table bordered hover responsive className="small">
                    <thead>
                      <tr>
                        <th>Participant</th>
                        <th>Role</th>
                        <th>Expert Field</th>
                        <th>Experience (yrs)</th>
                        <th>Answer Summary</th>
                        <th>Themes</th>
                        <th>Keywords</th>
                        <th>Sentiment</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map(({ interview, questionData, role, field, exp }, idx) => {
                        const participantID = interview?.anonymous_id?.value || interview?.id || 'No ID';
                        const place = interview?.place?.value || 'No place';
                        const answer = questionData?.audio_transcription || 'No answer';
                        const summary = questionData?.summary || 'No summary';
                        const themes = questionData?.theme || 'No theme';
                        const kws = questionData?.keywords || 'No keywords';
                        const sentiment = questionData?.sentiment || 'No sentiment';
  
                        return (
                          <tr key={idx}>
                            <td>
                              {participantID} <br />
                              <small style={{ color: '#666' }}>({place})</small>
                            </td>
                            <td>{role}</td>
                            <td>{field}</td>
                            <td>{exp}</td>
                            <td>
                              {/* Example: still using your popover for summary vs. full text */}
                              <LongResponsePopover summary={summary} answer={answer} />
                            </td>
                            <td>{themes}</td>
                            <td>{kws}</td>
                            <td>{sentiment}</td>
  
                            {/* "Details" button to open a modal with full info */}
                            <td>
                              <Button
                                variant="outline-primary"
                                size="sm"
                                onClick={() => handleOpenModal(questionData, interview)}
                              >
                                Details
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
  
        {/* -----------------------------------------------------------------
            C) The "Details" Modal
           ----------------------------------------------------------------- */}
        <DetailedAnswerModal
          show={showModal}
          onClose={handleCloseModal}
          data={modalQuestion}
        />
      </>
    );
}
  
/* -------------------------------------------------------------------------
    2) The DetailedAnswerModal
    Shows all relevant fields: 
    - Audio info
    - times
    - sentiments
    - answer quality
    - quotes
    - findings
    - ...
------------------------------------------------------------------------- */
function DetailedAnswerModal({ show, onClose, data }) {

    if (!data) return null; // no data, no modal
    const { questionData, interview } = data;
  
    const participantID = interview?.anonymous_id?.value || interview?.id || 'No ID';
    const place = interview?.place?.value || 'No place';
  
    // All the question data fields you mentioned:
    const {
      audioData,
      audio_transcription,
      audio_original_text,
      audio_duration,
      audio_time_start,
      audio_time_end,
      audio_file_size,
      audio_file_type,
      sentiment,
      sentiment_score,
      answer_quality,
      main_argument,
      field,
      contextual_tags,
      findings,
      quotes,
      goal,
      question,
      question_de,
      keywords,
      theme,
    } = questionData;
  
    return (
      <Modal show={show} onHide={onClose} size="lg" backdrop="static" scrollable>
        <Modal.Header closeButton>
          <Modal.Title>Answer Details for {participantID}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>Question:</h6>
          <p>{question || 'No question text'}</p>
  
          {question_de && (
            <>
              <h6>Question (DE):</h6>
              <p>{question_de}</p>
            </>
          )}
  
          <div className="mb-3">
            <b>Goal:</b> {goal || 'No goal'}
          </div>
  
          {/* Audio section */}
          <h5>Audio & Time Info</h5>
          {audioData ? (
            <div>
              <audio controls style={{ maxWidth: '100%' }}>
                <source src={audioData} type={audio_file_type || 'audio/mp3'} />
                Your browser does not support the audio element.
              </audio>
              <p className="small text-muted mb-0">
                Duration: {audio_duration || 'Unknown'}<br/>
                Start: {audio_time_start || 'N/A'} | End: {audio_time_end || 'N/A'}<br/>
                File size: {audio_file_size} bytes<br/>
                File type: {audio_file_type}
              </p>
            </div>
          ) : (
            <p>No audio data available</p>
          )}
  
          {/* Answer text & transcription */}
          <h5 className="mt-4">Full Transcription</h5>
          <p>{audio_transcription || 'No transcription available'}</p>
  
          {audio_original_text && (
            <div>
              <h6>Original Text:</h6>
              <p>{audio_original_text}</p>
            </div>
          )}
  
          {/* Sentiment, answer quality */}
          <div className="mt-3">
            <b>Sentiment:</b> {sentiment || 'N/A'} (Score: {sentiment_score ?? 'N/A'})<br/><br/>
            <b>Answer Quality:</b> {answer_quality || 'No data'}
          </div>
  
          {/* Theme, Keywords, Field */}
          <div className="mt-3">
            <b>Theme(s):</b> {theme || 'No theme'}<br/><br/>
            <b>Keywords:</b> {keywords || 'No keywords'}<br/><br/>
            <b>Contextual Tags:</b> 
            <ul>
                {contextual_tags && contextual_tags.length > 0 ? (
                    contextual_tags.map((tag, idx) => (
                        <li key={idx}>{tag}</li>
                    ))
                ) : (
                    <li>No contextual tags available</li>
                )}
            </ul>
            <b>Field(s)</b>
            <ul>
                {field && field.length > 0 ? (
                    field.map((f, idx) => (
                        <li key={idx}>{f}</li>
                    ))
                ) : (
                    <li>No field data available</li>
                )}
            </ul>
          </div>
  
          {/* Additional fields */}
          {main_argument && (
            <div className="mt-3">
              <b>Main Argument:</b>
              <p>{main_argument}</p>
            </div>
          )}
  
          {/* Lists */}
          {quotes && Array.isArray(quotes) && quotes.length > 0 && (
            <div className="mt-3">
              <b>Quotes:</b>
              <ul>
                {quotes.map((q, idx) => <li key={idx}>{q}</li>)}
              </ul>
            </div>
          )}
  
          {findings && Array.isArray(findings) && findings.length > 0 && (
            <div className="mt-3">
              <b>Findings:</b>
              <ul>
                {findings.map((f, idx) => <li key={idx}>{f}</li>)}
              </ul>
            </div>
          )}
  
          {/*
          {contextual_tags && Array.isArray(contextual_tags) && contextual_tags.length > 0 && (
            <div className="mt-3">
              <b>Contextual Tags:</b>
              <ul>
                {contextual_tags.map((tag, idx) => <li key={idx}>{tag}</li>)}
              </ul>
            </div>
          )}
          */}
  
          <hr/>
          <p className="small text-muted">
            Participant: {participantID}, from {place}.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
}

export const InterviewAnalysis = ({ interviewData, handleClear }) => {

    const [selectedParticipant, setSelectedParticipant] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);
    const [selectedField, setSelectedField] = useState(null);

    const {
        // General demographics
        expertFieldData,
        roleData, 
        placeData, 
        experienceData, 
        totalParticipants } = aggregateInterviewData(interviewData);

    // Filter data by selected participant or role
    const filteredInterviewData = selectedRole || interviewData;

    // Handle selecting individual survey response
    const handleParticipantSelect = (event) => {
        const selectedId = event.target.value;
        const participantData = interviewData.find((data) => data.anonymous_id.value === selectedId);
        setSelectedParticipant(participantData || null);
    };

    // Handle selecting participants by role
    const handleParticipantsSelectByRole = (event) => {
        const selectedRole = event.target.value;
        const participantData = selectedRole !== ''
            ? interviewData.filter((data) => data.role?.value === selectedRole)
            : interviewData.filter((data) => data.role?.value);
        setSelectedRole(participantData || null);
    };

    // Handle selecting participants by expert field
    const handleParticipantsSelectByField = (event) => {
        const selectedField = event.target.value;
        //console.log('Selected field:', selectedField);
        const participantData = selectedField !== ''
            ? interviewData.filter((data) => data.expert_field?.value === selectedField)
            : interviewData.filter((data) => data.expert_field?.value);
        setSelectedField(participantData || null);
    }

    let totalExperience = 0;
    Object.entries(experienceData).forEach(([years, count]) => {
        totalExperience += years * count;
    });

    return (
        <Row>
            <Col md={12} style={{ textAlign: 'left' }} id="content">
                {/* Title */}
                <Row style={{ marginBottom: '20px', marginTop: '20px' }}>
                    <Col>
                        <span>Interview Analysis</span>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <Button variant="outline-primary" className="btn-sm" onClick={handleClear}>
                            <i className="bi bi-arrow-left"></i> Go back
                        </Button>
                    </Col>
                </Row>

                {/* General Demographics */}
                <Row style={{ marginBottom: '30px' }}>
                    <StatisticsCard targetCount={totalParticipants} caption="Total Participants" />
                    <StatisticsCard targetCount={Object.keys(roleData).length} caption="Roles" />
                    <StatisticsCard targetCount={Object.keys(placeData).length} caption="Locations" />
                    <StatisticsCard targetCount={totalExperience || -1} caption="Years of Experience" />
                </Row>

                {/* Participant and Role Selection */}
                <Row style={{ marginBottom: '30px' }}>
                    {/* Individual Participant or Role Selection */}
                    <Col md={3}>
                        <Card className="h-100">
                            <Card.Header>Participant Selection</Card.Header>
                            <Card.Body>
                                <Form.Group controlId="participantSelect">
                                    {/* Select by Anonymized ID */}
                                    <Form.Label className="small">Select by Anonymized ID:</Form.Label>
                                    <Form.Control as="select" onChange={handleParticipantSelect}>
                                        <option value="">All Participants</option>
                                        {interviewData.map((data, idx) => (
                                            <option key={idx} value={data.anonymous_id?.value}>
                                                {data.anonymous_id?.value || `Participant ${idx + 1}`}
                                            </option>
                                        ))}
                                    </Form.Control>

                                    {/* Select by Expert Field */}
                                    <Form.Label className='small' style={{marginTop:'15px'}}>Select by Expert Field:</Form.Label>
                                    <Form.Control as="select" onChange={handleParticipantsSelectByField}>
                                        <option value="">All Fields</option>
                                        {Object.entries(expertFieldData).map(([field, count]) => (
                                            <option key={field} value={field}>
                                                {field} ({count})
                                            </option>
                                        ))}
                                    </Form.Control>

                                    {/* Select by Role */}
                                    <Form.Label className="small" style={{ marginTop: '15px' }}>Select by Role:</Form.Label>
                                    <Form.Control as="select" onChange={handleParticipantsSelectByRole}>
                                        <option value="">All Roles</option>
                                        {Object.entries(roleData || {}).map(([role, count]) => (
                                            <option key={role} value={role}>
                                                {role} ({count})
                                            </option>
                                        ))}
                                    </Form.Control>

                                </Form.Group>

                            </Card.Body>
                        </Card>
                    </Col>

                    {/* Interview Data Overview */}
                    <Col md={6}>
                        <Card className="h-100">
                            <Card.Header>Interview Data</Card.Header>
                            <Card.Body>
                                <p className="small">
                                    This analysis includes {totalParticipants} participants, each contributing insights on:
                                </p>
                                <Table bordered className="small">
                                    <tbody>
                                        <tr><th>#1</th><th>Theme</th><td>Overall aspects embodied by the answer</td></tr>
                                        <tr><th>#2</th><th>Keywords</th><td>Keywords and phrases used in the response</td></tr>
                                        <tr><th>#3</th><th>Summary</th><td>Summary of the given response</td></tr>
                                        <tr><th>#4</th><th>Sentiment</th><td>Positive, Neutral, Negative classifications</td></tr>
                                        <tr><th>#5</th><th>Duration</th><td>Duration of the audio response</td></tr>
                                        <tr><th>#6</th><th>Language</th><td>Detected language of the audio response</td></tr>
                                        <tr><th>#7</th><th>Key findings</th><td>List of findings in the response</td></tr>
                                        <tr><th>#8</th><th>Argument</th><td>Positive, Neutral, Negative classifications</td></tr>
                                        <tr><th>#9</th><th>Quotes</th><td>Positive, Neutral, Negative classifications</td></tr>
                                        <tr><th>#10</th><th>Relevance</th><td>Positive, Neutral, Negative classifications</td></tr>
                                        <tr><th>#11</th><th>Meta</th><td>i.e. original transcript and target transcript</td></tr>
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* Context and Disclaimer */}
                    <Col md={3}>
                        <Card
                            className="h-100"
                            style={{
                                backgroundColor: '#f1f8e9',
                                border: '1px solid #81c784',
                                color: '#2e7d32',
                            }}
                        >
                            <Card.Header style={{ fontWeight: 'bold', fontSize: '1.1rem', color: '#1b5e20', backgroundColor: '#a5d6a7' }}>
                                Interview Context
                            </Card.Header>
                            <Card.Body>
                                <p className="small" style={{ marginBottom: 0 }}>
                                    This interview is conducted as part of PhD research, aiming to propose a PEAM model.
                                    The acquired data is analyzed for key themes, sentiment, and insights to understand AMS.
                                    <br /><br />
                                    All participants are experts in fields such as engineering, urban planning, administration, computing, and AI,
                                    each approaching autonomous mobility and transportation from unique roles and experiences.
                                    <br /><br />
                                    The data is anonymized and used solely for research purposes.
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                {/* Data Analysis */}
                <Row>
                    <h4 style={{ fontWeight: 'bold', marginTop:'60px', marginBottom:'60px'}}>
                        Overarching Interview Data Analysis by Question and/or across all Participants
                    </h4>
                    <DataAnalysis interviewData={filteredInterviewData} />
                </Row>

                <hr  style={{ marginTop:'60px'}}/>

                {/* Data Display by Question Set */}
                <Row>
                    <h4 style={{ fontWeight: 'bold', marginTop:'60px', marginBottom:'60px'}}>
                        Interview Data Display by Question Set and Participant
                    </h4>
                    <QuestionsAccordionView interviewData={interviewData} />
                </Row>

                {/* Data Display by Question Set */}
                <Row>
                    <h4 style={{ fontWeight: 'bold', marginTop:'60px', marginBottom:'60px'}}>
                        Interview Data Display by Participant and Place
                    </h4>
                    <InterviewDataDisplay interviewData={filteredInterviewData} />
                </Row>

                {/* Individual Participant's Detailed Responses in Modal */}
                <Modal show={!!selectedParticipant} onHide={() => setSelectedParticipant(null)} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Participant's Responses</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedParticipant && (
                            <Table bordered>
                                <tbody className='small'>
                                    <tr><th>Anonymous ID</th><td>{selectedParticipant.anonymous_id?.value}</td></tr>
                                    <tr><th>Role</th><td>{selectedParticipant.role?.value || 'N/A'}</td></tr>
                                    <tr><th>Expert Field</th><td>{selectedParticipant.expert_field?.value || 'N/A'}</td></tr>
                                    <tr><th>Experience</th><td>{selectedParticipant.experience?.value || 'N/A'}</td></tr>      
                                    <tr><th>Place</th><td>{selectedParticipant.place?.value || 'N/A'}</td></tr>
                                    <tr><th>Year of Birth</th><td>{selectedParticipant.yearOfBirth?.value || 'N/A'}</td></tr>                              
                                </tbody>
                            </Table>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setSelectedParticipant(null)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Participants by Role / Expert Modal */}
                <Modal
                    show={!!selectedRole || !!selectedField}
                    onHide={() => { setSelectedRole(null); setSelectedField(null); }}
                    size="lg"
                    >
                    <Modal.Header closeButton>
                        <Modal.Title>
                        {selectedField
                            ? "Participants by Expert Field"
                            : "Participants by Role"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedField ? (
                        <Table bordered>
                            <thead>
                                <tr>
                                    <th>Anonymous ID</th>
                                    <th>Expert Field</th>
                                    <th>Role</th>
                                    <th>Place</th>
                                    <th>Year of Birth</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedField.map((participant, idx) => (
                                    <tr key={idx}>
                                        <td>{participant.anonymous_id?.value}</td>
                                        <td>{participant.expert_field?.value || "N/A"}</td>
                                        <td>{participant.role?.value || "N/A"}</td>
                                        <td>{participant.place?.value || "N/A"}</td>
                                        <td>{participant.yearOfBirth?.value || "N/A"}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        ) : selectedRole ? (
                        <Table bordered>
                            <thead>
                            <tr>
                                <th>Anonymous ID</th>
                                <th>Role</th>
                                <th>Expert Field</th>
                                <th>Place</th>
                                <th>Year of Birth</th>
                            </tr>
                            </thead>
                            <tbody>
                            {selectedRole.map((participant, idx) => (
                                <tr key={idx}>
                                <td>{participant.anonymous_id?.value}</td>
                                <td>{participant.role?.value || "N/A"}</td>
                                <td>{participant.expert_field?.value || "N/A"}</td>
                                <td>{participant.place?.value || "N/A"}</td>
                                <td>{participant.yearOfBirth?.value || "N/A"}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                        ) : null}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                        variant="secondary"
                        onClick={() => { setSelectedRole(null); setSelectedField(null); }}
                        >
                        Close
                        </Button>
                    </Modal.Footer>
                </Modal>             

            </Col>
        </Row>
    );
};