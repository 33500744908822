/**
 * Worldmap / Map with locations of use cases
 * 
 * Description: WIP
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-08-11
 * 
 */

import React, { useState, useEffect } from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Fix the default icon issue with Leaflet in React
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
    iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
    shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const PopupContent = ({ markerGroup }) => {
    return (
        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
            <h3>
                {markerGroup[0].country_flag?.value || markerGroup[0].country_flag} Use Cases in{' '}
                {markerGroup[0].city?.value || markerGroup[0].city}, {markerGroup[0].country?.value || markerGroup[0].country}
            </h3>
            {markerGroup.map((marker, idx) => (
                <div key={idx}>
                    <p><strong>{marker.title?.value || marker.title}</strong></p>
                    <p><strong>Tags:</strong> {(marker.tags?.value || marker.tags).join(', ')}</p>
                    <hr />
                </div>
            ))}
        </div>
    );
};


const createCustomIcon = (markerGroup) => {
    return L.divIcon({
        html: `
            <div style="position: relative;">
                <!-- Standard marker -->
                <img src="https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png" />
                <!-- Number next to the marker -->
                <div style="position: absolute; top: -10px; left: 20px; background-color: red; color: white; border-radius: 50%; width: 20px; height: 20px; display: flex; justify-content: center; align-items: center; font-size: 12px; font-weight: bold;">
                    ${markerGroup.length}
                </div>
            </div>
        `,
        className: 'custom-icon',
        iconSize: [25, 41], // size of the icon
        iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
        popupAnchor: [1, -34] // point from which the popup should open relative to the iconAnchor
    });
};

const cities = {
    // Major Cities in Europe
    Berlin: { lat: 52.5200, lon: 13.4050 },
    Paris: { lat: 48.8566, lon: 2.3522 },
    London: { lat: 51.5074, lon: -0.1278 },
    Madrid: { lat: 40.4168, lon: -3.7038 },
    Rome: { lat: 41.9028, lon: 12.4964 },
    Lisbon: { lat: 38.7223, lon: -9.1393 },
    Amsterdam: { lat: 52.3676, lon: 4.9041 },
    Brussels: { lat: 50.8503, lon: 4.3517 },
    Vienna: { lat: 48.2082, lon: 16.3738 },
    Athens: { lat: 37.9838, lon: 23.7275 },
    Budapest: { lat: 47.4979, lon: 19.0402 },
    Prague: { lat: 50.0755, lon: 14.4378 },
    Warsaw: { lat: 52.2297, lon: 21.0122 },
    Dublin: { lat: 53.3498, lon: -6.2603 },
    Stockholm: { lat: 59.3293, lon: 18.0686 },
    Copenhagen: { lat: 55.6761, lon: 12.5683 },
    Helsinki: { lat: 60.1695, lon: 24.9355 },
    Oslo: { lat: 59.9139, lon: 10.7522 },
    Zurich: { lat: 47.3769, lon: 8.5417 },
    Moscow: { lat: 55.7558, lon: 37.6173 },
    Valencia: { lat: 39.4699, lon: -0.3763 },
    Porches: { lat: 37.122666176, lon: -8.400998396},
    Barcelona: { lat: 41.3851, lon: 2.1734 },

    // Major Capitals Worldwide
    Washington: { lat: 38.9072, lon: -77.0369 },  // USA
    Ottawa: { lat: 45.4215, lon: -75.6972 },  // Canada
    Mexico_City: { lat: 19.4326, lon: -99.1332 },  // Mexico
    Brasilia: { lat: -15.8267, lon: -47.9218 },  // Brazil
    Buenos_Aires: { lat: -34.6037, lon: -58.3816 },  // Argentina
    Canberra: { lat: -35.2809, lon: 149.1300 },  // Australia
    Wellington: { lat: -41.2865, lon: 174.7762 },  // New Zealand
    Tokyo: { lat: 35.6895, lon: 139.6917 },  // Japan
    Beijing: { lat: 39.9042, lon: 116.4074 },  // China
    New_Delhi: { lat: 28.6139, lon: 77.2090 },  // India
    Jakarta: { lat: -6.2088, lon: 106.8456 },  // Indonesia
    Seoul: { lat: 37.5665, lon: 126.9780 },  // South Korea
    Bangkok: { lat: 13.7563, lon: 100.5018 },  // Thailand
    Riyadh: { lat: 24.7136, lon: 46.6753 },  // Saudi Arabia
    Ankara: { lat: 39.9334, lon: 32.8597 },  // Turkey
    Cairo: { lat: 30.0444, lon: 31.2357 },  // Egypt
    Pretoria: { lat: -25.7479, lon: 28.2293 },  // South Africa
    Nairobi: { lat: -1.2921, lon: 36.8219 },  // Kenya
    Addis_Ababa: { lat: 9.0036, lon: 38.7636 },  // Ethiopia

    // Major Cities in Germany
    Hamburg: { lat: 53.5511, lon: 9.9937 },
    Munich: { lat: 48.1351, lon: 11.5820 },
    Cologne: { lat: 50.9375, lon: 6.9603 },
    Frankfurt: { lat: 50.1109, lon: 8.6821 },
    Stuttgart: { lat: 48.7758, lon: 9.1829 },
    Düsseldorf: { lat: 51.2277, lon: 6.7735 },
    Dortmund: { lat: 51.5136, lon: 7.4653 },
    Essen: { lat: 51.4556, lon: 7.0116 },
    Leipzig: { lat: 51.3397, lon: 12.3731 },
    Bremen: { lat: 53.0793, lon: 8.8017 },
    Dresden: { lat: 51.0504, lon: 13.7373 },
    Hanover: { lat: 52.3759, lon: 9.7320 },
    Nuremberg: { lat: 49.4521, lon: 11.0767 },
    Duisburg: { lat: 51.4344, lon: 6.7623 },
    Bochum: { lat: 51.4818, lon: 7.2162 },
    Wuppertal: { lat: 51.2562, lon: 7.1508 },
    Bielefeld: { lat: 52.0302, lon: 8.5325 },
    Bonn: { lat: 50.7374, lon: 7.0982 },
    Münster: { lat: 51.9607, lon: 7.6261 },
    Karlsruhe: { lat: 49.0069, lon: 8.4037 },
    Mannheim: { lat: 49.4875, lon: 8.4660 },
    Augsburg: { lat: 48.3668, lon: 10.8985 },
    Wiesbaden: { lat: 50.0826, lon: 8.2400 },
    Mönchengladbach: { lat: 51.1805, lon: 6.4428 },
    Gelsenkirchen: { lat: 51.5177, lon: 7.0857 },
    Braunschweig: { lat: 52.2689, lon: 10.5268 },
    Chemnitz: { lat: 50.8278, lon: 12.9214 },
    Kiel: { lat: 54.3233, lon: 10.1228 },
    Aachen: { lat: 50.7753, lon: 6.0839 },
    Halle: { lat: 51.4963, lon: 11.9670 },
    Magdeburg: { lat: 52.1205, lon: 11.6276 },
    Freiburg: { lat: 47.9990, lon: 7.8421 },
    Krefeld: { lat: 51.3388, lon: 6.5853 },
    Lübeck: { lat: 53.8655, lon: 10.6866 },
    Oberhausen: { lat: 51.4963, lon: 6.8638 },
    Erfurt: { lat: 50.9848, lon: 11.0299 },
    Mainz: { lat: 49.9929, lon: 8.2473 },
    Rostock: { lat: 54.0924, lon: 12.0991 },
    Kassel: { lat: 51.3127, lon: 9.4797 },
    Hagen: { lat: 51.3671, lon: 7.4633 },
    Hamm: { lat: 51.6739, lon: 7.8151 },
    Saarbrücken: { lat: 49.2402, lon: 6.9969 },
    Mülheim: { lat: 51.4182, lon: 6.8846 },
    Potsdam: { lat: 52.3906, lon: 13.0645 },
    Ludwigshafen: { lat: 49.4774, lon: 8.4452 },
    Oldenburg: { lat: 53.1435, lon: 8.2146 },
    Leverkusen: { lat: 51.0459, lon: 7.0192 },
    Osnabrück: { lat: 52.2799, lon: 8.0472 },
    Solingen: { lat: 51.1652, lon: 7.0671 },
    Heidelberg: { lat: 49.3988, lon: 8.6724 },
    Herne: { lat: 51.5369, lon: 7.2009 },
    Neuss: { lat: 51.2042, lon: 6.6879 },
    Darmstadt: { lat: 49.8728, lon: 8.6512 },
    Paderborn: { lat: 51.7189, lon: 8.7575 },
    Regensburg: { lat: 49.0134, lon: 12.1016 },
    Ingolstadt: { lat: 48.7665, lon: 11.4258 },
    Würzburg: { lat: 49.7913, lon: 9.9534 },
    Wolfsburg: { lat: 52.4227, lon: 10.7865 },
    Ulm: { lat: 48.4011, lon: 9.9876 },
    Heilbronn: { lat: 49.1427, lon: 9.2109 },
    Pforzheim: { lat: 48.8922, lon: 8.6947 },
    Göttingen: { lat: 51.5413, lon: 9.9158 },
    Bottrop: { lat: 51.5230, lon: 6.9285 },
    Trier: { lat: 49.7490, lon: 6.6371 },
    Reutlingen: { lat: 48.4914, lon: 9.2043 },
    Koblenz: { lat: 50.3569, lon: 7.5880 },
    Bergisch_Gladbach: { lat: 50.9918, lon: 7.1347 },
    Jena: { lat: 50.9271, lon: 11.5892 },
    Remscheid: { lat: 51.1787, lon: 7.1897 },
    Erlangen: { lat: 49.5897, lon: 11.0078 },
    Moers: { lat: 51.4515, lon: 6.6286 },
    Siegen: { lat: 50.8748, lon: 8.0243 },
    Hildesheim: { lat: 52.1508, lon: 9.9519 },
    Salzgitter: { lat: 52.1548, lon: 10.3880 },
    Cottbus: { lat: 51.7563, lon: 14.3329 },
    Gera: { lat: 50.8819, lon: 12.0830 },
    Witten: { lat: 51.4436, lon: 7.3526 },
    Zwickau: { lat: 50.7189, lon: 12.4964 },
    Kaiserslautern: { lat: 49.4430, lon: 7.7716 },
    Schwerin: { lat: 53.6355, lon: 11.4167 },
    Herford: { lat: 52.1146, lon: 8.6767 },
    Neumünster: { lat: 54.0723, lon: 9.9839 },
    Fulda: { lat: 50.5558, lon: 9.6808 },
    Lüdenscheid: { lat: 51.2190, lon: 7.6274 },
    Ratingen: { lat: 51.2971, lon: 6.8493 },
    Villingen_Schwenningen: { lat: 48.0620, lon: 8.4934 },
    Bayreuth: { lat: 49.9427, lon: 11.5769 },
    Gladbeck: { lat: 51.5707, lon: 6.9856 },
    Lüneburg: { lat: 53.2467, lon: 10.4142 },
    Hanau: { lat: 50.1264, lon: 8.9233 },
    Hürth: { lat: 50.8702, lon: 6.8676 },
    Marl: { lat: 51.6563, lon: 7.0857 },
    Göppingen: { lat: 48.7032, lon: 9.6514 },
    Landshut: { lat: 48.5442, lon: 12.1526 },
    Bensheim: { lat: 49.6836, lon: 8.6200 },
    Köln: { lat: 50.9375, lon: 6.9603 },

    Neubrandenburg: { lat: 53.5571, lon: 13.2630 },
    Shenzhen: { lat: 22.5431, lon: 114.0579 },
    Sliema: { lat: 35.9122, lon: 14.5041 },
    Sindelfingen: { lat: 48.7122, lon: 9.0037 },
    Dubai: { lat: 25.276987, lon: 55.296249 },
    Frankenburg: { lat: 48.0667, lon: 13.3833 },
    Labasa: { lat: -16.4167, lon: 179.3833 },
};

// OLD; only handles the old state structure
export const UseCaseMap0 = ({ useCases }) => {

    const [markers, setMarkers] = useState([]);

    useEffect(() => {

        /*
        const fetchCoordinates = async () => {
        try {
                
            const markersWithCoords = await Promise.all(
                useCases.map(async (useCase) => {
                    if (!useCase.city || !useCase.country) {
                        //console.error('City or country is undefined for a use case:', useCase);
                        return null;
                    }

                    try {

                        const response = await fetch(`https://nominatim.openstreetmap.org/search?city=${encodeURIComponent(useCase.city)}&country=${encodeURIComponent(useCase.country)}&format=json`);

                        if (!response.ok) {
                            throw new Error(`Failed to fetch coordinates for ${useCase.city}, ${useCase.country}`);
                        }

                        const data = await response.json();

                        if (data.length > 0) {
                            return {
                                ...useCase,
                                lat: data[0].lat,
                                lon: data[0].lon,
                                tags: useCase.tags || [],
                            };
                        } else {
                            console.error(`Coordinates not found for ${useCase.city}, ${useCase.country}`);
                            return null;
                        }
                    } catch (err) {
                        console.error(`Error fetching data for ${useCase.city}, ${useCase.country}:`, err);
                        return null;
                    }
                })
            );
            */

            const markersWithCoords = useCases.flatMap(useCase => {
                if (!useCase.city || !useCase.country) {
                    // Skipping this use case if city or country is not provided
                    return [];
                }
            
                // Ensure city and country are arrays
                const citiesArray = Array.isArray(useCase.city) ? useCase.city : [useCase.city];
                const countriesArray = Array.isArray(useCase.country) ? useCase.country : [useCase.country];
            
                // If cities and countries are arrays, we assume they correspond to each other by index
                const markers = citiesArray.flatMap((city, index) => {
                    const country = countriesArray[index]; // Match by index
            
                    // Check if city exists in the cities mapping and handle errors if coordinates are missing
                    if (cities[city]) {
                        return {
                            ...useCase,
                            city,
                            country,
                            lat: cities[city].lat,
                            lon: cities[city].lon,
                            tags: useCase.tags || [],
                        };
                    } else {
                        console.error(`Coordinates not found for ${city}, ${country}`);
                        return null;
                    }
                });
            
                // Filter out null values (in case of missing coordinates)
                return markers.filter(marker => marker !== null);
            });
            
            const groupedMarkers = markersWithCoords.reduce((acc, marker) => {
                const key = `${marker.lat},${marker.lon}`;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(marker);
                return acc;
            }, {});
            
            setMarkers(Object.values(groupedMarkers));
            
            //setMarkers(markersWithCoords.filter(marker => marker !== null));

            /*
            } catch (err) {
                console.error('Error fetching use case coordinates:', err);
            }
            
        };
        

        fetchCoordinates();
        */
    }, [useCases]);

    return (
        <MapContainer center={[20, 0]} zoom={2} style={{ height: '600px', width: '100%' }}>
        <TileLayer
            //url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {markers.map((markerGroup, index) => (
                <Marker 
                    key={index} 
                    position={[markerGroup[0].lat, markerGroup[0].lon]}
                    icon={createCustomIcon(markerGroup)}
                >
                    <Popup>
                        <PopupContent markerGroup={markerGroup} />
                    </Popup>
                </Marker>
            ))}
        </MapContainer>
    );
};

const UseCaseMap = ({ useCases }) => {
    const [markers, setMarkers] = useState([]);

    const bounds = [[-85, -180], [85, 180]];

    useEffect(() => {
        const markersWithCoords = useCases.flatMap(useCase => {
            // Handle the new state structure
            const cityData = useCase.city?.value || useCase.city; // For new state, useCase.city is an object
            const countryData = useCase.country?.value || useCase.country; // For new state, useCase.country is an object

            if (!cityData || !countryData) {
                // Skipping this use case if city or country is not provided
                return [];
            }

            // Ensure city and country are arrays
            const citiesArray = Array.isArray(cityData) ? cityData : [cityData];
            const countriesArray = Array.isArray(countryData) ? countryData : [countryData];

            // If cities and countries are arrays, we assume they correspond to each other by index
            const markers = citiesArray.flatMap((city, index) => {
                const country = countriesArray[index]; // Match by index

                // Check if city exists in the cities mapping and handle errors if coordinates are missing
                if (cities[city]) {
                    return {
                        ...useCase,
                        city,
                        country,
                        lat: cities[city].lat,
                        lon: cities[city].lon,
                        tags: useCase.tags?.value || useCase.tags || [], // Handle tags in both state structures
                    };
                } else {
                    console.error(`Coordinates not found for ${city}, ${country}`);
                    return null;
                }
            });

            // Filter out null values (in case of missing coordinates)
            return markers.filter(marker => marker !== null);
        });

        const groupedMarkers = markersWithCoords.reduce((acc, marker) => {
            const key = `${marker.lat},${marker.lon}`;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(marker);
            return acc;
        }, {});

        setMarkers(Object.values(groupedMarkers));
    }, [useCases]);

    return (
        <MapContainer 
            center={[20, 0]} 
            zoom={2} 
            minZoom={2}
            maxZoom={18}
            maxBounds={bounds}
            style={{ height: '600px', width: '100%' }}
        >
            <TileLayer
                url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {markers.map((markerGroup, index) => (
                <Marker 
                    key={index} 
                    position={[markerGroup[0].lat, markerGroup[0].lon]}
                    icon={createCustomIcon(markerGroup)}
                >
                    <Popup>
                        <PopupContent markerGroup={markerGroup} />
                    </Popup>
                </Marker>
            ))}
        </MapContainer>
    );
};

export default UseCaseMap;
