/**
 * Visualizes the study demographics
 *
 * Description: Provides a visualization of the study demographics
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-27
 */

import React, { useState } from 'react';
import { Row, Col, Button, Card, Modal } from 'react-bootstrap';
import { Bar, Doughnut } from 'react-chartjs-2'; // Pie

import { StatisticsCard } from '../eco_analytics_handling/eco_stats';

import { prepareChartData } from '../eco_analytics_handling/eco_analytics_prepare_charts';

// Aggregates demographics data and calculates statistics
const aggregateDemographicsData = (surveyData) => {
    const roleData = {};
    const placeData = {};
    const experienceData = {};
    const fieldData = {};
    const departmentData = {};
    const birthYearData = {};

    surveyData.forEach((data) => {
        const role = data.role?.value || 'Unknown';
        roleData[role] = (roleData[role] || 0) + 1;

        const place = data.place?.value || 'Unknown';
        placeData[place] = (placeData[place] || 0) + 1;

        const experience = data.experience?.value || '0-1 years';
        experienceData[experience] = (experienceData[experience] || 0) + 1;

        const field = data.expert_field?.value || 'Other';
        fieldData[field] = (fieldData[field] || 0) + 1;

        const birthYear = data.yearOfBirth?.value || 'Unknown';
        birthYearData[birthYear] = (birthYearData[birthYear] || 0) + 1;
    });

    return {
        roleData,
        placeData,
        experienceData,
        fieldData,
        departmentData,
        birthYearData,
        totalParticipants: surveyData.length,
    };
};

export const DemographicsAnalysis = ({ surveyData, handleClear }) => {

    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);

    const [isChartRole, setIsChartRole] = useState(false);
    const [isChartExpert, setIsChartExpert] = useState(false);

    const { 
        roleData, 
        placeData, 
        experienceData, 
        fieldData, 
        birthYearData, 
        totalParticipants 
    } = aggregateDemographicsData(surveyData);

    // Chart click and hover handlers
    const handleChartClick = (evt, elems, chartData, topic) => {

        if (elems.length > 0) {
            const clickedIndex = elems[0].index;
            const clickedCategory = chartData.labels[clickedIndex];
            const clickedData = {
                topic,
                category: clickedCategory,
                res: topic !== 'yearOfBirth'
                    ? surveyData.filter((data) => data[topic.toLowerCase()]?.value === clickedCategory)
                    : surveyData.filter((data) => data[topic]?.value === clickedCategory)
            };
            setModalData(clickedData);
            setShowModal(true);
        }
    };

    const handleChartHover = (evt, elems) => {
        evt.native.target.style.cursor = elems.length > 0 ? 'pointer' : 'default';
    };

    let totalExperience = 0;
    Object.entries(experienceData).forEach(([years, count]) => {
        totalExperience += years * count;
    });

    return (
        <Row>
            <Col md={12} style={{ textAlign: 'left' }} id="content">
                {/* Title */}
                <Row style={{ marginBottom: '20px', marginTop: '20px' }}>
                    <Col>
                        <span>Expert Demographics</span>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <Button variant="outline-primary" className="btn-sm" onClick={handleClear}>
                            <i className="bi bi-arrow-left"></i> Go back
                        </Button>
                    </Col>
                </Row>

                {/* General Demographics */}
                <Row style={ { marginBottom:'30px'} }>
                    <StatisticsCard targetCount={totalParticipants} caption="Total Participants" />
                    <StatisticsCard targetCount={Object.keys(roleData).length} caption="Roles" />
                    <StatisticsCard targetCount={Object.keys(placeData).length} caption="Locations" />
                    <StatisticsCard targetCount={totalExperience} caption="Years of Experience" />
                </Row>
                
                <Row>
                    <Col md="6">
                        <Row>
                            {/* Role Distribution */}
                            <Col md={6}>
                                <Card className="h-100">
                                    <Card.Header className="d-flex justify-content-between align-items-center">
                                        <span>Roles (Total: {Object.keys(roleData).length})</span>
                                        <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                                        <Button variant="outline-primary" className="btn-sm" onClick={() => setIsChartRole(!isChartRole)}>
                                            {isChartRole ? (
                                                <i className="bi bi-bar-chart"></i>
                                            ) : (
                                                <i className="bi bi-pie-chart"></i>
                                            )}
                                        </Button>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        {isChartRole ? (
                                        <Doughnut
                                            data={prepareChartData(roleData, 'Role Distribution')}
                                            options={{
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                onClick: (evt, elems) => handleChartClick(evt, elems, prepareChartData(roleData, 'Roles'), 'Role'),
                                                onHover: (evt, elems) => handleChartHover(evt, elems),
                                            }}
                                        />              
                                        ) : (
                                        <Bar
                                            data={prepareChartData(roleData, 'Role Distribution')}
                                            options={{
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                onClick: (evt, elems) => handleChartClick(evt, elems, prepareChartData(roleData, 'Roles'), 'Role'),
                                                onHover: (evt, elems) => handleChartHover(evt, elems),
                                            }}
                                        />
                                        )}                          
                                    </Card.Body>
                                </Card>
                            </Col>
                            {/* Expert Field Distribution */}
                            <Col md={6}>
                                <Card className="h-100">
                                    <Card.Header className="d-flex justify-content-between align-items-center">
                                        <span>Experts (Total: {Object.keys(fieldData).length})</span>
                                        <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                                        <Button variant="outline-primary" className="btn-sm" onClick={() => setIsChartExpert(!isChartExpert)}>
                                            {isChartExpert ? (
                                                <i className="bi bi-bar-chart"></i>
                                            ) : (
                                                <i className="bi bi-pie-chart"></i>
                                            )}
                                        </Button>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        {isChartExpert ? (
                                        <Doughnut
                                            data={prepareChartData(fieldData, 'Expert Field Distribution')}
                                            options={{
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                onClick: (evt, elems) => handleChartClick(evt, elems, prepareChartData(fieldData, 'Expert Field'), 'expert_field'),
                                                onHover: (evt, elems) => handleChartHover(evt, elems),
                                            }}
                                        />
                                        ) : (
                                        <Bar
                                            data={prepareChartData(fieldData, 'Expert Field Distribution')}
                                            options={{
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                onClick: (evt, elems) => handleChartClick(evt, elems, prepareChartData(fieldData, 'Expert Field'), 'expert_field'),
                                                onHover: (evt, elems) => handleChartHover(evt, elems),
                                            }}
                                        />
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    {/* Year of Birth Distribution */}
                    <Col md="6">
                        <Card className="h-100">
                            <Card.Header>Age Distribution</Card.Header>
                            <Card.Body>
                                <Bar
                                    data={prepareChartData(birthYearData, 'Year of Birth')}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        scales: {
                                            x: {
                                                title: {
                                                    display: true,
                                                    text: 'Year of Birth',
                                                },
                                            },
                                            y: {
                                                title: {
                                                    display: true,
                                                    text: 'Number of Participants',
                                                },
                                            },
                                        },
                                        onClick: (evt, elems) => handleChartClick(evt, elems, prepareChartData(birthYearData, 'yearOfBirth'), 'yearOfBirth'),
                                        onHover: (evt, elems) => handleChartHover(evt, elems),
                                    }}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row style={{ marginTop: '20px' }}>
                    {/* Experience Level Distribution */}
                    <Col md={6}>
                        <Card className="h-100">
                            <Card.Header>Years of Experience</Card.Header>
                            <Card.Body>
                                <Bar
                                    data={prepareChartData(experienceData, 'Experience Level Distribution')}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        onClick: (evt, elems) => handleChartClick(evt, elems, prepareChartData(experienceData, 'Experience Level'), 'Experience'),
                                        onHover: (evt, elems) => handleChartHover(evt, elems),
                                    }}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* Location Distribution - City */}
                    <Col md={6}>
                        <Card className="h-100">
                            <Card.Header>Location Distribution</Card.Header>
                            <Card.Body>
                                <Bar
                                    data={prepareChartData(placeData, 'Location Distribution')}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        onClick: (evt, elems) => handleChartClick(evt, elems, prepareChartData(placeData, 'Location'), 'Place'),
                                        onHover: (evt, elems) => handleChartHover(evt, elems),
                                    }}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Col>

            {/* Detail Modal */}
            {modalData && (
                <DetailModal show={showModal} handleClose={() => setShowModal(false)} data={modalData} />
            )}
        </Row>
    );
};

const DetailModal = ({ show, handleClose, data }) => (
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>{data.topic}: {data.category}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <ul>
                {data.res.map((item, index) => (
                    <li key={index}>
                        {item.anonymous_id?.value || 'N/A'} - 
                        {data.topic !== 'yearOfBirth' ? item[data.topic.toLowerCase()]?.value || 'N/A' : item[data.topic]?.value || 'N/A'}
                    </li>
                ))}
            </ul>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
        </Modal.Footer>
    </Modal>
);