/**
 * Survey evaluation component
 *
 * Description: Provides a visualization of the study survey
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-27
 */



import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Row, Col, Card, Button, Table, Modal, Form, OverlayTrigger, Tooltip, ButtonGroup } from 'react-bootstrap';

import { Bar, Pie, Radar, PolarArea, Doughnut, Line } from 'react-chartjs-2'; 

import { StatisticsCard } from '../../eco_analytics_handling/eco_stats';

import { getBlueGreenColors } from '../../eco_analytics_handling/eco_analytics_prepare_charts'; // prepareChartData

const chartTypes = {
    Bar: Bar,
    Pie: Pie,
    Radar: Radar,
    //Scatter: Scatter,
    Polar: PolarArea,
    Doughnut: Doughnut,
    Line: Line,
    Table: Table,
};

// Aggregate survey data for chart preparation and calculations
const aggregateSurveyData = (surveyData) => {

    const feelingData = {
        expert_feeling: { angry: 0, sad: 0, neutral: 0, happy: 0 }, //  very_happy: 0 
        expert_work_level: { Beginner: 0, Intermediate: 0, Advanced: 0 },
        expert_work_layer: { Conceptional: 0, Strategic: 0, Execution: 0, Operational: 0 },
        expert_confidence: { 'Not confident': 0, 'Not really confident': 0, 'Somewhat confident': 0, 'Confident': 0, 'Very confident': 0 }
    };

    const addedValueData = {
        ucm_usefullness: { 'Not useful': 0, 'Neglectable': 0, 'Somewhat useful': 0, 'Useful': 0, 'Very useful': 0 },
        ucm_effectiveness: { 'Strongly disagree': 0, 'Disagree': 0, 'Neutral': 0, 'Agree': 0, 'Strongly agree': 0 },
        ucm_most_useful_aspects: { 'Description View': 0, 'Scenario Modeling': 0, 'Actor Mapping': 0, 'Component Modeling': 0, 'LLM Layer Integration (UCM Co-Pilot)': 0, 'Embedding of Domain-Specific Semantic Models': 0 },
        ucm_method_clarity: { 'Not clear': 0, 'Not so clear': 0, 'Somewhat clear': 0, 'Clear': 0, 'Very clear': 0 },
        //ucm_ranked_aspects: { 'UCM Approach': 0, 'Info View': 0, 'Scenario Model': 0, 'Actor Map': 0, 'Component Diagram': 0, 'LLM Layer Integration (UCM Co-Pilot)': 0, 'Embedding of Domain-Specific Semantic Models': 0 },
        ucm_additional_comments: [],
    };

    const modelUsageData = {
        model_input: { 'Not useful': 0, 'Neglectable': 0, 'Somewhat useful': 0, 'Useful': 0, 'Very useful': 0 },
        model_llm_integration: { 'Not useful': 0, 'Neglectable': 0, 'Somewhat useful': 0, 'Useful': 0, 'Very useful': 0 },
        model_semantic_clarity: { 'Not clear': 0, 'Neglectable': 0, 'Somewhat clear': 0, 'Clear': 0, 'Very clear': 0 },
        model_agreed_aspects: { 'Relevance to the domain': 0, 'Clarity of the inputs': 0, 'Simplicity of the suggested elememts': 0, 'Completeness of the information': 0 },
        additional_comments: [],
    };

    const uiDesignData = {
        ui_clarity: { 'Not clear': 0, 'Neglectable': 0, 'Somewhat clear': 0, 'Clear': 0, 'Very clear': 0 },
        ui_simplicity: { 'Very complicated': 0, 'Complicated': 0, 'Somewhat simple': 0, 'Simple': 0, 'Very simple': 0 },
        ui_overall_aspects: [
            'Navigation',
            'Information layout',
            'Content clarity',
            'Interactivity',
            'Visual design',
            'Consistency',
            'Ease of use',
            'Effectiveness',
            'Usefulness of semantic models',                    
        ],
    };

    const userExperience = {
        reproducibility: { 'Not reproducible': 0,  'Not really reproducible': 0, 'Somewhat reproducible': 0, 'Reproducible': 0, 'Highly reproducible': 0 },
        overall_experience: { 'Poor': 0, 'Fair': 0, 'Average': 0, 'Very good': 0, 'Excellent': 0 },
        additional_comments: [],
    };
    
    // General demographics
    let totalParticipants = surveyData.length;
    const experienceData = {};
    const roleData = {};
    const expertFieldData = {};
    const placeData = {};

    surveyData.forEach((data) => {

        // Aggregate `feeling` responses
        Object.entries(data.feeling || {}).forEach(([key, { answer }]) => {
            
            if (key !== 'expert_confidence') {
                feelingData[key] = feelingData[key] || {};
                feelingData[key][answer] = (feelingData[key][answer] || 0) + 1;
            } else {
                const expertConfidence = ['Not confident', 'Not really confident', 'Somewhat confident', 'Confident', 'Very confident'];                
                feelingData[key][expertConfidence[answer]]++;
            }
        });

        // Aggregate `addedValue` responses with type handling for `answer`
        Object.entries(data.addedValue || {}).forEach(([key, questionData]) => {
            const { answer, answer_set } = questionData;
            
            // For single-choice answers (numeric or string, like effectiveness)            
            if (typeof answer === 'number' || typeof answer === 'string') {                        
                const answerIndex = parseInt(answer, 10);
                const answerLabel = answer_set?.[answerIndex];
                if (addedValueData[key] && addedValueData[key][answerLabel] !== undefined) {
                    addedValueData[key][answerLabel]++;
                }
            }

            if (key === 'ucm_effectiveness') {
                if (addedValueData[key] && addedValueData[key][answer] !== undefined) {
                    addedValueData[key][answer]++;
                }
            }

            // For multi-select answers (array of strings, like most useful aspects)
            else if (Array.isArray(answer)) {
                answer.forEach((option) => {
                    if (addedValueData[key][option] !== undefined) {
                        addedValueData[key][option]++;
                    }
                });
            }

            // For ranking-style answers (object, like ranked aspects)
            else if (typeof answer === 'object' && answer !== null) {
                Object.entries(answer).forEach(([aspect, rank]) => {
                    if (addedValueData[key][aspect] !== undefined) {
                        addedValueData[key][aspect] += 1; // Optionally use rank as a weight if needed
                    }
                });
            }

            // For additional comments
            else if (key === 'ucm_additional_comments') {
                addedValueData[key] = addedValueData[key] || [];
                addedValueData[key].push(answer);
            }
        });
        
        // Aggregate `modelUsage` responses
        Object.entries(data.modelUsage || {}).forEach(([key , { answer }]) => {

            if (key === 'model_semantic_clarity') {
                const model_semantic_clarity = ['Not clear', 'Neglectable', 'Somewhat clear', 'Clear', 'Very clear'];                
                modelUsageData[key][model_semantic_clarity[answer-1]]++;
            } else if (key === 'model_input' || key === 'model_llm_integration') {
                const model_llm_integration = ['Not useful', 'Neglectable', 'Somewhat useful', 'Useful', 'Very useful'];                
                modelUsageData[key][model_llm_integration[answer-1]]++;
            } else if (key === 'model_additional_comments') {
                modelUsageData[key] = modelUsageData[key] || [];
                modelUsageData[key].push(answer);
            } else if (key === 'model_agreed_aspects') {
                const modelAgreedAspectsOptions = [
                    'Relevance to the domain', 
                    'Clarity of the inputs', 
                    'Simplicity of the suggested elements', 
                    'Completeness of the information'
                ];
            
                // Ensure the structure for model_agreed_aspects exists
                modelUsageData[key] = modelUsageData[key] || modelAgreedAspectsOptions.reduce((acc, aspect) => {
                    acc[aspect] = 0;
                    return acc;
                }, {});
            
                // Check if answer is an array and iterate over it
                if (Array.isArray(answer)) {
                    answer.forEach((selectedAspect) => {
                        if (modelUsageData[key][selectedAspect] !== undefined) {
                            modelUsageData[key][selectedAspect]++;
                        }
                    });
                }                                        

            } else {               
                modelUsageData[key] = modelUsageData[key] || {};
                modelUsageData[key][answer] = (modelUsageData[key][answer] || 0) + 1;
            }
        });

        // Aggregate `uiDesign` responses
        Object.entries(data.uiDesign || {}).forEach(([key, { answer }]) => {

            if (key === 'ui_clarity') {
                const ui_clarity = ['Not clear', 'Neglectable', 'Somewhat clear', 'Clear', 'Very clear'];
                uiDesignData[key][ui_clarity[answer-1]]++;
            } else if (key === 'ui_simplicity') {
                if (uiDesignData[key] && uiDesignData[key][answer] !== undefined) {
                    uiDesignData[key][answer]++;
                }
            }
            else if (key === 'ui_overall_aspects') {                

                const uiAspectCategories = [
                    'Navigation', 'Information layout', 'Content clarity', 'Interactivity',
                    'Visual design', 'Consistency', 'Ease of use', 'Effectiveness', 'Usefulness of semantic models'
                ];

                uiDesignData[key] = {};  // Make sure ui_overall_aspects is an object
            
                uiAspectCategories.forEach((aspect) => {
                    uiDesignData[key][aspect] = {
                        "Very Poor": 0,
                        "Poor": 0,
                        "Neutral": 0,
                        "Good": 0,
                        "Very Good": 0
                    };
                });
                
                // Iterate over each aspect and increment the corresponding rating
                Object.entries(answer).forEach(([aspect, rating]) => {
                    if (uiDesignData[key][aspect] && uiDesignData[key][aspect][rating] !== undefined) {
                        uiDesignData[key][aspect][rating]++;
                    }
                });
        
            } else {
                uiDesignData[key] = uiDesignData[key] || {};
                uiDesignData[key][answer] = (uiDesignData[key][answer] || 0) + 1;
            }
        });

        // Aggregate `userExperience` responses
        Object.entries(data.userExperience || {}).forEach(([key, { answer }]) => {

            if (key === 'reproducibility' || key === 'overall_experience') {
                userExperience[key] = userExperience[key] || {};
                userExperience[key][answer] = (userExperience[key][answer] || 0) + 1;
            } else if (key === 'additional_comments') {
                userExperience[key] = userExperience[key] || [];
                userExperience[key].push(answer);
            }
        });

        // Aggregate years of work experience for each participant
        experienceData[data.experience?.value] = (experienceData[data.experience?.value] || 0) + 1;
    
        // Aggregate `expert_field` responses
        expertFieldData[data.expert_field?.value] = (expertFieldData[data.expert_field?.value] || 0) + 1;

        // Aggregate `role` responses
        roleData[data.role?.value] = (roleData[data.role?.value] || 0) + 1;

        // Aggregate `place` responses
        placeData[data.place?.value] = (placeData[data.place?.value] || 0) + 1;

    });

    return { 
        feelingData, 
        addedValueData, 
        modelUsageData, 
        uiDesignData, 
        userExperience,
        // General demographics
        expertFieldData,
        roleData, 
        placeData, 
        experienceData, 
        totalParticipants, 
    };
};

const GenericChart = ({ chartType, obj, caption }) => {
    // 1) Always create your ref unconditionally:
    const chartRef = useRef(null);
  
    // 2) If there's no data, return early
    if (!obj || Object.keys(obj).length === 0) {
      return <div>No data</div>;
    }
  
    // 3) Decide which chart component to render
    const ChartCmp = chartTypes[chartType] || Bar;

    // The # of data points
    const count = Object.keys(obj).length;
    // Retrieve color arrays sized for however many data points we have
    const { backgrounds, borders } = getBlueGreenColors(count);
  
    const data = {
      labels: Object.keys(obj),
      datasets: [
        {
          label: caption,
          data: Object.values(obj),
          backgroundColor: backgrounds, // 'rgb(26, 188, 156)', 
          borderColor: borders, // 'rgb(46, 204, 113)',
          borderWidth: 1,
        },
      ],
    };
  
    const options = {
      // This forces the canvas to render at 2x DPI
      //devicePixelRatio: 2,
      devicePixelRatio: 4,
      maintainAspectRatio: false,
      // other chart.js options...
    };
  
    /**
     * Called when user clicks "Download PNG"
     */
    const handleDownloadPNG = () => {
      // (A) See if we have a chart ref
      if (!chartRef.current) {
        console.log('No chartRef.current');
        return;
      }
      // (B) In react-chartjs-2 v4, the chart instance is the ref itself
      const chartInstance = chartRef.current;
      if (!chartInstance.toBase64Image) {
        console.log('Chart instance found, but no toBase64Image()');
        return;
      }
  
      // (C) Convert chart to base64
      const base64Image = chartInstance.toBase64Image();
      // (D) Download it
      const link = document.createElement('a');
      link.download = caption +  '.png';
      link.href = base64Image;
      link.click();
    };
  
    return (
      <div style={{ height: '400px' }}>
        {chartType !== 'Table' ? (
          <div>
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Download Image</Tooltip>}
            >
            <Button
                align='right'
                variant='outline-secondary'
                onClick={handleDownloadPNG}
            >
                <i className="bi bi-card-image"></i>
            </Button>
            </OverlayTrigger>
            <div>
            <ChartCmp 
              ref={chartRef}
              data={data} 
              options={options}
              height={300}
            />
            </div>
          </div>
        ) : (
            <TableFrequency object={obj} header={caption} />
        )}
      </div>
    );
};

// Helper Table with header, and object data
const TableFrequency = ({ object, header }) => (

    <div style={{ overflowY: 'auto', maxHeight: '400px' }}>
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>{header}</th>
                    <th>Frequency</th>
                </tr>
            </thead>
            <tbody>
                {Object.entries(object).map(([obj, count]) => (
                    <tr key={obj}>
                        <td>{obj}</td>
                        <td>{count}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    </div>
);


export const SurveyAnalysis = ({ surveyData, handleClear }) => {

    const [selectedParticipant, setSelectedParticipant] = useState('');

    //const [showModal, setShowModal] = useState(false);
    //const [modalData, setModalData] = useState(null);

    const [modalFieldParticipants, setModalFieldParticipants] = useState(null);
    const [modalRoleParticipants, setModalRoleParticipants] = useState(null);

    // For the main filters:
    const [filterField, setFilterField] = useState('');
    const [filterRole, setFilterRole] = useState('');
    const [filterExperience, setFilterExperience] = useState('');

    const [feelingData, setFeelingData] = useState({ 
        expert_feeling: {}, 
        expert_work_level: {}, 
        expert_work_layer: {}, 
        expert_confidence: {}
    });
    const [addedValueData, setAddedValueData] = useState({});
    const [modelUsageData, setModelUsageData] = useState({});
    const [userExperience, setUserExperience] = useState({});
    // General demographics
    const [expertFieldData, setExpertFieldData] = useState({});
    const [roleData, setRoleData] = useState({});
    const [placeData, setPlaceData] = useState({});
    const [experienceData, setExperienceData] = useState({});
    const [totalParticipants, setTotalParticipants] = useState(0);

    // Chart types Feeling
    const [feelingFeelingChartType, setFeelingFeelingChartType] = useState('Doughnut');
    const [feelingWorkLevelChartType, setFeelingWorkLevelChartType] = useState('Bar');
    const [feelingWorkLayerChartType, setFeelingWorkLayerChartType] = useState('Doughnut');
    const [feelingConfidenceChartType, setFeelingConfidenceChartType] = useState('Line');
    // Chart types Added Value
    const [valueValueChartType, setValueValueChartType] = useState('Doughnut');
    const [valueEffectivenessChartType, setValueEffectivenessChartType] = useState('Bar');
    const [valueMostUsefulAspectsChartType, setValueMostUsefulAspectsChartType] = useState('Bar');
    const [valueMethodClarityChartType, setValueMethodClarityChartType] = useState('Bar');
    // Chart types Model Usage
    const [modelInputChartType, setModelInputChartType] = useState('Doughnut');
    const [modelLLMIntegrationChartType, setModelLLMIntegrationChartType] = useState('Doughnut');
    const [modelSemanticClarityChartType, setModelSemanticClarityChartType] = useState('Bar');
    const [modelAgreedAspectsChartType, setModelAgreedAspectsChartType] = useState('Bar');
    // Chart types User Experience
    const [experienceReproducibilityChartType, setExperienceReproducibilityChartType] = useState('Bar');
    const [experienceOverallExperienceChartType, setExperienceOverallExperienceChartType] = useState('Bar');

    // Adv. filter
    const allFields = new Set();
    const allRoles = new Set();
    const allExperiences = new Set();

    surveyData.forEach((item) => {
        if (item?.expert_field?.value) {
            allFields.add(item.expert_field.value);
        }
        if (item?.role?.value) {
            allRoles.add(item.role.value);
        }
        if (item?.experience?.value) {
            allExperiences.add(item.experience.value);
        }
    })

    const fieldOptions = Array.from(allFields).sort();
    const roleOptions = Array.from(allRoles).sort();
    const experienceOptions = Array.from(allExperiences).sort();

    const filteredData = useMemo(() => {
        return surveyData.filter((item) => {
          const fieldVal = item?.expert_field?.value || '';
          const roleVal = item?.role?.value || '';
          const expVal = item?.experience?.value || '';
          //const participantID = item?.anonymous_id?.value || '';
      
          // Compare to filter states
          if (filterField && fieldVal !== filterField) return false;
          if (filterRole && roleVal !== filterRole) return false;
          if (filterExperience && expVal !== filterExperience) return false;
          return true;
        });
    }, [surveyData, filterField, filterRole, filterExperience]);
      
    // Aggregate survey data for chart preparation and calculations
    // This function is called every time the filteredData changes
    useEffect(() => {

        if (!filteredData || filteredData.length === 0) {
          // Reset
          setFeelingData({
            expert_feeling: {},
            expert_work_level: {},
            expert_work_layer: {},
            expert_confidence: {}
          });
          setAddedValueData({});
          setModelUsageData({});
          setUserExperience({});
          setExpertFieldData({});
          setRoleData({});
          setPlaceData({});
          setExperienceData({});
          setTotalParticipants(0);
          return;
        }
    
        const {
            feelingData,
            addedValueData,
            modelUsageData,
            userExperience,
            expertFieldData,
            roleData,
            placeData,
            experienceData,
            totalParticipants,
        } = aggregateSurveyData(filteredData);
    
        setFeelingData(feelingData);
        setAddedValueData(addedValueData);
        setModelUsageData(modelUsageData);
        setUserExperience(userExperience);
        setExpertFieldData(expertFieldData);
        setRoleData(roleData);
        setPlaceData(placeData);
        setExperienceData(experienceData);
        setTotalParticipants(totalParticipants);

    }, [filteredData]);


    // Handle selecting individual survey response
    const handleParticipantSelect = (event) => {
        const selectedId = event.target.value;
        const participantData = surveyData.find((data) => data.anonymous_id.value === selectedId);
        setSelectedParticipant(participantData || null);
    };

    function handleOpenFieldParticipants(e) {
        const chosenField = e.target.value;
        if (!chosenField) {
          setModalFieldParticipants(null);
        } else {
          // gather all participants with that field
          const participants = surveyData.filter(
            (data) => data.expert_field?.value === chosenField
          );
          setModalFieldParticipants(participants);
        }
    }      

    function handleOpenRoleParticipants(e) {
        const chosenRole = e.target.value;
        if (!chosenRole) {
          setModalRoleParticipants(null);
        } else {
          const participants = surveyData.filter(
            (data) => data.role?.value === chosenRole
          );
          setModalRoleParticipants(participants);
        }
    }

    // Chart click and hover handlers
    /*
    const handleChartClick = (evt, elems, chartData, topic) => {

        if (elems.length > 0) {
            const clickedIndex = elems[0].index;
            const clickedCategory = chartData.labels[clickedIndex];
            const clickedData = {
                topic,
                category: clickedCategory,
                res: surveyData.filter((data) => data.feeling[topic]?.answer === clickedCategory),
            };
            setModalData(clickedData);
            setShowModal(true);
        }
    };
    */

    // Chart click and hover handlers => used with prepareChartData
    /*
    const handleChartClick = (evt, elems, chartData, topic) => {

        if (elems.length > 0) {
            const clickedIndex = elems[0].index;
            const clickedCategory = chartData.labels[clickedIndex];
            const clickedData = {
                topic,
                category: clickedCategory,
                res: surveyData.filter((data) => data.feeling[topic]?.answer === clickedCategory),
            };
            setModalData(clickedData);
            setShowModal(true);
        }
    };

    const handleChartHover = (evt, elems) => {
        evt.native.target.style.cursor = elems.length > 0 ? 'pointer' : 'default';
    };

    const chartContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '300px'
    };
    */

    let totalExperience = 0;
    Object.entries(experienceData).forEach(([years, count]) => {
        totalExperience += years * count;
    });

    return (
        <Row>
            <Col md={12} style={{ textAlign: 'left' }} id="content">
                {/* Title */}
                <Row style={{ marginBottom: '20px', marginTop: '20px' }}>
                    <Col>
                        <span>Expert Demographics</span>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <Button variant="outline-primary" className="btn-sm" onClick={handleClear}>
                            <i className="bi bi-arrow-left"></i> Go back
                        </Button>
                    </Col>
                </Row>

                {/* General Demographics */}
                <Row style={ { marginBottom:'30px'} }>
                    <StatisticsCard targetCount={totalParticipants} caption="Total Participants" />
                    <StatisticsCard targetCount={Object.keys(roleData).length} caption="Roles" />
                    <StatisticsCard targetCount={Object.keys(placeData).length} caption="Locations" />
                    <StatisticsCard targetCount={totalExperience} caption="Years of Experience" />
                </Row>

                {/* Intro and selection */}
                <Row style={ { marginBottom:'30px'} }>                    
                    {/* Individual Participant or Role Selection */}
                    <Col md={3}>
                        <Card className="h-100">
                        <Card.Header>Participant Selection</Card.Header>
                        <Card.Body>
                            <Form.Group controlId="participantSelect">  

                                {/* Select by Anonymized ID */}                       
                                <Form.Label className='small'>Select by Anonymized ID:</Form.Label>  
                                <Form.Control as="select" onChange={handleParticipantSelect}>
                                    <option value="">All Participants</option>
                                    {surveyData.map((data, idx) => (
                                        <option key={idx} value={data.anonymous_id.value}>
                                            {data.anonymous_id.value || `Participant ${idx + 1}`}
                                        </option>
                                    ))}
                                </Form.Control>

                                {/* Select by Expert Field */}
                                <Form.Label className='small' style={{ marginTop:'15px' }}>
                                    Select a Field to see participants:
                                </Form.Label>
                                <Form.Control as="select" onChange={handleOpenFieldParticipants}>
                                    <option value="">(None)</option>
                                    {Object.entries(expertFieldData).map(([field, count]) => (
                                        <option key={field} value={field}>
                                            {field} ({count})
                                        </option>
                                    ))}
                                </Form.Control>

                                {/* Select by Role */}
                                <Form.Label className='small' style={{ marginTop:'15px' }}>
                                    Select a Role to see participants:
                                </Form.Label>
                                <Form.Control as="select" onChange={handleOpenRoleParticipants}>
                                    <option value="">(None)</option>
                                    {Object.entries(roleData).map(([role, count]) => (
                                        <option key={role} value={role}>
                                            {role} ({count})
                                        </option>
                                    ))}
                                </Form.Control>

                            </Form.Group>

                            {/*
                            <div className="mt-3">
                                <span className="text-muted small">Select a participant to view detailed responses.</span>
                                <br />
                                <span className="text-muted small">All data is anonymized.</span>                              
                            </div>
                            */}
                        </Card.Body>
                        </Card>
                    </Col>
                    {/* Introduction to data collected in this survey */}
                    <Col md={6}>
                        <Card className="h-100">
                            <Card.Header>Survey Data</Card.Header>
                            <Card.Body>
                                <p className='small'>
                                    This survey collected data from {totalParticipants} participants.
                                    The data includes responses to questions divided into the following categories:                                    
                                </p>          
                                <Table bordered className='small'>
                                    <tbody>
                                        <tr><th>#1</th><th>Feeling</th><td>About You and this Research Study</td></tr>
                                        <tr><th>#2</th><th>Added Value</th><td>Added Value of the UCM method</td></tr>
                                        <tr><th>#3</th><th>Model Usage</th><td>Model usage ratings</td></tr>
                                        <tr><th>#4</th><th>Experience</th><td>Overall experience about the tool and study</td></tr>
                                    </tbody>
                                </Table>                     
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* Disclaimer and context of this survey */}
                    <Col md={3}>
                        <Card
                            className="h-100"
                            style={{
                                backgroundColor: '#f1f8e9',
                                border: '1px solid #81c784',
                                color: '#2e7d32',
                                //borderRadius: '10px',
                                //padding: '15px',
                            }}
                        >
                            <Card.Header style={{ fontWeight: 'bold', fontSize: '1.1rem', color: '#1b5e20', backgroundColor: '#a5d6a7' }}>
                                Survey Context
                            </Card.Header>
                            <Card.Body>
                                <p className="small" style={{ marginBottom: 0 }}>
                                    This survey is conducted as part of PhD research, aiming to propose a PEAM model.
                                    <br /><br />
                                    All participants are experts in fields such as engineering, urban planning, administration, computing, and AI,
                                    each approaching autonomous mobility and transportation from unique roles and experiences.
                                    <br /><br />
                                    The data is anonymized and used solely for research purposes.
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>   

                {/* ----------------------------------
                    (1) FILTERS for Field, Role, Exp, Question Q1, Q2, ...
                ---------------------------------- */}
                <Row className="mb-3">

                    <Col md={4}>
                        <Form.Group controlId="fieldSelect">
                            <Form.Label>Expert Field</Form.Label>
                            <Form.Control
                                as="select"
                                value={filterField}
                                onChange={(e) => setFilterField(e.target.value)}
                            >
                                <option value="">All Fields</option>
                                {fieldOptions.map((f) => (
                                    <option key={f} value={f}>{f}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
            
                    <Col md={4}>
                        <Form.Group controlId="roleSelect">
                            <Form.Label>Role</Form.Label>
                            <Form.Control
                                as="select"
                                value={filterRole}
                                onChange={(e) => setFilterRole(e.target.value)}
                            >
                                <option value="">All Roles</option>
                                {roleOptions.map((r) => (
                                    <option key={r} value={r}>{r}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
            
                    <Col md={2}>
                        <Form.Group controlId="experienceSelect">
                            <Form.Label>Experience (Years)</Form.Label>
                            <Form.Control
                                as="select"
                                value={filterExperience}
                                onChange={(e) => setFilterExperience(e.target.value)}
                            >
                                <option value="">All</option>
                                {experienceOptions.map((exp) => (
                                    <option key={exp} value={exp}>{exp}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
        
                    <Row style={{marginTop:'15px'}}>
                    {/* Display Selected Question Analysis
                    {selectedQuestion && selectedQuestion !== 'All' ? (
                        <>
                            <h5>Question: {getQuestionLabel(interviewData, selectedQuestion)}</h5>
                            <h6 style={{ color:'blue'}}>
                                <b>
                                The participant(s) `{selectedRole}` in the 
                                sample with expert field `{selectedField}`
                                for question `{selectedQuestion}` provide the following insights:
                                </b>
                            </h6>
                        </>
                    ) : (
                        <p>All questions are considered in the analysis.</p>
                    )
                    }
                    */}
                    </Row>
        
                </Row>
        
                <hr />         
            
                {/* Section 1: About You and this Research Study */}
                <Row>
                    <h4 style={{ fontWeight: 'bold', marginTop:'30px', marginBottom:'60px'}}>
                        Survey Section One: `About You and this Research Study´
                    </h4>
                </Row>

                <Row>
                    {/* Expert Feeling */}
                    <Col md={6}>
                        {/* Charttype selection */}
                        <Row className="mb-3">
                            <Col md={12}>
                                <Form.Label>Chart Type</Form.Label>
                                <ButtonGroup className="d-block">
                                {Object.keys(chartTypes).map((ct) => (
                                    <Button
                                    key={ct}
                                    variant="outline-secondary"
                                    onClick={() => setFeelingFeelingChartType(ct)}
                                    active={feelingFeelingChartType === ct}
                                    >
                                    {ct}
                                    </Button>
                                ))}
                                </ButtonGroup>
                            </Col>
                        </Row>
                        {/* Expert Feeling Chart */}
                        <Row>
                            <Col md={12}>
                                <Card>
                                <Card.Header>Expert Feeling</Card.Header>
                                <Card.Body>
                                    <GenericChart
                                    chartType={feelingFeelingChartType}
                                    obj={feelingData.expert_feeling}
                                    caption={'Expert Feeling'}
                                    />
                                </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    {/* Expert Work Level (Skill UC Modeling) */}
                    <Col md={6}>
                        {/* Charttype selection */}
                        <Row className="mb-3">
                            <Col md={12}>
                                <Form.Label>Chart Type</Form.Label>
                                <ButtonGroup className="d-block">
                                {Object.keys(chartTypes).map((ct) => (
                                    <Button
                                    key={ct}
                                    variant="outline-secondary"
                                    onClick={() => setFeelingWorkLevelChartType(ct)}
                                    active={feelingWorkLevelChartType === ct}
                                    >
                                    {ct}
                                    </Button>
                                ))}
                                </ButtonGroup>
                            </Col>
                        </Row>
                        {/* Expert Work Level (Skill UC Modeling) */}
                        <Row>
                            <Col md={12}>
                                <Card>
                                <Card.Header>Expert Work Level (Skill UC Modeling)</Card.Header>
                                <Card.Body>
                                    <GenericChart
                                    chartType={feelingWorkLevelChartType}
                                    obj={feelingData.expert_work_level}
                                    caption={'Expert Work Level'}
                                    />
                                </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row style={{ marginTop: '30px' }}>              
                    {/* Expert Work Layer / Daily Doing */}
                    <Col md={6}>
                        {/* Charttype selection */}
                        <Row className="mb-3">
                            <Col md={12}>
                                <Form.Label>Chart Type</Form.Label>
                                <ButtonGroup className="d-block">
                                {Object.keys(chartTypes).map((ct) => (
                                    <Button
                                    key={ct}
                                    variant="outline-secondary"
                                    onClick={() => setFeelingWorkLayerChartType(ct)}
                                    active={feelingWorkLayerChartType === ct}
                                    >
                                    {ct}
                                    </Button>
                                ))}
                                </ButtonGroup>
                            </Col>
                        </Row>
                        {/* Expert Work Layer / Daily Doing */}
                        <Row>
                            <Col md={12}>
                                <Card>
                                <Card.Header>Expert Work Layer / Daily Doing</Card.Header>
                                <Card.Body>
                                    <GenericChart
                                        chartType={feelingWorkLayerChartType}
                                        obj={feelingData.expert_work_layer}
                                        caption={'Expert Work Layer'}
                                    />
                                </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    {/* Expert Confidence */}
                    <Col md={6}>
                        {/* Charttype selection */}
                        <Row className="mb-3">
                            <Col md={12}>
                                <Form.Label>Chart Type</Form.Label>
                                <ButtonGroup className="d-block">
                                {Object.keys(chartTypes).map((ct) => (
                                    <Button
                                    key={ct}
                                    variant="outline-secondary"
                                    onClick={() => setFeelingConfidenceChartType(ct)}
                                    active={feelingConfidenceChartType === ct}
                                    >
                                    {ct}
                                    </Button>
                                ))}
                                </ButtonGroup>
                            </Col>
                        </Row>
                        {/* Expert Confidence */}
                        <Row>
                            <Col md={12}>
                                <Card>
                                <Card.Header>Expert Confidence</Card.Header>
                                <Card.Body>
                                    <GenericChart
                                        chartType={feelingConfidenceChartType}
                                        obj={feelingData.expert_confidence}
                                        caption={'Expert Confidence'}
                                    />
                                </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* Section 2: Value of UCM Method */}
                <Row>
                    <h4 style={{ fontWeight: 'bold', marginTop:'30px', marginBottom:'60px'}}>
                        Survey Section Two: `Added Value of UCM Method´
                    </h4>
                </Row>

                <Row>
                    {/* UCM usefullness */}
                    <Col md={4}>
                        {/* Charttype selection */}
                        <Row className="mb-3">
                            <Col md={12}>
                                <Form.Label>Chart Type</Form.Label>
                                <ButtonGroup className="d-block">
                                {Object.keys(chartTypes).map((ct) => (
                                    <Button
                                        key={ct}
                                        variant="outline-secondary"
                                        onClick={() => setValueValueChartType(ct)}
                                        active={valueValueChartType === ct}
                                    >
                                        {ct}
                                    </Button>
                                ))}
                                </ButtonGroup>
                            </Col>
                        </Row>
                        {/* UCM usefullness */}
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Header>UCM Usefulness</Card.Header>
                                    <Card.Body>
                                        <GenericChart
                                            chartType={valueValueChartType}
                                            obj={addedValueData.ucm_usefullness}
                                            caption={'UCM Usefulness'}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    {/* UCM effectiveness */}
                    <Col md={4}>
                        {/* Charttype selection */}
                        <Row className="mb-3">
                            <Col md={12}>
                                <Form.Label>Chart Type</Form.Label>
                                <ButtonGroup className="d-block">
                                {Object.keys(chartTypes).map((ct) => (
                                    <Button
                                        key={ct}
                                        variant="outline-secondary"
                                        onClick={() => setValueEffectivenessChartType(ct)}
                                        active={valueEffectivenessChartType === ct}
                                    >
                                        {ct}
                                    </Button>
                                ))}
                                </ButtonGroup>
                            </Col>
                        </Row>
                        {/* UCM effectiveness */}
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Header>UCM Effectiveness</Card.Header>
                                    <Card.Body>
                                        <GenericChart
                                            chartType={valueEffectivenessChartType}
                                            obj={addedValueData.ucm_effectiveness}
                                            caption={'UCM Effectiveness'}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    {/* UCM Most Useful Aspects */}
                    <Col md={4}>
                        {/* Charttype selection */}
                        <Row className="mb-3">
                            <Col md={12}>
                                <Form.Label>Chart Type</Form.Label>
                                <ButtonGroup className="d-block">
                                {Object.keys(chartTypes).map((ct) => (
                                    <Button
                                        key={ct}
                                        variant="outline-secondary"
                                        onClick={() => setValueMostUsefulAspectsChartType(ct)}
                                        active={valueMostUsefulAspectsChartType === ct}
                                    >
                                        {ct}
                                    </Button>
                                ))}
                                </ButtonGroup>
                            </Col>
                        </Row>
                        {/* UCM Most Useful Aspects */}
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Header>UCM Most Useful Aspects</Card.Header>
                                    <Card.Body>
                                        <GenericChart
                                            chartType={valueMostUsefulAspectsChartType}
                                            obj={addedValueData.ucm_most_useful_aspects}
                                            caption={'UCM Most Useful Aspects'}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row style={{ marginTop: '30px' }}>
                    {/* UCM method clarity */}
                    <Col md={6}>
                        {/* Charttype selection */}
                        <Row className="mb-3">
                            <Col md={12}>
                                <Form.Label>Chart Type</Form.Label>
                                <ButtonGroup className="d-block">
                                {Object.keys(chartTypes).map((ct) => (
                                    <Button
                                        key={ct}
                                        variant="outline-secondary"
                                        onClick={() => setValueMethodClarityChartType(ct)}
                                        active={valueMethodClarityChartType === ct}
                                    >
                                        {ct}
                                    </Button>
                                ))}
                                </ButtonGroup>
                            </Col>
                        </Row>
                        {/* UCM method clarity */}
                        <Row>
                            <Col md={12}>
                                <Card className='h-100'>
                                    <Card.Header>UCM Method Clarity</Card.Header>
                                    <Card.Body>
                                        <GenericChart
                                            chartType={valueMethodClarityChartType}
                                            obj={addedValueData.ucm_method_clarity}
                                            caption={'UCM Method Clarity'}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    {/* UCM Additional Comments */}
                    <Col md={6}>
                        {/* Charttype selection */}
                        <Row className="mb-3">
                            <Col md={12}>
                                <Form.Label>---</Form.Label>
                                <ButtonGroup className="d-block">
                                    <Button
                                        variant="outline-secondary"
                                        disabled
                                    >
                                        N/A
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>                        
                        {/* UCM Additional Comments */}
                        <Row>
                            <Col md={12}>
                                <Card className='h-100'>
                                    <Card.Header>UCM Additional Comments</Card.Header>
                                    <Card.Body>
                                        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                            {addedValueData?.ucm_additional_comments !== undefined && addedValueData?.ucm_additional_comments.length > 0 ? (
                                                addedValueData?.ucm_additional_comments.map((comment, idx) => (
                                                    <p key={idx} className="small">
                                                        {comment}
                                                    </p>
                                                ))
                                            ) : (
                                                <p>No additional comments available</p>
                                            )}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* Section 3: Model Usage */}
                <Row>
                    <h4 style={{ fontWeight: 'bold', marginTop:'30px', marginBottom:'60px'}}>
                        Survey Section Three: `Model Usage´
                    </h4>
                </Row>

                <Row>
                    {/* Model input */}
                    <Col md={6}>
                        {/* Charttype selection */}
                        <Row className="mb-3">
                            <Col md={12}>
                                <Form.Label>Chart Type</Form.Label>
                                <ButtonGroup className="d-block">
                                {Object.keys(chartTypes).map((ct) => (
                                    <Button
                                        key={ct}
                                        variant="outline-secondary"
                                        onClick={() => setModelInputChartType(ct)}
                                        active={modelInputChartType === ct}
                                    >
                                        {ct}
                                    </Button>
                                ))}
                                </ButtonGroup>
                            </Col>
                        </Row>
                        {/* Model input */}
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Header>Model Input</Card.Header>
                                    <Card.Body>
                                        <GenericChart
                                            chartType={modelInputChartType}
                                            obj={modelUsageData.model_input}
                                            caption={'Model Input'}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    {/* Model LLM integration */}
                    <Col md={6}>
                        {/* Charttype selection */}
                        <Row className="mb-3">
                            <Col md={12}>
                                <Form.Label>Chart Type</Form.Label>
                                <ButtonGroup className="d-block">
                                {Object.keys(chartTypes).map((ct) => (
                                    <Button
                                        key={ct}
                                        variant="outline-secondary"
                                        onClick={() => setModelLLMIntegrationChartType(ct)}
                                        active={modelLLMIntegrationChartType === ct}
                                    >
                                        {ct}
                                    </Button>
                                ))}
                                </ButtonGroup>
                            </Col>
                        </Row>
                        {/* Model LLM integration */}
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Header>Model LLM Integration</Card.Header>
                                    <Card.Body>
                                        <GenericChart
                                            chartType={modelLLMIntegrationChartType}
                                            obj={modelUsageData.model_llm_integration}
                                            caption={'Model LLM Integration'}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row style={{ marginTop: '30px' }}>
                    {/* Model Semantic Clarity */}
                    <Col md={6}>
                        {/* Charttype selection */}
                        <Row className="mb-3">
                            <Col md={12}>
                                <Form.Label>Chart Type</Form.Label>
                                <ButtonGroup className="d-block">
                                {Object.keys(chartTypes).map((ct) => (
                                    <Button
                                        key={ct}
                                        variant="outline-secondary"
                                        onClick={() => setModelSemanticClarityChartType(ct)}
                                        active={modelSemanticClarityChartType === ct}
                                    >
                                        {ct}
                                    </Button>
                                ))}
                                </ButtonGroup>
                            </Col>
                        </Row>
                        {/* Model Semantic Clarity */}
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Header>Model Semantic Clarity</Card.Header>
                                    <Card.Body>
                                        <GenericChart
                                            chartType={modelSemanticClarityChartType}
                                            obj={modelUsageData.model_semantic_clarity}
                                            caption={'Model Semantic Clarity'}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    {/* Model Agreed Aspects */}
                    <Col md={6}>
                        {/* Charttype selection */}
                        <Row className="mb-3">
                            <Col md={12}>
                                <Form.Label>Chart Type</Form.Label>
                                <ButtonGroup className="d-block">
                                {Object.keys(chartTypes).map((ct) => (
                                    <Button
                                        key={ct}
                                        variant="outline-secondary"
                                        onClick={() => setModelAgreedAspectsChartType(ct)}
                                        active={modelAgreedAspectsChartType === ct}
                                    >
                                        {ct}
                                    </Button>
                                ))}
                                </ButtonGroup>
                            </Col>
                        </Row>
                        {/* Model Agreed Aspects */}
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Header>Model Agreed Aspects</Card.Header>
                                    <Card.Body>
                                        <GenericChart
                                            chartType={modelAgreedAspectsChartType}
                                            obj={modelUsageData.model_agreed_aspects}
                                            caption={'Model Agreed Aspects'}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* Additional Comments */}
                <Row style={{ marginTop: '30px' }}>
                    {/* Model Usage Comments */}
                    <Col md={12}>
                        <Card className="h-100">
                            <Card.Header>Model Usage Comments</Card.Header>
                            <Card.Body>
                                <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                    {modelUsageData?.model_additional_comments !== undefined && modelUsageData?.model_additional_comments.length > 0 ? (
                                        modelUsageData?.model_additional_comments.map((comment, idx) => (
                                            <p key={idx} className="small">
                                                {comment}
                                            </p>
                                        ))
                                    ) : (
                                        <p>No additional comments available</p>
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>    

                {/* Section 4: User Experience */}
                <Row>
                    <h4 style={{ fontWeight: 'bold', marginTop:'30px', marginBottom:'60px'}}>
                        Survey Section Four: `User Experience´
                    </h4>
                </Row>

                <Row>
                    {/* Reproducibiliry */}
                    <Col md={6}>
                        {/* Charttype selection */}
                        <Row className="mb-3">
                            <Col md={12}>
                                <Form.Label>Chart Type</Form.Label>
                                <ButtonGroup className="d-block">
                                {Object.keys(chartTypes).map((ct) => (
                                    <Button
                                    key={ct}
                                    variant="outline-secondary"
                                    onClick={() => setExperienceReproducibilityChartType(ct)}
                                    active={experienceReproducibilityChartType === ct}
                                    >
                                    {ct}
                                    </Button>
                                ))}
                                </ButtonGroup>
                            </Col>
                        </Row>
                        {/* Reproducibiliry */}
                        <Row>
                            <Col md={12}>
                                <Card>
                                <Card.Header>Reproducibility</Card.Header>
                                <Card.Body>
                                    <GenericChart
                                    chartType={experienceReproducibilityChartType}
                                    obj={userExperience.reproducibility}
                                    caption={'Reproducibility'}
                                    />
                                </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    {/* Overall Experience */}
                    <Col md={6}>
                        {/* Charttype selection */}
                        <Row className="mb-3">
                            <Col md={12}>
                                <Form.Label>Chart Type</Form.Label>
                                <ButtonGroup className="d-block">
                                {Object.keys(chartTypes).map((ct) => (
                                    <Button
                                    key={ct}
                                    variant="outline-secondary"
                                    onClick={() => setExperienceOverallExperienceChartType(ct)}
                                    active={experienceOverallExperienceChartType === ct}
                                    >
                                    {ct}
                                    </Button>
                                ))}
                                </ButtonGroup>
                            </Col>
                        </Row>
                        {/* Overall Experience */}
                        <Row>
                            <Col md={12}>
                                <Card>
                                <Card.Header>Overall Experience</Card.Header>
                                <Card.Body>
                                    <GenericChart
                                    chartType={experienceOverallExperienceChartType}
                                    obj={userExperience.overall_experience}
                                    caption={'Overall Experience'}
                                    />
                                </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row style={{ marginTop: '30px' }}>
                    {/* Additional comments */}
                    <Col md={12}>
                        <Card className="h-100">
                            <Card.Header>Additional comments</Card.Header>
                            <Card.Body>
                                <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                    {userExperience?.additional_comments !== undefined && userExperience?.additional_comments.length > 0 ? (
                                        userExperience?.additional_comments.map((comment, idx) => (
                                            <p key={idx} className="small">
                                                {comment}
                                            </p>
                                        ))
                                    ) : (
                                        <p>No additional comments available</p>
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>  
                </Row>
     

                {/* Individual Participant's Detailed Responses in Modal */}
                <Modal show={!!selectedParticipant} onHide={() => setSelectedParticipant(null)} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Participant's Responses</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedParticipant && (
                            <Table bordered>
                                <tbody className='small'>
                                    <tr><th>Anonymous ID</th><td>{selectedParticipant.anonymous_id?.value}</td></tr>
                                    <tr><th>Role</th><td>{selectedParticipant.role?.value || 'N/A'}</td></tr>
                                    <tr><th>Expert Field</th><td>{selectedParticipant.expert_field?.value || 'N/A'}</td></tr>
                                    <tr><th>Experience</th><td>{selectedParticipant.experience?.value || 'N/A'}</td></tr>     
                                    <tr><th>Place</th><td>{selectedParticipant.place?.value || 'N/A'}</td></tr>
                                    <tr><th>Year of Birth</th><td>{selectedParticipant.yearOfBirth?.value || 'N/A'}</td></tr>
                                    {/* Dynamically render responses for each question set */}
                                    {Object.entries(selectedParticipant).map(([sectionKey, sectionObj]) => {

                                        if (typeof sectionObj !== 'object') return null;

                                        return (
                                            <React.Fragment key={sectionKey}>
                                            {Object.entries(sectionObj).map(([questionKey, questionObj]) => {
                                                if (questionObj?.setLabel && questionObj?.question) {
                                                return (
                                                    <React.Fragment key={questionKey}>
                                                    <tr>
                                                        <th colSpan="2">{questionObj.setLabel}</th>
                                                    </tr>
                                                    <tr>
                                                        <th>{questionObj.question}</th>
                                                        <td>
                                                        {Array.isArray(questionObj.answer)
                                                            ? questionObj.answer.join(", ")
                                                            : questionObj.answer}
                                                        </td>
                                                    </tr>
                                                    </React.Fragment>
                                                );
                                                }
                                                return null;
                                            })}
                                            </React.Fragment>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setSelectedParticipant(null)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Participants by Role / Expert Modal */}
                <Modal
                    show={Boolean(modalFieldParticipants || modalRoleParticipants)}
                    onHide={() => {
                        setModalFieldParticipants(null);
                        setModalRoleParticipants(null);
                    }}
                    size="lg"
                    >
                    <Modal.Header closeButton>
                        <Modal.Title>
                        {modalFieldParticipants
                            ? "Participants by Expert Field"
                            : "Participants by Role"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {modalFieldParticipants && (
                        <Table bordered>
                            <thead>
                            <tr>
                                <th>Anonymous ID</th>
                                <th>Expert Field</th>
                                <th>Role</th>
                                <th>Place</th>
                                <th>Year of Birth</th>
                            </tr>
                            </thead>
                            <tbody>
                            {modalFieldParticipants.map((p, i) => (
                                <tr key={i}>
                                <td>{p.anonymous_id?.value}</td>
                                <td>{p.expert_field?.value || "N/A"}</td>
                                <td>{p.role?.value || "N/A"}</td>
                                <td>{p.place?.value || "N/A"}</td>
                                <td>{p.yearOfBirth?.value || "N/A"}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                        )}
                        {modalRoleParticipants && (
                        <Table bordered>
                            <thead>
                            <tr>
                                <th>Anonymous ID</th>
                                <th>Role</th>
                                <th>Expert Field</th>
                                <th>Place</th>
                                <th>Year of Birth</th>
                            </tr>
                            </thead>
                            <tbody>
                            {modalRoleParticipants.map((p, i) => (
                                <tr key={i}>
                                <td>{p.anonymous_id?.value}</td>
                                <td>{p.role?.value || "N/A"}</td>
                                <td>{p.expert_field?.value || "N/A"}</td>
                                <td>{p.place?.value || "N/A"}</td>
                                <td>{p.yearOfBirth?.value || "N/A"}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                        variant="secondary"
                        onClick={() => {
                            setModalFieldParticipants(null);
                            setModalRoleParticipants(null);
                        }}
                        >
                        Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Detail Modal 
                {modalData && (
                    <DetailModal show={showModal} handleClose={() => setShowModal(false)} data={modalData} />
                )}
                */}

            </Col>
        </Row>
    );
};

/*
const DetailModal = ({ show, handleClose, data }) => (
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>{data.topic}: {data.category}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h6>List of Participants in "{data.category}"</h6>
            <ul>
                {data.res.map((item, index) => (
                    <li key={index}>{item.anonymous_id?.value || 'N/A'}</li>
                ))}
            </ul>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
        </Modal.Footer>
    </Modal>
);
*/