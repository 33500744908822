/**
 * Ecosystem Analytics Charts
 * 
 * Description: These charts provide insights into the modeled use cases overall - USE CASE INSIGHTS.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-06-26 (last updated 30.06.2024)
 * 
 */

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Button, Card, Row, Col, Table, Modal, Accordion, Badge, Alert, OverlayTrigger, Tooltip, ListGroup, Form, Dropdown } from 'react-bootstrap';
import { Chart, Bar, Pie, Scatter, Bubble } from 'react-chartjs-2';

import { generateAnalyticsData, prepareChartData, aggregateData, getNextBlueGreenColor, getColorForItem, getBlueGreenColor } from './eco_analytics_prepare_charts';

import {
  //countDefaultFields,
  //countFieldsGivenSetofUseCases,
} from './eco_analytics_prepare_charts';

import {
    Chart as ChartJS,

    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
    ArcElement,
} from 'chart.js';

import { MatrixController, MatrixElement } from "chartjs-chart-matrix";
  
// Register the required components with Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  ArcElement,

  MatrixController, 
  MatrixElement
);

// #########################################################################################################################
// #################################### View-Agnostic Statistics Dashboard #################################################
// #########################################################################################################################

/**
 * Statistics Modeled Use Cases Dashboard
 */
const StatisticsModeledUCs = ({ data, handleClear }) => {

  let analytics = generateAnalyticsData(data);

  const isAnalyticsReady = analytics && Object.keys(analytics).length > 0;

  //const actor_component_data = aggregateActorsAndComponents(data);
  
  //console.log('Actor and Component data:', actor_component_data);
  //console.log('Analytics data:', analytics);

  const { 

    // components aggregator
    totalComponents, // distinct comp items

    // Sample size
    totalUseCases,

    // Step-based aggregator
    stepCounts,

    // Actor aggregator

      allNodes,
      allEdges,
      actorList,

      totalEdgeConnections,
      totalActorNodes,
      actorCategories,        // e.g. { Consumer: 5, Producer: 7, ... }
      actorCategoriesCount,   // e.g. { Consumer: 5, Producer: 7, ... }
      totalActorsInList,      // sum of all .list references
      repeatedActors,         // actor strings repeated in usage
      totalRepeatedCount,     // total repeated actor strings
      distinctActors,         //: uniqueActorIDs.size,
      actorUsage,             // map of each actor => usage count

    // Aggregator

      totalActions,
      totalConditions,
      totalRelations,
      totalActorEntries,                // how many references from actorObj.value across categories

    // Component aggregator

      componentCategories,
      // simple counting items
      componentCategoryCount,
      totalComponentItems,              // sum of all item references
      distinctComponents,               // # distinct item strings
      componentCategoryDetails,         // cat => { total, items:[] }
      componentCategoryDetailsNested,   // cat => { subMap:{desc => {description, items:[]}}, subcomponents:[] }
      repeatedComponentCount,
      totalRepeatedComponentUsage,

  } = aggregateData(data);

  /*
    handleGenericStatistics, 
    handleChartHover 
  */

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  // Handle click on the generic statistics charts
  const handleGenericStatistics = (evt, elems, chartData, topic) => {

    if (elems.length > 0) {
      const clickedIndex = elems[0].index;
      const clickedCategory = chartData.labels[clickedIndex];

      // Filter use cases by the clicked category status
      let useCasesInCategory =  [];
      if (topic === 'Maturity Levels') {
        useCasesInCategory = data.filter((useCase) => useCase.maturity.value === clickedCategory);
      } else if (topic === 'Status') {
        useCasesInCategory = data.filter((useCase) => useCase.status.value === clickedCategory);
      } else if (topic === 'Application Areas') {
        useCasesInCategory = data.filter((useCase) => useCase.application.value === clickedCategory);
      }

      // Get the names of the filtered use cases
      const useCaseNames = useCasesInCategory.map((useCase) => useCase.title.value);

      const clickedData = {
        topic: topic,
        category: clickedCategory,
        res: useCaseNames,
      };

      setModalData(clickedData);
      setShowModal(true);
    }
  };

  // Handle click on the actors and components charts
  const handleChartClick = (evt, elems, chartData, topic) => {
    if (elems.length > 0) {
      const clickedIndex = elems[0].index;
      const clickedCategory = chartData.labels[clickedIndex];      
  
      let inCategory = [];
  
      if (topic === 'Actors per Category') {
        inCategory = data
          .flatMap((useCase) => useCase.actors.value.nodes.value)
          .filter((actor) => actor.group === clickedCategory);      
      } else if (topic === 'Components per Category') {
        inCategory = data
          .flatMap((useCase) => useCase.components.value)
          .filter((componentCategory) => componentCategory.category === clickedCategory)
          .flatMap((componentCategory) => componentCategory.components);
      }    

      const clickedData = {
        topic: topic,
        category: clickedCategory,
        res: inCategory,
      };
  
      setModalData(clickedData);
      setShowModal(true);
    }
  };

  const handleChartHover = (evt, elems) => {
    if (elems.length > 0) {
      evt.native.target.style.cursor = 'pointer';
    } else {
      evt.native.target.style.cursor = 'default';
    }
  };

  return (
    <Row>
      <Col md={12} style={{ textAlign: 'left' }} id="content">
        {/* Title */}
        <Row style={ { marginBottom:'20px', marginTop:'20px'} }>        
          <Col>
            {handleClear ? (  
              <span>Insights to modeled use cases</span>
            ) : (
              <span>
                Insights to selected modeled use case:
                <Badge bg="info" style={{ marginLeft: '5px' }}>{data[0].title.value}</Badge>                
              </span>
            )}
          </Col>          
          <Col className="d-flex justify-content-end">                
          {handleClear && (
            <Button variant="outline-primary" className="btn-sm" style={{ marginRight: '10px' }} onClick={handleClear}>
              <i className="bi bi-arrow-left"></i> Go back
            </Button>
          )}
          </Col>   
        </Row>
                          
        {isAnalyticsReady && (
          <>
            {/* General Insights */}
            <Row style={{ marginTop: '20px' }}>
              <Col md={12}>
                <Card className="h-100">
                  <Card.Header>General Insights</Card.Header>
                  <Card.Body>
                    <GeneralInsightsSection 
                      analytics={analytics} 

                      totalUseCases={totalUseCases}

                      stepCounts={stepCounts}

                      totalEdgeConnections={totalEdgeConnections}
                      totalActorNodes={totalActorNodes}
                      actorCategoriesCount={actorCategoriesCount}
                      totalActorsInList={totalActorsInList}
                      totalActorsEntries={totalActorEntries} 
                      distinctActors={distinctActors}
                      totalRelations={totalRelations} 

                      repeatedActors={repeatedActors}
                      totalRepeatedCount={totalRepeatedCount}

                      totalActions={totalActions} 
                      totalConditions={totalConditions}

                      componentCategoryCount={componentCategoryCount}
                      totalComponents={totalComponents} 
                      distinctComponents={distinctComponents}
                      totalComponentItems={totalComponentItems}
                      repeatedComponentCount={repeatedComponentCount}
                      totalRepeatedComponentUsage={totalRepeatedComponentUsage}

                      data={data} 

                      handleGenericStatistics={handleGenericStatistics} 
                      handleChartHover={handleChartHover} 
                      />
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            {/* Keywords analysis */}
            <Row style={{ marginTop: '20px' }}>
              <Col md={12}>
                <Card className="h-100">
                  <Card.Header>Keywords Analysis</Card.Header>
                  <Card.Body>
                    <KeywordAnalysisSection data={data} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            {/* Scenario Analysis */}
            <Row style={{ marginTop: '20px' }}>
              <Col md={12}>
                <Card className="h-100">
                  <Card.Header>Scenario Analysis</Card.Header>
                  <Card.Body>
                    <ScenarioAnalysisSection data={data} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            {/* Actors Analysis */}
            <Row style={{ marginTop: '20px' }}>
              <Col md={12}>
                <Card className="h-100">
                  <Card.Header>Actor Analysis</Card.Header>
                  <Card.Body>
                    <ActorAnalysisSection 
                      data={data} 

                      allNodes={allNodes}
                      allEdges={allEdges}
                      actorList={actorList}

                      actorCategories={actorCategories} 
                      actorUsage={actorUsage}
                      repeatedActors={repeatedActors}
                      distinctActors={distinctActors}
                      totalActorEntries={totalActorEntries}
                      handleChartClick={handleChartClick} 
                      handleChartHover={handleChartHover} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            {/* Components Analysis */}
            <Row style={{ marginTop: '20px' }}>
              <Col md={12}>
                <Card className="h-100">
                  <Card.Header>Component Analysis</Card.Header>
                  <Card.Body>
                    <ComponentAnalysisSection 

                      data={data}

                      componentCategories={componentCategories} 
                      componentCategoryDetails={componentCategoryDetails}
                      componentCategoryDetailsNested={componentCategoryDetailsNested}

                      totalComponentItems={totalComponentItems}
                      totalRepeatedComponentUsage={totalRepeatedComponentUsage}
                      repeatedComponentCount={repeatedComponentCount}

                      handleChartClick={handleChartClick} 
                      handleChartHover={handleChartHover} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            {modalData && <DetailModal show={showModal} handleClose={() => setShowModal(false)} data={modalData} />}
          </>
        )}
      </Col>
    </Row>  
  );
};

const groupItemsByDescription = (data) => {
  return data.reduce((acc, component) => {
    const description = component.description || 'Unknown';
    if (!acc[description]) {
      acc[description] = [];
    }
    acc[description].push(...component.items);
    return acc;
  }, {});
};

const DetailModal = ({ show, handleClose, data }) => (

  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>{data.topic}: {data.category}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <span>Items:<b>{data.category}</b> (Total: <b>{data.res.length}</b>)</span>
      {data.topic === 'Actors per Category' && (
        <ul>
          {data.res.map((actor, index) => (
            <li key={index}>{actor.label}</li>
          ))}
        </ul>
      )}
      {data.topic === 'Components per Category' && (
        <ul>
        {Object.entries(groupItemsByDescription(data.res)).map(([description, items], index) => (
          <li key={index}>
            <strong>Description:</strong> {description}<br />
            <strong>Items:</strong><br />
            {items.map((item, idx) => (
              <span key={idx} className="badge bg-light text-dark" style={{ marginLeft: '5px' }}>
                  {item}
              </span>

            ))}
            <br />
          </li>
        ))}
        </ul>
      )}
      {(data.topic === 'Status' || data.topic === 'Maturity Levels' || data.topic === 'Application Areas') && (
        <ul>
          {data.res.map((res, index) => (
            <li key={index}>{res}</li>
          ))}
        </ul>
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
);


// #########################################################################################################################
// #################################### General Insights Section ###########################################################
// #########################################################################################################################

/**
 * General statistics, Overall numbers
 * @param {*} param0
 * @returns
 */
function GeneralStatistics({ 

    totalUseCases,

    stepCounts,

    //totalEdgeConnections,
    totalActorNodes,
    actorCategoriesCount,
    totalActorsInList,
    totalActorsEntries,
    distinctActors,
    totalRelations,
    repeatedActors,
    totalRepeatedCount,

    totalActions,
    totalConditions,

    //componentCategoryCount,
    totalComponents,
    distinctComponents,
    totalComponentItems,
    repeatedComponentCount,
    totalRepeatedComponentUsage,

    data,

    handleGenericStatistics,
    handleChartHover
    
  }) {

  return (
    <>
      {/* Overview */}
      <Row>
        {/* Summary */}
        <Col md={12} className="mb-3">
          <h6>Summary</h6>
          <ul style={{ fontSize: '0.9rem' }}>
            <li><strong>Use Cases:</strong> This view currently captures <Badge bg="info">{totalUseCases}</Badge> in the system.</li>
            <li><strong>Actions (Scenarios):</strong> A total of <Badge bg="info">{totalActions}</Badge> scenario entries were detected.</li>
            <li><strong>Conditions:</strong> A total of <Badge bg="info">{totalConditions}</Badge> conditions were identified.</li>
            <li><strong>Actors (absolute)</strong>A total of <Badge bg="info">{totalActorsEntries}</Badge> actors were identified.</li>
            <li><strong>Actors (distinct):</strong> The aggregator found <Badge bg="info">{distinctActors}</Badge> distinct actors overall.</li>
            <li><strong>Components:</strong> A total unique count of <Badge bg="info">{totalComponents}</Badge> are identified.</li>
          </ul>
          <p style={{ fontSize: '0.85rem', color: '#6c757d' }}>
            This overview highlights the basic modeling statistics for the selected dataset. 
            More specific details (e.g., distinct vs. total item usage, conditions, components) 
            can be found in the below sections.
          </p>
        </Col>

        {/* High-level counts */}
        <Col md={4}>
          <Card className="mb-4 h-100">
            <Card.Header>High-Level Counts</Card.Header>
            <Card.Body>
              <Table borderless size="sm" className="mb-0">
                <tbody>
                  <tr>
                    <td><small>Total Use Cases</small></td>
                    <td className="text-end"><strong>{totalUseCases}</strong></td>
                  </tr>
                  <tr>
                    <td><small>Total Description</small></td>
                    <td className="text-end"><strong>{stepCounts.step1_general}</strong></td>
                  </tr>
                  <tr>
                    <td><small>Total Actions (scenarios)</small></td>
                    <td className="text-end"><strong>{totalActions}</strong></td>
                  </tr>
                  <tr>
                    <td><small>Total Conditions (across scenarios)</small></td>
                    <td className="text-end"><strong>{totalConditions}</strong></td>
                  </tr>
                  <tr>
                    <td><small>Total Actors (absolute)</small></td>
                    <td className="text-end"><strong>{totalActorsEntries}</strong></td>
                  </tr>
                  <tr>
                    <td><small>Total Components (absolute)</small></td>
                    <td className="text-end"><strong>{distinctComponents}</strong></td>
                  </tr>
                  <tr>
                    <td><small>Total elements (absolute)</small></td>
                    <td className='text-end'>
                      <strong style={{ color: 'green' }}>
                        { 
                          stepCounts.step1_general + 
                          totalActions +
                          totalConditions +
                          totalActorsEntries +
                          distinctComponents
                        }
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>

        {/* Dataset Notes */}
        <Col md={8}>
          <Card className="mb-4 h-100">
            <Card.Header>Dataset Notes</Card.Header>
            <Card.Body style={{ fontSize: '0.85rem' }}>
              <p>
                In total, the aggregator detected 
                <strong> {totalActorsEntries} actor items</strong>, while <strong>{distinctActors} items</strong> are unique. Also, there are
                <strong> {distinctComponents} unique component items</strong>.
                Across all references, there are <strong>{totalComponents} component items</strong>.
              </p>
              <p>
                This means multiple subcomponents or repeated items 
                might appear across the modeled use cases. 
                Check below sections for deeper breakdowns.
              </p>
              <p style={{ color: '#6c757d', marginBottom: 0 }}>
                This section is meant to give a high-level overview. 
                Expand the next panel for thorough use case details.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Additional insights */}
      <Row className="mt-3">
        <Col md={12}>
          <p style={{ fontSize: '0.85rem' }}>
            Below table depicts aggregated use case data, 
            such as the total unique actors or components, 
            It should help retrieve an overview about the total counts.
            For a per-category breakdown (e.g., subcomponents in 
            <em>“Operations,” “Technical Layer,” etc.</em>), refer to the 
            specialized component tables.
          </p>
        </Col>
      </Row>

      {/* At a glance */}
      <Row>
        <Col md={12}>
          <Card>
            <Card.Header>At a glance</Card.Header>
            <Card.Body style={{ maxHeight: '460px', overflowY: 'auto' }}>
              <Table bordered hover size="sm">
                <tbody>
                  <tr>
                    <td><small>Description (each UC = 10; sanity: {totalUseCases * 10})</small></td>
                    <td className="text-end"><strong>{stepCounts.step1_general}</strong></td>
                  </tr>
                  <tr>
                    <td><small>Total Actors (all nodes incl. fixed baseline duplicates: 7 each), that is 7 * {totalUseCases} = {totalUseCases * 7} </small></td>
                    <td className="text-end"><strong>{totalActorNodes}</strong></td>
                  </tr>
                  <tr>
                    <td><small>Total Actors (absolute): {totalActorNodes} - (7 * {totalUseCases}) = {totalActorNodes - (7 * totalUseCases)}</small></td>
                    <td className="text-end"><strong>{totalActorsEntries}</strong></td>
                  </tr>
                  <tr>
                    <td><small>Total Actors (unique count), that is: {totalActorsEntries} - {totalRepeatedCount} + {repeatedActors.length} = {totalActorsEntries -totalRepeatedCount + repeatedActors.length}</small></td>
                    <td className="text-end"><strong>{distinctActors}</strong></td>
                  </tr>
                  <tr>
                    <td><small>Total Actors (repeated, unique count)</small></td>
                    <td className="text-end"><strong>{repeatedActors.length}</strong></td>
                  </tr>
                  <tr>
                    <td><small>Total Actors (accumulated repeated actors)</small></td>
                    <td className="text-end"><strong>{totalRepeatedCount}</strong></td>
                  </tr>
                  <tr>
                    <td><small>Total Actions (Scenarios)</small></td>
                    <td className="text-end"><strong>{totalActions}</strong></td>
                  </tr>
                  <tr>
                    <td><small>Total Conditions (across scenarios)</small></td>
                    <td className="text-end"><strong>{totalConditions}</strong></td>
                  </tr>
                  <tr>
                    <td><small>Total Components (absolute, excl. reference elements, categories and subcategories)</small></td>
                    <td className="text-end"><strong>{totalComponents}</strong></td>
                  </tr>
                  <tr>
                    <td><small>Total Components (unique cunt), that is {totalComponents} - {totalRepeatedComponentUsage} + {repeatedComponentCount} = {totalComponents - totalRepeatedComponentUsage + repeatedComponentCount}</small></td>
                    <td className="text-end"><strong>{distinctComponents}</strong></td>
                  </tr>
                  <tr>
                    <td><small>Total Components (repeated, unique count)</small></td>
                    <td className="text-end"><strong>{repeatedComponentCount}</strong></td>
                  </tr>
                  <tr>
                    <td><small>Total Components (accumulated repeated actors)</small></td>
                    <td className="text-end"><strong>{totalRepeatedComponentUsage}</strong></td>
                  </tr>
                  <tr>
                    <td><small>Total Component Items (absolute)</small></td>
                    <td className="text-end"><strong>{totalComponentItems}</strong></td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}

/**
 * Application area, status and maturity
 * @param {*} param0
 * @returns
 */
function UseCaseClassification({ 
  analytics, 
  handleGenericStatistics, 
  handleChartHover,
}) {

  return (
    <>
      <Row>
        {/* Application area */}
        <Col md={4}>
          <Card className="h-100">
            <Card.Header>Application Areas</Card.Header>
            <Card.Body>
              <Pie data={
                prepareChartData(analytics.application, 'Application Areas')} 
                options={{ 
                  responsive: true, 
                  maintainAspectRatio: false,
                  onClick: (evt, elems) => handleGenericStatistics(evt, elems, prepareChartData(analytics.application, 'Application'), 'Application Areas'),
                  onHover: (evt, elems) => handleChartHover(evt, elems),
                }} 
              />
            </Card.Body>
          </Card>
        </Col>
        {/* Maturity levels */}
        <Col md={4}>
            <Card className="h-100">
              <Card.Header>Maturity Levels</Card.Header>
              <Card.Body>
                <Bar data={
                  prepareChartData(analytics.maturityLevels, 'Maturity Levels')} 
                  options={{ 
                    responsive: true, 
                    maintainAspectRatio: false,
                    onClick: (evt, elems) => handleGenericStatistics(evt, elems, prepareChartData(analytics.maturityLevels, 'Maturity'), 'Maturity Levels'),
                    onHover: (evt, elems) => handleChartHover(evt, elems),
                  }} 
                />
              </Card.Body>
            </Card>
        </Col>
        {/* status */}
        <Col md={4}>
          <Card className="h-100">
            <Card.Header>Status</Card.Header>
            <Card.Body>
              <Pie 
                data={prepareChartData(analytics.status, 'status')} 
                options={{ 
                  responsive: true, 
                  maintainAspectRatio: false,
                  onClick: (evt, elems) => handleGenericStatistics(evt, elems, prepareChartData(analytics.status, 'status'), 'Status'),
                  onHover: (evt, elems) => handleChartHover(evt, elems),
                  }} 
              />
            </Card.Body>
          </Card>
        </Col>     
      </Row>
    </>
  )
}

// Scatter plot for classifying use cases per maturity and application area

function prepareScatterPlotData(useCases) {
  const xValues = []; // e.g. maturities
  const yValues = []; // e.g. application areas

  // Collect unique strings for X and Y
  useCases.forEach((uc) => {
    const xStr = uc.maturity?.value || "UnknownX";
    const yStr = uc.application?.value || "UnknownY";

    if (!xValues.includes(xStr)) xValues.push(xStr);
    if (!yValues.includes(yStr)) yValues.push(yStr);
  });

  // alphabetical order
  xValues.sort();
  yValues.sort();

  // Build a map => key: "xIndex,yIndex", value: array of useCases
  const pointMap = new Map();

  useCases.forEach((uc) => {
    const xStr = uc.maturity?.value || "UnknownX";
    const yStr = uc.application?.value || "UnknownY";

    const xIndex = xValues.indexOf(xStr);
    const yIndex = yValues.indexOf(yStr);

    if (xIndex === -1 || yIndex === -1) {
      console.warn(`Skipping use case with x="${xStr}", y="${yStr}" (mismatch)`);
      return;
    }

    const key = `${xIndex},${yIndex}`;
    if (!pointMap.has(key)) {
      pointMap.set(key, []);
    }

    const titleVal = uc.title?.value || uc.title || "Untitled";
    const keywordsVal = uc.tags?.value || [];
    const descrVal = uc.description?.value || "No description";
    const acrVal = uc.acronym?.value || "No acronym";
    const statusVal = uc.status?.value || "No status";

    pointMap.get(key).push({
      title: titleVal,
      xLabel: xStr,
      yLabel: yStr,
      keywords: keywordsVal,
      acr: acrVal,
      status: statusVal,
      description: descrVal,
    });
  });

  // Convert Map => final array of dots
  const dataset = [];
  for (const [key, arr] of pointMap.entries()) {
    const [xStr, yStr] = key.split(",");
    dataset.push({
      x: parseInt(xStr, 10),
      y: parseInt(yStr, 10),
      useCases: arr // multiple use cases can be here
    });
  }

  return {
    xValues, // maturity strings
    yValues, // application strings
    dataset,
  };
};

function CompareKeyTerms({ data }) {
  const chartRef = useRef(null);
  const [scatterData, setScatterData] = useState(null);

  // For modal
  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);

  useEffect(() => {
    if (data && data.length) {
      const prepared = prepareScatterPlotData(data);
      //console.log("Prepared aggregator =>", prepared);
      setScatterData(prepared);
    }
  }, [data]);

  if (!scatterData) return null;

  // scatterData.xValues, scatterData.yValues, scatterData.dataset
  const chartData = {
    datasets: [
      {
        label: "Use Cases (Aggregated)",
        data: scatterData.dataset, // each => { x: number, y: number, useCases: [...] }
        backgroundColor: "rgba(75,192,192,0.8)",
        pointRadius: (ctx) => {
          // scale radius by how many use cases are aggregated
          const idx = ctx.dataIndex;
          const dot = scatterData.dataset[idx];
          const count = dot.useCases.length;
          // base radius 10 plus 2 for each extra UC
          return 10 + (count - 1) * 2;
        },
        pointHoverRadius: 8,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        offset: true,
        type: "linear",
        title: { display: true, text: "Maturity" },
        min: 0, 
        max: scatterData.xValues.length - 1, // if xValues has 4 items => 0..3
        ticks: {
          stepSize: 1,
          callback: function(value) {
            // e.g. scatterData.xValues[0] => "Draft"
            return scatterData.xValues[value] || "";
          },
        },
      },
      y: {
        offset: true,
        type: "linear",
        title: { display: true, text: "Application Area" },
        min: 0,
        max: scatterData.yValues.length - 1,
        ticks: {
          stepSize: 1,
          callback: function(value) {
            // e.g. scatterData.yValues[1] => "Industry"
            return scatterData.yValues[value] || "";
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (ctx) => {
            const dot = ctx.raw; // e.g. { x, y, useCases: [...] }
            const c = dot.useCases.length;
            return c === 1
              ? "1 use case. Click for details."
              : `${c} use cases. Click for details.`;
          },
        },
      },
    },
    onHover: (evt, elements) => {
      if (!evt) return;
      evt.native.target.style.cursor = elements?.length ? "pointer" : "default";
    },
    onClick: (evt) => {
      if (!chartRef.current) return;
      const chart = chartRef.current;

      // use getElementsAtEvent or getElementsAtEventForMode
      const points = chart.getElementsAtEventForMode(
        evt,
        "nearest",
        { intersect: true },
        false
      );
      if (!points.length) return;

      const { datasetIndex, index } = points[0];
      const dot = chartData.datasets[datasetIndex].data[index];
      setModalInfo(dot);
      setShowModal(true);
    },
  };

  //console.log('modalinfo', modalInfo);

  return (
    <>
      <Scatter ref={chartRef} data={chartData} options={options} />

      {/* Modal for showing use cases */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" scrollable>
        <Modal.Header closeButton>
          <Modal.Title>Use Case(s) in Application Area <b>{modalInfo?.useCases[0].yLabel}</b> and Maturity <b>{modalInfo?.useCases[0].xLabel}</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalInfo && (
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {modalInfo.useCases.map((uc, i) => (
                <div key={i} style={{ marginBottom: "1rem" }}>
                  <h5>{uc.title} - {uc.acr}</h5>
                  <p>
                    <strong>Maturity:</strong> {uc.xLabel} <br />
                    <strong>Application:</strong> {uc.yLabel} <br />
                    <strong>Status:</strong> {uc.status} <br />
                  </p>
                  <strong>Keywords:</strong>{" "}
                  {uc.keywords.length ? (
                    <ul>
                      {uc.keywords.map((kw, idx) => (
                        <li key={idx}>{kw}</li>
                      ))}
                    </ul>
                  ) : "(none)"}
                  <p>
                  <strong>Description:</strong> {uc.description} <br />
                  </p>
                  <hr />
                </div>
              ))}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

/**
 * Use Case Distribution
 * @param {*} param0
 * @returns
 */
function UseCaseDistribution({ data }) {

  return (
    <>
      {/* Use case distribution */}
      <Row style={{ marginTop: '20px' }}>
        <Col md="12">
          {/* Compare key terms and attributes against the use cases */}
          <Card className="h-100">
            <Card.Header>Use Case Distribution at the Intersection of Application and Topic Areas</Card.Header>
            <Card.Body align='center'>
              <CompareKeyTerms data={data} /> 
            </Card.Body>
        </Card>
        </Col>
      </Row>  
    </>
  )
}

/**
 * Helper: Group an array of use cases by a given key.
 * It assumes that the use case object has a property (e.g. "role") that is an object with a "value" property.
 * The value is normalized (trimmed and lowercased) so that "Manager" and "manager" are grouped together.
 */
function groupUseCasesByKey(useCases, key) {
  return useCases.reduce((groups, uc) => {
    let keyVal = "unknown";
    if (uc[key] && typeof uc[key] === "object" && uc[key].value) {
      keyVal = uc[key].value.toString().trim().toLowerCase();
    } else if (typeof uc[key] === "string") {
      keyVal = uc[key].trim().toLowerCase();
    }
    if (!groups[keyVal]) {
      groups[keyVal] = [];
    }
    groups[keyVal].push(uc);
    return groups;
  }, {});
}

/**
 * Helper: Get a displayable value for a field.
 * If the field is an object with a "value" property, return that.
 * Otherwise, return the field itself (or an empty string if falsy).
 */
function getFieldText(uc, field) {
  const val = uc[field];
  if (val && typeof val === "object" && "value" in val) {
    return val.value;
  }
  return val || "";
}

/**
 * Main component that classifies use cases by a chosen expert key.
 * You can pass groupBy="expert_field", "role", or "experience" (or any other key).
 * When grouping by role, a different table layout is used.
 */
function ClassifyExpertsUseCaseChoice({ data }) {

  const [groupBy, setGroupBy] = useState("expert_field");
  const groups = useMemo(() => groupUseCasesByKey(data, groupBy), [data, groupBy]);
  const [searchQuery, setSearchQuery] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [currentGroupKey, setCurrentGroupKey] = useState("");
  const [groupUseCases, setGroupUseCases] = useState([]);
  const [modalContent, setModalContent] = useState("");

  // Filter groups based on search query.
  const filteredGroups = useMemo(
    () =>
      Object.entries(groups).filter(([key]) =>
        key.includes(searchQuery.toLowerCase())
      ),
    [groups, searchQuery]
  );

  const handleShowDetails = (groupKey) => {
    setCurrentGroupKey(groupKey);
    setGroupUseCases(groups[groupKey] || []);
    setModalShow(true);
  };

  return (
    <>
      {/* Navigation */}
      <Row>
        <Col>
        <Form.Control
          type="text"
          placeholder={`Search by ${groupBy}...`}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ marginBottom: "1rem" }}
        />
        </Col>
        <Col>
        <Dropdown>
          <Dropdown.Toggle variant="primary" id="groupby-dropdown">
            Group By: {groupBy}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setGroupBy("expert_field")}>
              Expert Field
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setGroupBy("role")}>
              Role
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setGroupBy("experience")}>
              Experience
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setGroupBy("yearOfBirth")}>
              Year of Birth (Expert)
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        </Col>
      </Row>  
      {/* Table of groups */}    
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>{groupBy}</th>
            <th>Count</th>
            <th>Use Case Titles</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredGroups.map(([groupKey, ucArray], idx) => {

              //const first = ucArray[0];
              //const year = getFieldText(first, "yearOfBirth") || "N/A";
              //const experience = getFieldText(first, "experience") || "N/A";
              //const fullPrompt = getFieldText(first, "prompt") || "";
              //const prompt = fullPrompt.length > 100 ? fullPrompt.slice(0, 100) + "…" : fullPrompt;
              
              return (
                <tr key={idx} style={{ cursor: "pointer" }} onClick={() => handleShowDetails(groupKey)}>
                  <td>{groupKey}</td>
                  <td>{ucArray.length}</td>                                                  
                  <td>
                    {ucArray
                      //.slice(0, 3)
                      .map((uc) => getFieldText(uc, "title") || "Untitled")
                      .join(", ")}
                  </td>
                  <td>
                    <Button variant="outline-primary" size="sm" onClick={() => handleShowDetails(groupKey)}>
                      Details
                    </Button>
                  </td>
                </tr>
              );
            
          })}
        </tbody>
      </Table>

      <Modal show={modalShow} onHide={() => setModalShow(false)} size="xl" scrollable>
        <Modal.Header closeButton>
          <Modal.Title>Details for Group: {currentGroupKey}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Toggle between card and table view */}
          <Button
            style={{ marginBottom: "1rem" }}
            variant="outline-secondary"
            size="sm"
            onClick={() => setModalContent(modalContent === "table" ? "card" : "table")}
          >
            Toggle to {modalContent === "table" ? "Card View" : "Table View"}
          </Button>
          {modalContent === "card" ? (        
            <>
              {/* Card view of use cases in the group */}
              {groupUseCases.map((uc, idx) => (
                <Card key={idx} className="mb-2">
                  <Card.Body>
                    <h5>{getFieldText(uc, "title") || "Untitled"}</h5>
                    <p><strong>Keywords:</strong>{Array.isArray(uc.tags?.value) ? uc.tags.value.join(", ") : uc.tags.value || "N/A"}</p>
                    <p><strong>Prompt:</strong> {getFieldText(uc, "prompt") || "No prompt"}</p>
                    <p>
                      <strong>Expert Info:</strong> Year: {getFieldText(uc, "yearOfBirth") || "N/A"}, Experience: {getFieldText(uc, "experience") || "N/A"}, Role: {getFieldText(uc, "role") || "N/A"}
                    </p>
                  </Card.Body>
                </Card>
              ))}
            </>
          ) : (
            <>
            {/* Table view of use cases in the group */}
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Keywords</th>
                  <th>Application</th>
                  <th>Status</th>
                  <th>Maturity</th>
                  <th>Expert Info</th>
                  <th>Initial Prompt</th>
                </tr>
              </thead>
              <tbody className='small'>
                {groupUseCases.map((uc, idx) => (
                  <tr key={idx}>  
                    <td>{getFieldText(uc, "title") || "Untitled"}</td>
                    <td>{Array.isArray(uc.tags?.value) ? uc.tags.value.join(", ") : uc.tags.value || "N/A"}</td>
                    <td>{getFieldText(uc, "application") || "N/A"}</td>
                    <td>{getFieldText(uc, "status") || "N/A"}</td>
                    <td>{getFieldText(uc, "maturity") || "N/A"}</td>
                    <td>
                      <div>
                        <strong>Year:</strong> {getFieldText(uc, "yearOfBirth") || "N/A"}<br />
                        <strong>Experience:</strong> {getFieldText(uc, "experience") || "N/A"}<br />
                        <strong>Expert Field</strong> {getFieldText(uc, "expert_field") || "N/A"}<br />
                        <strong>Role:</strong> {getFieldText(uc, "role") || "N/A"}                      
                      </div>
                    </td>
                    <td>{getFieldText(uc, "prompt")}</td>
                  </tr>
                ))}
              </tbody>
            </Table>      
          </>
          )}                    
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

/**
 * Shows a cross-tabulation of Expert Field and Role.
 * Each cell shows the count of use cases for a given field-role combination.
 */
function ExpertsMatrix({ data }) {
  // Extract unique expert fields and roles
  const uniqueFields = useMemo(() => {
    return [...new Set(data.map((uc) => uc.expert_field?.value || "Unknown"))];
  }, [data]);

  const uniqueRoles = useMemo(() => {
    return [...new Set(data.map((uc) => uc.role?.value || "Unknown"))];
  }, [data]);

  // Construct the matrix where fields are rows and roles are columns
  const matrixData = useMemo(() => {
    const groupedData = {};

    data.forEach((uc) => {
      const field = uc.expert_field?.value || "Unknown";
      const role = uc.role?.value || "Unknown";
      const key = `${field}_${role}`;

      if (!groupedData[key]) {
        groupedData[key] = { field, role, count: 0, useCases: [] };
      }
      groupedData[key].count += 1;
      groupedData[key].useCases.push(uc);
    });

    return groupedData;
  }, [data]);

  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>Expert Field \ Role</th>
          {uniqueRoles.map((role, idx) => (
            <th key={idx}>{role}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {uniqueFields.map((field, rowIdx) => (
          <tr key={rowIdx}>
            <td><strong>{field}</strong></td>
            {uniqueRoles.map((role, colIdx) => {
              const cellKey = `${field}_${role}`;
              const cellData = matrixData[cellKey] || { count: 0, useCases: [] };
              
              return (
                <td key={colIdx} style={{ textAlign: "center" }}>
                  {cellData.count > 0 ? (
                    <>
                      <strong>{cellData.count}</strong>
                      <br />
                      <small>
                        {cellData.useCases
                          .slice(0, 2)
                          .map((uc) => uc.title?.value || "Untitled")
                          .join(", ")}
                      </small>
                    </>
                  ) : (
                    "-"
                  )}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

/**
 * General Insights Section
 * @param {*} param0
 * @returns
 */
function GeneralInsightsSection({ 

    analytics,

    totalUseCases,

    stepCounts,

    totalEdgeConnections,
    totalActorNodes,
    actorCategoriesCount,
    totalActorsInList,
    totalActorsEntries,
    distinctActors,
    totalRelations,
    repeatedActors,
    totalRepeatedCount,

    totalActions,
    totalConditions,

    componentCategoryCount,
    totalComponents,
    distinctComponents,
    totalComponentItems,
    repeatedComponentCount,
    totalRepeatedComponentUsage,

    data,

    handleGenericStatistics,
    handleChartHover
  }) {

  return (
    <Accordion style={{ marginTop: '20px' }}>

      {/* Section Analysis */}
      <Accordion.Item>
        <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
          <Badge bg="success" className="me-2">Overall Analysis:</Badge>
          <h5 style={{ margin: 0 }}>
            General data and insights about the use case actors, components, and actions.
          </h5>
        </div>
      </Accordion.Item>

      {/* General statistics */}
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">1</Badge>
          General Data
        </Accordion.Header>
        <Accordion.Body>
          {/* General statistics */}
          <GeneralStatistics 
              totalUseCases={totalUseCases}
              stepCounts={stepCounts}

              totalEdgeConnections={totalEdgeConnections}
              totalActorNodes={totalActorNodes}
              actorCategoriesCount={actorCategoriesCount}
              totalActorsInList={totalActorsInList}
              totalActorsEntries={totalActorsEntries} 
              distinctActors={distinctActors}
              totalRelations={totalRelations} 
              repeatedActors={repeatedActors}
              totalRepeatedCount={totalRepeatedCount}

              totalActions={totalActions} 
              totalConditions={totalConditions}

              componentCategoryCount={componentCategoryCount}
              totalComponents={totalComponents} 
              distinctComponents={distinctComponents}
              totalComponentItems={totalComponentItems}
              repeatedComponentCount={repeatedComponentCount}
              totalRepeatedComponentUsage={totalRepeatedComponentUsage}

              data={data} 

              handleGenericStatistics={handleGenericStatistics} 
              handleChartHover={handleChartHover} 
            />
        </Accordion.Body>
      </Accordion.Item>

      {/* Use Case Classification */}
      <Accordion.Item eventKey="1">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">2</Badge>
          Use Case Classification
        </Accordion.Header>
        <Accordion.Body>
          <UseCaseClassification
            analytics={analytics}
            handleGenericStatistics={handleGenericStatistics}
            handleChartHover={handleChartHover}
          />
        </Accordion.Body>
      </Accordion.Item>

      {/* Use Case Distribution */}
      <Accordion.Item eventKey="2">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">3</Badge>
          Use Case Distribution
        </Accordion.Header>
        <Accordion.Body>
          <UseCaseDistribution data={data} />
        </Accordion.Body>
      </Accordion.Item>

      {/* Section Expert */}
      <Accordion.Item>
        <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
          <Badge bg="success" className="me-2">Expert Views:</Badge>
          <h5 style={{ margin: 0 }}>
            The following sections are grouped by expert field and role.
          </h5>
        </div>
      </Accordion.Item>

      {/* Classify Experts Use Case Choice */}
      <Accordion.Item eventKey="3">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">4</Badge>
          Classify Experts Use Case Choice
        </Accordion.Header>
        <Accordion.Body>
          <ClassifyExpertsUseCaseChoice data={data} />
        </Accordion.Body>
      </Accordion.Item>

      {/* Experts Matrix (Expert Field vs Role) */}
      <Accordion.Item eventKey="4">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">5</Badge>
          Experts Matrix
        </Accordion.Header>
        <Accordion.Body>
          <ExpertsMatrix data={data} />
        </Accordion.Body>
      </Accordion.Item>

    </Accordion>
  )
}

// #########################################################################################################################
// #################################### Keyword Analysis ###################################################################
// #########################################################################################################################

/**
 * 
 */
function getKeywordDetails(data) {
  const keywordMap = {};

  data.forEach((useCase) => {
    // For the table or tooltip, we can extract the use case's title or ID
    const useCaseTitle = useCase.title || useCase.id || "Unnamed Use Case";

    // e.g. useCase.tags?.value => an array of strings
    const tags = useCase.tags?.value || [];

    tags.forEach((tag) => {
      const keyword = tag.trim().toLowerCase();

      if (!keywordMap[keyword]) {
        keywordMap[keyword] = {
          frequency: 0,
          useCases: new Set(), // Use a Set so we avoid duplicating the same useCase
        };
      }
      keywordMap[keyword].frequency += 1;
      keywordMap[keyword].useCases.add(useCaseTitle);
    });
  });

  // Convert each useCases set to an array and count them
  // Return a final object structure
  const finalMap = {};
  Object.entries(keywordMap).forEach(([kw, info]) => {
    finalMap[kw] = {
      frequency: info.frequency,
      useCases: Array.from(info.useCases),
      distinctUseCaseCount: info.useCases.size,
    };
  });
  return finalMap;
}

/**
 * Renders a bar chart of the Top N most common keywords
 * Each item => { keyword: string, frequency: number }
 */
function MostCommonKeywordsChart({ data, topN, keywordFilter }) {
  /**
   * Build the detailed keyword map: keyword => { frequency, useCases, distinctUseCaseCount }
   */
  const keywordMap = useMemo(() => {
    return getKeywordDetails(data);
  }, [data]);

  /**
   * Filter by user’s text input (case-insensitive) and sort by frequency
   */
  const filteredKeywords = useMemo(() => {
    const search = (keywordFilter || '').toLowerCase();
    const entries = Object.entries(keywordMap);

    // 1) optional text filter
    let filtered = entries;
    if (search) {
      filtered = entries.filter(([kw]) => kw.includes(search));
    }

    // 2) sort by frequency desc
    filtered.sort((a, b) => b[1].frequency - a[1].frequency);

    // 3) slice topN
    filtered = filtered.slice(0, topN);

    return filtered; // [ [keyword, {frequency, useCases, ...}], ... ]
  }, [keywordMap, keywordFilter, topN]);

  // Prepare chart data
  const labels = filteredKeywords.map(([kw]) => kw);
  const frequencies = filteredKeywords.map(([_, info]) => info.frequency);

  // Optional: color arrays
  const backgroundColors = labels.map((_, i) => getNextBlueGreenColor(i, labels.length, 0.6));
  const borderColors = labels.map((_, i) => getNextBlueGreenColor(i, labels.length, 1));

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Keyword Frequency',
        data: frequencies,
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        title: { display: true, text: 'Frequency' },
        ticks: { stepSize: 1 },
      },
      x: {
        title: { display: true, text: 'Keywords' },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function(context) {
            const freq = context.parsed.y; 
            const kwLabel = context.label;
            // Find the actual use cases from filteredKeywords
            const item = filteredKeywords.find(([kw]) => kw === kwLabel);
            const useCases = item ? item[1].useCases : [];
            const useCaseList = useCases
              .map((uc) => uc.value)
              .join(', ');

            return [
              `${kwLabel}: ${freq} occurrence(s)`,
              `Use Cases: ${useCaseList}`
            ];
          }
        }
      }
    }
  };

  return <Bar data={chartData} options={options} />;
}

/**
 * Most common keywords
 */
function MostCommonKeywords({ data }) {
  // let user pick topN
  const [topN, setTopN] = useState(10);
  // let user do a text filter
  const [keywordFilter, setKeywordFilter] = useState('');

  const handleTopNChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setTopN(value);
  };

  return (
    <Row style={{ marginTop: '20px' }}>
      <Col md={12}>
        <Card className="h-100">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <span>Keywords per Use Case</span>
            <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
              {/* Filter input */}
              <Form.Control 
                type="text"
                placeholder="Filter keywords..."
                style={{ width: '150px' }}
                value={keywordFilter}
                onChange={(e) => setKeywordFilter(e.target.value)}
              />
              {/* The dropdown for picking topN */}
              <Form.Select 
                size="sm" 
                style={{ width: 'auto' }}
                value={topN} 
                onChange={handleTopNChange}
              >
                <option value={5}>Top 5</option>
                <option value={10}>Top 10</option>
                <option value={20}>Top 20</option>
                <option value={9999}>All</option>
              </Form.Select>
            </div>
          </Card.Header>
          <Card.Body>
            <MostCommonKeywordsChart 
              data={data} 
              topN={topN} 
              keywordFilter={keywordFilter} 
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

// -----

// HELPERS for Keyword analysis

/**
 * Function to get the bubble chart data for keywords
 * Gathers:
 * 1) A unique list of all keywords across all use cases
 * 2) The frequency of each keyword => how many use cases have it
 * 3) The set of use cases for each keyword
 */
function prepareKeywordBubbleData(allUseCases) {
  // 1) Build a map from keyword => set of use cases that contain it
  //    Also track how many times the keyword appears (for bubble radius)
  const keywordMap = new Map(); // key => keyword, value => { freq, useCases: [] }

  allUseCases.forEach((uc) => {
    const titleVal = uc.title?.value || uc.title || 'Untitled';
    const tagsArr = uc.tags?.value || [];
    // For each tag => add this UC to that keyword’s aggregator
    tagsArr.forEach((kw) => {
      if (!keywordMap.has(kw)) {
        keywordMap.set(kw, { freq: 0, useCases: [] });
      }
      // increment freq
      const entry = keywordMap.get(kw);
      entry.freq++;
      // store the useCase details
      entry.useCases.push({
        title: titleVal,
        // store other tags for that UC as well
        keywords: tagsArr
      });
    });
  });

  // 2) Convert the map to an array of keywords
  const keywords = Array.from(keywordMap.keys()).sort(); // sorted alphabetical
  // If you prefer no sorting => remove .sort()

  // 3) Build the dataset points
  //    x => index in 'keywords' array,
  //    y => small random offset or 0 if you want a single line,
  //    r => freq * scale
  //    useCases => array of UC that contain this keyword
  const points = keywords.map((kw, i) => {
    const entry = keywordMap.get(kw);
    // Suppose freq => radius scale
    const radius = entry.freq * 5; // adjust scale as you like
    // optional random y offset
    const randomOffset = (Math.random() - 0.5) * 2; // between -1 and +1
    return {
      x: i,
      y: randomOffset,
      r: radius,
      keyword: kw,
      freq: entry.freq,
      useCases: entry.useCases // all UCs that have this keyword
    };
  });

  return {
    keywords, // e.g. ["AI","Parking","IoT","Data", ...]
    points
  };
}

/**
 * Bubble chart of keywords
 * Each bubble represents a keyword, its size is proportional to the frequency of that keyword.
 * Clicking on a bubble shows the use cases that contain that keyword.
 */
function KeywordBubbleChartOffSet({ data }) {
  const chartRef = useRef(null);

  const [bubbleData, setBubbleData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);

  useEffect(() => {
    if (data && data.length) {
      const prepared = prepareKeywordBubbleData(data);
      setBubbleData(prepared);
    }
  }, [data]);

  if (!bubbleData) return null;

  // Build chart data => single dataset with bubble points
  // bubbleData.points => array of { x, y, r, keyword, freq, useCases: [...] }
  const chartData = {
    datasets: [
      {
        label: 'Keywords',
        data: bubbleData.points,
        backgroundColor: 'rgba(75,192,192,0.6)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1
      }
    ]
  };

  // X axis => 0..(keywords.length-1)
  // Y axis => small range if we do random offsets
  const options = {
    responsive: true,
    scales: {
      x: {
        type: 'linear',
        min: 0,
        max: bubbleData.keywords.length - 1, // so if we have 10 keywords => x from 0..9
        ticks: {
          stepSize: 1,
          callback: function(value) {
            // e.g. if value=0 => bubbleData.keywords[0]
            return bubbleData.keywords[value] || '';
          }
        },
        title: { display: true, text: 'Keywords' }
      },
      y: {
        type: 'linear',
        min: -2,
        max: 2,
        ticks: {
          stepSize: 1
        },
        title: { display: true, text: 'Random offset' }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (ctx) => {
            // raw => { x, y, r, keyword, freq, useCases }
            const raw = ctx.raw;
            return `${raw.keyword}: ${raw.freq} use case(s). Click for details.`;
          }
        }
      }
    },
    onHover: (evt, elements) => {
      if (!evt) return;
      evt.native.target.style.cursor = elements.length ? 'pointer' : 'default';
    },
    onClick: (evt) => {
      if (!chartRef.current) return;
      const chart = chartRef.current;
      const points = chart.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, false);
      if (!points.length) return;
      const { datasetIndex, index } = points[0];
      const dot = chartData.datasets[datasetIndex].data[index];
      setModalInfo(dot);
      setShowModal(true);
    }
  };

  return (
    <>
      <Bubble ref={chartRef} data={chartData} options={options} />

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" scrollable>
        <Modal.Header closeButton>
          <Modal.Title>Use Cases for this Keyword</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalInfo && (
            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
              <h5>Keyword: {modalInfo.keyword}</h5>
              <p>Frequency: {modalInfo.freq}</p>
              {modalInfo.useCases.map((uc, i) => (
                <div key={i} style={{ marginBottom: '1rem' }}>
                  <strong>Use Case: </strong> {uc.title}
                  <br />
                  <strong>Other Keywords:</strong>
                  {uc.keywords.length > 1 ? (
                    <ul>
                      {uc.keywords
                        .filter(k => k !== modalInfo.keyword)
                        .map((k, idx) => <li key={idx}>{k}</li>)}
                    </ul>
                  ) : '(none besides this one)'}
                  <hr />
                </div>
              ))}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function HorizontalBarKeywords({ data, topN = 20 }) {
  const [showModal, setShowModal] = useState(false);
  const [selectedKeyword, setSelectedKeyword] = useState(null);

  // 1) Build a map from your aggregator
  const keywordMap = useMemo(() => getKeywordDetails(data), [data]);

  // 2) Sort by frequency desc, slice topN
  const sorted = useMemo(() => {
    const arr = Object.entries(keywordMap); // [ [kw, { frequency, useCases }], ...]
    arr.sort((a,b) => b[1].frequency - a[1].frequency);
    return arr.slice(0, topN);
  }, [keywordMap, topN]);

  // 3) Build chart data (horizontal bar => indexAxis='y')
  const labels = sorted.map(([kw]) => kw);
  const frequencies = sorted.map(([_, info]) => info.frequency);

  const chartData = {
    labels,
    datasets: [
      {
        label: "Keyword Frequency",
        data: frequencies,
        backgroundColor: "rgba(54, 162, 235, 0.6)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    indexAxis: "y", // horizontal bars
    responsive: true,
    onClick: (evt, elements) => {
      if (!elements.length) return;
      const { index } = elements[0];
      const clickedKeyword = labels[index];
      const info = keywordMap[clickedKeyword];
      //console.log("Clicked on", clickedKeyword, "with info", info);
      setSelectedKeyword({ keyword: clickedKeyword, ...info });
      setShowModal(true);
    },
    scales: {
      x: {
        title: { display: true, text: "Frequency" },
        beginAtZero: true
      },
      y: {
        title: { display: true, text: "Keyword" }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (ctx) => {
            const kwLabel = ctx.label;
            const freq = ctx.parsed.x; // b/c horizontal bar
            return `${kwLabel}: ${freq} use case(s)`;
          }
        }
      }
    }
  };

  return (
    <>
      <div style={{ width: "100%", height: "600px" }}>
        <Bar data={chartData} options={options} />
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Use Cases Containing "{selectedKeyword?.keyword}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedKeyword?.useCases.length >= 1 ? (
            <>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>IP</th>
                  <th>Created</th>
                </tr>
              </thead>
              <tbody>
                {selectedKeyword.useCases.map((uc, idx) => (
                  <tr key={idx}>
                    <td>{uc?.value}</td>
                    <td>{uc?.updated_by}</td>
                    <td>{uc?.created_timestamp}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            </>
          ) : '(none besides this one)'}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

// Function to list keywords per use case
const getKeywordsPerUseCase = (data) => {
  return data.map(useCase => ({
      useCaseTitle: useCase.title,
      keywords: useCase.tags.value.map(tag => tag.trim().toLowerCase())
  }));
};

// Function to list keywords per use case
const KeywordsPerUseCaseTable = ({ data }) => {
  const keywordsPerUseCase = getKeywordsPerUseCase(data);

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Use Case Title</th>
          <th>Keywords</th>
        </tr>
      </thead>
      <tbody>
        {keywordsPerUseCase.map((useCase, index) => (
          <tr key={index}>
            <td>{useCase.useCaseTitle.value}</td>
            <td>{useCase.keywords.join(', ')}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

/**
 * Keyword bubble chart for scenario descriptions as well as table view
 */
function KeywordsDistribution({ data }) {

  const [isKeyChart, setIsKeyChart] = useState(true);

  return (
    <>
      {/* Keywords distribution */}
      <Row style={{ marginTop: '20px' }}>
        <Col md={12}>
          <Card className="h-100">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <span>Keywords Distribution per Use Case</span>
                <Button variant="outline-primary" className="btn-sm" onClick={() => setIsKeyChart(!isKeyChart)}>
                  {isKeyChart ? (
                    <i className="bi bi-table"></i>
                  ) : (
                    <i className="bi bi-bar-chart"></i>
                  )}
                </Button>
              </Card.Header>
              <Card.Body align='center'>
                {isKeyChart ? (
                  <>
                    <HorizontalBarKeywords data={data} />

                    <KeywordBubbleChartOffSet data={data} />
                  </>
                ) : (
                  <KeywordsPerUseCaseTable data={data} />
                )}
              </Card.Body>
          </Card>
        </Col>
      </Row>  
    </>
  )
}

// ------

/**
 * Build a frequency map: keyword -> total count across all use cases.
 */
function buildKeywordFrequency(useCases) {
  const freqMap = {};
  useCases.forEach((uc) => {
    const tags = uc.tags?.value || [];
    tags.forEach((kw) => {
      freqMap[kw] = (freqMap[kw] || 0) + 1;
    });
  });
  return freqMap;
}

/**
 * Build the co-occurrence matrix for the topN keywords by frequency.
 * Returns { keywordArray, matrix }.
 */
function buildCoOccurrenceMatrixTopN(useCases, topN = 20) {
  const freqMap = buildKeywordFrequency(useCases);
  const sorted = Object.entries(freqMap).sort((a,b) => b[1]-a[1]);
  const topKeywords = sorted.slice(0, topN).map(([k]) => k).sort();

  const size = topKeywords.length;
  const matrix = Array.from({ length: size }, () => Array(size).fill(0));

  useCases.forEach((uc) => {
    const tags = [...new Set(uc.tags?.value || [])];
    const relevant = tags.filter((t) => topKeywords.includes(t));
    for (let i = 0; i < relevant.length; i++) {
      for (let j = i + 1; j < relevant.length; j++) {
        const k1 = topKeywords.indexOf(relevant[i]);
        const k2 = topKeywords.indexOf(relevant[j]);
        matrix[k1][k2] += 1;
        matrix[k2][k1] += 1;
      }
    }
  });

  return { keywordArray: topKeywords, matrix };
}

/**
 * Build an array of { k1, k2, count } for all pairs, sorted desc by count.
 * Then slice top X results.
 */
function findTopCoOccurrencePairs(matrix, keywordArray, howMany = 10) {
  const pairs = [];
  for (let i = 0; i < keywordArray.length; i++) {
    for (let j = i + 1; j < keywordArray.length; j++) {
      const count = matrix[i][j];
      if (count > 0) {
        pairs.push({ k1: keywordArray[i], k2: keywordArray[j], count });
      }
    }
  }
  pairs.sort((a,b) => b.count - a.count);
  return pairs.slice(0, howMany);
}

/**
 * Co-occurrence heatmap and top pairs
 */
function CoOccurrenceHeatmap({ data }) {
  const [isKeyChart, setIsKeyChart] = useState(true);
  const [topN, setTopN] = useState(20);

  // 1) build aggregator
  const { keywordArray, matrix } = useMemo(() => {
    return buildCoOccurrenceMatrixTopN(data, topN);
  }, [data, topN]);

  // 2) points => { x, y, v }
  const points = useMemo(() => {
    const arr = [];
    for (let i = 0; i < keywordArray.length; i++) {
      for (let j = 0; j < keywordArray.length; j++) {
        arr.push({ x: i, y: j, v: matrix[i][j] });
      }
    }
    return arr;
  }, [keywordArray, matrix]);

  // 3) chart data
  const chartData = useMemo(() => {
    return {
      datasets: [
        {
          label: "Co-Occurrence",
          data: points,
          width: (ctx) => {
            const area = ctx.chart.chartArea;
            if (!area) return 20;
            return (area.width / keywordArray.length) - 2;
          },
          height: (ctx) => {
            const area = ctx.chart.chartArea;
            if (!area) return 20;
            return (area.height / keywordArray.length) - 2;
          },
          backgroundColor: (ctx) => {
            const val = ctx.dataset.data[ctx.dataIndex].v;
            return getBlueGreenColor(val);
          },
          borderColor: "rgba(150,150,150,0.5)",
          borderWidth: 1
        }
      ]
    };
  }, [points, keywordArray]);

  // 4) chart options
  const chartOptions = useMemo(() => {
    return {
      responsive: true,
      // onClick: (evt, elements) => { ... } // if you want a click handler
      scales: {
        x: {
          type: "linear",
          position: "top",
          min: -0.5,
          max: keywordArray.length - 0.5,
          ticks: {
            autoSkip: false, // ensure all are shown
            callback: (val) => {
              const idx = Math.round(val);
              return keywordArray[idx] ?? "";
            }
          }
        },
        y: {
          type: "linear",
          reverse: true,
          min: -0.5,
          max: keywordArray.length - 0.5,
          ticks: {
            autoSkip: false,
            callback: (val) => {
              const idx = Math.round(val);
              return keywordArray[idx] ?? "";
            }
          }
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: (ctx) => {
              const { x, y, v } = ctx.dataset.data[ctx.dataIndex];
              const kx = keywordArray[x];
              const ky = keywordArray[y];
              return `${ky} & ${kx}: ${v} co-occurrence(s)`;
            }
          }
        },
        legend: { display: false }
      }
    };
  }, [keywordArray]);

  // 5) top pairs
  const topPairs = useMemo(() => {
    return findTopCoOccurrencePairs(matrix, keywordArray, 10);
  }, [matrix, keywordArray]);

  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <span>{isKeyChart ? "Co-Occurrence Matrix" : "Top Co-Occurrences"}</span>
        <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
          <Form.Select
            size="sm"
            style={{ width: 'auto' }}
            value={topN}
            onChange={(e) => setTopN(parseInt(e.target.value, 10))}
          >
            <option value={5}>Top 5</option>
            <option value={10}>Top 10</option>
            <option value={20}>Top 20</option>
            <option value={30}>Top 30</option>
            <option value={9999}>All</option>
          </Form.Select>
          <Button
            variant="outline-primary"
            className="btn-sm"
            onClick={() => setIsKeyChart(!isKeyChart)}
          >
            {isKeyChart ? <i className="bi bi-table"></i> : <i className="bi bi-bar-chart"></i>}
          </Button>
        </div>
      </Card.Header>
      <Card.Body>
        {isKeyChart ? (
          <Chart type="matrix" data={chartData} options={chartOptions} />
        ) : (
          <>
            {topPairs.length ? (
              <Table bordered size="sm" style={{ maxWidth: "500px" }}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Keyword 1</th>
                    <th>Keyword 2</th>
                    <th>Count</th>
                  </tr>
                </thead>
                <tbody>
                  {topPairs.map((p, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{p.k1}</td>
                      <td>{p.k2}</td>
                      <td>{p.count}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p>No co-occurrences found.</p>
            )}
          </>
        )}
      </Card.Body>
    </Card>
  );
}

/**
 * Keywords grouped by expert field, role or experience
 */

/**
 * Extract keywords from a use case.
 * It uses tags (if available) and splits the prompt text into words.
 * Words shorter than 4 characters are skipped.
 */
function extractKeywords(uc) {
  let keywords = [];
  // From tags
  if (Array.isArray(uc.tags?.value)) {
    keywords = keywords.concat(uc.tags.value);
  } else if (typeof uc.tags?.value === "string") {
    keywords = keywords.concat(uc.tags.value.split(/,\s*/));
  }
  // From prompt: split by non-word characters and filter short words
  const prompt = getFieldText(uc, "prompt");
  if (prompt) {
    const promptWords = prompt.split(/\W+/).filter(word => word.length > 3);
    keywords = keywords.concat(promptWords);
  }
  return keywords.map(kw => kw.toLowerCase());
}

/**
 * Count keyword frequencies for an array of use cases.
 */
function countKeywordFrequencies(useCases) {
  const freqMap = {};
  useCases.forEach(uc => {
    const kws = extractKeywords(uc);
    kws.forEach(kw => {
      freqMap[kw] = (freqMap[kw] || 0) + 1;
    });
  });
  return freqMap;
}

/**
 * Modal to display details for a group of use cases.
 */
function GroupDetailsModal({ show, onHide, groupName, useCases }) {

  return (
    <>
      <Modal show={show} onHide={onHide} size="xl" scrollable>
        <Modal.Header closeButton>
          <Modal.Title>Group: {groupName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Title</th>
                <th>Keywords</th>
                <th>Application</th>
                <th>Status</th>
                <th>Maturity</th>
                <th>Expert Info</th>
                <th>Prompt</th>
              </tr>
            </thead>
            <tbody>
              {useCases.map((uc, idx) => (
                <tr key={idx}>
                  <td>{getFieldText(uc, "title") || "Untitled"}</td>
                  <td>
                    {Array.isArray(uc.tags?.value)
                      ? uc.tags.value.join(", ")
                      : uc.tags?.value || "N/A"}
                  </td>
                  <td>{getFieldText(uc, "application") || "N/A"}</td>
                  <td>{getFieldText(uc, "status") || "N/A"}</td>
                  <td>{getFieldText(uc, "maturity") || "N/A"}</td>
                  <td>
                    <div>
                      <strong>Year:</strong> {getFieldText(uc, "yearOfBirth") || "N/A"}{" "}
                      <br />
                      <strong>Exp:</strong> {getFieldText(uc, "experience") || "N/A"}
                    </div>
                  </td>
                  <td>
                    {getFieldText(uc, "prompt") || "No prompt"}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      
    </>
  );
}

/**
 * ExpertsKeywordsTable
 * Groups use cases by a given key and displays a table showing:
 * - Group name
 * - Count of use cases
 * - Top 5 keywords (with frequencies) aggregated from use cases in that group.
 * A Details button opens a modal with detailed use case info.
 */
function ExpertsKeywordsTable({ data, groupBy }) {

  const groups = useMemo(() => groupUseCasesByKey(data, groupBy), [data, groupBy]);
  const [showModal, setShowModal] = useState(false);
  const [modalGroup, setModalGroup] = useState("");
  const [groupDetails, setGroupDetails] = useState([]);

  const handleShowDetails = (groupKey) => {
    setModalGroup(groupKey);
    setGroupDetails(groups[groupKey] || []);
    setShowModal(true);
  };

  return (
    <>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>{groupBy}</th>
            <th>Use Cases</th>
            <th>Top Keywords</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(groups).map(([groupKey, useCases], idx) => {
            const freqMap = countKeywordFrequencies(useCases);
            const sortedKeywords = Object.entries(freqMap).sort((a, b) => b[1] - a[1]);
            const topKeywords = sortedKeywords.slice(0, 5).map(([kw, count]) => `${kw} (${count})`).join(", ");
            return (
              <tr key={idx}>
                <td>{groupKey}</td>
                <td>{useCases.length}</td>
                <td>{topKeywords}</td>
                <td>
                  <Button variant="outline-primary" size="sm" onClick={() => handleShowDetails(groupKey)}>
                    Details
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <GroupDetailsModal
        show={showModal}
        onHide={() => setShowModal(false)}
        groupName={modalGroup}
        useCases={groupDetails}
      />
    </>
  );
}

/**
 * ExpertsKeywordsChart
 * Displays a bar chart of aggregated keyword counts per group.
 */
function ExpertsKeywordsChart({ data, groupBy }) {
  const groups = useMemo(() => groupUseCasesByKey(data, groupBy), [data, groupBy]);
  const groupLabels = Object.keys(groups);
  const groupKeywordCounts = groupLabels.map(groupKey => {
    const useCases = groups[groupKey];
    const freqMap = countKeywordFrequencies(useCases);
    return Object.values(freqMap).reduce((a, b) => a + b, 0);
  });

  const chartData = {
    labels: groupLabels,
    datasets: [
      {
        label: "Total Keywords",
        data: groupKeywordCounts,
        backgroundColor: "rgba(75,192,192,0.6)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: { legend: { position: "top" } },
    scales: { y: { beginAtZero: true } },
  };

  return (
    <Card className="h-100">
      <Card.Header>Keywords by {groupBy}</Card.Header>
      <Card.Body>
        <Bar data={chartData} options={options} />
      </Card.Body>
    </Card>
  );
}

/**
 * ExpertsKeywordsChoice
 * Provides a dropdown to choose grouping (expert_field, role, experience)
 * and a toggle to switch between table and chart view.
 */
function ExpertsKeywordsChoice({ data }) {
  const [groupBy, setGroupBy] = useState("expert_field");
  const [view, setView] = useState("table"); // "table" or "chart"

  return (
    <>
      <Row className="mb-3">
        <Col md={4}>
          <Form.Group>
            <Form.Label>Group By</Form.Label>
            <Form.Select value={groupBy} onChange={(e) => setGroupBy(e.target.value)}>
              <option value="expert_field">Expert Field</option>
              <option value="role">Role</option>
              <option value="experience">Experience</option>
              <option value="yearOfBirth">Year of Birth</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group>
            <Form.Label>View</Form.Label>
            <Form.Select value={view} onChange={(e) => setView(e.target.value)}>
              <option value="table">Table</option>
              <option value="chart">Chart</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      {view === "table" ? (
        <ExpertsKeywordsTable data={data} groupBy={groupBy} />
      ) : (
        <ExpertsKeywordsChart data={data} groupBy={groupBy} />
      )}
    </>
  );
}

/**
 *  Keyword section
 */
function KeywordAnalysisSection({ data }) {

  // defaultActiveKey="0"

  return (
    <Accordion style={{ marginTop: '20px' }}>

      {/* Section Analysis */}
      <Accordion.Item>
        <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
          <Badge bg="success" className="me-2">Keyword Analysis:</Badge>
          <h5 style={{ margin: 0 }}>
            Overview of keywords and their usage in the use cases.
          </h5>
        </div>
      </Accordion.Item>

      {/* Most common keywords */}
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">1</Badge>
          Most Common Keywords
        </Accordion.Header>
        <Accordion.Body>
          <MostCommonKeywords data={data} />
        </Accordion.Body>
      </Accordion.Item>
      
      {/* Keyword distribution */}
      <Accordion.Item eventKey="1">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">2</Badge>
          Keyword Distribution
        </Accordion.Header>
        <Accordion.Body>
          <Alert variant="secondary">
            <strong>What:</strong> Distribution of keywords across use cases. <br />
            <strong>How:</strong> Bar chart with frequency and table view. <br />
            <strong>Why:</strong> Identify common themes and topics.
          </Alert>
          <KeywordsDistribution data={data} /> 
        </Accordion.Body>
      </Accordion.Item>

      {/* Co-Occurrence Heatmap */}
      <Accordion.Item eventKey="2">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">3</Badge>
          Co-Occurrence Heatmap
        </Accordion.Header>
        <Accordion.Body>
          <CoOccurrenceHeatmap data={data} />
        </Accordion.Body>
      </Accordion.Item>

      {/* Section Expert */}
      <Accordion.Item>
        <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
          <Badge bg="success" className="me-2">Expert Views:</Badge>
          <h5 style={{ margin: 0 }}>
            The following sections are grouped by expert field and role.
          </h5>
        </div>
      </Accordion.Item>

      {/* Experts Keywords Choice */}
      <Accordion.Item eventKey="3">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">4</Badge>
          Experts Keywords Choice
        </Accordion.Header>
        <Accordion.Body>
          <ExpertsKeywordsChoice data={data} />
        </Accordion.Body>
      </Accordion.Item>

    </Accordion>
  )

}

// #########################################################################################################################
// #################################### Scenarios and Condition Section ####################################################
// #########################################################################################################################

/**
 * Extract scenario (actions) info from a single use case,
 * linking each scenario i with conditions i by index.
 */
function getScenariosFromUseCase(useCase) {
  const actions = useCase.actions?.value || [];
  const conditionsArray = useCase.conditions?.value || [];

  // We'll line up actions[i] with conditionsArray[i].
  return actions.map((action, i) => {
    const matchedCondition = conditionsArray[i] || {};
    return {
      useCaseId: useCase.id || 'unknown',
      name: action.name || 'Unnamed Scenario',
      description: action.description || '',
      preCondition: matchedCondition.preConditions || '',
      postCondition: matchedCondition.postConditions || '',
      constraints: matchedCondition.constraints || '',
      assumptions: matchedCondition.assumptions || '',

      // Expert Metadata
      expertField: useCase.expert_field?.value || "Unknown Field",
      role: useCase.role?.value || "Unknown Role",
      yearOfBirth: useCase.yearOfBirth?.value || "N/A",
      experience: useCase.experience?.value || "N/A"
    };
  });
}

// Gather all scenarios from an entire dataset
function gatherAllScenarios(data) {
  let allScenarios = [];
  data.forEach((uc) => {
    let scenarios = getScenariosFromUseCase(uc);
    allScenarios.push(...scenarios);
  });
  return allScenarios; // array of {name, description, preCondition, ...}
}

/**
 * Group scenarios by a given expert attribute (role, field, experience, etc.).
 */
function groupScenariosByExpertAttribute(scenarios, attribute) {
  return scenarios.reduce((groups, scenario) => {
    const key = scenario[attribute] || "Unknown";
    if (!groups[key]) groups[key] = [];
    groups[key].push(scenario);
    return groups;
  }, {});
}

function NumberOfScenariosChart({ data }) {
  // Each use case has actions?.value => array of scenarios
  const useCaseTitles = data.map((uc) => uc.title?.value || 'Untitled UC');
  const scenarioCounts = data.map((uc) => uc.actions?.value?.length || 0);

  const chartData = {
    labels: useCaseTitles,
    datasets: [
      {
        label: 'Number of Scenarios',
        data: scenarioCounts,
        backgroundColor: '#36A2EB',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: { legend: { position: 'top' } },
    // Setting indexAxis = 'y' flips the chart horizontally:
    indexAxis: 'y',
    scales: {
      x: {
        beginAtZero: true,
        title: { display: true, text: 'Number of Scenarios' },
        ticks: {
          stepSize: 1,
          precision: 0,
        },
      },
      y: {
        title: { display: true, text: 'Scenario Names' },
      },
    },
  };

  return (
    <Bar data={chartData} options={options} />
  );
}

const STOP_WORDS = new Set([
  'the','and','to','a','of','in','with','is','for','on','at','by','an','be',
  'are','this','must','or','am','will','that','from','it','not','as','can',
  'have','etc','was','had','shall','we','you','i','your','their','etc',
  'any','may','also','but','if','our','the','value'
]);

/**
 * Extract scenario text from a single use case's actions[]. 
 * Then combine with conditions if you want to unify them.
 */
function gatherScenarioTexts(useCase) {
  const actionsArr = useCase.actions?.value || [];
  const conditionsArr = useCase.conditions?.value || [];
  
  // We'll line up actions[i] with conditionsArr[i], if same length
  return actionsArr.map((action, i) => {
    // scenario description:
    let scenarioText = (action.description || '') + ' ' + (action.name || '');
    
    // match condition by index if it exists
    const cond = conditionsArr[i] || {};
    scenarioText += ' ' + (cond.preConditions || '');
    scenarioText += ' ' + (cond.postConditions || '');
    scenarioText += ' ' + (cond.constraints || '');
    scenarioText += ' ' + (cond.assumptions || '');

    return scenarioText.trim();
  });
}

/**
 * Enhanced Word Frequency Bar Chart
 * Handles:
 * - Aggregated word frequencies
 * - Word frequencies grouped by Expert Field or Role
 */
function ScenarioWordFrequencyBar({ freqMap }) {

  const [topN, setTopN] = useState(10);
  const [groupByCategory, setGroupByCategory] = useState(true);

  // If the structure contains multiple groups (expert fields/roles), process differently
  const isGrouped = typeof Object.values(freqMap)[0] === "object";

  let labels = [];
  let datasets = [];

  if (isGrouped) {
    // Handling grouped data (e.g., by Expert Field or Role)
    const expertGroups = Object.keys(freqMap);

    // Extracting top words across all groups
    const wordCounts = {};
    expertGroups.forEach((group) => {
      Object.entries(freqMap[group]).forEach(([word, count]) => {
        wordCounts[word] = (wordCounts[word] || 0) + count;
      });
    });

    // Get top N words
    const topWords = Object.entries(wordCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, topN)
      .map(([word]) => word);

    labels = topWords;

    if (groupByCategory) {
      // Grouped by category (expert field/role)
      datasets = expertGroups.map((group) => ({
        label: group,
        data: topWords.map((word) => freqMap[group][word] || 0),
        backgroundColor: getNextBlueGreenColor(),
      }));
    } else {
      // When `groupByCategory === false`, show expert groups as labels and words as datasets
      labels = expertGroups;

      datasets = topWords.map((word) => ({
        label: word,
        data: expertGroups.map((group) => freqMap[group][word] || 0),
        backgroundColor: getNextBlueGreenColor(),
      }));
    }

  } else {
    // Handling aggregated frequency map
    const sortedWords = Object.entries(freqMap)
      .sort((a, b) => b[1] - a[1])
      .slice(0, topN);

    labels = sortedWords.map(([word]) => word);
    const values = sortedWords.map(([, count]) => count);

    datasets = [
      {
        label: "Word Frequency",
        data: values,
        backgroundColor: "#36A2EB",
      },
    ];
  }

  const chartData = {
    labels,
    datasets,
  };

  const options = {
    responsive: true,
    plugins: { legend: { position: "top" } },
    scales: { y: { beginAtZero: true } },
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        {/* Dropdown for selecting top N words */}
        <Form.Group className="d-flex align-items-center">
          <Form.Label className="me-2 mb-0">Top N Words</Form.Label>
          <Form.Select
            value={topN}
            onChange={(e) => setTopN(e.target.value)}
            style={{ width: "auto" }}
          >
            <option value={5}>Top 5</option>
            <option value={10}>Top 10</option>
            <option value={20}>Top 20</option>
            <option value={9999}>All</option>
          </Form.Select>
        </Form.Group>
  
        {/* Toggle between aggregated and grouped view */}
        {isGrouped && (
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => setGroupByCategory(!groupByCategory)}
          >
            {groupByCategory ? "Show Aggregated View" : "Show Expert Breakdown"}
          </Button>
        )}
      </div>
  
      <Bar data={chartData} options={options} />
    </>
  );
}

/**
 * Build a global frequency map of words from scenario texts
 */
function buildScenarioWordFrequency(data) {
  // data = array of useCases
  const freqMap = {};

  data.forEach((uc) => {
    const scenarioTexts = gatherScenarioTexts(uc);
    scenarioTexts.forEach((text) => {
      const tokens = text.toLowerCase().split(/\W+/).filter(Boolean);
      tokens.forEach((token) => {
        if (token.length > 2 && !STOP_WORDS.has(token)) {
          freqMap[token] = (freqMap[token] || 0) + 1;
        }
      });
    });
  });

  return freqMap;  // e.g. { 'bus': 10, 'data': 15, 'routing': 4, ... }
}

// --------------------------------------------------------------------------------------------
// Expert Scenario Analysis Section
// --------------------------------------------------------------------------------------------

/**
 * Scenario Count Chart (Grouped by Expert Field or Role)
 */
function ScenarioCountByExpertChart({ data, groupBy = "expertField" }) {
  const allScenarios = useMemo(() => gatherAllScenarios(data), [data]);
  const groupedScenarios = useMemo(() => groupScenariosByExpertAttribute(allScenarios, groupBy), [allScenarios, groupBy]);

  const labels = Object.keys(groupedScenarios);
  const scenarioCounts = labels.map((key) => groupedScenarios[key].length);

  const chartData = {
    labels,
    datasets: [
      {
        label: `Scenarios per ${groupBy === "expertField" ? "Expert Field" : "Role"}`,
        data: scenarioCounts,
        backgroundColor: "#36A2EB",
      },
    ],
  };

  return (
    <Bar data={chartData} options={{ indexAxis: "y", responsive: true }} />
  );
}

/**
 * Build a frequency map of words from scenario descriptions & conditions
 * grouped by expert attribute (role, field, experience).
 */
function buildScenarioWordFrequencyByExpert(data, groupBy) {
  
  const allScenarios = gatherAllScenarios(data);
  const groupedScenarios = groupScenariosByExpertAttribute(allScenarios, groupBy);

  let frequencyByGroup = {};

  Object.entries(groupedScenarios).forEach(([group, scenarios]) => {
    let freqMap = {};

    scenarios.forEach((scenario) => {
      const text = `${scenario.description} ${scenario.preCondition} ${scenario.postCondition} ${scenario.constraints} ${scenario.assumptions}`;
      const tokens = text.toLowerCase().split(/\W+/).filter(Boolean);

      tokens.forEach((token) => {
        if (token.length > 2 && !STOP_WORDS.has(token)) {
          freqMap[token] = (freqMap[token] || 0) + 1;
        }
      });
    });

    frequencyByGroup[group] = freqMap;
  });

  //console.log(frequencyByGroup);

  return frequencyByGroup;
}

/**
 * Table showing all scenarios with expert metadata.
 */
function ScenarioDetailsTable({ data }) {
  const allScenarios = useMemo(() => gatherAllScenarios(data), [data]);

  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>Scenario Name</th>
          <th>Description</th>
          <th>Pre-Condition</th>
          <th>Post-Condition</th>
          <th>Expert Field</th>
          <th>Role</th>
          <th>Year of Birth</th>
          <th>Experience</th>
        </tr>
      </thead>
      <tbody>
        {allScenarios.map((s, i) => (
          <tr key={i}>
            <td>{s.name}</td>
            <td>{s.description}</td>
            <td>{s.preCondition}</td>
            <td>{s.postCondition}</td>
            <td>{s.expertField}</td>
            <td>{s.role}</td>
            <td>{s.yearOfBirth}</td>
            <td>{s.experience}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

const ScenarioAnalysisSection = ({ data }) => {
  // 1) Gather all scenario data from the dataset
  const allScenarios = gatherAllScenarios(data);

  // 1) Build freq map from scenario descriptions & conditions
  const freqMap = buildScenarioWordFrequency(data);

  // defaultActiveKey="0" 

  const scenarioWordFreqByField = buildScenarioWordFrequencyByExpert(data, "expertField");
  const scenarioWordFreqByRole = buildScenarioWordFrequencyByExpert(data, "role");

  return (
    <Accordion style={{ marginTop: '20px' }}>

      {/* Section Analysis */}
      <Accordion.Item>
        <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
          <Badge bg="success" className="me-2">Scenario Analysis:</Badge>
          <h5 style={{ margin: 0 }}>
            Overview of scenarios, conditions and their usage in the use cases.
          </h5>
        </div>
      </Accordion.Item>

      {/* Scenario Frequency Overview */}
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">1</Badge>
          Scenario Frequency Overview in Scenario Descriptions & Conditions
        </Accordion.Header>
        <Accordion.Body>
          <Alert variant="secondary">
            <strong>What:</strong> Analyzes the text from scenario 
            descriptions, pre/post-conditions, constraints, assumptions.<br />
            <strong>Why:</strong> Helps identify recurring terms or 
            themes participants used in scenario modeling.<br />
            <strong>How:</strong> We combine all scenario text, remove short 
            tokens & stop words, and count frequency. The bar chart highlights 
            the top used words.
          </Alert>
          <ScenarioWordFrequencyBar freqMap={freqMap} topN={15} />
        </Accordion.Body>
      </Accordion.Item>

      {/* Number of scenarios per use case */}
      <Accordion.Item eventKey="1">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">2</Badge>
          Number of Scenarios per Use Case
        </Accordion.Header>
        <Accordion.Body>
          <Alert variant="secondary">
            <strong>What:</strong> Shows how many scenarios each use case contains.<br />
            <strong>Why:</strong> Reveals participants who created multiple scenarios
            (more thorough) vs. only one scenario (basic).<br />
            <strong>How:</strong> Each bar extends along the X-axis, with the
            <em> length of the actions array</em> per use case shown on the horizontal axis.
          </Alert>
          <NumberOfScenariosChart data={data} />
        </Accordion.Body>
      </Accordion.Item>

      {/* Detailed Scenario Table */} 
      <Accordion.Item eventKey="2">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">3</Badge>
          Detailed Scenario Table
        </Accordion.Header>
        <Accordion.Body>
          <Alert variant="secondary">
            <strong>What:</strong> A scrollable table listing each scenario’s 
            name, description, and conditions. <br />
            <strong>Why:</strong> Provides direct insight into how participants 
            modeled each scenario. <br />
            <strong>How:</strong> Pre/Post conditions, constraints, 
            and assumptions are displayed to see completeness or patterns.
          </Alert>
          <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
            <Table bordered hover size="sm">
              <thead>
                <tr>
                  <th>Scenario Name</th>
                  <th>Description</th>
                  <th>Pre-Condition</th>
                  <th>Post-Condition</th>
                  <th>Constraints</th>
                  <th>Assumptions</th>
                </tr>
              </thead>
              <tbody>
                {allScenarios.map((s, i) => (
                  <tr key={i}>
                    <td>{s.name}</td>
                    <td>{s.description}</td>
                    <td>{s.preCondition}</td>
                    <td>{s.postCondition}</td>
                    <td>{s.constraints}</td>
                    <td>{s.assumptions}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Accordion.Body>
      </Accordion.Item>

      {/* Section Expert */}
      <Accordion.Item>
        <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
          <Badge bg="success" className="me-2">Expert Views:</Badge>
          <h5 style={{ margin: 0 }}>
            The following sections are grouped by expert field and role.
          </h5>
        </div>
      </Accordion.Item>

      {/* Scenario Count by Expert Field or Role */}
      <Accordion.Item eventKey="3">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">4</Badge>
          Scenario Count by Expert Field or Role
        </Accordion.Header>
        <Accordion.Body>
          <Alert variant="secondary">
            <strong>What:</strong> Bar chart showing the number of scenarios
            grouped by expert field or role.<br />
            <strong>Why:</strong> Helps identify which expert groups created
            more scenarios.<br />
            <strong>How:</strong> Each bar represents the number of scenarios
            per expert field or role.
          </Alert>
          <ScenarioCountByExpertChart data={data} groupBy="expertField" />
        </Accordion.Body>
      </Accordion.Item>

      {/* Scenario Word Frequency by Expert Field or Role */}
      <Accordion.Item eventKey="4">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">5</Badge>
          Scenario Word Frequency by Expert Field
        </Accordion.Header>
        <Accordion.Body>  
          <Alert variant="secondary">
            <strong>What:</strong> Bar chart showing the frequency of words
            in scenario descriptions grouped by expert field or role.<br />
            <strong>Why:</strong> Helps identify common terms or themes used
            by different expert groups.<br />
            <strong>How:</strong> Each bar represents the frequency of words
            in scenario descriptions for a given expert field or role.
          </Alert>

          <ScenarioWordFrequencyBar freqMap={scenarioWordFreqByField} />

        </Accordion.Body>
      </Accordion.Item>

      {/* Scenario Word Frequency by Expert Role */}
      <Accordion.Item eventKey="5">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">6</Badge>
          Scenario Word Frequency by Expert Role
        </Accordion.Header>
        <Accordion.Body>
          <Alert variant="secondary">
            <strong>What:</strong> Bar chart showing the frequency of words
            in scenario descriptions grouped by expert role.<br />
            <strong>Why:</strong> Helps identify common terms or themes used
            by different expert roles.<br />
            <strong>How:</strong> Each bar represents the frequency of words
            in scenario descriptions for a given expert role.
          </Alert>

          <ScenarioWordFrequencyBar freqMap={scenarioWordFreqByRole} />

        </Accordion.Body>
      </Accordion.Item>

      {/* Scenario Details Table */}
      <Accordion.Item eventKey="6">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">7</Badge>
          Expert Scenario Details Table 
        </Accordion.Header>
        <Accordion.Body>
          <Alert variant="secondary">
            <strong>What:</strong> A table listing all scenarios with expert metadata.<br />
            <strong>Why:</strong> Provides a comprehensive view of all scenarios
            and their associated expert information.<br />
            <strong>How:</strong> Each row shows a scenario with its description,
            pre/post conditions, and expert metadata.
          </Alert>
          <ScenarioDetailsTable data={data} />
        </Accordion.Body>
      </Accordion.Item>

    </Accordion>
  );
};

// #########################################################################################################################
// #################################### Actor Section ######################################################################
// #########################################################################################################################

/**
 * Actor categories tables
 */
function ActorCategoriesTable({ actorCategories }) {

  // Helper to sum an object’s values
  const sumValues = (obj) => Object.values(obj).reduce((acc, val) => acc + val, 0);

  return (
    <>
    <Card className="mb-4 h-100">
          <Card.Header>
            <h5 className="mb-0">Actor Categories</h5>
          </Card.Header>
          <Card.Body>
            <Table bordered hover size="sm">
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Count</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(actorCategories).map(([cat, count]) => (
                  <tr key={cat}>
                    <td>{cat}</td>
                    <td>{count}</td>
                  </tr>
                ))}
                {/* Summation row */}
                <tr>
                  <td><strong>Total</strong></td>
                  <td><strong>{sumValues(actorCategories)}</strong></td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
    </>
  )
}

/**
 * Actor list table
 * @param {*} param0 
 * @returns 
 */
function ActorListTable({ list }) {
  if (!list) return <p>No data</p>;

  return (
    <table className="table table-sm table-bordered">
      <thead>
        <tr>
          <th style={{ width: "25%" }}>Category</th>
          <th>Actors</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(list).map(([catName, actorArray]) => {

          const actors = Array.isArray(actorArray) ? actorArray : [];

          return (
            <tr key={catName}>
              <td>{catName}</td>
              <td>
                {actors.length === 0 ? (
                  <span className="text-muted">No actors</span>
                ) : (
                  actors.map((actor, i) => (
                    <span
                      key={i}
                      className="badge bg-light text-dark me-2"
                      style={{ border: "1px solid #ccc" }}
                    >
                      {actor}
                    </span>
                  ))
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

/**
 * Merged component that offers:
 *  - Bar chart view
 *  - Pie chart view
 *  - Table view
 */
function ActorCategories({
  actorCategories,
  actorList,
  handleChartClick,
  handleChartHover,
}) {
  const [view, setView] = useState("bar");

  const chartTitle = "Actors per Category";
  const chartData = prepareChartData(actorCategories, chartTitle);

  const showBarChart = () => setView("bar");
  const showPieChart = () => setView("pie");
  const showTable = () => setView("table");

  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <h5 className="mb-0">List of Actors</h5>
        <div>
          {/* Button: Bar Chart */}
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>View as Bar Chart</Tooltip>}
          >
            <Button
              variant="outline-primary"
              className={`btn-sm ${view === "bar" ? "active" : ""}`}
              style={{ marginLeft: "5px" }}
              onClick={showBarChart}
            >
              <i className="bi bi-bar-chart"></i>
            </Button>
          </OverlayTrigger>

          {/* Button: Pie Chart */}
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>View as Pie Chart</Tooltip>}
          >
            <Button
              variant="outline-primary"
              className={`btn-sm ${view === "pie" ? "active" : ""}`}
              style={{ marginLeft: "5px" }}
              onClick={showPieChart}
            >
              <i className="bi bi-pie-chart"></i>
            </Button>
          </OverlayTrigger>

          {/* Button: Table */}
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>View in Table Format</Tooltip>}
          >
            <Button
              variant="outline-primary"
              className={`btn-sm ${view === "table" ? "active" : ""}`}
              style={{ marginLeft: "5px" }}
              onClick={showTable}
            >
              <i className="bi bi-table"></i>
            </Button>
          </OverlayTrigger>
        </div>
      </Card.Header>

      <Card.Body style={{ position: "relative", height: "400px" }}>
        {/* Render based on current view */}
        {view === "bar" && (
          <Bar
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              onClick: (evt, elems) =>
                handleChartClick?.(evt, elems, chartData, chartTitle),
              onHover: (evt, elems) =>
                handleChartHover?.(evt, elems),
              plugins: {
                tooltip: {
                  callbacks: {
                    title: (tooltipItems) => `Category: ${tooltipItems[0].label}`,
                    label: (tooltipItem) =>
                      `Actors Count: ${tooltipItem.raw}`,
                  },
                },
              },
              scales: {
                x: {
                  title: {
                    display: true,
                    text: "Actor Categories",
                    font: { weight: "bold", size: 14 },
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: "Number of Actors",
                    font: { weight: "bold", size: 14 },
                  },
                  beginAtZero: true,
                },
              },
            }}
          />
        )}

        {view === "pie" && (
          <Pie
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              onClick: (evt, elems) =>
                handleChartClick?.(evt, elems, chartData, chartTitle),
              onHover: (evt, elems) =>
                handleChartHover?.(evt, elems),
              plugins: {
                tooltip: {
                  callbacks: {
                    title: (tooltipItems) => `Category: ${tooltipItems[0].label}`,
                    label: (tooltipItem) =>
                      `Actors Count: ${tooltipItem.raw}`,
                  },
                },
              },
            }}
          />
        )}

        {view === "table" && (
          <div style={{ overflowY: "auto", maxHeight: "100%" }}>
            <ActorListTable list={actorList} />
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

/**
 * Actor usage
 * @param {*} param0
 * @returns
 */
function ActorUsage({ actorUsage, handleChartClick, handleChartHover }) {

  return (
    <>
      <Card className="mb-4">
        <Card.Header>
          <h5 className="mb-0">Actor Usage</h5>
          <small className="text-muted">Actor name → usage count</small>
          <small className="text-muted">; total count incl. baseline: {Object?.values(actorUsage)?.reduce((acc, val) => acc + val, 0)}</small>
        </Card.Header>
        <Card.Body style={{ maxHeight: '350px', overflowY: 'auto' }}>
          {Object.keys(actorUsage).length === 0 ? (
            <p>No actor usage found.</p>
          ) : (
            <Table bordered hover size="sm">
              <thead>
                <tr>
                  <th>Actor</th>
                  <th>Usage</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(actorUsage).map(([actorName, usage]) => (
                  <tr key={actorName}>
                    <td>{actorName}</td>
                    <td>{usage}</td>
                  </tr>
                ))}
                <tr>
                  <td><strong>Total</strong></td>
                  <td><strong>{Object.values(actorUsage).reduce((acc, val) => acc + val, 0)}</strong></td>
                </tr>
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>
    </>
  )
}

/**
 * Repeated actors
 * @param {*} param0 
 * @returns 
 */
function RepeatedActors({ repeatedActors, actorUsage, distinctActors, totalActorEntries }) {

  return (
    <>
      <Card className="mb-4">
        <Card.Header>
          <h5 className="mb-0">Repeated Actors</h5>
          <small className="text-muted">Used more than once</small>
          <small className="text-muted">: {repeatedActors.length}</small>
          <small className="text-muted">; distinct count: {distinctActors}</small>
          <small className="text-muted">; total entries incl. baseline (6): {totalActorEntries}</small>
        </Card.Header>
        <Card.Body style={{ maxHeight: '350px', overflowY: 'auto' }}>
          {(repeatedActors.length === 0) ? (
            <p>No repeated actors found.</p>
          ) : (
            <>
              <ul className="list-group mb-3">
                {repeatedActors.map((actor) => {
                  const usageCount = actorUsage[actor] || 0; // fallback if not found
                  return (
                    <li
                      className="list-group-item d-flex justify-content-between align-items-center"
                      key={actor}
                    >
                      {actor}
                      <Badge bg="secondary">{usageCount} times</Badge>
                    </li>
                  );
                })}
              </ul>
              {/* Summation row at the bottom */}
              <div className="d-flex justify-content-between">
                <strong>Total Repeated Actors</strong>
                <span><strong>{repeatedActors.length}</strong></span>
              </div>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  )
}

/**
 * Node centrality
 * @param {*} param0 
 * @returns 
 */
const NodeCentrality = ({ nodes, edges }) => {

  // Initially show top 5 keywords
  const [topN, setTopN] = useState(10);

  if (!nodes || !edges) return <p>No data available.</p>;

  const centralityScores = calculateCentrality(nodes, edges);

  // Sort descending by centrality value for better readability
  centralityScores.sort((a, b) => b.value - a.value);

  const barData = {
    labels: centralityScores.map(score => score.node.label).slice(0, topN),
    datasets: [
      {
        label: "Centrality Score",
        data: centralityScores.map(score => score.value).slice(0, topN),
        backgroundColor: "rgba(75,192,192,0.6)", // Consistent color
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: "Nodes",
          font: { weight: "bold", size: 14 },
        },
      },
      y: {
        title: {
          display: true,
          text: "Centrality Score",
          font: { weight: "bold", size: 14 },
        },
        beginAtZero: true,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `Score: ${tooltipItem.raw}`,
        },
      },
    },
  };

  const handleTopNChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setTopN(value);
  };

  return (
    <>      
      <div className="mb-2 d-flex justify-content-between align-items-center">
        <span>Top {topN} Node Centrality</span>
        {/* The dropdown for picking topN */}
        <select value={topN} onChange={handleTopNChange} className="form-select form-select-sm" style={{ width: 'auto' }}>
          <option value={5}>Top 5</option>
          <option value={10}>Top 10</option>
          <option value={20}>Top 20</option>
          <option value={9999}>All</option>
        </select>
      </div>

      <Bar data={barData} options={options} />
    </>
  );
};

/**
 * Calculate centrality
 * @param {*} nodes 
 * @param {*} edges 
 * @returns 
 */
const calculateCentrality = (nodes, edges) => {
  if (!nodes || !edges) return [];

  const centralityMap = {};
  nodes.forEach((node) => (centralityMap[node.id] = 0));

  edges.forEach((edge) => {
    if (centralityMap[edge.from] !== undefined) centralityMap[edge.from]++;
    if (centralityMap[edge.to] !== undefined) centralityMap[edge.to]++;
  });

  return nodes.map((node) => ({
    node,
    value: centralityMap[node.id] || 0, // Use 0 if the node has no connections
  }));
};

/**
 * Top connected nodes
 * @param {*} param0 
 * @returns 
 */
const TopConnectedNodes = ({ nodes, edges }) => {

  // Initially show top 5 keywords
  const [topN, setTopN] = useState(5);

  if (!nodes || !edges) return <p>No data available.</p>;

  const nodeDegrees = nodes.map((node) => ({
    ...node,
    degree: edges.filter((edge) => edge.from === node.id || edge.to === node.id).length,
  }));

  const handleTopNChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setTopN(value);
  };

  // Sort by degree and take top 5
  const sortedNodes = nodeDegrees.sort((a, b) => b.degree - a.degree).slice(0, topN);

  return (
    <>
      <div className="mb-2 d-flex justify-content-between align-items-center">
        <span>Top {topN} Most Connected Nodes</span>
        {/* The dropdown for picking topN */}
        <select value={topN} onChange={handleTopNChange} className="form-select form-select-sm" style={{ width: 'auto' }}>
          <option value={5}>Top 5</option>
          <option value={10}>Top 10</option>
          <option value={20}>Top 20</option>
          <option value={9999}>All</option>
        </select>
      </div>
      
      <ListGroup style={{ maxHeight: "300px", overflowY: "auto" }}>
        {sortedNodes.map((node) => (
          <ListGroup.Item key={node.id} className="d-flex justify-content-between align-items-center">
            <strong>{node.label}</strong>
            <span className="badge bg-primary">{node.degree} connections</span>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </>
  );
};

/**
 * NodeCentrality and TopConnectedNodes
 * @param {*} param0
 * @returns
 */
function NodeCentralityAndTopConnectedNodes({ nodes, edges }) {

  const [view, setView] = useState("centrality");

  const showCentrality = () => setView("centrality");
  const showTopConnected = () => setView("connected");

  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <h5 className="mb-0">Node Centrality and Top Connected Nodes (Actors)</h5>

        <div>
          {/* Centrality */}
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Centrality</Tooltip>}
          >
            <Button
              variant="outline-primary"
              className={`btn-sm ${view === "bar" ? "active" : ""}`}
              style={{ marginLeft: "5px" }}
              onClick={showCentrality}
            >
              <i className="bi bi-bar-chart"></i>
            </Button>
          </OverlayTrigger>

          {/* Button: Pie Chart */}
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Top connected</Tooltip>}
          >
            <Button
              variant="outline-primary"
              className={`btn-sm ${view === "pie" ? "active" : ""}`}
              style={{ marginLeft: "5px" }}
              onClick={showTopConnected}
            >
              <i className="bi bi-1-circle"></i>
            </Button>
          </OverlayTrigger>
        </div>
      </Card.Header>
      <Card.Body style={{ position: "relative", height: "400px" }}>              
        {view === "centrality" && (
          <NodeCentrality nodes={nodes} edges={edges} />
        )}

        {view === "connected" && (
          <TopConnectedNodes nodes={nodes} edges={edges} />
        )}
      </Card.Body>
    </Card>
  );
}

// --------------------------------------------------------------------------------------------
// Expert Views on Actors
// --------------------------------------------------------------------------------------------

/*
function ActorCategoriesByExpert({ data }) {
  const groupedByExpert = {};

  data.forEach((useCase) => {
    const expertField = useCase.expert_field?.value || "Unknown Expert Field";
    const role = useCase.role?.value || "Unknown Role";
    const key = `${expertField} (${role})`;

    if (useCase.actors?.value?.list) {
      Object.entries(useCase.actors.value.list).forEach(([category, actorData]) => {
        if (!groupedByExpert[key]) groupedByExpert[key] = {};
        
        // Extract actor values if they exist
        const actorValues = actorData?.value || [];
        actorValues.forEach((actor) => {
          groupedByExpert[key][category] = (groupedByExpert[key][category] || 0) + 1;
        });
      });
    }
  });

  return (
    <Card className="mb-4 h-100">
      <Card.Header>
        <h5 className="mb-0">Actor Categories by Expert Field & Role</h5>
      </Card.Header>
      <Card.Body>
        <Table bordered hover size="sm">
          <thead>
            <tr>
              <th>Expert Field (Role)</th>
              <th>Category</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(groupedByExpert).map(([expert, categories]) =>
              Object.entries(categories).map(([category, count], index) => (
                <tr key={`${expert}-${category}-${index}`}>
                  {index === 0 && (
                    <td rowSpan={Object.keys(categories).length}>
                      <strong>{expert}</strong>
                    </td>
                  )}
                  <td>{category}</td>
                  <td>{count}</td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}
*/

function ActorCategoriesByExpert({ data }) {
  // Track how we want to group the actor data
  const [viewBy, setViewBy] = useState("expert_field"); 
  // Possible values:
  //   "expert_field" => group only by expert_field
  //   "role"         => group only by role
  //   "both"         => group by combination of expert_field + role

  /**
   * Build a structure like:
   * {
   *   [groupKey]: {
   *       "ActorCategoryX": {
   *           totalCount: 4, // total references
   *           actorCounts: {
   *             "Actor A": 2,
   *             "Actor B": 1,
   *             ...
   *           }
   *       },
   *       ...
   *   },
   *   ...
   * }
   */
  const groupedData = useMemo(() => {
    const result = {};

    data.forEach((useCase) => {
      const expertField = useCase.expert_field?.value || "Unknown Expert Field";
      const role = useCase.role?.value || "Unknown Role";

      let groupKey;
      switch (viewBy) {
        case "expert_field":
          groupKey = expertField;
          break;
        case "role":
          groupKey = role;
          break;
        default:
          // "both"
          groupKey = `${expertField} (${role})`;
          break;
      }

      if (useCase.actors?.value?.list) {
        Object.entries(useCase.actors.value.list).forEach(([category, actorData]) => {
          if (!result[groupKey]) {
            result[groupKey] = {};
          }
          if (!result[groupKey][category]) {
            result[groupKey][category] = { totalCount: 0, actorCounts: {} };
          }

          const actorValues = actorData?.value || [];
          actorValues.forEach((actorItem) => {
            // If 'actorItem' is an object with a name, adjust accordingly:
            // e.g. const actorName = actorItem.name
            const actorName = typeof actorItem === "string" 
              ? actorItem 
              : JSON.stringify(actorItem);

            // Increase total references for that category
            result[groupKey][category].totalCount++;

            // Increase count for the specific actor
            if (!result[groupKey][category].actorCounts[actorName]) {
              result[groupKey][category].actorCounts[actorName] = 0;
            }
            result[groupKey][category].actorCounts[actorName]++;
          });
        });
      }
    });

    return result;
  }, [data, viewBy]);

  return (
    <Card className="mb-4 h-100">
      <Card.Header>
        <h5 className="mb-0">Actor Categories</h5>
      </Card.Header>
      <Card.Body>
        {/* Control to switch between expert_field, role, or both */}
        <Form.Group controlId="viewBySelect">
          <Form.Label>View By:</Form.Label>
          <Form.Control
            as="select"
            value={viewBy}
            onChange={(e) => setViewBy(e.target.value)}
          >
            <option value="expert_field">Expert Field</option>
            <option value="role">Role</option>
            <option value="both">Expert Field &amp; Role</option>
          </Form.Control>
        </Form.Group>

        <Table bordered hover size="sm" className="mt-3">
          <thead>
            <tr>
              <th>
                {viewBy === "both" 
                  ? "Expert Field (Role)" 
                  : viewBy === "expert_field" 
                    ? "Expert Field" 
                    : "Role"}
              </th>
              <th>Category</th>
              <th>Total Count</th>
              <th>Actors</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(groupedData).map(([groupLabel, categories]) => {
              const categoryEntries = Object.entries(categories);
              return categoryEntries.map(([category, info], idx) => (
                <tr key={`${groupLabel}-${category}-${idx}`}>
                  {/* Only show the group label on the first row of each group */}
                  {idx === 0 && (
                    <td rowSpan={categoryEntries.length}>
                      <strong>{groupLabel}</strong>
                    </td>
                  )}
                  <td>{category}</td>
                  <td>{info.totalCount}</td>
                  <td>
                    {Object.entries(info.actorCounts).map(
                      ([actorName, actorCount]) => (
                        <Badge
                          key={actorName}
                          {...(actorCount > 1 ? { bg: "warning" } : { bg:"light" })}

                          text="dark"
                          className="me-2"
                          style={{ border: "1px solid #ccc" }}                          
                        >
                          {actorName} {actorCount > 1 ? `(${actorCount})` : ""}
                        </Badge>
                      )
                    )}
                  </td>
                </tr>
              ));
            })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

function ActorListByExpert({ data }) {
  const groupedActors = {};

  data.forEach((useCase) => {
    const expertField = useCase.expert_field?.value || "Unknown Expert Field";
    const role = useCase.role?.value || "Unknown Role";
    const key = `${expertField} (${role})`;

    if (useCase.actors?.value?.list) {
      Object.entries(useCase.actors.value.list).forEach(([category, actorData]) => {
        if (!groupedActors[key]) groupedActors[key] = [];

        // Extract actor values
        const actorValues = actorData?.value || [];
        actorValues.forEach((actor) => {
          if (!groupedActors[key].includes(actor)) {
            groupedActors[key].push(actor);
          }
        });
      });
    }
  });

  return (
    <Card className="mb-4 h-100">
      <Card.Header>
        <h5 className="mb-0">Actors by Expert Field & Role</h5>
      </Card.Header>
      <Card.Body>
        <Table bordered hover size="sm">
          <thead>
            <tr>
              <th>Expert Field (Role)</th>
              <th>Actors</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(groupedActors).map(([expert, actors], index) => (
              <tr key={index}>
                <td>{expert}</td>
                <td>
                  {actors.length === 0 ? (
                    <span className="text-muted">No actors</span>
                  ) : (
                    actors.map((actor, i) => (
                      <span key={i} className="badge bg-light text-dark me-2" style={{ border: "1px solid #ccc" }}>
                        {actor}
                      </span>
                    ))
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

function ActorUsageByExpertChart({ data }) {

  const [topN, setTopN] = useState(10);
  const groupedUsage = {};

  data.forEach((useCase) => {
    const expertField = useCase.expert_field?.value || "Unknown Expert Field";
    const role = useCase.role?.value || "Unknown Role";
    const key = `${expertField} (${role})`;

    if (useCase.actors?.value?.list) {
      Object.entries(useCase.actors.value.list).forEach(([category, actorData]) => {
        if (!groupedUsage[key]) groupedUsage[key] = {};

        // Extract actor values
        const actorValues = actorData?.value || [];
        actorValues.forEach((actor) => {
          groupedUsage[key][actor] = (groupedUsage[key][actor] || 0) + 1;
        });
      });
    }
  });

  const expertGroups = Object.keys(groupedUsage);
  const actorSet = new Set();

  expertGroups.forEach((group) => {
    Object.keys(groupedUsage[group]).forEach((actor) => actorSet.add(actor));
  });

  const actors = Array.from(actorSet).slice(0, topN);

  const chartData = {
    labels: expertGroups,
    datasets: actors.map((actor) => ({
      label: actor,
      data: expertGroups.map((group) => groupedUsage[group][actor] || 0),
      backgroundColor: getNextBlueGreenColor(),
    })),
  };

  return (
    <Card className="mb-4 h-100">
      <Card.Header>
        <h5 className="mb-0">Actor Usage by Expert Field & Role</h5>        
      </Card.Header>
      <Card.Body>
        {/* Dropdown for selecting topN */}
        <div className="mb-2 d-flex justify-content-between align-items-center">
          <span>Top {topN} Actors</span>
          <select value={topN} onChange={(e) => setTopN(parseInt(e.target.value, 10))} className="form-select form-select-sm" style={{ width: 'auto' }}>
            <option value={5}>Top 5</option>
            <option value={10}>Top 10</option>
            <option value={20}>Top 20</option>
            <option value={50}>Top 50</option>
            <option value={100}>Top 100</option>
            <option value={9999}>All</option>
          </select>
        </div>
        <Bar
          data={chartData}
          options={{
            responsive: true,
            plugins: { legend: { position: "top" } },
            scales: { y: { beginAtZero: true } },
          }}
        />
      </Card.Body>
    </Card>
  );
}

function RepeatedActorsByExpert({ data }) {
  const actorUsage = {};
  const expertAttribution = {};

  data.forEach((useCase) => {
    const expertField = useCase.expert_field?.value || "Unknown Expert Field";
    const role = useCase.role?.value || "Unknown Role";
    const key = `${expertField} (${role})`;

    if (useCase.actors?.value?.list) {
      Object.entries(useCase.actors.value.list).forEach(([category, actorData]) => {
        const actorValues = actorData?.value || [];
        actorValues.forEach((actor) => {
          if (!actorUsage[actor]) {
            actorUsage[actor] = 0;
            expertAttribution[actor] = [];
          }
          actorUsage[actor] += 1;
          if (!expertAttribution[actor].includes(key)) {
            expertAttribution[actor].push(key);
          }
        });
      });
    }
  });

  const repeatedActors = Object.entries(actorUsage)
    .filter(([_, count]) => count > 1)
    .sort((a, b) => b[1] - a[1]);

  return (
    <Card className="mb-4">
      <Card.Header>
        <h5 className="mb-0">Repeated Actors & Expert Attribution</h5>
      </Card.Header>
      <Card.Body>
        <Table bordered hover size="sm">
          <thead>
            <tr>
              <th>Actor</th>
              <th>Usage Count</th>
              <th>Modeled By</th>
            </tr>
          </thead>
          <tbody>
            {repeatedActors.map(([actor, count]) => (
              <tr key={actor}>
                <td>{actor}</td>
                <td>{count}</td>
                <td>
                  {expertAttribution[actor].map((expert, i) => (
                    <span key={i} className="badge bg-secondary me-1">
                      {expert}
                    </span>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}


function ActorAnalysisSection({ data, allNodes, allEdges, actorList, actorCategories, actorUsage, repeatedActors, distinctActors, totalActorEntries, handleChartClick, handleChartHover }) {

  // defaultActiveKey="0"

  return (
    <Accordion style={{ marginTop: '20px' }}>

      {/* Section Analysis */}
      <Accordion.Item>
        <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
          <Badge bg="success" className="me-2">Actor Analysis:</Badge>
          <h5 style={{ margin: 0 }}>
            Overview of actors and their usage in the use cases.
          </h5>
        </div>
      </Accordion.Item>

      {/* Actors per category */}
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">1</Badge>
          Actors per Category
        </Accordion.Header>
        <Accordion.Body>
          <ActorCategories actorCategories={actorCategories} actorList={actorList} handleChartClick={handleChartClick} handleChartHover={handleChartHover} />
        </Accordion.Body>
      </Accordion.Item>

      {/* Actor categories */}
      <Accordion.Item eventKey="1">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">2</Badge>
          Actor categories Table
        </Accordion.Header>
        <Accordion.Body>
          <Alert variant="secondary">
            <strong>What:</strong> ...<br />
            <strong>Why:</strong> ...<br />
            <strong>How:</strong> ...
          </Alert>
          <ActorCategoriesTable actorCategories={actorCategories} />
        </Accordion.Body>
      </Accordion.Item>

      {/* Actor Usage */}
      <Accordion.Item eventKey="2">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">3</Badge>
          Actor Usage
        </Accordion.Header>
        <Accordion.Body>
          <ActorUsage actorUsage={actorUsage} handleChartClick={handleChartClick} handleChartHover={handleChartHover} />
        </Accordion.Body>
      </Accordion.Item>

      {/* Repeated actors */}
      <Accordion.Item eventKey='3'>
        <Accordion.Header>
          <Badge bg="primary" className="me-2">4</Badge>
          Repeated Actors
        </Accordion.Header>
        <Accordion.Body>
          <RepeatedActors repeatedActors={repeatedActors} actorUsage={actorUsage} distinctActors={distinctActors} totalActorEntries={totalActorEntries} />
        </Accordion.Body>
      </Accordion.Item>

      {/* Node centrality and Top connected nodes */}
      <Accordion.Item eventKey="4">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">5</Badge>
          Node Centrality & Top Connected Nodes
        </Accordion.Header>
        <Accordion.Body>
          <NodeCentralityAndTopConnectedNodes nodes={allNodes} edges={allEdges} />
        </Accordion.Body>
      </Accordion.Item>

      {/* Section Expert */}
      <Accordion.Item>
        <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
          <Badge bg="success" className="me-2">Expert Views:</Badge>
          <h5 style={{ margin: 0 }}>
            The following sections are grouped by expert field and role.
          </h5>
        </div>
      </Accordion.Item>

      {/* Actor Categories by Expert */}
      <Accordion.Item eventKey="5">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">6</Badge>
          Actor Categories by Expert
        </Accordion.Header>
        <Accordion.Body>
          <ActorCategoriesByExpert data={data} />
        </Accordion.Body>
      </Accordion.Item>

      {/* Actor List by Expert */}
      <Accordion.Item eventKey="6">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">7</Badge>
          Actor List by Expert
        </Accordion.Header>
        <Accordion.Body>
          <ActorListByExpert data={data} />
        </Accordion.Body>
      </Accordion.Item>

      {/* Actor Usage by Expert Chart */}
      <Accordion.Item eventKey="7">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">8</Badge>
          Actor Usage by Expert Chart
        </Accordion.Header>
        <Accordion.Body>
          <ActorUsageByExpertChart data={data} />
        </Accordion.Body>
      </Accordion.Item>

      {/* Repeated Actors by Expert */}
      <Accordion.Item eventKey="8">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">9</Badge>
          Repeated Actors by Expert
        </Accordion.Header>
        <Accordion.Body>
          <RepeatedActorsByExpert data={data} />
        </Accordion.Body>
      </Accordion.Item>

    </Accordion>
  )

}

// #########################################################################################################################
// #################################### Component Section ##################################################################
// #########################################################################################################################

// ----- used for component categories -----

function convertNestedToArray(nestedData) {
  // nestedData = { "Operations": { subcomponents: [...] }, "Producer": { subcomponents: [...] }, ... }

  return Object.entries(nestedData).map(([catName, catObj]) => ({
    category: catName,
    components: catObj?.subcomponents || [],
  }));
}

const ComponentListTable = ({ components }) => {
  // components here is the 'componentCategoryDetailsNested' object
  // we must transform it to an array
  const rows = convertNestedToArray(components);

  if (!rows || rows.length === 0) {
    return <p>No data available.</p>;
  }

  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>Category</th>
          <th>Component</th>
          <th>Items</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((categoryObj, catIndex) => (
          <React.Fragment key={catIndex}>
            {categoryObj.components.map((component, compIndex) => (
              <tr key={`${catIndex}-${compIndex}`}>
                {/* Show category name only on the first row of this category */}
                {compIndex === 0 && (
                  <td rowSpan={categoryObj.components.length} className='small'>
                    {categoryObj.category}
                  </td>
                )}
                <td className='small'>{component.description || "(no description)"}</td>
                <td>
                  {component.items && component.items.length > 0 ? (
                    component.items.map((item, j) => (
                      <span key={j} className="badge bg-light text-dark me-1">
                        {item}
                      </span>
                    ))
                  ) : (
                    <span className="text-muted">No items</span>
                  )}
                </td>
              </tr>
            ))}
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  );
};

function prepareNestedBarChartData(nestedData) {
  // nestedData is the object: { categoryName: { subcomponents: [ {description, items}, ...] }, ... }

  const labels = [];
  const data = [];

  // For each category in the object
  Object.entries(nestedData).forEach(([categoryName, catObj]) => {
    if (Array.isArray(catObj?.subcomponents)) {
      catObj.subcomponents.forEach((sub) => {
        const subName = sub.description || "(no desc)";
        const itemCount = sub.items?.length || 0;
        const label = `${categoryName} - ${subName}`;
        labels.push(label);
        data.push(itemCount);
      });
    }
  });

  return {
    labels,
    datasets: [
      {
        label: "Number of Items",
        data,
        backgroundColor: "rgba(75,192,192,0.6)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
      },
    ],
  };
}

/**
 * Component Bar Chart Nested
 */
const ComponentBarChartNested = ({ components, handleChartClick, handleChartHover }) => {
  // components = componentCategoryDetailsNested

  // Check if it's empty or null
  const categoryNames = Object.keys(components || {});
  if (categoryNames.length === 0) {
    return <p>No data available.</p>;
  }

  // Build the bar chart data
  const chartData = prepareNestedBarChartData(components);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Bar
        data={chartData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              title: { display: true, text: "Subcomponents" },
              ticks: {
                autoSkip: false,
                maxRotation: 90,
                minRotation: 45,
              },
            },
            y: {
              title: { display: true, text: "Number of Items" },
              beginAtZero: true,
              ticks: {
                stepSize: 1,
                min: 0,
              },
            },
          },
          //onClick: (evt, elems) => handleChartClick(evt, elems, chartData, "Subcomponents per Category"),
          onHover: (evt, elems) => handleChartHover(evt, elems),
        }}
      />
    </div>
  );
};

/**
 * Component categories: Bar, Pie, Table, NestedBar
 * @param {*} param0
 * @returns
 */
function ComponentCategories({ componentCategories, componentCategoryDetails, handleChartClick, handleChartHover }) {
  
  const [view, setView] = useState("bar"); // "bar", "pie", "table", "nestedBar"

  const componentBarData = prepareChartData(componentCategories, 'Components per Category');
  const componentPieData = prepareChartData(componentCategories, 'Components per Category');

  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <h5 className="mb-0">Components per Category</h5>
        <div>
          {/* Bar Chart Button */}
          <OverlayTrigger placement="top" overlay={<Tooltip>Bar Chart</Tooltip>}>
            <Button variant="outline-primary" className={`btn-sm ${view === "bar" ? "active" : ""}`} onClick={() => setView("bar")} style={ { marginLeft:'10px' } }>
              <i className="bi bi-bar-chart"></i>
            </Button>
          </OverlayTrigger>

          {/* Pie Chart Button */}
          <OverlayTrigger placement="top" overlay={<Tooltip>Pie Chart</Tooltip>}>
            <Button variant="outline-primary" className={`btn-sm ${view === "pie" ? "active" : ""}`} onClick={() => setView("pie")} style={ { marginLeft:'10px' } }>
              <i className="bi bi-pie-chart"></i>
            </Button>
          </OverlayTrigger>

          {/* Nested Bar Chart Button */}
          <OverlayTrigger placement="top" overlay={<Tooltip>Nested Bar Chart</Tooltip>}>
            <Button variant="outline-primary" className={`btn-sm ${view === "nestedBar" ? "active" : ""}`} onClick={() => setView("nestedBar")} style={ { marginLeft:'10px' } }>
              <i className="bi bi-file-bar-graph"></i>
            </Button>
          </OverlayTrigger>

          {/* Table View Button */}
          <OverlayTrigger placement="top" overlay={<Tooltip>Table View</Tooltip>}>
            <Button variant="outline-primary" className={`btn-sm ${view === "table" ? "active" : ""}`} onClick={() => setView("table")} style={ { marginLeft:'10px' } }>
              <i className="bi bi-table"></i>
            </Button>
          </OverlayTrigger>          

        </div>
      </Card.Header>
      
      <Card.Body style={{ position: "relative", height: "400px" }}>
        {view === "bar" && (
          <Bar 
            data={componentBarData} 
            options={{ 
              responsive: true, 
              maintainAspectRatio: false,
              scales: {
                x: { title: { display: true, text: 'Categories' } },
                y: { title: { display: true, text: 'Number of Items' }, beginAtZero: true }
              },
              onClick: (evt, elems) => handleChartClick(evt, elems, componentBarData, 'Components per Category'),
              onHover: (evt, elems) => handleChartHover(evt, elems)
            }} 
          />
        )}

        {view === "pie" && (
          <Pie 
            data={componentPieData} 
            options={{ 
              responsive: true, 
              maintainAspectRatio: false,
              onClick: (evt, elems) => handleChartClick(evt, elems, componentPieData, 'Components per Category'),
              onHover: (evt, elems) => handleChartHover(evt, elems),
            }} 
          />
        )}

        {view === "table" && (
          <div style={{ overflowY: "auto", maxHeight: "100%" }}>
            <ComponentListTable components={componentCategoryDetails} />
          </div>
        )}

        {view === "nestedBar" && (
          <ComponentBarChartNested components={componentCategoryDetails} handleChartClick={handleChartClick} handleChartHover={handleChartHover} />
        )}
      </Card.Body>
    </Card>
  );
}

// --------------------------------------------------------------------------------------------

/**
 * Component categories table
 * @param {*} param0
 *  @returns
 */
function ComponentCategoriesTable({ componentCategories }) {

  // Helper to sum an object’s values
  const sumValues = (obj) => Object.values(obj).reduce((acc, val) => acc + val, 0);

  if (!componentCategories || Object.keys(componentCategories).length === 0) {
    return (
      <Card className="mb-4 h-100">
        <Card.Header>
          <h5 className="mb-0">Component Categories Count (incl. duplicates)</h5>
        </Card.Header>
        <Card.Body>
          <p>No component categories found.</p>
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      <Card className="mb-4 h-100">
        <Card.Header>
          <h5 className="mb-0">Component Categories Count (incl. duplicates)</h5>
        </Card.Header>
        <Card.Body>
          <Table bordered hover size="sm">
            <thead>
              <tr>
                <th>Category</th>
                <th>Count</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(componentCategories).map(([cat, count]) => (
                <tr key={cat}>
                  <td>{cat}</td>
                  <td>{count}</td>
                </tr>
              ))}
              <tr>
                <td><strong>Total</strong></td>
                <td><strong>{sumValues(componentCategories)}</strong></td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  )
}

// --------------------------------------------------------------------------------------------

/**
 * Component category details, provides a high-level view of the component categories
 * @param {*} param0
 * @returns
 */
function ComponentCategoryDetails({ componentCategoryDetails }) {

  if (!componentCategoryDetails || Object.keys(componentCategoryDetails).length === 0) {
    return (
      <Card className="mb-4">
        <Card.Header>
          <h5 className="mb-0">Component Category Details</h5>
          <small className="text-muted">Lowercased items, no duplicates listed, usage shown</small>
        </Card.Header>
        <Card.Body>
          <p>No detailed component categories found.</p>
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      <Card className="mb-4">
        <Card.Header>
          <h5 className="mb-0">Component Category Details</h5>
          <small className="text-muted">Lowercased items, no duplicates listed, usage shown</small>
        </Card.Header>
        <Card.Body style={{ maxHeight: '500px', overflowY: 'auto' }}>
          {Object.keys(componentCategoryDetails).length === 0 ? (
            <p>No detailed component categories found.</p>
          ) : (
            <Table bordered hover size="sm">
              <thead>
                <tr>
                  <th style={{ width: '25%' }}>Category</th>
                  {/* Distinct / Total column */}
                  <th style={{ width: '15%' }}>Distinct / Total</th>
                  <th>Items</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(componentCategoryDetails).map(([catName, details]) => {
                  // STEP 1: Build a frequency map for the (lowercased) items
                  const freqMap = {};
                  (details.items || []).forEach(item => {
                    if (!item) return; // skip null/undefined
                    const lower = item.toLowerCase().trim();
                    if (lower) freqMap[lower] = (freqMap[lower] || 0) + 1;
                  });

                  // distinctCount = how many distinct lowercased items
                  const distinctCount = Object.keys(freqMap).length;
                  // totalCount = sum of all appearances (including duplicates)
                  const totalCount = Object.values(freqMap).reduce((acc, val) => acc + val, 0);

                  return (
                    <tr key={catName}>
                      <td>{catName}</td>
                      {/* show "distinctCount / totalCount" */}
                      <td>{distinctCount} / {totalCount}</td>
                      <td>
                        {distinctCount === 0 ? (
                          '(none)'
                        ) : (
                          <ul className="mb-0 small">
                            {Object.entries(freqMap).map(([itemName, usageCount]) => (
                              <li key={itemName} style={{ fontWeight: usageCount > 1 ? 'bold' : 'normal', color: usageCount > 1 ? 'blue' : 'inherit' }}>
                                {itemName}
                                {usageCount > 1 ? ` (${usageCount})` : ''}
                              </li>
                            ))}
                          </ul>
                        )}
                      </td>
                    </tr>
                  );
                })}
                {/* Summation row for distinct & total across categories (naive sum) */}
                <tr>
                  <td><strong>Total</strong></td>
                  <td colSpan={2}>
                    <strong>
                      {(() => {
                        // Sums each category’s distinct & total (naive approach)
                        let distinctSum = 0;
                        let totalSum = 0;
                        Object.entries(componentCategoryDetails).forEach(([catName, details]) => {
                          const freqMap = {};
                          (details.items || []).forEach(item => {
                            if (item) {
                              const lower = item.toLowerCase().trim();
                              if (lower) freqMap[lower] = (freqMap[lower] || 0) + 1;
                            }
                          });
                          distinctSum += Object.keys(freqMap).length;
                          totalSum += Object.values(freqMap).reduce((acc, val) => acc + val, 0);
                        });
                        return `${distinctSum} / ${totalSum}`;
                      })()}
                    </strong>
                  </td>
                </tr>
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

// --------------------------------------------------------------------------------------------

/**
 * Displays detailed cluster of components by category + subcategory,
 * with distinct vs. total item usage. Items are lowercased to unify duplicates.
 *
 * @param {Object} props
 * @param {Object} props.componentCategoryDetails
 */
function EnhancedComponentsTable({ componentCategoryDetails }) {

  // If none
  if (!componentCategoryDetails || Object.keys(componentCategoryDetails).length === 0) {
    return (
      <Card className="mb-4">
        <Card.Header>
          <h5 className="mb-0">Component Category Details</h5>
          <small className="text-muted">
            Lowercased items, no duplicates listed, usage shown
          </small>
        </Card.Header>
        <Card.Body>
          <p>No detailed component categories found.</p>
        </Card.Body>
      </Card>
    );
  }

  // Summaries for final row (naive approach)
  let grandDistinctSum = 0;
  let grandTotalSum = 0;

  /**
   * Helper to create an OverlayTrigger tooltip for "Distinct / Total" explanation
   */
  const distinctTotalTooltip = (
    <Tooltip id="distinct-total-tooltip">
      <div style={{ maxWidth: '200px' }}>
        <strong>Distinct</strong> means unique item strings (lowercased). <br />
        <strong>Total</strong> includes duplicates if an item appears multiple times.
      </div>
    </Tooltip>
  );

  /**
   * Main loop:
   * 1) group subcomponents
   * 2) for each subcomponent => distinct vs. total
   * 3) sum up category-level distinct & total
   */
  const rows = Object.entries(componentCategoryDetails).map(([catName, catDetails]) => {
    if (!catDetails.subcomponents || !Array.isArray(catDetails.subcomponents)) {
      // fallback if aggregator hasn't structured it properly
      return (
        <tr key={catName}>
          <td>{catName}</td>
          <td colSpan={3}>(no components array?)</td>
        </tr>
      );
    }

    let catDistinctSum = 0;
    let catTotalSum = 0;

    // Build subrows
    const subRows = catDetails.subcomponents.map((subComp, index) => {
      const freqMap = {};
      (subComp.items || []).forEach(item => {
        if (!item) return;
        const lower = item.toLowerCase().trim();
        if (lower) freqMap[lower] = (freqMap[lower] || 0) + 1;
      });

      const distinctCount = Object.keys(freqMap).length;
      const totalCount = Object.values(freqMap).reduce((acc, val) => acc + val, 0);

      catDistinctSum += distinctCount;
      catTotalSum += totalCount;

      // Build bullet list for distinct items
      const itemList = Object.entries(freqMap).map(([itemName, usageCount]) => (
        <li 
          key={itemName} 
          style={{ fontWeight: usageCount > 1 ? 'bold' : 'normal', color: usageCount > 1 ? 'blue' : 'inherit' }}
        >
          {itemName}
          {usageCount > 1 ? ` (${usageCount})` : ''}
        </li>
      ));
      

      return (
        <tr key={`${catName}-${index}`}>
          {/* First column is the subcomponent's "description" */}
          <td className="ps-4">{subComp.description || '(no desc)'}</td>
          {/* Distinct / total with tooltip */}
          <OverlayTrigger placement="top" overlay={distinctTotalTooltip}>
            <td>{distinctCount} / {totalCount}</td>
          </OverlayTrigger>
          <td>
            {distinctCount === 0 ? '(none)' : (
              <ul className="mb-0 small">
                {itemList}
              </ul>
            )}
          </td>
        </tr>
      );
    });

    // We'll do a subfooter row for the category
    grandDistinctSum += catDistinctSum;
    grandTotalSum += catTotalSum;

    return (
      <React.Fragment key={catName}>
        {/* Category row (spanning 3 columns) */}
        <tr className="table-active">
          <td colSpan={3}>
            <strong>{catName}</strong> 
            {'  '}
            <Badge bg="info" className="ms-2">
              Distinct / Total: {catDistinctSum} / {catTotalSum}
            </Badge>
          </td>
        </tr>
        {subRows}
      </React.Fragment>
    );
  });

  return (
    <Card className="mb-4">
      <Card.Header>
        <h5 className="mb-0">Component Category Details</h5>
        <small className="text-muted">
          Lowercased items, no duplicates listed, usage shown
        </small>
      </Card.Header>
      <Card.Body style={{ maxHeight: '500px', overflowY: 'auto' }}>
        <Table bordered hover size="sm">
          <thead>
            <tr>
              <th style={{ width: '25%' }}>Subcategory</th>
              <OverlayTrigger placement="top" overlay={distinctTotalTooltip}>
                <th style={{ width: '15%' }}>Distinct / Total</th>
              </OverlayTrigger>
              <th>Items</th>
            </tr>
          </thead>
          <tbody>
            {rows}
            {/* Final grand total row */}
            <tr className="table-warning">
              <td><strong>All Categories Sum</strong></td>
              <td colSpan={2}>
                <strong>{grandDistinctSum} / {grandTotalSum}</strong>
              </td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

// --------------------------------------------------------------------------------------------

/**
 * Component usage by expert field and role
 */
function ComponentUsageChartDetails({ data }) {

  const [chartMode, setChartMode] = useState("categorySubcat");
  const [topNItems, setTopNItems] = useState(20);
  const [includeOther, setIncludeOther] = useState(true);

  /**
   * Parse data into a structure
   * 
   * {
   *   [xAxisLabel]: {
   *     [itemName]: usageCount
   *   }
   * }
   *
   * Where xAxisLabel might be "Consumer" or "Consumer - Consumer Analytics".
   */
  const usageMap = useMemo(() => {
    const result = {};

    data.forEach((useCase) => {
      // each useCase has 'components.value' => array of categoryObjects
      // each categoryObject => { category: "Consumer", components: [ ... ] }
      useCase.components?.value?.forEach((catObj) => {
        const topCategory = catObj.category || "Unknown Category";

        catObj.components?.forEach((componentObj) => {
          // e.g. componentObj.description = "Consumer Delivery", items = [...]
          const subcat = componentObj.description || "Unnamed Subcategory";

          // Decide which label goes on the x-axis
          let xLabel;
          if (chartMode === "categoryOnly") {
            xLabel = topCategory; 
          } else {
            // e.g. "Consumer - Consumer Delivery"
            xLabel = `${topCategory} - ${subcat}`;
          }

          // Ensure nested object structure
          if (!result[xLabel]) {
            result[xLabel] = {};
          }

          // For each item, increment usage count
          componentObj.items?.forEach((itemName) => {
            if (!result[xLabel][itemName]) {
              result[xLabel][itemName] = 0;
            }
            result[xLabel][itemName]++;
          });
        });
      });
    });

    return result;
  }, [data, chartMode]);

  /**
   * Identify the overall frequency of each item across all x-axis labels
   */
  const itemFrequencyMap = useMemo(() => {
    const freq = {};
    Object.values(usageMap).forEach((itemsObj) => {
      // itemsObj is { [itemName]: usageCount, ... }
      Object.entries(itemsObj).forEach(([itemName, count]) => {
        freq[itemName] = (freq[itemName] || 0) + count;
      });
    });
    return freq;
  }, [usageMap]);

  /**
   * Sort items by total usage descending, take top N
   */
  const topItemNames = useMemo(() => {
    return Object.entries(itemFrequencyMap)
      .sort((a, b) => b[1] - a[1])
      .slice(0, topNItems)
      .map(([itemName]) => itemName);
  }, [itemFrequencyMap, topNItems]);

  /**
   * The x-axis labels are simply the keys of usageMap
   */
  const xAxisLabels = useMemo(() => {
    // e.g. ["Consumer - Consumer Analytics", "Consumer - Consumer Delivery", ...]
    return Object.keys(usageMap);
  }, [usageMap]);

  /**
   * Build chart data in a "stacked bar" style:
   *  - create one dataset per item, limited to the top items.
   *  - If an x-axis label has usage for items NOT in the top list, put those in an "Other" dataset
   */
  const chartData = useMemo(() => {

    // one dataset for each "top" item
    const datasets = topItemNames.map((itemName) => ({
      label: itemName,
      backgroundColor: getNextBlueGreenColor(),
      data: xAxisLabels.map((label) => usageMap[label][itemName] || 0),
    }));
    
    if (includeOther) {
      const otherDataset = {
        label: "Other",
        backgroundColor: "rgba(128,128,128,0.5)",
        data: xAxisLabels.map((label) => {
          const itemsObj = usageMap[label];
          let sumOther = 0;
          Object.entries(itemsObj).forEach(([itemName, count]) => {
            if (!topItemNames.includes(itemName)) {
              sumOther += count;
            }
          });
          return sumOther;
        }),
      };
      datasets.push(otherDataset);
    }

    return {
      labels: xAxisLabels,
      datasets,
    };
  }, [usageMap, topItemNames, xAxisLabels, includeOther]);

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          maxRotation: 45,
          minRotation: 0,
        },
      },
      y: {
        stacked: true, 
        beginAtZero: true,
      },
    },
  };

  return (
    <Card className="mb-4 h-100">
      <Card.Header>
        <h5 className="mb-0">Component subcategory / Item Usage</h5>
      </Card.Header>
      <Card.Body>
        <Form.Group className="mb-3" controlId="chartModeSelect">
          <Form.Label>X-Axis Mode:</Form.Label>
          <Form.Control
            as="select"
            value={chartMode}
            onChange={(e) => setChartMode(e.target.value)}
          >
            <option value="categoryOnly">Category Only</option>
            <option value="categorySubcat">Category - Subcategory</option>
          </Form.Control>
          <Form.Text>
            "Category Only" means x-axis labels = <em>Consumer, Producer, etc.</em>
            <br />
            "Category - Subcategory" yields <em>Consumer - Consumer Delivery</em>, etc.
          </Form.Text>
        </Form.Group>

        {/* Choose how many items we want stacked */}
        <Form.Group className="mb-3" controlId="topNItemsSelect">
          <Form.Label>Top N Items to Show in Chart:</Form.Label>
          <Form.Control
            type="number"
            min={1}
            max={50}
            value={topNItems}
            onChange={(e) => setTopNItems(parseInt(e.target.value, 10))}
          />
        </Form.Group>

        {/* Include "Other" dataset */}
        <Form.Group className="mb-3" controlId="includeOtherCheckbox">
          <Form.Check
            type="checkbox"
            label="Include 'Other' dataset"
            checked={includeOther}
            onChange={(e) => setIncludeOther(!includeOther)}
          />
        </Form.Group>

        <Bar data={chartData} options={chartOptions} />
      </Card.Body>
    </Card>
  );
}

// --------------------------------------------------------------------------------------------

// helper to build usage map from aggregator data
function buildGlobalItemUsage(componentCategoryDetails) {
  const usageMap = {};
  for (const catName in componentCategoryDetails) {
    const { items = [] } = componentCategoryDetails[catName];
    items.forEach(item => {
      usageMap[item] = (usageMap[item] || 0) + 1;
    });
  }
  return usageMap;
}

/**
 * Displays top repeated component items across all categories,
 * sorted descending by usage. Show only topN.
 *
 * PROPS:
 *  - componentCategoryDetails: aggregator result: {catName => { total, items: [] }}
 *  - topN: how many to show
 */
function TopRepeatedComponentsChart({ componentCategoryDetails, topN }) {
  // build or fetch the global usage map
  const usageMap = buildGlobalItemUsage(componentCategoryDetails);

  // filter repeated => usage > 1
  const repeatedArr = Object.entries(usageMap).filter(([,count]) => count > 1);
  // sort descending
  repeatedArr.sort((a,b) => b[1] - a[1]);
  // pick top N
  const topRepeated = repeatedArr.slice(0, topN);

  const labels = topRepeated.map(([item]) => item);
  const dataVals = topRepeated.map(([, usage]) => usage);

  const chartData = {
    labels,
    datasets: [
      {
        label: `Top ${topN} Repeated Items`,
        data: dataVals,
        backgroundColor: 'rgba(255, 99, 132, 0.6)'
      }
    ]
  };

  const options = {
    indexAxis: 'y',   // horizontal bar
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (ctx) => {
            const label = ctx.label || '';
            const val = ctx.parsed.x;
            return `${label}: ${val} occurrences`;
          }
        }
      }
    }
  };

  return (
    <>
      <Bar data={chartData} options={options} />
    </>
  );
}

// --------------------------------------------------------------------------------------------

// buildCategoryItemUsage
function buildCategoryItemUsage(componentCategoryDetails) {
  const result = {};
  for (const catName in componentCategoryDetails) {
    const { items } = componentCategoryDetails[catName];
    // build a frequency map for each category
    const freqMap = {};
    items.forEach((item) => {
      freqMap[item] = (freqMap[item] || 0) + 1;
    });
    result[catName] = freqMap;
  }
  return result;
}

/**
 * Category bubble
 * @param {*} param0 
 * @returns 
 */
function CategoryItemBubbleChart({ componentCategoryDetails }) {
  // build the usage map => {cat => {item => usageCount}}
  const categoryItemUsage = buildCategoryItemUsage(componentCategoryDetails);

  // gather sorted categories
  const categories = Object.keys(categoryItemUsage).sort();

  // gather all distinct items
  const allItemsSet = new Set();
  categories.forEach(cat => {
    Object.keys(categoryItemUsage[cat]).forEach(item => {
      allItemsSet.add(item);
    });
  });
  const items = Array.from(allItemsSet).sort();

  // create a bubble data array of {x, y, r} 
  // x = category index, y = item index, r = usage
  const bubbleData = [];
  categories.forEach((cat, catIndex) => {
    const freqMap = categoryItemUsage[cat];
    items.forEach((item, itemIndex) => {
      const usage = freqMap[item] || 0;
      if (usage > 0) {
        bubbleData.push({
          x: catIndex,
          y: itemIndex,
          r: Math.sqrt(usage) * 5 + 3
        });
      }
    });
  });

  const chartData = {
    datasets: [
      {
        label: 'Category vs. Item usage',
        data: bubbleData,
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
        hoverBackgroundColor: 'rgba(54, 162, 235, 0.8)',
      }
    ]
  };

  const options = {
    responsive: true,
    aspectRatio: 1.5,
    scales: {
      x: {
        type: 'linear',
        min: -1,
        max: categories.length,
        ticks: {
          callback: function(value, index) {
            if (value >= 0 && value < categories.length) {
              return categories[value];
            }
            return '';
          }
        },
        title: {
          display: true,
          text: 'Categories'
        }
      },
      y: {
        type: 'linear',
        min: -1,
        max: items.length,
        ticks: {
          callback: function(value, index) {
            if (value >= 0 && value < items.length) {
              return items[value];
            }
            return '';
          }
        },
        title: {
          display: true,
          text: 'Items'
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (ctx) => {
            const raw = ctx.raw;
            const catName = categories[Math.floor(raw.x)];
            const itemName = items[Math.floor(raw.y)];
            const radius = raw.r; // bubble radius
            const usageApprox = Math.round(Math.pow((radius - 3)/5, 2));
            return [`Category: ${catName}`, `Item: ${itemName}`, `Usage: ~${usageApprox}`];
          }
        }
      }
    }
  };

  return (
      <Scatter data={chartData} options={options} />
  );
}

// --------------------------------------------------------------------------------------------

/**
 * Repeat vs unique components (bar and pie)
 * @param {*} param0 
 * @returns 
 */
function RepeatedVsUniqueComponents({
  totalComponentItems,
  totalRepeatedComponentUsage,
  repeatedComponentCount
}) {

  const [isBarChartComp, setIsBarChartComp] = useState(true);
  
  const repeatedUsage = totalRepeatedComponentUsage;
  const uniqueUsage = totalComponentItems - repeatedUsage;

  const data = {
    labels: ['Repeated Items Usage', 'Unique Items Usage'],
    datasets: [{
      data: [repeatedUsage, uniqueUsage],
      backgroundColor: ['#FF6384', '#36A2EB'],
      hoverBackgroundColor: ['#FF6384', '#36A2EB']
    }]
  };

  return (
    <div style={{ maxWidth: '300px', margin: '0 auto' }}>

      <Button variant="outline-primary" className="btn-sm" onClick={() => setIsBarChartComp(!isBarChartComp)}>
        {isBarChartComp ? (
          <i className="bi bi-bar-chart"></i>
        ) : (
          <i className="bi bi-pie-chart"></i>
        )}
      </Button>

      <span className="text-muted" style={{ marginLeft:'30px'}}> Repeated: {repeatedComponentCount} / Total: {totalComponentItems}</span>

      {isBarChartComp ? (
        <Pie 
          data={data}
        />
      ) : (
        <Bar 
          data={data}
          options={{ 
            indexAxis: 'y',
            scales: { x: { beginAtZero: true } },
            responsive: true,
          }} 
        />
      )}
    </div>
  );
}

// --------------------------------------------------------------------------------------------
// Expert Views on Components Section
// --------------------------------------------------------------------------------------------

/**
 * Component items by expert field and role
 */
function ComponentsByExpert({ data }) {
  // Provide the same "viewBy" toggle as in ActorCategoriesByExpert
  const [viewBy, setViewBy] = useState("both");
  // Possible values: "expert_field", "role", or "both"

  /**
   * Build a structure like:
   * {
   *   [groupKey]: {
   *     [categoryName]: {
   *       [componentDescription]: {
   *         count: <number of times used>,
   *         itemCounts: {
   *           [itemName]: <number of times used>
   *         }
   *       }
   *     }
   *   }
   * }
   *
   * - groupKey = "ExpertField (Role)" or just "ExpertField" or "Role"
   * - categoryName = "Operations", "Producer", "Consumer", etc.
   * - componentDescription = the "description" field of each component
   * - itemCounts = dictionary of item => how many times it appears
   */
  const groupedComponents = useMemo(() => {
    const result = {};

    data.forEach((useCase) => {
      const expertField = useCase.expert_field?.value || "Unknown Expert Field";
      const role = useCase.role?.value || "Unknown Role";

      let groupKey;
      switch (viewBy) {
        case "expert_field":
          groupKey = expertField;
          break;
        case "role":
          groupKey = role;
          break;
        default: // "both"
          groupKey = `${expertField} (${role})`;
          break;
      }

      // For each high-level category (like 'Operations', 'Producer', etc.)
      useCase.components?.value?.forEach((categoryObj) => {
        const categoryName = categoryObj.category || "Unknown Category";

        // Initialize structures if needed
        if (!result[groupKey]) {
          result[groupKey] = {};
        }
        if (!result[groupKey][categoryName]) {
          result[groupKey][categoryName] = {};
        }

        // For each component within that category
        categoryObj.components?.forEach((comp) => {
          const compDesc = comp.description || "Unnamed Component";

          // Create a record for that component if it doesn't exist
          if (!result[groupKey][categoryName][compDesc]) {
            result[groupKey][categoryName][compDesc] = {
              count: 0,
              itemCounts: {},
            };
          }

          // Increment usage count for the component itself
          result[groupKey][categoryName][compDesc].count++;

          // For each item in that component, track usage
          comp.items?.forEach((item) => {
            if (!result[groupKey][categoryName][compDesc].itemCounts[item]) {
              result[groupKey][categoryName][compDesc].itemCounts[item] = 0;
            }
            result[groupKey][categoryName][compDesc].itemCounts[item]++;
          });
        });
      });
    });

    return result;
  }, [data, viewBy]);

  return (
    <Card className="mb-4 h-100">
      <Card.Header>
        <h5 className="mb-0">Components</h5>
      </Card.Header>
      <Card.Body>
        <Form.Group controlId="viewBySelect">
          <Form.Label>View By:</Form.Label>
          <Form.Control
            as="select"
            value={viewBy}
            onChange={(e) => setViewBy(e.target.value)}
          >
            <option value="expert_field">Expert Field</option>
            <option value="role">Role</option>
            <option value="both">Expert Field &amp; Role</option>
          </Form.Control>
        </Form.Group>

        <Table bordered hover size="sm" className="mt-3">
          <thead>
            <tr>
              <th>
                {viewBy === "both"
                  ? "Expert Field (Role)"
                  : viewBy === "expert_field"
                  ? "Expert Field"
                  : "Role"}
              </th>
              <th>Category</th>
              <th>Component</th>
              <th>Count</th>
              <th>Items</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(groupedComponents).map(([groupLabel, categories]) => {
              // Collect categories for this group
              const categoryEntries = Object.entries(categories);

              // Sum of the number of components in each category
              const totalRowsInGroup = categoryEntries.reduce((sum, [_, comps]) => {
                return sum + Object.keys(comps).length;
              }, 0);

              // iterate over categories, then the components within each category
              return categoryEntries.flatMap(([categoryName, compMap], catIndex) => {
                const compEntries = Object.entries(compMap);
                // Each category has compEntries.length components
                const rowSpanForCategory = compEntries.length;

                return compEntries.map(([compDesc, compInfo], compIndex) => {
                  // rowSpan for the group label only on the *very first row* of the entire group
                  const shouldShowGroupLabel = catIndex === 0 && compIndex === 0;
                  // rowSpan for the category label only on the *first row* of each category
                  const shouldShowCategoryLabel = compIndex === 0;

                  const row = (
                    <tr key={`${groupLabel}-${categoryName}-${compDesc}`}>
                      {/* Group Label cell (row-spanned) */}
                      {shouldShowGroupLabel && (
                        <td rowSpan={totalRowsInGroup} className="fw-bold">
                          {groupLabel}
                        </td>
                      )}

                      {/* Category Name cell (row-spanned) */}
                      {shouldShowCategoryLabel && (
                        <td rowSpan={rowSpanForCategory}>{categoryName}</td>
                      )}

                      {/* Component Description */}
                      <td>{compDesc}</td>

                      {/* Count of this component's usage */}
                      <td>{compInfo.count}</td>

                      {/* Items (render each item as a Badge) */}
                      <td>
                        {Object.entries(compInfo.itemCounts).map(
                          ([itemName, itemCount]) => (
                            <Badge
                              key={itemName}
                              // Show a different color if count>1, for example
                              {...(itemCount > 1
                                ? { bg: "warning" }
                                : { bg: "light" })}
                              text="dark"
                              className="me-2"
                              style={{ border: "1px solid #ccc" }}
                            >
                              {itemName} {itemCount > 1 ? `(${itemCount})` : ""}
                            </Badge>
                          )
                        )}
                      </td>
                    </tr>
                  );

                  //usedRows++;
                  return row;
                });
              });
            })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

// --------------------------------------------------------------------------------------------

/**
 * Item component usage by expert field and role
 */
function ComponentUsageByExpertChartItem({ data }) {
  // 1) Let the user decide how to group the data
  const [viewBy, setViewBy] = useState("both");
  // 2) Let the user pick how many top items to show
  const [topN, setTopN] = useState(10);

  /**
   * Build a structure mapping each ITEM -> each GROUP -> count
   * 
   * For example:
   * {
   *   "On-Demand Services": {
   *     "Engineering (Technician)": 3,
   *     "Business (Manager)": 1
   *     ...
   *   },
   *   "Brake": {
   *     "Engineering (Driver)": 2,
   *     ...
   *   },
   *   ...
   * }
   */
  const itemUsageMap = useMemo(() => {
    const result = {};

    data.forEach((useCase) => {
      // Decide the "group" label based on viewBy
      const expertField = useCase.expert_field?.value || "Unknown Expert Field";
      const role = useCase.role?.value || "Unknown Role";
      let groupKey;
      switch (viewBy) {
        case "expert_field":
          groupKey = expertField;
          break;
        case "role":
          groupKey = role;
          break;
        default: // "both"
          groupKey = `${expertField} (${role})`;
      }

      // Walk each top-level category (e.g., "Consumer", "Technical Layer", etc.)
      useCase.components?.value?.forEach((categoryObj) => {
        // Then each component within that category
        categoryObj.components?.forEach((componentObj) => {
          // Finally each item in that component
          componentObj.items?.forEach((item) => {
            if (!result[item]) {
              result[item] = {};
            }
            if (!result[item][groupKey]) {
              result[item][groupKey] = 0;
            }
            // Increment usage of this item for this group
            result[item][groupKey] += 1;
          });
        });
      });
    });

    return result;
  }, [data, viewBy]);

  /**
   * Compute the total usage of each item across all groups
   * to sort items by their global usage.
   */
  const itemFrequencyMap = useMemo(() => {
    const freqMap = {};
    Object.entries(itemUsageMap).forEach(([item, groupCounts]) => {
      let total = 0;
      Object.values(groupCounts).forEach((cnt) => {
        total += cnt;
      });
      freqMap[item] = total;
    });
    return freqMap;
  }, [itemUsageMap]);

  /**
   * Sort items by their total usage, descending,
   * then take the top N for charting.
   */
  const topItems = useMemo(() => {
    return Object.entries(itemFrequencyMap)
      .sort((a, b) => b[1] - a[1])
      .slice(0, topN)
      .map(([itemName]) => itemName);
  }, [itemFrequencyMap, topN]);

  // Distinct groups (the chart's "datasets") come from scanning all group keys
  // in itemUsageMap. We'll gather them into a Set first.
  const groupSet = useMemo(() => {
    const set = new Set();
    Object.values(itemUsageMap).forEach((groupObj) => {
      Object.keys(groupObj).forEach((g) => set.add(g));
    });
    return set;
  }, [itemUsageMap]);

  const groups = Array.from(groupSet); // For consistent ordering, you could sort them if you wish

  /**
   * Build chart data:
   *  - X-axis = topItems
   *  - One dataset per group
   *  - data points are usage counts for that group on each item
   */
  const chartData = useMemo(() => {
    return {
      labels: topItems, // x-axis is items
      datasets: groups.map((groupKey) => {
        return {
          label: groupKey,
          data: topItems.map((item) => itemUsageMap[item]?.[groupKey] || 0),
          backgroundColor: getNextBlueGreenColor(),
        };
      }),
    };
  }, [topItems, groups, itemUsageMap]);

  const chartOptions = {
    responsive: true,
    plugins: { legend: { position: "top" } },
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        ticks: {
          maxRotation: 50,
          minRotation: 0,
        },
      },
    },
  };

  return (
    <Card className="mb-4 h-100">
      <Card.Header>
        <h5 className="mb-0">Component usage by Items</h5>
      </Card.Header>
      <Card.Body>
        {/* 1) Toggle grouping */}
        <Form.Group className="mb-3" controlId="viewBySelect">
          <Form.Label>View By:</Form.Label>
          <Form.Control
            as="select"
            value={viewBy}
            onChange={(e) => setViewBy(e.target.value)}
          >
            <option value="expert_field">Expert Field</option>
            <option value="role">Role</option>
            <option value="both">Expert Field + Role</option>
          </Form.Control>
        </Form.Group>

        {/* 2) Top N items */}
        <Form.Group className="mb-3" controlId="topNSelect">
          <Form.Label>Show Top N Items:</Form.Label>
          <Form.Control
            type="number"
            min={1}
            max={200}
            value={topN}
            onChange={(e) => setTopN(parseInt(e.target.value, 10))}
          />
        </Form.Group>

        <Bar data={chartData} options={chartOptions} />
      </Card.Body>
    </Card>
  );
}

// --------------------------------------------------------------------------------------------

/**
 * Component view details (usage, items, etc.) per category and subcategory, with expert fields
 */
function AdvancedComponentUsageChartItem({ data }) {

  // modal
  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);

  // 1) Group by expert fields/roles?
  //    "expert_field", "role", or "both"
  const [groupBy, setGroupBy] = useState("both");

  // 2) Define the x-axis categories?
  //    "categoryOnly" => just the top-level category (e.g. "Consumer", "Producer")
  //    "categorySubcat" => "Category - Subcategory" (e.g. "Consumer - Consumer Analytics")
  const [xAxisMode, setXAxisMode] = useState("categoryOnly");

  // 3) How many top items to display in stacked bars?
  //    Everything else gets lumped into "Other" if you choose to do so.
  const [topNItems, setTopNItems] = useState(5);

  // 4) Optionally, let the user decide whether to collect leftover items into "Other"
  const [includeOther, setIncludeOther] = useState(true);

  /**
   * Build a structure:
   *
   *   usageMap[subcatLabel][groupKey][itemName] = count
   *
   * Where:
   * - subcatLabel = either just the category or "Category - Subcategory"
   * - groupKey = based on groupBy => "Expert Field", "Role", or both combined
   * - itemName = each string in componentObj.items
   */
  const usageMap = useMemo(() => {
    const result = {};

    data.forEach((useCase) => {
      // Determine which group this useCase belongs to
      const expertField = useCase.expert_field?.value || "Unknown Expert Field";
      const role = useCase.role?.value || "Unknown Role";

      let groupKey;
      switch (groupBy) {
        case "expert_field":
          groupKey = expertField;
          break;
        case "role":
          groupKey = role;
          break;
        default:
          groupKey = `${expertField} (${role})`;
      }

      // Now, for each top-level category object
      useCase.components?.value?.forEach((catObj) => {
        const catName = catObj.category || "Uncategorized";

        catObj.components?.forEach((componentObj) => {
          // subcat is either "categoryOnly" => catName
          // or "categorySubcat" => e.g. "Consumer - Consumer Analytics"
          let subcatLabel;
          if (xAxisMode === "categoryOnly") {
            subcatLabel = catName;
          } else {
            // "categorySubcat"
            const desc = componentObj.description || "Unnamed";
            subcatLabel = `${catName} - ${desc}`;
          }

          if (!result[subcatLabel]) {
            result[subcatLabel] = {};
          }
          if (!result[subcatLabel][groupKey]) {
            result[subcatLabel][groupKey] = {};
          }

          // For each item in the component, increment usage
          componentObj.items?.forEach((itemName) => {
            if (!result[subcatLabel][groupKey][itemName]) {
              result[subcatLabel][groupKey][itemName] = 0;
            }
            result[subcatLabel][groupKey][itemName]++;
          });
        });
      });
    });

    return result;
  }, [data, groupBy, xAxisMode]);

  /**
   * Build a frequency map: itemName -> total usage across everything (topn)
   */
  const itemFrequency = useMemo(() => {
    const freq = {};
    Object.values(usageMap).forEach((groupObj) => {
      // groupObj => { groupKey => { itemName => count } }
      Object.values(groupObj).forEach((itemObj) => {
        // itemObj => { itemName => count }
        Object.entries(itemObj).forEach(([itemName, cnt]) => {
          freq[itemName] = (freq[itemName] || 0) + cnt;
        });
      });
    });
    return freq;
  }, [usageMap]);

  // Sort items descending, pick top N
  const topItems = useMemo(() => {
    return Object.entries(itemFrequency)
      .sort((a, b) => b[1] - a[1]) // descending
      .slice(0, topNItems)
      .map(([itemName]) => itemName);
  }, [itemFrequency, topNItems]);

  /**
   * The x-axis labels = subcategory labels (keys of usageMap).
   */
  const xAxisLabels = useMemo(() => {
    return Object.keys(usageMap).sort();
  }, [usageMap]);

  /**
   * Next, we need the "groups" array => distinct groupKey values
   * so we can produce the "grouped stacks."
   */
  const groupSet = useMemo(() => {
    const set = new Set();
    Object.values(usageMap).forEach((groupsObj) => {
      Object.keys(groupsObj).forEach((g) => set.add(g));
    });
    return set;
  }, [usageMap]);

  const groups = useMemo(() => Array.from(groupSet).sort(), [groupSet]);

  /**
   * Build the Chart.js data structure in a "grouped stacked" format:
   *
   * - Create a separate dataset for each (group, item) pair among the top items.
   *   That means if G groups and M top items are given, that's G * M datasets,
   *   plus possibly G "Other" datasets if including an "Other" bucket.
   *
   * - label each dataset with just the item name (or item+group?), but assign
   *   the dataset's `stack = group` so that each group forms its own vertical stack
   *   at each x-axis position. Items become the segments in that stack.
   *
   * - For each x-axis label, look up usageMap[xLabel][group][itemName].
   */
  const chartData = useMemo(() => {
    const datasets = [];

    // For each group, we make one dataset per top item
    groups.forEach((groupKey) => {
      topItems.forEach((itemName) => {
        datasets.push({
          label: itemName,     // This is the name that appears in the legend
          stack: groupKey,     // This ensures all items for this group are stacked together
          backgroundColor: getColorForItem(itemName, topItems),
          data: xAxisLabels.map((label) => {
            return usageMap[label]?.[groupKey]?.[itemName] || 0;
          }),
        });
      });

      if (includeOther) {
        // Sum up usage for items that are NOT in topItems
        // We'll produce an "Other" dataset for each group
        datasets.push({
          label: "Other", 
          stack: groupKey,
          backgroundColor: "rgba(128,128,128,0.5)",
          data: xAxisLabels.map((label) => {
            const itemObj = usageMap[label]?.[groupKey] || {};
            let sum = 0;
            // Accumulate counts for items not in topItems
            Object.entries(itemObj).forEach(([itemName, count]) => {
              if (!topItems.includes(itemName)) {
                sum += count;
              }
            });
            return sum;
          }),
        });
      }
    });

    return {
      labels: xAxisLabels,
      datasets,
    };
  }, [groups, topItems, xAxisLabels, usageMap, includeOther]);

  /**
   * Chart options:
   * - stacked = true for y so that each group is a separate side-by-side stack
   * - stacked = false for x to get grouped stacks
   */
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: { 
        position: "bottom",
        labels: {
          usePointStyle: true,
        },
      },
      tooltip: {
        callbacks: {
          title: (items) => {
            // items is an array of tooltip items for the same x value
            return items[0].label;
          },
          label: (context) => {
            const dataset = context.dataset;
            const itemName = dataset.label;  // The item
            const groupKey = dataset.stack;  // The group
            const value = context.parsed.y;  // The usage count
            return `${groupKey} - ${itemName}: ${value}`;
          }
        }
      },
    },
    scales: {
      x: {
        stacked: false, // so that each group is side-by-side
        ticks: {
          maxRotation: 45,
          minRotation: 0,
        },
      },
      y: {
        stacked: true, // items stack within each group
        beginAtZero: true,
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        handleChartClick(event, elements);
      }
    },
  };

  const handleChartClick = (event, elements) => {
    if (!elements || elements.length === 0) return;
    const { datasetIndex, index } = elements[0];
    
    // dataset => the dataset that was clicked
    const dataset = chartData.datasets[datasetIndex];
    // subcatLabel => x-axis label for the bar
    const subcatLabel = chartData.labels[index];
    // itemName => dataset.label
    const itemName = dataset.label;
    // groupKey => dataset.stack
    const groupKey = dataset.stack;
    const value = dataset.data[index];

    setModalInfo({
      subcatLabel,
      itemName,
      groupKey,
      value
    });

    setShowModal(true);
  };

  return (
    <Card className="mb-4 h-100">
      <Card.Header>
        <h5 className="mb-0">Advanced Usage by Category & Expert View</h5>
      </Card.Header>
      <Card.Body>
        {/* Group by: "expert_field", "role", or "both" */}
        <Form.Group className="mb-3" controlId="groupBySelect">
          <Form.Label>Group By:</Form.Label>
          <Form.Control
            as="select"
            value={groupBy}
            onChange={(e) => setGroupBy(e.target.value)}
          >
            <option value="expert_field">Expert Field</option>
            <option value="role">Role</option>
            <option value="both">Expert Field + Role</option>
          </Form.Control>
        </Form.Group>

        {/* X-axis Mode: "categoryOnly" or "categorySubcat" */}
        <Form.Group className="mb-3" controlId="xAxisModeSelect">
          <Form.Label>X-Axis Mode:</Form.Label>
          <Form.Control
            as="select"
            value={xAxisMode}
            onChange={(e) => setXAxisMode(e.target.value)}
          >
            <option value="categoryOnly">Category Only</option>
            <option value="categorySubcat">Category + Subcategory</option>
          </Form.Control>
        </Form.Group>

        {/* Top N Items */}
        <Form.Group className="mb-3" controlId="topNItemsSelect">
          <Form.Label>Top N Items:</Form.Label>
          <Form.Control
            type="number"
            min={1}
            max={50}
            value={topNItems}
            onChange={(e) => setTopNItems(parseInt(e.target.value, 10))}
          />
        </Form.Group>

        {/* Include "Other" bucket? */}
        <Form.Group className="mb-3" controlId="includeOtherCheckbox">
          <Form.Check
            type="checkbox"
            label="Include 'Other' bucket for less frequent items?"
            checked={includeOther}
            onChange={(e) => setIncludeOther(e.target.checked)}
          />
        </Form.Group>

        <Bar data={chartData} options={chartOptions} />
        
      </Card.Body>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalInfo && (
            <>
              <p><strong>Subcategory:</strong> {modalInfo.subcatLabel}</p>
              <p><strong>Group:</strong> {modalInfo.groupKey}</p>
              <p><strong>Item:</strong> {modalInfo.itemName}</p>
              <p><strong>Value:</strong> {modalInfo.value}</p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </Card>
  );
}

// --------------------------------------------------------------------------------------------

/**
 * Advanced component usage chart
 */
function AdvancedComponentUsageChart({ data }) {
  // 1) How do we define the "expert"?  (expert_field, role, or both)
  const [groupBy, setGroupBy] = useState("both");

  // 2) How do we define "subcat"?  (just category or "category - description")
  const [subcatMode, setSubcatMode] = useState("categorySubcat");

  // 3) Top N items to display as separate stacked segments. Others can go in "Other" (optional).
  const [topNItems, setTopNItems] = useState(5);

  // 4) If there are many subcategories, optionally limit to top M subcats (or show all).
  const [topNSubcats, setTopNSubcats] = useState(0); // 0 means "show all"

  // 5) Include an "Other" segment for items not in the top N?
  const [includeOtherItems, setIncludeOtherItems] = useState(true);

  // 6) Include an "Other Subcat" if you only want the top M subcats?
  const [includeOtherSubcat, setIncludeOtherSubcat] = useState(false);

  /**
   * usageMap => usageMap[groupKey][subcatLabel][itemName] = count
   */
  const usageMap = useMemo(() => {
    const result = {};

    data.forEach((useCase) => {
      // Determine the "group" label (the x-axis dimension)
      const expertField = useCase.expert_field?.value || "Unknown Expert";
      const role = useCase.role?.value || "Unknown Role";

      let groupKey;
      switch (groupBy) {
        case "expert_field":
          groupKey = expertField;
          break;
        case "role":
          groupKey = role;
          break;
        default:
          groupKey = `${expertField} (${role})`;
      }

      // For each top-level category object
      useCase.components?.value?.forEach((catObj) => {
        const categoryName = catObj.category || "Uncategorized";

        catObj.components?.forEach((componentObj) => {
          // subcat might be just the category or "category - component description"
          const subDesc = componentObj.description || "Unnamed Subcategory";
          let subcatLabel;
          if (subcatMode === "categoryOnly") {
            subcatLabel = categoryName;
          } else {
            subcatLabel = `${categoryName} - ${subDesc}`;
          }

          // Initialize nested objects
          if (!result[groupKey]) {
            result[groupKey] = {};
          }
          if (!result[groupKey][subcatLabel]) {
            result[groupKey][subcatLabel] = {};
          }

          // For each item in this component, increment usage
          componentObj.items?.forEach((itemName) => {
            if (!result[groupKey][subcatLabel][itemName]) {
              result[groupKey][subcatLabel][itemName] = 0;
            }
            result[groupKey][subcatLabel][itemName]++;
          });
        });
      });
    });

    return result;
  }, [data, groupBy, subcatMode]);

  /**
   * Distinct groups (experts) for the x-axis
   */
  const groupKeys = useMemo(() => {
    // e.g. ["Engineering (Driver)", "Business (Analyst)", ...]
    return Object.keys(usageMap).sort();
  }, [usageMap]);

  /**
   * Collect all subcatLabels across all groups
   */
  /*
  const allSubcats = useMemo(() => {
    const set = new Set();
    Object.values(usageMap).forEach((subcatObj) => {
      // subcatObj => { [subcatLabel]: { itemName: count } }
      Object.keys(subcatObj).forEach((subcat) => set.add(subcat));
    });
    return Array.from(set).sort();
  }, [usageMap]);
  */

  /**
   * (Optional) If we want "Top N subcats" (by total usage) 
   * and place the rest in an "Other Subcat" group:
   */
  const subcatFrequency = useMemo(() => {
    // subcat => sum of usage for all groups, all items
    const freq = {};
    Object.values(usageMap).forEach((subcatObj) => {
      Object.entries(subcatObj).forEach(([subcat, itemsObj]) => {
        let sum = 0;
        Object.values(itemsObj).forEach((cnt) => {
          sum += cnt;
        });
        freq[subcat] = (freq[subcat] || 0) + sum;
      });
    });
    return freq;
  }, [usageMap]);

  const sortedSubcats = useMemo(() => {
    return Object.entries(subcatFrequency)
      .sort((a, b) => b[1] - a[1])
      .map(([subcat]) => subcat);
  }, [subcatFrequency]);

  let finalSubcats;
  if (topNSubcats > 0 && topNSubcats < sortedSubcats.length) {
    finalSubcats = sortedSubcats.slice(0, topNSubcats);
  } else {
    // if topNSubcats is 0 or bigger than total subcats, we show all
    finalSubcats = sortedSubcats;
  }

  /**
   * Collect all items across all subcats, so we can figure out top N items
   */
  const itemFrequency = useMemo(() => {
    // item => total usage
    const freq = {};
    Object.values(usageMap).forEach((subcatObj) => {
      Object.values(subcatObj).forEach((itemObj) => {
        Object.entries(itemObj).forEach(([itemName, cnt]) => {
          freq[itemName] = (freq[itemName] || 0) + cnt;
        });
      });
    });
    return freq;
  }, [usageMap]);

  // Sort items and keep top N
  const sortedItems = useMemo(() => {
    return Object.entries(itemFrequency)
      .sort((a, b) => b[1] - a[1]) // descending
      .map(([itemName]) => itemName);
  }, [itemFrequency]);

  const topItems = useMemo(() => sortedItems.slice(0, topNItems), [sortedItems, topNItems]);

  /**
   * Now we build the chart data in a "grouped + stacked" format:
   *
   * - The x-axis is groups (i.e., the experts).
   * - We produce one "stack" per subcat. 
   * - Within that stack, we have multiple segments (datasets) - one for each top item,
   *   plus optionally an "Other" item segment if includeOtherItems = true.
   *
   * So the total number of datasets = subcats.length * (topItems.length + maybe 1 for "Other").
   */
  const chartData = useMemo(() => {
    const datasets = [];

    // We'll handle the "Other subcat" approach by merging usage into a
    // special subcat label "Other Subcat" if needed:
    const subcatIsInTop = (sc) => finalSubcats.includes(sc);

    // For each subcat in the final list, we create multiple datasets (one per item).
    // If a subcat is not in finalSubcats (meaning we are doing topNSubcats), we put
    // that usage into "Other Subcat". 
    // But if we have includeOtherSubcat = false, we just ignore them.
    const actualSubcatUsageMap = {}; 
    groupKeys.forEach((group) => {
      // usageMap[group] => { subcatLabel => { itemName => count } }
      const subcatObj = usageMap[group] || {};

      Object.entries(subcatObj).forEach(([subcat, itemsObj]) => {
        let finalLabel = subcat;
        if (!subcatIsInTop(subcat)) {
          if (topNSubcats > 0 && includeOtherSubcat) {
            finalLabel = "Other Subcat";
          } else if (topNSubcats > 0 && !includeOtherSubcat) {
            // If we don't want an 'Other Subcat', skip usage entirely
            return;
          }
        }
        if (!actualSubcatUsageMap[group]) {
          actualSubcatUsageMap[group] = {};
        }
        if (!actualSubcatUsageMap[group][finalLabel]) {
          actualSubcatUsageMap[group][finalLabel] = {};
        }

        // Merge item usage
        Object.entries(itemsObj).forEach(([itemName, cnt]) => {
          actualSubcatUsageMap[group][finalLabel][itemName] =
            (actualSubcatUsageMap[group][finalLabel][itemName] || 0) + cnt;
        });
      });
    });

    // Gather all subcats (including "Other Subcat" if used) from actualSubcatUsageMap:
    const allUsedSubcats = new Set();
    Object.values(actualSubcatUsageMap).forEach((subcatObj) => {
      Object.keys(subcatObj).forEach((sc) => allUsedSubcats.add(sc));
    });
    const finalSubcatArray = Array.from(allUsedSubcats).sort();

    // For each subcat, we'll produce "stack = subcat" 
    // and one dataset per top item plus possibly "Other" item.
    finalSubcatArray.forEach((subcat) => {
      // For each of the top items, create a dataset
      topItems.forEach((itemName) => {
        datasets.push({
          label: itemName,        // Legend label is item name
          stack: subcat,          // all items with the same subcat stack together
          backgroundColor: getNextBlueGreenColor(),
          data: groupKeys.map((group) => {
            return actualSubcatUsageMap[group]?.[subcat]?.[itemName] || 0;
          }),
        });
      });

      // "Other" item: sum usage for all items not in topItems
      if (includeOtherItems) {
        datasets.push({
          label: "Other",
          stack: subcat,
          backgroundColor: "rgba(150,150,150,0.6)",
          data: groupKeys.map((group) => {
            const itemsObj = actualSubcatUsageMap[group]?.[subcat] || {};
            let sum = 0;
            Object.entries(itemsObj).forEach(([itemName, cnt]) => {
              if (!topItems.includes(itemName)) {
                sum += cnt;
              }
            });
            return sum;
          }),
        });
      }
    });

    return {
      labels: groupKeys, // x-axis = the experts
      datasets,
    };
  }, [
    usageMap,
    groupKeys,
    finalSubcats,
    topItems,
    includeOtherItems,
    includeOtherSubcat,
    topNSubcats,
  ]);

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          // optional custom tooltip
        },
      },
    },
    scales: {
      x: {
        stacked: false, 
        // "false" means subcats appear side-by-side for each group
      },
      y: {
        stacked: true,  
        // "true" means items appear stacked vertically within each subcat
        beginAtZero: true,
      },
    },
  };

  return (
    <Card className="mb-4 h-100">
      <Card.Header>
        <h5 className="mb-0">Advanced Expert Usage</h5>
      </Card.Header>
      <Card.Body>
        {/* 1) groupBy toggle */}
        <Form.Group className="mb-3" controlId="groupBySelect">
          <Form.Label>Group By (x-axis = Experts):</Form.Label>
          <Form.Control
            as="select"
            value={groupBy}
            onChange={(e) => setGroupBy(e.target.value)}
          >
            <option value="expert_field">Expert Field</option>
            <option value="role">Role</option>
            <option value="both">Expert Field + Role</option>
          </Form.Control>
        </Form.Group>

        {/* 2) subcatMode toggle */}
        <Form.Group className="mb-3" controlId="subcatModeSelect">
          <Form.Label>How to define Subcategory:</Form.Label>
          <Form.Control
            as="select"
            value={subcatMode}
            onChange={(e) => setSubcatMode(e.target.value)}
          >
            <option value="categoryOnly">Category Only</option>
            <option value="categorySubcat">Category + Subcategory</option>
          </Form.Control>
        </Form.Group>

        {/* 3) topNItems */}
        <Form.Group className="mb-3" controlId="topNItemsSelect">
          <Form.Label>Show Top N Items:</Form.Label>
          <Form.Control
            type="number"
            min={1}
            max={100}
            value={topNItems}
            onChange={(e) => setTopNItems(parseInt(e.target.value, 10))}
          />
        </Form.Group>

        {/* 4) topNSubcats */}
        <Form.Group className="mb-3" controlId="topNSubcatsSelect">
          <Form.Label>Show Top N Subcategories (0 = show all):</Form.Label>
          <Form.Control
            type="number"
            min={0}
            max={50}
            value={topNSubcats}
            onChange={(e) => setTopNSubcats(parseInt(e.target.value, 10))}
          />
        </Form.Group>

        {/* 5) Include "Other" item? */}
        <Form.Group className="mb-3" controlId="includeOtherItemsCheckbox">
          <Form.Check
            type="checkbox"
            label="Include 'Other' for items beyond Top N?"
            checked={includeOtherItems}
            onChange={(e) => setIncludeOtherItems(e.target.checked)}
          />
        </Form.Group>

        {/* 6) Include "Other Subcat"? */}
        <Form.Group className="mb-3" controlId="includeOtherSubcatCheckbox">
          <Form.Check
            type="checkbox"
            label="Include 'Other Subcat' for subcategories beyond Top N?"
            checked={includeOtherSubcat}
            onChange={(e) => setIncludeOtherSubcat(e.target.checked)}
          />
        </Form.Group>

        <Bar data={chartData} options={chartOptions} />
      </Card.Body>
    </Card>
  );
}

// --------------------------------------------------------------------------------------------

/**
 * Assume data has the structure:
 *   data[].expert_field?.value
 *   data[].role?.value
 *   data[].components?.value => [ { category, components: [ { description, items } ] }, ... ]
 *
 * DRILL-DOWN:
 *   Level 0 => All Experts on x-axis
 *   Level 1 => For the clicked expert, show categories
 *   Level 2 => For the clicked category + expert, show subcategories
 *   Level 3 => For the clicked subcategory + category + expert, show items
 */
function ComponentDrillDownChart({ data }) {

  // Modal
  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);

  // 1) drillLevel: 0,1,2,3
  const [drillLevel, setDrillLevel] = useState(0);

  // 2) Let the user pick how to define "expert" (i.e. the grouping dimension at Level 0)
  const [expertMode, setExpertMode] = useState("both");
  // possible: "expert_field", "role", "both"

  // 3) Choose how many bars to display at each level (topN).
  const [topN, setTopN] = useState(5);

  // Store which bar the user clicked in each level:
  // - selectedExpert => from L0
  // - selectedCategory => from L1
  // - selectedSubcat => from L2
  const [selectedExpert, setSelectedExpert] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcat, setSelectedSubcat] = useState("");

  //--------------------------------------------------
  //  LEVEL 0: Show topN experts + total usage counts
  //--------------------------------------------------

  /**
   * Build: expertUsageMap[expertString] = total usage
   * "usage" can be the total # of items, or # of subcategories, etc.
   */
  const level0Map = useMemo(() => {
    const map = {};
    data.forEach((useCase) => {
      const eField = useCase.expert_field?.value || "Unknown Expert Field";
      const role = useCase.role?.value || "Unknown Role";
      let expertKey;
      switch (expertMode) {
        case "expert_field":
          expertKey = eField;
          break;
        case "role":
          expertKey = role;
          break;
        default:
          expertKey = `${eField} (${role})`;
          break;
      }

      // Sum all items from all categories for this useCase
      let itemCount = 0;
      useCase.components?.value?.forEach((catObj) => {
        catObj.components?.forEach((comp) => {
          itemCount += comp.items?.length || 0;
        });
      });

      map[expertKey] = (map[expertKey] || 0) + itemCount;
    });
    return map;
  }, [data, expertMode]);

  /**
   * Sort by usage desc, keep topN, prepare Chart.js data
   */
  const level0ChartData = useMemo(() => {
    const sorted = Object.entries(level0Map)
      .sort((a, b) => b[1] - a[1])
      .slice(0, topN);

    const labels = sorted.map(([expertKey]) => expertKey);
    const values = sorted.map(([_, usage]) => usage);

    return {
      labels,
      datasets: [
        {
          label: "Total Items",
          data: values,
          backgroundColor: labels.map(() => getNextBlueGreenColor()),
        },
      ],
    };
  }, [level0Map, topN]);

  const level0Options = {
    onClick: (evt, elements) => {
      // If user clicks a bar => we set selectedExpert + drillLevel=1
      if (elements.length > 0) {
        const { index } = elements[0];
        const clickedExpert = level0ChartData.labels[index];
        setSelectedExpert(clickedExpert);
        setDrillLevel(1);
      }
    },
    responsive: true,
    scales: {
      y: { beginAtZero: true },
    },
  };

  //----------------------------------------------
  // LEVEL 1: Categories for selectedExpert
  //----------------------------------------------
  /**
   * For the selectedExpert, build a map: category => total item usage
   */
  const level1Map = useMemo(() => {
    if (!selectedExpert) return {};

    // Which useCases belong to that selectedExpert
    // Then sum usage by category
    const map = {};
    data.forEach((useCase) => {
      const eField = useCase.expert_field?.value || "Unknown Expert Field";
      const role = useCase.role?.value || "Unknown Role";
      let expertKey;
      switch (expertMode) {
        case "expert_field":
          expertKey = eField;
          break;
        case "role":
          expertKey = role;
          break;
        default:
          expertKey = `${eField} (${role})`;
      }

      if (expertKey === selectedExpert) {
        useCase.components?.value?.forEach((catObj) => {
          const catName = catObj.category || "No Category";
          let catCount = 0;
          catObj.components?.forEach((comp) => {
            catCount += comp.items?.length || 0;
          });
          map[catName] = (map[catName] || 0) + catCount;
        });
      }
    });

    return map;
  }, [data, expertMode, selectedExpert]);

  const level1ChartData = useMemo(() => {
    const sorted = Object.entries(level1Map)
      .sort((a, b) => b[1] - a[1])
      .slice(0, topN);

    const labels = sorted.map(([cat]) => cat);
    const values = sorted.map(([_, usage]) => usage);

    return {
      labels,
      datasets: [
        {
          label: `Items for ${selectedExpert}`,
          data: values,
          backgroundColor: labels.map(() => getNextBlueGreenColor()),
        },
      ],
    };
  }, [level1Map, topN, selectedExpert]);

  const level1Options = {
    onClick: (evt, elements) => {
      if (elements.length > 0) {
        const { index } = elements[0];
        const clickedCat = level1ChartData.labels[index];
        setSelectedCategory(clickedCat);
        setDrillLevel(2);
      }
    },
    responsive: true,
    scales: {
      y: { beginAtZero: true },
    },
  };

  //----------------------------------------------
  // LEVEL 2: Subcategories for (selectedExpert, selectedCategory)
  //----------------------------------------------
  /**
   * subcat => total item usage
   * subcat is the 'componentObj.description'
   */
  const level2Map = useMemo(() => {
    if (!selectedExpert || !selectedCategory) return {};

    const map = {};
    data.forEach((useCase) => {
      // same logic to check if the useCase belongs to selectedExpert
      const eField = useCase.expert_field?.value || "Unknown Expert Field";
      const role = useCase.role?.value || "Unknown Role";
      let expertKey;
      switch (expertMode) {
        case "expert_field":
          expertKey = eField;
          break;
        case "role":
          expertKey = role;
          break;
        default:
          expertKey = `${eField} (${role})`;
      }

      if (expertKey === selectedExpert) {
        useCase.components?.value?.forEach((catObj) => {
          if ((catObj.category || "No Category") === selectedCategory) {
            // Gather usage by subcategory
            catObj.components?.forEach((comp) => {
              const subcatName = comp.description || "Unnamed Subcategory";
              map[subcatName] = (map[subcatName] || 0) + (comp.items?.length || 0);
            });
          }
        });
      }
    });
    return map;
  }, [data, expertMode, selectedExpert, selectedCategory]);

  const level2ChartData = useMemo(() => {
    const sorted = Object.entries(level2Map)
      .sort((a, b) => b[1] - a[1])
      .slice(0, topN);

    const labels = sorted.map(([subcat]) => subcat);
    const values = sorted.map(([_, usage]) => usage);

    return {
      labels,
      datasets: [
        {
          label: `Items for ${selectedExpert} - ${selectedCategory}`,
          data: values,
          backgroundColor: labels.map(() => getNextBlueGreenColor()),
        },
      ],
    };
  }, [level2Map, topN, selectedExpert, selectedCategory]);

  const level2Options = {
    onClick: (evt, elements) => {
      if (elements.length > 0) {
        const { index } = elements[0];
        const clickedSubcat = level2ChartData.labels[index];
        setSelectedSubcat(clickedSubcat);
        setDrillLevel(3);
      }
    },
    responsive: true,
    scales: {
      y: { beginAtZero: true },
    },
  };

  //----------------------------------------------
  // LEVEL 3: Items for (selectedExpert, selectedCategory, selectedSubcat)
  //----------------------------------------------
  /**
   * item => usage count
   * Might have repeated usage of the same item across multiple
   * useCase definitions, so sum them up if found multiple times.
   */
  const level3Map = useMemo(() => {
    if (!selectedExpert || !selectedCategory || !selectedSubcat) return {};

    const map = {};
    data.forEach((useCase) => {
      const eField = useCase.expert_field?.value || "Unknown Expert Field";
      const role = useCase.role?.value || "Unknown Role";
      let expertKey;
      switch (expertMode) {
        case "expert_field":
          expertKey = eField;
          break;
        case "role":
          expertKey = role;
          break;
        default:
          expertKey = `${eField} (${role})`;
      }

      if (expertKey === selectedExpert) {
        useCase.components?.value?.forEach((catObj) => {
          if ((catObj.category || "No Category") === selectedCategory) {
            catObj.components?.forEach((comp) => {
              const subcatName = comp.description || "Unnamed Subcategory";
              if (subcatName === selectedSubcat) {
                // Each item in comp.items => increment usage
                comp.items?.forEach((itemStr) => {
                  map[itemStr] = (map[itemStr] || 0) + 1;
                });
              }
            });
          }
        });
      }
    });
    return map;
  }, [data, expertMode, selectedExpert, selectedCategory, selectedSubcat]);

  const level3ChartData = useMemo(() => {
    const sorted = Object.entries(level3Map)
      .sort((a, b) => b[1] - a[1])
      .slice(0, topN);

    const labels = sorted.map(([item]) => item);
    const values = sorted.map(([_, usage]) => usage);

    return {
      labels,
      datasets: [
        {
          label: `Items for ${selectedExpert} - ${selectedCategory} - ${selectedSubcat}`,
          data: values,
          backgroundColor: labels.map(() => getNextBlueGreenColor()),
        },
      ],
    };
  }, [level3Map, topN, selectedExpert, selectedCategory, selectedSubcat]);

  const level3Options = {
    responsive: true,
    scales: {
      y: { beginAtZero: true },
    },
    onClick: (evt, elements) => {
      if (elements.length > 0) {
        const { index } = elements[0];
        const clickedItem = level3ChartData.labels[index];
        const value = level3ChartData.datasets[0].data[index];
        setModalInfo({
          expert: selectedExpert,
          category: selectedCategory,
          subcat: selectedSubcat,
          item: clickedItem,
          value,
        });
        setShowModal(true);
      }
    },
  };

  //----------------------------------------------
  // RENDER 
  //----------------------------------------------
  /**
   * Conditionally render which chart is shown based on drillLevel (0..3).
   * Provide a "Go Back" button for the user to step up one level.
   */
  const renderChart = () => {
    if (drillLevel === 0) {
      return <Bar data={level0ChartData} options={level0Options} />;
    }
    if (drillLevel === 1) {
      return <Bar data={level1ChartData} options={level1Options} />;
    }
    if (drillLevel === 2) {
      return <Bar data={level2ChartData} options={level2Options} />;
    }
    if (drillLevel === 3) {
      return <Bar data={level3ChartData} options={level3Options} />;
    }
    return null;
  };

  return (
    <Card className="mb-4 h-100">
      <Card.Header>
        <h5 className="mb-0">
          Component Drill-Down Chart (Level {drillLevel})
        </h5>
      </Card.Header>
      <Card.Body>
        {/* CONTROLS: GroupBy + topN */}
        <Form.Group className="mb-3" controlId="expertModeSelect">
          <Form.Label>Group By (Expert):</Form.Label>
          <Form.Control
            as="select"
            value={expertMode}
            onChange={(e) => {
              setExpertMode(e.target.value);
              // reset to top level
              setDrillLevel(0);
              setSelectedExpert("");
              setSelectedCategory("");
              setSelectedSubcat("");
            }}
          >
            <option value="expert_field">Expert Field</option>
            <option value="role">Role</option>
            <option value="both">Expert Field + Role</option>
          </Form.Control>
        </Form.Group>

        <Form.Group className="mb-3" controlId="topNInput">
          <Form.Label>Show Top N Bars:</Form.Label>
          <Form.Control
            type="number"
            min={1}
            max={100}
            value={topN}
            onChange={(e) => setTopN(parseInt(e.target.value, 10))}
          />
        </Form.Group>

        {drillLevel > 0 && (
          <Button
            variant="outline-secondary"
            className="mb-3"
            onClick={() => {
              // Go up one level
              setDrillLevel(drillLevel - 1);
              if (drillLevel === 1) {
                setSelectedExpert("");
              } else if (drillLevel === 2) {
                setSelectedCategory("");
              } else if (drillLevel === 3) {
                setSelectedSubcat("");
              }
            }}
          >
            &larr; Go Back
          </Button>
        )}

        {renderChart()}
      </Card.Body>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalInfo && (
            <>
              <p><strong>Expert:</strong> {modalInfo.expert}</p>
              <p><strong>Category:</strong> {modalInfo.category}</p>
              <p><strong>Subcategory:</strong> {modalInfo.subcat}</p>
              <p><strong>Item:</strong> {modalInfo.item}</p>
              <p><strong>Value:</strong> {modalInfo.value}</p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </Card>
  );
}

function RepeatedItemsByExpert({ data }) {
  /**
   * itemUsage[itemName] = total usage count across all use cases
   * expertBreakdown[itemName][expertKey] = how many times this expert used that item
   */
  const itemUsage = {};
  const expertBreakdown = {};

  data.forEach((useCase) => {
    const expertField = useCase.expert_field?.value || "Unknown Expert Field";
    const role = useCase.role?.value || "Unknown Role";
    const expertKey = `${expertField} (${role})`;

    // For each category in useCase.components.value
    useCase.components?.value?.forEach((catObj) => {
      // Then each component
      catObj.components?.forEach((componentObj) => {
        // Then each 'item' in that component
        componentObj.items?.forEach((itemName) => {
          // 1) Bump total usage
          if (!itemUsage[itemName]) {
            itemUsage[itemName] = 0;
            expertBreakdown[itemName] = {};
          }
          itemUsage[itemName]++;

          // 2) Bump usage for this expert
          if (!expertBreakdown[itemName][expertKey]) {
            expertBreakdown[itemName][expertKey] = 0;
          }
          expertBreakdown[itemName][expertKey]++;
        });
      });
    });
  });

  // Filter to show only items used more than once
  const repeatedItems = Object.entries(itemUsage)
    .filter(([_, count]) => count > 1)
    .sort((a, b) => b[1] - a[1]); // sort descending by usage

  return (
    <Card className="mb-4">
      <Card.Header>
        <h5 className="mb-0">Repeated Items &amp; Expert Attribution</h5>
      </Card.Header>
      <Card.Body>
        <Table bordered hover size="sm">
          <thead>
            <tr>
              <th>Item</th>
              <th>Total Usage</th>
              <th>Used By (Expert : Count)</th>
            </tr>
          </thead>
          <tbody>
            {repeatedItems.map(([itemName, totalUsage]) => (
              <tr key={itemName}>
                <td>{itemName}</td>
                <td>{totalUsage}</td>
                <td>
                  {/* For each expert that used this item, display usage count */}
                  {Object.entries(expertBreakdown[itemName])
                    .sort((a, b) => b[1] - a[1]) // optional: sort experts descending
                    .map(([expertKey, usageCount], i) => (
                      <span key={i} className="badge bg-secondary me-1">
                        {expertKey}: {usageCount}
                      </span>
                    ))}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

function RepeatedItemsByExpertAndSubcat({ data }) {
  /**
   * Data structures:
   *
   * itemUsage[itemName] => total usage count
   * expertBreakdown[itemName][expertKey] => how many times that expert used itemName
   * subcatBreakdown[itemName][subcatName] => how many times itemName appears in this subcategory
   */
  const itemUsage = {};
  const expertBreakdown = {};
  const subcatBreakdown = {};

  data.forEach((useCase) => {
    const expertField = useCase.expert_field?.value || "Unknown Expert Field";
    const role = useCase.role?.value || "Unknown Role";
    const expertKey = `${expertField} (${role})`;

    // For each category in useCase.components.value
    useCase.components?.value?.forEach((catObj) => {
      // Combine category + component description to get a "subcategory" label
      const topCategory = catObj.category || "Uncategorized";
      catObj.components?.forEach((componentObj) => {
        const desc = componentObj.description || "Unnamed";
        // e.g. "Consumer - Consumer Delivery"
        const subcatName = `${topCategory} - ${desc}`;

        // Then each 'item' in that component
        componentObj.items?.forEach((itemName) => {
          // 1) Bump total usage
          if (!itemUsage[itemName]) {
            itemUsage[itemName] = 0;
            expertBreakdown[itemName] = {};
            subcatBreakdown[itemName] = {};
          }
          itemUsage[itemName]++;

          // 2) Bump usage for this expert
          if (!expertBreakdown[itemName][expertKey]) {
            expertBreakdown[itemName][expertKey] = 0;
          }
          expertBreakdown[itemName][expertKey]++;

          // 3) Bump usage for this subcategory
          if (!subcatBreakdown[itemName][subcatName]) {
            subcatBreakdown[itemName][subcatName] = 0;
          }
          subcatBreakdown[itemName][subcatName]++;
        });
      });
    });
  });

  // Filter: show only items used more than once
  const repeatedItems = Object.entries(itemUsage)
    .filter(([_, total]) => total > 1)
    .sort((a, b) => b[1] - a[1]); // sort descending by total usage

  return (
    <Card className="mb-4">
      <Card.Header>
        <h5 className="mb-0">Repeated Items (Expert &amp; Subcategory Breakdown)</h5>
      </Card.Header>
      <Card.Body>
        <Table bordered hover size="sm">
          <thead>
            <tr>
              <th>Item</th>
              <th>Total Usage</th>
              <th>Used By Experts</th>
              <th>Subcategories</th>
            </tr>
          </thead>
          <tbody>
            {repeatedItems.map(([itemName, totalUsage]) => (
              <tr key={itemName}>
                <td>{itemName}</td>
                <td>{totalUsage}</td>
                <td>
                  {/* Show each expert + usage count */}
                  {Object.entries(expertBreakdown[itemName])
                    .sort((a, b) => b[1] - a[1]) // optional: sort descending
                    .map(([expert, usageCount], i) => (
                      <span key={i} className="badge bg-secondary me-1">
                        {expert}: {usageCount}
                      </span>
                    ))}
                </td>
                <td>
                  {/* Show each subcategory + usage count */}
                  {Object.entries(subcatBreakdown[itemName])
                    .sort((a, b) => b[1] - a[1])
                    .map(([subcatName, usageCount], i) => (
                      <span key={i} className="badge bg-info me-1">
                        {subcatName}: {usageCount}
                      </span>
                    ))}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

/**
 * Component analysis section
 * @param {*} useCases 
 * @returns 
 */
function ComponentAnalysisSection({ 

  data,

  componentCategories, 
  componentCategoryDetails, 
  componentCategoryDetailsNested, 

  totalComponentItems,
  totalRepeatedComponentUsage,
  repeatedComponentCount,

  handleChartClick, 
  handleChartHover 
}) {

  // defaultActiveKey="0"

  const [topN, setTopN] = useState(10);
  
  const handleTopNChange = (e) => {
    setTopN(parseInt(e.target.value, 10));
  }

  return (
    <Accordion style={{ marginTop: '20px' }}>

      {/* Section Analysis */}
      <Accordion.Item>
        <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
          <Badge bg="success" className="me-2">Component Analysis:</Badge>
          <h5 style={{ margin: 0 }}>
            Overview of components and their usage in the use cases.
          </h5>
        </div>
      </Accordion.Item>

      {/* Components per category */}
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">1</Badge>
          Components per Category
        </Accordion.Header>
        <Accordion.Body>
          <ComponentCategories componentCategories={componentCategories} componentCategoryDetails={componentCategoryDetailsNested} handleChartClick={handleChartClick} handleChartHover={handleChartHover} />
        </Accordion.Body>
      </Accordion.Item>

      {/* Component categories table (incl. duplicates) */}
      <Accordion.Item eventKey="1">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">2</Badge>
          Component categories Table
        </Accordion.Header>
        <Accordion.Body>
          <Alert variant="secondary">
            <strong>What:</strong> This table shows all high-level component categories and their usage in the use cases. <br />
            <strong>Why:</strong> See which categories are used most frequently. <br />
            <strong>How:</strong> Each row shows the total count.
          </Alert>
          <ComponentCategoriesTable componentCategories={componentCategories} />
        </Accordion.Body>
      </Accordion.Item>

      {/* Component category details */}
      <Accordion.Item eventKey="2">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">3</Badge>
          Component Category Details
        </Accordion.Header>
        <Accordion.Body>
          <ComponentCategoryDetails componentCategoryDetails={componentCategoryDetails} />
        </Accordion.Body>
      </Accordion.Item>

      {/* Enhanced Components Table */}
      <Accordion.Item eventKey="3">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">4</Badge>
          Enhanced Components Table
        </Accordion.Header>
        <Accordion.Body>
          <EnhancedComponentsTable componentCategoryDetails={componentCategoryDetailsNested} />
        </Accordion.Body>
      </Accordion.Item>

      {/* Component subcategory / Item Usage */}
      <Accordion.Item eventKey="4">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">5</Badge>
          Component subcategory / Item Usage
        </Accordion.Header>
        <Accordion.Body>
          <ComponentUsageChartDetails data={data} />
        </Accordion.Body>
      </Accordion.Item>

      {/* Top Repeated Components */}
      <Accordion.Item eventKey="5">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">6</Badge>
          Top Repeated Components
        </Accordion.Header>
        <Accordion.Body>
            {/* The dropdown for picking topN */}
            <select value={topN} onChange={handleTopNChange} className="form-select form-select-sm" style={{ width: 'auto' }}>
              <option value={5}>Top 5</option>
              <option value={10}>Top 10</option>
              <option value={20}>Top 20</option>
              <option value={9999}>All</option>
            </select>
          <TopRepeatedComponentsChart componentCategoryDetails={componentCategoryDetails} topN={topN} />
        </Accordion.Body>
      </Accordion.Item>

      {/* Category Item Bubble Chart */}
      <Accordion.Item eventKey="6">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">7</Badge>
          Category Item Bubble Chart
        </Accordion.Header>
        <Accordion.Body>
          <CategoryItemBubbleChart componentCategoryDetails={componentCategoryDetails} />
        </Accordion.Body>
      </Accordion.Item>

      {/* Repeated vs. Unique Components */}
      <Accordion.Item eventKey="7">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">8</Badge>
          Repeated vs. Unique Components
        </Accordion.Header>
        <Accordion.Body>
          <RepeatedVsUniqueComponents
            totalComponentItems={totalComponentItems}
            totalRepeatedComponentUsage={totalRepeatedComponentUsage}
            repeatedComponentCount={repeatedComponentCount}
          />
        </Accordion.Body>
      </Accordion.Item>

      {/* ---------------------------------------------- */}

      {/* Section Expert */}
      <Accordion.Item>
        <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
          <Badge bg="success" className="me-2">Expert Views:</Badge>
          <h5 style={{ margin: 0 }}>
            The following sections are grouped by expert field and role.
          </h5>
        </div>
      </Accordion.Item>

      {/* Components by Expert */}
      <Accordion.Item eventKey="8">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">9</Badge>
          Components by Expert Field & Role
        </Accordion.Header>
        <Accordion.Body>
          <ComponentsByExpert data={data} />
        </Accordion.Body>
      </Accordion.Item>

      {/* Component Usage by Expert */}
      <Accordion.Item eventKey="9">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">10</Badge>
          Component Usage by Expert Field & Role
        </Accordion.Header>
        <Accordion.Body>
          <ComponentUsageByExpertChartItem data={data} />
        </Accordion.Body>
      </Accordion.Item>

      {/* Advanced Component Usage by Category & Expert View */}
      <Accordion.Item eventKey="10">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">11</Badge>
          Advanced Component Usage by Category & Expert View
        </Accordion.Header>
        <Accordion.Body>
          <AdvancedComponentUsageChartItem data={data} />
        </Accordion.Body>
      </Accordion.Item>

      {/* Advanced Expert Component Usage Chart */}
      <Accordion.Item eventKey="11">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">12</Badge>
          Advanced Expert Component Usage Chart
        </Accordion.Header>
        <Accordion.Body>
          <AdvancedComponentUsageChart data={data} />
        </Accordion.Body>
      </Accordion.Item>

      {/* Drill down chart */}
      <Accordion.Item eventKey="12">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">13</Badge>
          Component Drill Down Chart
        </Accordion.Header>
        <Accordion.Body>
          <ComponentDrillDownChart data={data} />
        </Accordion.Body>
      </Accordion.Item>

      {/* Repeated Components by Expert */}
      <Accordion.Item eventKey="13">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">14</Badge>
          Repeated Components by Expert Field & Role
        </Accordion.Header>
        <Accordion.Body>
          <RepeatedItemsByExpert data={data} />
        </Accordion.Body>
      </Accordion.Item>

      {/* Repeated Components by Expert & Subcategory */}
      <Accordion.Item eventKey="14">
        <Accordion.Header>
          <Badge bg="primary" className="me-2">15</Badge>
          Repeated Components by Expert Field & Subcategory
        </Accordion.Header>
        <Accordion.Body>
          <RepeatedItemsByExpertAndSubcat data={data} />
        </Accordion.Body>
      </Accordion.Item>

    </Accordion>
  )

}

// Word Cloud
/*
const TagWordCloud = ({ tagFrequency }) => {
  // Calculate the maximum and minimum values to scale the font sizes
  const maxFontSize = 50;
  const minFontSize = 15;
  const maxValue = Math.max(...tagFrequency.map(tag => tag.value));
  const minValue = Math.min(...tagFrequency.map(tag => tag.value));

  // Function to scale the font size based on the value
  const getFontSize = (value) => {
    return ((value - minValue) / (maxValue - minValue)) * (maxFontSize - minFontSize) + minFontSize;
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
      {tagFrequency.map((tag, index) => (
        <span
          key={index}
          style={{
            margin: '5px',
            fontSize: `${getFontSize(tag.value)}px`,
            color: '#61dafb'
          }}
        >
          {tag.text}
        </span>
      ))}
    </div>
  );
};*/

export { StatisticsModeledUCs };