/**
 * Chart elements for the analytics page
 *
 * Description: Provides the necessary chart elements for the analytics page of a SINGLE use case.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-07-14, updated on 2025-03-03
 * 
 */

import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button, OverlayTrigger, Tooltip, Accordion, Modal } from 'react-bootstrap';

import { BarChart, PieChart } from '../analytics_handling/analytics_charts';

import { categorizeUseCases, calculateMatchingScore } from './analytics_prepare_data';
import { MatchingScoreModal } from './analytics_modal_score';

import { ActorView, ComponentView, ActorComponentNetwork } from './analytics_uc_actor_components';

import { 

    // General adoption charts
    GeneralFieldsAdoptionChart,

    ScenarioAdoptionChart,
    ScenarioConditionsAdoptionChart,
    ScenarioConditionCombinedChart,

    ActorAdoptionChart,
    ComponentsAdoptionChart,

    // Stepwise adoption charts
    StepwiseAdoptionChart, 
    StepwiseAccumulatedAdoptionChart,
    StepwiseAccumulatedAdoptionChartCleaned,
    
    AccumulatedAdoptionChart,
    AccumulatedAdoptionChartCleaned,

    UseCaseDeepDiffView,

    // Version history charts
    AdaptationOverTimeChart, 
    VersionHistoryTimeline,
    UserEditingBehaviorChart,

} from './analytics_adoption';

import { 
    VisualizeUserPrompt,
    VisualizePromptResponse,
    VisualizeAutomaticPromptsResponses,
} from '../study_handling/study_use_cases/study_use_case_charts';

import {
    StatisticsModeledUCs,
} from '../eco_analytics_handling/eco_analytics_charts';

const formatTimestamp = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
  
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
  
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
  
    return 'N/A';
};

/**
 * Raw data of the selected use case in a hierarchical format for the user to expand / collapse the information (parent/child)
 */
function RawDataExplorer({ data, propertyName = 'Root', level = 0 }) {
    const [expanded, setExpanded] = useState(false);
  
    const isObject = data && typeof data === 'object' && !Array.isArray(data);
    const isArray = Array.isArray(data);
  
    const toggleExpand = () => setExpanded(!expanded);
  
    const indentStyle = {
      marginLeft: level * 20,
      borderLeft: level > 0 ? '1px solid #ccc' : 'none',
      paddingLeft: '5px'
    };
  
    // Render primitive values immediately
    if (!isObject && !isArray) {
      return (
        <div style={indentStyle}>
          <strong>{propertyName}:</strong> {String(data)}
        </div>
      );
    }
  
    // For arrays or objects, show toggle and child items
    return (
      <div style={indentStyle}>
        <Button
          variant="link"
          size="sm"
          onClick={toggleExpand}
          style={{ textDecoration: 'none' }}
        >
          {expanded ? '▼' : '▶'}
        </Button>
        <strong>{propertyName}</strong>
        {expanded && (
          <div style={{ marginTop: '5px' }}>
            {isArray &&
              data.map((item, index) => (
                <RawDataExplorer
                  key={index}
                  data={item}
                  propertyName={`[${index}]`}
                  level={level + 1}
                />
              ))}
  
            {isObject &&
              sortKeysInUCMOrder(Object.keys(data)).map((key) => (
                <RawDataExplorer
                  key={key}
                  data={data[key]}
                  propertyName={key}
                  level={level + 1}
                />
              ))}
          </div>
        )}
      </div>
    );
}
  
/* Sorting function placed below or in a separate file */
const UCM_PRIORITY = [
    'id',
    'title',
    'acronym',
    'country',
    'city',
    'tags',
    'description',
    'maturity',
    'actions',
    'conditions',
    'actors',
    'components',
    'history',
];
  
function sortKeysInUCMOrder(keys) {
    return keys.sort((a, b) => {
        const indexA = UCM_PRIORITY.indexOf(a);
        const indexB = UCM_PRIORITY.indexOf(b);

        const bothUnrecognized = indexA === -1 && indexB === -1;
        if (bothUnrecognized) {
        return a.localeCompare(b); // alphabetical for unrecognized
        }
        if (indexA === -1) return 1; // b is recognized, so put b first
        if (indexB === -1) return -1; // a is recognized, b is not
        return indexA - indexB; // both recognized, compare indices
    });
}

function GroupedRawData({ data }) {
    // separate out the top-level keys from data
    if (!data || typeof data !== 'object') {
      return <div>No valid data to display.</div>;
    }
  
    const topLevelKeys = Object.keys(data);
  
    // Build an object for each group -> { [groupName]: { key: value } }
    // plus an "other" group for unassigned keys
    const grouped = {};
    GROUPS.forEach((grp) => {
      grouped[grp.name] = {};
    });
    grouped['Misc'] = {};
  
    topLevelKeys.forEach((key) => {
      // find which group this key belongs to
      const group = GROUPS.find((g) => g.keys.includes(key));
      if (group) {
        grouped[group.name][key] = data[key];
      } else {
        grouped['Misc'][key] = data[key];
      }
    });
  
    return (
        <Accordion defaultActiveKey="0">
          {Object.entries(grouped).map(([groupName, groupData], idx) => {
            const groupKeys = Object.keys(groupData);
            if (groupKeys.length === 0) return null; // Hide if empty
    
            return (
              <Accordion.Item eventKey={String(idx)} key={groupName}>
                {/* Use Accordion.Header instead of Accordion.Toggle */}
                <Accordion.Header>
                  {groupName} ({groupKeys.length})
                </Accordion.Header>
    
                {/* Use Accordion.Body instead of Accordion.Collapse */}
                <Accordion.Body>
                  {groupKeys.map((fieldKey) => (
                    <RawDataExplorer
                      key={fieldKey}
                      data={groupData[fieldKey]}
                      propertyName={fieldKey}
                    />
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            );
          })}

          {/* All data in one group */}
            <Accordion.Item eventKey={String(GROUPS.length + 1)}>   
                <Accordion.Header>
                    All Data ({topLevelKeys.length})
                </Accordion.Header>
                <Accordion.Body>
                    {topLevelKeys.map((fieldKey) => (
                        <RawDataExplorer
                            key={fieldKey}
                            data={data[fieldKey]}
                            propertyName={fieldKey}
                        />
                    ))}

                </Accordion.Body>
            </Accordion.Item>

        </Accordion>
    );
}

const GROUPS = [
    {
        name: 'Step 1: Description - General Information',
        keys: ['title', 'acronym', 'description', 'maturity', 'application',  'status', 'continents', 'country', 'city', 'tags', 'ucid', 'created_by', 'country_code', 'country_flag'],
    },
    {
        name: 'Step 2: Scenario',
        keys: ['actions', 'conditions'],
    },
    {
        name: 'Step 3: Actors',
        keys: ['actors'],
    },    
    {
        name: 'Step 4: Components',
        keys: ['components'],
    },
    {
        name: 'Meta: Co-Pilot Data',
        keys: ['prompt', 'prompt_response', 'llm_model', 'semantic_model', 'copilot_messages'],
    },
    {
        name: 'Meta: Use Case Data',
        keys: ['creation_mode', 'adoption', 'mode', 'visibility', 'domain_model', 'share', 'updated', 'start_processing', 'end_processing'],
    },
    {
        name: 'Meta: History',
        keys: ['history'],
    },
    {
        name: 'Study: Data',
        keys: ['anonymous_id', 'yearOfBirth', 'role', 'place', 'experience', 'study_step_start', 'study_step_end', 'consent', 'consent_date', 'study_step', 'expert_field' ],
    },
    {
        name: 'User data',
        keys: ['user_first_name', 'user', 'yearOfBirth', 'role', 'user_id', 'experience' ],
    },
];

/**
 * Cards to display the statistics of the analysis
 * @param {*} param0 
 * @returns 
 */
const StatisticsCard = ({ col, number, caption }) => {

    return (
        <Col md={col}>
        <Card className="text-center h-100" style={{ padding: '10px' }}>
            <Card.Body>
            <Card.Title style={{ fontSize: '45px', fontWeight: 'bold' }}>
                {number}
            </Card.Title>
            <Card.Text style={{ fontSize: '14px' }}>
                {caption}
            </Card.Text>
            </Card.Body>
        </Card>
        </Col>
  );
};

// ############################### Messages for the info modals per chart  ###############################

const GENERAL_FIELDS_MSG = `
    This chart shows the adoption of the description fields of the use case.
    Click on a bar to see the details of the change.
`;

const SCENARIO_MSG = `
    This chart shows the adoption of the scenarios in the use case.
    Click on a bar to see the details of the change.
`;

const SCENARIO_CONDITIONS_MSG = `
    This chart shows the adoption of the scenarios precondition, postcondition, assumption and constraint.
    Click on a bar to see the details of the change.
`;

const SCENARIO_CONDITIONS_COMBINED_MSG = `
    This chart shows the adoption of the scenarios and their conditions.
    Click on a bar to see the details of the change.
`;

const ACTOR_MSG = `
    This chart shows the adoption of the actors in the use case.
    Click on a bar to see the details of the change.
`;

const COMPONENTS_MSG = `
    This chart shows the adoption of the components in the use case.
    Click on a bar to see the details of the change.
`;

const TabStatistics = ( { data, comparisonData, selectedUseCase, matchingScore, setShowModalScore, stats, setModalData, setShowModal, setModalDataMeta, setShowModalMeta, chartContainerStyle, handleChartHover } ) => {

    const [activeTab, setActiveTab] = useState('general'); // Manage active tab state

    // State variables for statistics: application, maturity, status
    const [isCompApplicationBar, setIsCompApplicationBar] = useState(true);
    const [isCompMaturityBar, setIsCompMaturityBar] = useState(true);
    const [isCompStatusBar, setIsCompStatusBar] = useState(true);

    // State variables for chart data ACTOR: table, bar, nested bar
    const [isActorBar, setIsActorBar] = useState(true);
  
    const handleTabClick = (tab) => {
      setActiveTab(tab); // Set active tab based on user selection
    };

    const handleCompApplication = () => {
        setIsCompApplicationBar(!isCompApplicationBar);
    };
    
    const handleComMaturity = () => {
    setIsCompMaturityBar(!isCompMaturityBar);
    };
    
    const handleCompStatus = () => {
    setIsCompStatusBar(!isCompStatusBar);
    };

    const handleChartClick = (evt, elems, chartData, attribute) => {

        if (elems.length > 0) {
            const clickedIndex = elems[0].index;
            let clickedCategory = chartData.labels[clickedIndex];
            
            // Filter use cases by the clicked category based on the attribute
            let useCasesInCategory = [];
            
            if (attribute === 'maturity') {
                useCasesInCategory = data.filter((useCase) => useCase.maturity.value === clickedCategory);
            } else if (attribute === 'status') {
                useCasesInCategory = data.filter((useCase) => useCase.status.value === clickedCategory);
            } else if (attribute === 'application') {
                useCasesInCategory = data.filter((useCase) => useCase.application.value === clickedCategory);
            }
        
            const useCaseNames = useCasesInCategory.map((useCase) => useCase.title.value);        
            
            const clickedData = {
                topic: attribute,
                category: clickedCategory,
                res: useCaseNames,
            };
            
            setModalData(clickedData);
            setShowModal(true);
        }
    };

    /*
    const handleChartClickMeta = (evt, elems, chartData, attribute) => {

        if (elems.length > 0) {

            const clickedIndex = elems[0].index;
            let clickedCategory = chartData.labels[clickedIndex];
            
            // Filter use cases by the clicked category based on the attribute
            let useCasesInCategory = [];

            // Map the clicked category to the corresponding category in the selected use case
            if (clickedCategory === 'Adopted from UCM Copilot') {
                clickedCategory = 'ucm_copilot';
            } else if (clickedCategory === 'Manually Modified') {
                clickedCategory = 'manually';
            }

            if (attribute === 'adoption') {
                // Retrieve all fields from the selected use case that match the adoption source
                useCasesInCategory = Object.keys(selectedUseCase).filter((field) => {
                    // Check if the field has a 'source' property that matches the clicked category
                    if (clickedCategory === 'manually' || clickedCategory === 'manual') {
                        return selectedUseCase[field]?.source === 'manually' || selectedUseCase[field]?.source === 'manual';
                    }
                    return selectedUseCase[field]?.source === clickedCategory;
                }).map((field) => ({
                    field,
                    value: selectedUseCase[field].value,
                    source: selectedUseCase[field].source
                }));
                
            } else { // adaptation
                useCasesInCategory = {
                    field: 'adaptation',
                    value: selectedUseCase[clickedCategory].value,
                    source: selectedUseCase[clickedCategory].source
                };            
            }

            let useCaseNames = [];
            // Get the names of the filtered use cases
            if (attribute === 'adoption') {
                useCaseNames = useCasesInCategory.map((useCase) => useCase.field);
            } else {
                useCaseNames = selectedUseCase[clickedCategory].value;
            }

            const clickedData = {
                topic: attribute,
                category: clickedCategory,
                res: useCaseNames,
            };

            setModalDataMeta(clickedData);
            setShowModalMeta(true);
        }
    };
    */

    /*
    const handleChartClickAC = (evt, elems, chartData, topic) => {
        if (elems.length > 0) {
            const clickedIndex = elems[0].index;
            const clickedCategory = chartData.labels[clickedIndex];
    
            let inCategory = [];
    
            if (topic === 'Actors per Category') {
                // Assuming actors are now stored in selectedUseCase.actors.value.nodes.value
                inCategory = selectedUseCase.actors.value.nodes.value.filter(
                    (actor) => actor.group === clickedCategory
                );
            } else if (topic === 'Components per Category') {
                if (isComponentsBarNested) {
                    const [mainCategory, subCategory] = clickedCategory.split(' - ');
                    const category = selectedUseCase.components.value.find(
                        (componentCategory) => componentCategory.category === mainCategory
                    );
                    if (category) {
                        const subCat = category.components.find(
                            (comp) => comp.description === subCategory
                        );
                        inCategory = subCat ? subCat.items : [];
                    }
                } else {
                    const category = selectedUseCase.components.value.find(
                        (componentCategory) => componentCategory.category === clickedCategory
                    );
                    inCategory = category ? category.components.flatMap((comp) => comp.items) : [];
                }
            }
    
            const clickedData = {
                topic: topic,
                category: clickedCategory,
                res: inCategory,
            };
    
            setModalData(clickedData);
            setShowModal(true);
        }
    };
    */

    // Handling for actors: table, bar, nested bar
    const handleActorBar = () => {
        setIsActorBar(!isActorBar);
    };

    const mapActorToComponentCategory = (actorCategory) => {
        const mapping = {
          Producer: 'Producer',
          Consumer: 'Consumer',
          Platform: 'Technical Layer',
          Technical: 'Technical Layer',
          Operator: 'Operations',
          Regulator: 'Regulator',
        };
        return mapping[actorCategory] || actorCategory; // If no match, return the same category
    };

    // Handle the expansion of categories of actors and components
    const [expandedCategory, setExpandedCategory] = useState(null);
    const handleToggle = (category) => {
        // Check if the clicked category is from actors and map it to corresponding component category
        const mappedCategory = mapActorToComponentCategory(category);

        // Check if the category is already expanded or not, then toggle it
        setExpandedCategory(expandedCategory === category || expandedCategory === mappedCategory ? null : category);

        // Expand the matching component category as well
        if (category !== mappedCategory) {
            setExpandedCategory(expandedCategory === mappedCategory ? null : mappedCategory);
        }
    };

    // set old and new state depending on the study_step
    // study_step 1: oldState = history?.data[0], newState = history?.data[1]
    // study_step 2: oldState = null, newState = history?.data[0]
    // study_step 3: oldState = history?.data[1], newState = history?.data[2]

    let oldState = {};
    let newState = {};

    if (selectedUseCase.study_step.value === 1) {
        oldState = selectedUseCase.history?.data?.[0] || {};  // Initial state
        newState = selectedUseCase.history?.data?.[1] //slice(-1)[0] || {};  // Latest state
    }
    else if (selectedUseCase.study_step.value === 2) {
        oldState = {};
        newState = selectedUseCase.history?.data?.[0] || {};  // Latest state
    }
    else if (selectedUseCase.study_step.value === 3) {
        oldState = selectedUseCase.history?.data?.[1] || {};  // Initial state
        newState = selectedUseCase.history?.data?.[2] //slice(-1)[0] || {};  // Latest state
    }

    //const oldState = selectedUseCase.history.data[0];
    //const newState = selectedUseCase.history.data[1];
    //const oldState = selectedUseCase.history?.data?.[1] || {};  // Initial state
    //const newState = selectedUseCase.history?.data?.[2] //slice(-1)[0] || {};  // Latest state

    // Call modal messages for the info modals per chart

    const [modalShow, setModalShow] = useState(false);
    const [metaModalMessages, setMetaModalMessages] = useState({
        'General Fields': GENERAL_FIELDS_MSG,
        'Scenario': SCENARIO_MSG,
        'Scenario Conditions': SCENARIO_CONDITIONS_MSG,
        'Scenario Condition Combined': SCENARIO_CONDITIONS_COMBINED_MSG,
        'Actor': ACTOR_MSG,
        'Components': COMPONENTS_MSG,
    });

    const handleMetaInfoModalMsg = (topic) => {
        setMetaModalMessages(metaModalMessages[topic]);
        setModalShow(true);
    }

    return (
      <Col md={12}>
        {/* Tabs Navigation */}
        <Row style={{ marginBottom: '20px' }}>
            <ul className="nav nav-tabs">
            <li className="nav-item">
                <button
                className={`nav-link ${activeTab === 'general' ? 'active' : ''}`}
                onClick={() => handleTabClick('general')}
                >
                    General
                </button>
            </li>
            <li className="nav-item">
                <button
                className={`nav-link ${activeTab === 'statistics' ? 'active' : ''}`}
                onClick={() => handleTabClick('statistics')}
                >
                    Insights
                </button>
            </li>            
            <li className="nav-item">
                <button
                className={`nav-link ${activeTab === 'meta' ? 'active' : ''}`}
                onClick={() => handleTabClick('meta')}
                >
                    Model Adoption
                </button>
            </li>
            <li className="nav-item">
                <button
                className={`nav-link ${activeTab === 'llm_data' ? 'active' : ''}`}
                onClick={() => handleTabClick('llm_data')}
                >
                    LLM Data
                </button>
            </li>
            <li className="nav-item">
                <button
                className={`nav-link ${activeTab === 'compare' ? 'active' : ''}`}
                onClick={() => handleTabClick('compare')}
                >
                    Interrelationships
                </button>
            </li>
            <li className="nav-item">
                <button
                className={`nav-link ${activeTab === 'version' ? 'active' : ''}`}
                onClick={() => handleTabClick('version')}
                >
                    Version History
                </button>
            </li>
            <li className="nav-item">
                <button
                className={`nav-link ${activeTab === 'raw_data' ? 'active' : ''}`}
                onClick={() => handleTabClick('raw_data')}
                >
                Raw Data
                </button>
            </li>
            </ul>
        </Row>
  
        {/* Tab Content */}
        <Row>
          <Col>
            {activeTab === 'general' && (
              <div>
                {/* Use Case Insights - General Statistics */}    
                <Row style={ { marginBottom:'20px'} }>
                    <h5>Use Case Insights</h5>
                    <Col className="d-flex justify-content-end">
                        <Button variant="outline-primary" className="btn-sm" style={{ marginRight: '10px' }} disabled>
                            <i className="bi bi-info-circle"></i>
                        </Button>
                    </Col>                
                </Row>    

                <Row style={ { marginBottom:'20px'} }>
                    {/* Matching score to existing modeled components*/}
                    <Col md={5}>        
                        <Row style={{ height: '100%' }}>
                            <StatisticsCard col="7" number={matchingScore} caption="Matching Score" />          
                            <Col md={5}>
                                <span>
                                    <small>
                                        <i className="bi bi-info-circle"></i> Matching score indicator {/*indicates how well the selected use case maps in the ecosystem of modeled use cases.*/}                                                                                            
                                    </small>
                                </span>
                                <Button 
                                    variant="outline-primary" 
                                    className="btn-sm" 
                                    style={{ marginLeft: '10px', marginTop:'15px' }}
                                    onClick={() => setShowModalScore(true)}
                                >
                                    <i className="bi bi-calculator"></i>
                                </Button>
                            </Col>
                        </Row>                                                    
                    </Col>      
                    <Col md={7}>
                        {/* Findings at a glance */}
                        <Row style={{ height: '100%' }}>
                            <StatisticsCard col="4" number={stats.similar} caption="Similar Use Cases" />
                            <StatisticsCard col="4" number={stats.overlapping} caption="Overlapping Use Cases" />   
                            <StatisticsCard col="4"number={stats.distinct} caption="Distinct Use Cases" />                                       
                        </Row>       
                    </Col>          
                </Row>    

                {/* Use Case Insights - Comparison Charts */}
                <Row style={ { marginTop:'20px' } }>
                    {/* Application area */}
                    <Col md={4}>
                        <div className="d-flex justify-content-between align-items-center">
                                <h5>Application area</h5>
                                <Button variant="outline-primary" className="btn-sm" onClick={handleCompApplication}>
                                    {isCompApplicationBar ? (
                                    <i className="bi bi-pie-chart"></i>
                                    ) : (
                                    <i className="bi bi-bar-chart"></i>
                                    )}
                                </Button>
                            </div>
                            <div style={chartContainerStyle}>
                            {isCompApplicationBar ? (
                                <BarChart 
                                    comparisonData={comparisonData} 
                                    attribute={'application'} 
                                    selectedUseCase={selectedUseCase} 
                                    handleChartClick={handleChartClick}
                                    handleChartHover={handleChartHover}
                                />                                                
                                ) : (                      
                                <PieChart comparisonData={comparisonData} attribute={'application'} />                                                  
                            )}
                        </div>
                    </Col>
                    {/* Maturity */}
                    <Col md={4}>                      
                        <div className="d-flex justify-content-between align-items-center">
                                <h5>Maturity</h5>
                                <Button variant="outline-primary" className="btn-sm" onClick={handleComMaturity}>
                                    {isCompMaturityBar ? (
                                    <i className="bi bi-pie-chart"></i>
                                    ) : (
                                    <i className="bi bi-bar-chart"></i>
                                    )}
                                </Button>
                            </div>
                            <div style={chartContainerStyle}>
                            {isCompMaturityBar ? (
                                <BarChart 
                                    comparisonData={comparisonData} 
                                    attribute={'maturity'} 
                                    selectedUseCase={selectedUseCase} 
                                    handleChartClick={handleChartClick}
                                    handleChartHover={handleChartHover}
                                />
                                ) : (
                                <PieChart comparisonData={comparisonData} attribute={'maturity'} />
                            )}
                        </div>
                    </Col>
                    {/* Status */}
                    <Col md={4}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Status</h5>
                            <Button variant="outline-primary" className="btn-sm" onClick={handleCompStatus}>
                                {isCompStatusBar ? (
                                <i className="bi bi-pie-chart"></i>
                                ) : (
                                <i className="bi bi-bar-chart"></i>
                                )}
                            </Button>
                        </div>      
                        <div style={chartContainerStyle}>
                            {isCompStatusBar ? (        
                            <BarChart 
                                comparisonData={comparisonData} 
                                attribute={'status'} 
                                selectedUseCase={selectedUseCase} 
                                handleChartClick={handleChartClick}
                                handleChartHover={handleChartHover}
                            />     
                            ) : (
                            <PieChart comparisonData={comparisonData} attribute={'status'} />
                            )}
                        </div>
                    </Col>                                               
                </Row>

              </div>
            )}

            {activeTab === 'statistics' && (
              <div>

                <div className="d-flex justify-content-between align-items-center" style={ { marginBottom:'15px' } }>
                    <h5>Cross-section insights - view adaptable to input sample</h5>
                    <div>
                        <OverlayTrigger placement="top" overlay={<Tooltip>Cross-Section Insights</Tooltip>}>
                            <Button disabled variant="outline-primary" className="btn-sm" style={ { marginLeft:'10px' } }>             
                                <i className="bi bi-info-circle"></i>                            
                            </Button>
                        </OverlayTrigger>    
                    </div>
                </div>

                {/* Comprehensive statistics of the selected use case - also applies to larger samples */}
                <StatisticsModeledUCs data={[selectedUseCase]} />
                             
              </div>
            )}
  
            {activeTab === 'meta' && (
              <div>

                {(!selectedUseCase?.history?.data || selectedUseCase.history.data.length < 2) && (
                    <p>No comparison available. Need at least two states in history.data.</p>
                )}

                {/* General Adoption */}
                <Row style={ { marginBottom:'20px'} }>
                    {/* General Adoption */}
                    <Col md={6}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>General Adoption</h5>
                            <Button variant="outline-primary" className="btn-sm" onClick={() => handleMetaInfoModalMsg('General Fields')}>
                                <i className="bi bi-info-circle"></i>
                            </Button>
                        </div>
                        <div style={chartContainerStyle}>
                            <GeneralFieldsAdoptionChart
                                oldData={oldState}
                                newData={newState}
                            />
                        </div>
                    </Col>
                    {/* Scenario Adoption */}
                    <Col md={6}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Scenario Adoption Chart</h5>
                            <Button variant="outline-primary" className="btn-sm" disabled>
                                <i className="bi bi-info-circle"></i>
                            </Button>
                        </div>
                        <div style={chartContainerStyle}>
                            <ScenarioAdoptionChart
                                oldData={oldState}
                                newData={newState}
                            />
                        </div>
                    </Col>
                </Row>

                {/* Scenario and Condition Adoption */} 
                <Row style={ { marginBottom:'20px'} }>
                    <Col md={6}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Condition Adoption Chart</h5>
                            <Button variant="outline-primary" className="btn-sm" disabled>
                                <i className="bi bi-info-circle"></i>
                            </Button>
                        </div>
                        <div style={chartContainerStyle}>
                            <ScenarioConditionsAdoptionChart
                                oldData={oldState}
                                newData={newState}
                            />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Action Adoption Chart (Scenarios and Conditions)</h5>
                            <Button variant="outline-primary" className="btn-sm" disabled>
                                <i className="bi bi-info-circle"></i>
                            </Button>
                        </div>
                        <div style={chartContainerStyle}>
                            <ScenarioConditionCombinedChart
                                oldData={oldState}
                                newData={newState}
                            />
                        </div>
                    </Col>
                </Row>

                {/* Actor and component Adoption */}
                <Row style={ { marginBottom:'20px'} }>
                    <Col md={6}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Actor Adoption Chart</h5>
                            <Button variant="outline-primary" className="btn-sm" disabled>
                                <i className="bi bi-info-circle"></i>
                            </Button>
                        </div>
                        <div style={chartContainerStyle}>                                            
                            <ActorAdoptionChart
                                oldData={oldState}
                                newData={newState} 
                            />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Component Adoption Chart</h5>
                            <Button variant="outline-primary" className="btn-sm" disabled>
                                <i className="bi bi-info-circle"></i>
                            </Button>
                        </div>
                        <div style={chartContainerStyle}>
                            <ComponentsAdoptionChart
                                oldData={oldState}
                                newData={newState}
                            />
                        </div>
                    </Col>
                </Row>

                {/* Step-wise adoption */}
                <Row style={ { marginBottom:'20px'} }>
                    {/* Adoption */}
                    <Col md={12}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Stepwise Adoption</h5>
                            <Button variant="outline-primary" className="btn-sm" disabled>
                                <i className="bi bi-info-circle"></i>
                            </Button>
                        </div>
                        <div style={chartContainerStyle}>
                            <StepwiseAdoptionChart 
                                oldData={oldState}
                                newData={newState}
                            />
                        </div>
                    </Col>              
                </Row>

                {/* Accumulated Stepwise adoption */}
                <Row style={ { marginBottom:'20px'} }>
                    <Col md={6}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Accumulated Stepweise Adoption</h5>
                            <Button variant="outline-primary" className="btn-sm" disabled>
                                <i className="bi bi-info-circle"></i>
                            </Button>
                        </div>
                        <div style={chartContainerStyle}>
                            <StepwiseAccumulatedAdoptionChart
                                oldData={oldState}
                                newData={newState}
                            />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Accumulated Stepwise Adoption (Cleaned)</h5>
                            <Button variant="outline-primary" className="btn-sm" disabled>
                                <i className="bi bi-info-circle"></i>
                            </Button>
                        </div>
                        <div style={chartContainerStyle}>
                            <StepwiseAccumulatedAdoptionChartCleaned
                                oldData={oldState}
                                newData={newState}
                            />
                        </div>
                    </Col>
                </Row>



                {/* Accumulated adoption */}
                <Row style={ { marginBottom:'20px'} }>
                    <Col md={6}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Accumulated Adoption</h5>
                            <Button variant="outline-primary" className="btn-sm" disabled>
                                <i className="bi bi-info-circle"></i>
                            </Button>
                        </div>
                        <div style={chartContainerStyle}>
                            <AccumulatedAdoptionChart 
                                oldData={oldState}
                                newData={newState}
                            />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Accumulated Adoption (Normalized)</h5>
                            <Button variant="outline-primary" className="btn-sm" disabled>
                                <i className="bi bi-info-circle"></i>
                            </Button>
                        </div>
                        <div style={chartContainerStyle}>
                            <AccumulatedAdoptionChartCleaned 
                                oldData={oldState}
                                newData={newState}
                            />
                        </div>
                    </Col>
                </Row>


                {/* Use Case Diff - Deep Dive */}
                <Row style={ { marginBottom:'20px'} }>
                    <h5 style={ { marginBottom:'20px'} }>Use Case Diff - Deep Dive</h5>
                    <UseCaseDeepDiffView 
                        oldData={oldState} 
                        newData={newState} 
                    />
                </Row>     

              </div>
            )}

            {/* llm data */}
            {activeTab === 'llm_data' && (
            <div>
                {/* Initial prompt */}
                <Row style={ { marginBottom:'20px'} }>
                    <Col md={12}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Initial Prompt</h5>
                            <Button variant="outline-primary" className="btn-sm" disabled>
                                <i className="bi bi-info-circle"></i>
                            </Button>
                        </div>
                        <div style={chartContainerStyle}>
                            <VisualizeUserPrompt
                                useCaseData={selectedUseCase}
                            />
                        </div>
                    </Col>
                </Row>

                {/* Use Case Insights - Prompt Response */}
                <Row style={ { marginBottom:'20px'} }>
                    <Col md={12}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Initial Prompt Response</h5>
                            <Button variant="outline-primary" className="btn-sm" disabled>
                                <i className="bi bi-info-circle"></i>
                            </Button>
                        </div>
                        <div>
                            <VisualizePromptResponse 
                                useCaseData={selectedUseCase} 
                            />
                        </div>
                    </Col>
                </Row>

                {/* User editing behavior */}
                <Row style={ { marginBottom:'20px'} }>
                    <Col md={12}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Automatic Generated and Processed Prompts & Responses to Model Use Case</h5>
                            <Button variant="outline-primary" className="btn-sm" disabled>
                                <i className="bi bi-info-circle"></i>
                            </Button>
                        </div>
                        <div>
                            <VisualizeAutomaticPromptsResponses 
                                useCaseData={selectedUseCase} 
                            />
                        </div>
                    </Col>
                </Row>
            </div>
            )}

            {activeTab === 'compare' && (
              <div>   
                {/* Use Case Data: compare actor and components */}       
                <Row style={ { marginBottom:'20px'} }>                                
                    <Col md={12}>
                        <div className="d-flex justify-content-between align-items-center" style={ { marginBottom:'15px' } }>
                            <h5>Comparison of Actors & Components</h5>
                            <div>
                                <OverlayTrigger placement="top" overlay={<Tooltip>High-level component overview</Tooltip>}>
                                    <Button variant="outline-primary" className={`btn-sm ${isActorBar ? 'active' : ''}`} style={ { marginLeft:'10px' } } onClick={handleActorBar} disabled={isActorBar}>                            
                                        <i className="bi bi-info-circle"></i>                            
                                    </Button>
                                </OverlayTrigger>    
                            </div>
                        </div>
                        <ActorComponentNetwork actors={selectedUseCase.actors.value.nodes} components={selectedUseCase.components.value} />
                    </Col>
                </Row>

                {/* Use Case Data: compare actor and components */}       
                <Row style={ { marginBottom:'20px'} }>
                    <Col md={12}>
                        <div className="d-flex justify-content-between align-items-center" style={ { marginBottom:'15px' } }>
                            <h5>Assignment of Actors & Components</h5>
                        </div>
                        <Row style={ { marginBottom:'20px'} }>         
                            {/* Actors Section */}   
                            <Col md={6}>         
                                <h5>Actors</h5>         
                                <ActorView nodes={selectedUseCase.actors.value.nodes} expandedCategory={expandedCategory} onToggle={handleToggle} />
                            </Col>
                            {/* Components Section */}  
                            <Col md={6}>     
                                <h5>Components</h5>                           
                                <ComponentView components={selectedUseCase.components.value} expandedCategory={expandedCategory} onToggle={handleToggle} />
                            </Col>
                        </Row>
                    </Col>
                </Row>                
              </div>
            )}

            {/* Version History */}
            {activeTab === 'version' && (
            <div>

                <Row style={ { marginBottom:'20px'} }>
                    {/* Adaptation over time */}
                    <Col md={6}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Adaptation Over Time</h5>
                            <Button variant="outline-primary" className="btn-sm" disabled>
                                <i className="bi bi-info-circle"></i>
                            </Button>
                        </div>
                        <div style={chartContainerStyle}>
                            <AdaptationOverTimeChart 
                                useCaseData={selectedUseCase} 
                            />
                        </div>
                    </Col>
                    {/* Version history */}
                    <Col md={6}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Version History</h5>
                            <Button variant="outline-primary" className="btn-sm" disabled>
                                <i className="bi bi-info-circle"></i>
                            </Button>
                        </div>
                        <div style={chartContainerStyle}>
                            <VersionHistoryTimeline 
                                useCaseData={selectedUseCase} 
                            />
                        </div>                        
                    </Col>
                </Row>      
                
                <Row style={ { marginBottom:'20px'} }>
                    {/* User editing behavior */}
                    <Col md={6}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>User Editing Behavior</h5>
                            <Button variant="outline-primary" className="btn-sm" disabled>
                                <i className="bi bi-info-circle"></i>
                            </Button>
                        </div>
                        <div style={chartContainerStyle}>
                            <UserEditingBehaviorChart 
                                useCaseData={selectedUseCase} 
                            />
                        </div>
                    </Col>
                </Row>
                
            </div>
            )}

            {/* List the raw Data of the selected use case in a hierarchical format for the user to expand / collapse the information (parent/child) */}
            {activeTab === 'raw_data' && (
            <div>
                <Row style={{ marginBottom:'20px' }}>
                    <h5>Raw Data</h5>
                    <Col md={1}></Col>
                    <Col md={4}>                        
                        <p>
                            Creator: <b>{selectedUseCase?.created_by?.value}</b> <br />
                            Date Created: <b>{formatTimestamp(selectedUseCase?.created?.value)}</b> <br />
                            Date Updated: <b>{formatTimestamp(selectedUseCase?.updated?.value)}</b> <br />                            
                        </p>
                    </Col>
                    <Col md={3}>
                        <p>
                            Mode: <b style={ {color:'green'}}>{selectedUseCase?.mode?.value}</b> <br />
                            Creation Mode: <b style={ {color:'red'}}>{selectedUseCase?.creation_mode?.value}</b> <br />
                            Study Step: <b>{selectedUseCase?.study_step?.value}</b> <br />
                        </p>
                    </Col>
                    <Col md={3}>
                        <p>
                            Title: <b>{selectedUseCase?.title?.value}</b> <br />
                            Acronym: <b>{selectedUseCase?.acronym?.value}</b> <br />
                        </p>
                    </Col>
                </Row>

                <GroupedRawData data={selectedUseCase} />
            </div>
            )}

          </Col>
        </Row>

        {/* Modal for meta information */}
        <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Meta Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{metaModalMessages}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setModalShow(false)}>Close</Button>
            </Modal.Footer>
        </Modal>

      </Col>      
    );
};

export const UCInsight = ({ data, selectedUseCase, comparisonData, setModalData, setShowModal, setShowModalMeta, setModalDataMeta,  }) => {

    // State variables for statistics: similar, distinct, overlapping
    const [stats, setStats] = useState({ similar: 0, distinct: 0, overlapping: 0 });
    const [matchingScore, setMatchingScore] = useState(0);
    const [showModalScore, setShowModalScore] = useState(false);    

    // Handling for statistics: score, similar, distinct, overlapping
    useEffect(() => {
        if (selectedUseCase && data.length > 0) {
            const categorizedStats = categorizeUseCases(selectedUseCase, data);
            setStats(categorizedStats);

            const score = calculateMatchingScore(selectedUseCase, data);
            setMatchingScore(score);
        }
        
    }, [selectedUseCase, data]);    
    
    const handleChartHover = (evt, elems) => {
      if (elems.length > 0) {
        evt.native.target.style.cursor = 'pointer';
      } else {
        evt.native.target.style.cursor = 'default';
      }
    };

    const chartContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '300px'
    };

    return (
        <Row style={ { marginTop:'20px'} }>
            {/* Tab for use case general statistics and meta statistics */}
            <TabStatistics
                data={data}
                comparisonData={comparisonData}
                selectedUseCase={selectedUseCase}
                matchingScore={matchingScore}
                setShowModalScore={setShowModalScore}
                stats={stats}
                setModalData={setModalData}
                setShowModal={setShowModal}
                chartContainerStyle={chartContainerStyle}
                handleChartHover={handleChartHover}
                setShowModalMeta={setShowModalMeta}
                setModalDataMeta={setModalDataMeta}
             />            
   
            <MatchingScoreModal show={showModalScore} handleClose={() => setShowModalScore(false)} />                       
        </Row>
    );
}